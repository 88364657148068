import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Row,
  Col,
  Form,
  Divider,
  Input,
  DatePicker,
} from "antd";
import moment from "moment";
import { faCheckCircle, faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelBtn, SaveBtn } from "../../../common/components/form";
import { phoneCodeAndNumberFormatter } from "../../../common/utils/phone-number-helper";
import { IconButtons } from '../../../common/components';
import NominalTypesSelect from '../../../common/components/nominal-type-select';
import actions from '../actions';
// import { DynamicSelect } from "../../../common/components/dynamic-select";
// import { HelperService, OrganisationService } from "../../../services";

const { LinkButton } = IconButtons;

class NominalForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();

    this.state = {
    //   DeviceLinked: null,
      Id: 0,
      ConsumerId: null,
      FirstName: "",
      LastName: "",
      DOB: moment().toString(),
      Address1: "",
      Address2: "",
      City: "",
      PostalCode: "",
      PhoneNumber: "",
      ConfirmationCode: "",
      NominalTypeId: null,
    };
  }

  componentDidUpdate() {
    this.formIK.current.handleReset();
  }

  pivotData = (data) => ({
    Id: data.Id,
    FirstName: data.FirstName,
    LastName: data.LastName,
    DateOfBirth: data.DOB.format("l"),
    Address1: data.Address1,
    Address2: data.Address2,
    City: data.City,
    PostalCode: data.PostalCode,
    PhoneNumber: data.PhoneNumber.replace(/[^\d]/g, ""),
    ConsumerId: data.ConsumerId,   
    NominalTypeId: data.NominalTypeId,
  });

  linkDevice = () => {
    const { ConfirmationCode } = this.formIK.current.values;
    this.props.dispatch(actions.linkDevice(ConfirmationCode, this.setConsumerId));
  }

  setConsumerId = (consumerId) => {
      this.setState({
        ConsumerId: consumerId
      });

      this.formIK.current.setFieldValue("ConsumerId", consumerId);
  }

  handleChange = (e) => {
    const { value, id } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  render() {
    const dateFormat = ["DD/MM/YYYY", "DD/MM/YY"];

    const deviceLinked = (
      <>
        <span style={{color: "#1C5906"}}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className=""
            alt="deviceLinkedError"
          />
        </span>
        <span style={{color: "#1C5906"}}>{` Device Linked Successfully!`}</span>
      </>
    );

    const deviceLinkedError = (
      <>
        <span style={{color: "red"}}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className=""
            alt="deviceLinkedError"
          />
        </span>
        <span style={{color: "red"}}>{` Device code not found, please try again.`}</span>
      </>
    );

    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.props.Id,
          ConsumerId: this.props.ConsumerId || this.state.ConsumerId,
          ConfirmationCode: this.props.ConfirmationCode || this.state.ConfirmationCode,
          FirstName: this.props.FirstName || this.state.FirstName,
          LastName: this.props.LastName || this.state.LastName,
          DOB: moment(this.props.DOB) || this.state.DOB,
          Address1: this.props.Address1 || this.state.Address1,
          Address2: this.props.Address2 || this.state.Address2,
          City: this.props.City || this.state.City,
          PostalCode: this.props.PostalCode || this.state.PostalCode,
          PhoneNumber: this.props.PhoneNumber || this.state.PhoneNumber,
          NominalTypeId: this.props.NominalTypeId || this.state.NominalTypeId,
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          ConsumerId: Yup.number(),
          ConfirmationCode: Yup.string()
            .required("Confirmation Code is required")
            .typeError("Confirmation Code is required"),
          FirstName: Yup.string()
            .required("First Name is required")
            .typeError("First Name is required"),
          LastName: Yup.string()
            .required("Last Name is required")
            .typeError("Last Name is required"),
          DOB: Yup.string()
            .typeError("Date of Birth is required")
            .required("Date of Birth is required"),
          Address1: Yup.string()
            .required("Address 1 is required")
            .typeError("Address 1 is required"),
          Address2: Yup.string(),
          City: Yup.string()
            .required("City is required")
            .typeError("City is required"),
          PostalCode: Yup.string()
            .required("Postal Code is required")
            .typeError("Postal Code is required"),
          PhoneNumber: Yup.string()
            .required("Phone Number is required")
            .typeError("Phone Number is required"),
          NominalTypeId: Yup.number()
            .required("Nominal Type is required")
            .typeError("Nominal Type is required"),
        })}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
        //   handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form
            id="NominalForm"
            onSubmit={handleSubmit}
            onChange={this.handleChange}
          >
            <div>
              <Row>
                <Col span={24}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={24} className="gutter-row">
                      <Row>
                        <Col xs={24} sm={24} md={8}>
                          <span className="required-field">First Name: </span>
                        </Col>
                        <Col xs={24} sm={24} md={16}>
                          <Form.Item
                            validateStatus={
                              errors && errors.FirstName && touched.FirstName
                                ? "error"
                                : "success"
                            }
                            help={
                              errors && errors.FirstName && touched.FirstName
                                ? errors.FirstName
                                : null
                            }
                          >
                            <Input
                              id="FirstName"
                              //   style={{width:"100%"}}
                              onBlur={handleBlur}
                              placeholder="Nominal first name"
                              value={values.FirstName}
                              onChange={(val) => {
                                setFieldValue("FirstName", val.target.value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={8} className="gutter-row">
                          <span className="required-field">Last Name: </span>
                        </Col>
                        <Col xs={24} sm={24} md={16}>
                          <Form.Item
                            validateStatus={
                              errors && errors.LastName && touched.LastName
                                ? "error"
                                : "success"
                            }
                            help={
                              errors && errors.LastName && touched.LastName
                                ? errors.LastName
                                : null
                            }
                          >
                            <Input
                              id="LastName"
                              placeholder="Nominal last name"
                              onBlur={handleBlur}
                              value={values.LastName}
                              onChange={(val) => {
                                setFieldValue("LastName", val.target.value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={8}>
                          <span className="required-field">
                            Date of Birth:{" "}
                          </span>
                        </Col>
                        <Col xs={24} sm={24} md={16}>
                          <Form.Item
                            validateStatus={
                              errors && errors.DOB && touched.DOB
                                ? "error"
                                : "success"
                            }
                            help={
                              errors && errors.DOB && touched.DOB
                                ? errors.DOB
                                : null
                            }
                          >
                            <DatePicker
                              className="p5-form-datepicker-input"
                              value={moment(values.DOB, dateFormat[0])}
                              format={dateFormat}
                              onChange={(val) => {
                                setFieldValue("DOB", val);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={8} className="gutter-row">
                          <span className="required-field">Home Address: </span>
                        </Col>
                        <Col xs={24} sm={24} md={16} className="gutter-row">
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col xs={24} sm={24} md={24} className="gutter-row">
                              <Form.Item
                                validateStatus={
                                  errors && errors.Address1 && touched.Address1
                                    ? "error"
                                    : "success"
                                }
                                help={
                                  errors && errors.Address1 && touched.Address1
                                    ? errors.Address1
                                    : null
                                }
                              >
                                <Input
                                  id="Address1"
                                  onBlur={handleBlur}
                                  value={values.Address1}
                                  placeholder="Address line 1"
                                  onChange={(val) => {
                                    setFieldValue("Address1", val.target.value);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={24} className="gutter-row">
                              <Form.Item
                                validateStatus={
                                  errors && errors.Address2 && touched.Address2
                                    ? "error"
                                    : "success"
                                }
                                help={
                                  errors && errors.Address2 && touched.Address2
                                    ? errors.Address2
                                    : null
                                }
                              >
                                <Input
                                  id="Address2"
                                  //   style={{width:"100%"}}
                                  onBlur={handleBlur}
                                  value={values.Address2}
                                  placeholder="Address line 2"
                                  onChange={(val) => {
                                    setFieldValue("Address2", val.target.value);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={16} className="gutter-row">
                              <Form.Item
                                validateStatus={
                                  errors && errors.City && touched.City
                                    ? "error"
                                    : "success"
                                }
                                help={
                                  errors && errors.City && touched.City
                                    ? errors.City
                                    : null
                                }
                              >
                                <Input
                                  id="City"
                                  onBlur={handleBlur}
                                  value={values.City}
                                  placeholder="Town/City"
                                  onChange={(val) => {
                                    setFieldValue("City", val.target.value);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={24} sm={24} md={8} className="gutter-row">
                              <Form.Item
                                validateStatus={
                                  errors &&
                                  errors.PostalCode &&
                                  touched.PostalCode
                                    ? "error"
                                    : "success"
                                }
                                help={
                                  errors &&
                                  errors.PostalCode &&
                                  touched.PostalCode
                                    ? errors.PostalCode
                                    : null
                                }
                              >
                                <Input
                                  id="PostalCode"
                                  onBlur={handleBlur}
                                  value={values.PostalCode}
                                  placeholder="Postal Code"
                                  onChange={(val) => {
                                    setFieldValue(
                                      "PostalCode",
                                      val.target.value
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={8}>
                          <span className="required-field">Nominal Type:</span>
                        </Col>
                        <Col xs={24} sm={24} md={16}>
                          <Form.Item
                            validateStatus={
                              errors &&
                              errors.NominalTypeId &&
                              touched.NominalTypeId
                                ? "error"
                                : "success"
                            }
                            help={
                              errors &&
                              errors.NominalTypeId &&
                              touched.NominalTypeId
                                ? errors.NominalTypeId
                                : null
                            }
                          >
                            <NominalTypesSelect
                              id="NominalTypeId"
                              name="NominalTypeId"
                              value={values.NominalTypeId}
                              onBlur={handleBlur}
                              onChange={(v) => this.setState({NominalTypeId: v})}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Divider />
                      <Row>
                        <h6>Device Information</h6>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={8} className="gutter-row">
                          <span className="required-field">Phone Number: </span>
                        </Col>
                        <Col xs={24} sm={24} md={16}>
                          <Form.Item
                            validateStatus={
                              errors &&
                              errors.PhoneNumber &&
                              touched.PhoneNumber
                                ? "error"
                                : "success"
                            }
                            help={
                              errors &&
                              errors.PhoneNumber &&
                              touched.PhoneNumber
                                ? errors.PhoneNumber
                                : null
                            }
                          >
                            <Input
                              id="PhoneNumber"
                              placeholder="xxx-xxx-xxxx"
                              onBlur={handleBlur}
                              value={phoneCodeAndNumberFormatter(
                                values.PhoneNumber
                              )}
                              onChange={(val) => {
                                setFieldValue("PhoneNumber", val.target.value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={24} sm={24} md={8}>
                          <span className="required-field">Device Code: </span>
                        </Col>
                        <Col xs={24} sm={24} md={16}>
                          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                            <Col xs={16} sm={16} md={16} className="gutter-row">
                              <Form.Item
                                validateStatus={
                                  errors &&
                                  errors.ConfirmationCode &&
                                  touched.ConfirmationCode
                                    ? "error"
                                    : "success"
                                }
                                help={
                                  errors &&
                                  errors.ConfirmationCode &&
                                  touched.ConfirmationCode
                                    ? errors.ConfirmationCode
                                    : null
                                }
                              >
                                <Input
                                  id="ConfirmationCode"
                                  placeholder="Registered Device Code"
                                  onBlur={handleBlur}
                                  value={values.ConfirmationCode}
                                  onChange={(val) => {
                                    setFieldValue(
                                      "ConfirmationCode",
                                      val.target.value
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col xs={8} sm={8} md={8} className="gutter-row">
                              <LinkButton
                                tooltip="Link Device to Nominal"
                                title="Link Device"
                                onClick={this.linkDevice}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={24} sm={24} md={8} />
                        {this.state.ConsumerId > 0 && (
                          <Col xs={24} sm={24} md={16}>
                            {deviceLinked}
                          </Col>
                        )}
                        {this.state.ConsumerId === 0 && (
                          <Col xs={24} sm={24} md={16}>
                            {deviceLinkedError}
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider plain />
              <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <SaveBtn
                    onClick={handleSubmit}
                    loading={this.props.isLoading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

NominalForm.propTypes = {
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
//   isEdit: PropTypes.bool,
  Id: PropTypes.number,
  ConsumerId: PropTypes.number,
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  DOB: PropTypes.string,
  Address1: PropTypes.string,
  Address2: PropTypes.string,
  City: PropTypes.string,
  PostalCode: PropTypes.string,
  PhoneNumber: PropTypes.string,
  ConfirmationCode: PropTypes.string,
  NominalTypeId: PropTypes.number,
};

NominalForm.defaultProps = {
  dispatch: () => {},
  onCancel: () => {},
//   isEdit: false,
  isLoading: false,
  Id: 0,
  ConsumerId: null,
  FirstName: "",
  LastName: "",
  DOB: moment().toString(),
  Address1: "",
  Address2: "",
  City: "",
  PostalCode: "",
  PhoneNumber: "",
  ConfirmationCode: "",
  NominalTypeId: null,
};

function mapStateToProps() {
    return {};
  }
  
  const connectedNominalForm = connect(mapStateToProps)(NominalForm);
  
  export { connectedNominalForm as default };

// export default NominalForm;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Row, Col, Tooltip, Button } from "antd";
import moment from "moment";
import {
  faEye,
  faCheck,
  faSyncAlt
} from "@fortawesome/pro-regular-svg-icons";
import { faWrench, faExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButtons, MosovoModals } from "../../../common/components";
import { getAlertTypeElement } from "../../../common/utils/alert-settings-helper";
import modalactions from "../../../common/components/mosovo-modals/actions";
import AlertModal from "../../../common/components/alert-modal";
import actions from "../actions";
import AlertService from "../../../services/Alert";
import { NominalService } from "../../../services";
import {
  getColumnSearchProps,
  getAlertGridAlertStateFilter,
  numericSorter,
  dateSorter,
  timeSorter,
  stringSorter,
  booleanSorter,
  // getAlertGridUrgencyFilter,
  getAlertGridAlertTypeFilter,
  INPUT,
  DATE,
  TIME,
  AlertStates,
} from "../../../common/utils/grid-filter-helper";

const { AlertCircleIconButton } = IconButtons;
const { BulkAlertResolveModal } = MosovoModals;

class AlertHistoryGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      AlertId: 0,
      AlertModalVisible: false,
      TypeIcon: null,
      alerts: props.alerts,
      pendingAlerts: [],
      numUpdates: 0,
      // isShowExclusionZones: false,
    };
  }

  componentDidMount() {
    if(this.props.Nominal.Id) {
      this.getNominalAlerts();
      setTimeout(() => this.getAlertsAsync(this.props.Nominal.Id), 60000)
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.Nominal ? prevProps.Nominal.Id !== this.props.Nominal.Id : false) {
      this.getNominalAlerts();
      setTimeout(() => this.getAlertsAsync(this.props.Nominal.Id), 60000)
    }
  }

  getAlertsAsync(id) {
    NominalService.getNominalAlerts(id).then(
      (response) => {
        this.setPendingAlertsData(response.data);
        setTimeout(() => this.getAlertsAsync(id), 60000);
      });
  }

  openBulkAlertResolveModal = () => {
    this.props.dispatch(modalactions.openBulkAlertModal());
  }

  setPendingAlertsData = (data) => {
    let updates = 0;
    if (data && data.length > this.state.alerts.length) {
      updates = data.length - this.state.alerts.length;
    }
    this.setState((prevState) => ({
      ...prevState,
      pendingAlerts: data,
      numUpdates: updates
    }));
  }

  getNominalAlerts = () => {
    const { dispatch } = this.props;
    dispatch(
      actions.getNominalAlerts(this.props.Nominal.Id, this.setNominalAlerts)
    );
    this.props.refreshGrid();
  };

  setNominalAlerts = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      alerts: data,
    }));
  };

  onRefreshClick = () => {
    const pending = this.state.pendingAlerts;
    if (this.state.numUpdates) {
      this.setState({
        alerts: pending,
        pendingAlerts: [],
        numUpdates: 0
      });
    } else {
      this.getNominalAlerts();
    }
  }

  onClickRow = (record) => {
    AlertService.getAlertDetail(record.Id).then((result) => {
      // const alert = result.data;
      // let ezs = [];
      // if (alert && alert.AlertType.Id === 1) ezs = [alert.ExclusionZone];
      // else if (alert && alert.AlertType.Id === 3) ezs = [alert.CurfewSettings];
      // let isShow = false;
      // if (ezs.length > 0) isShow = true;
      // this.updateExclusionZone(ezs, () => {
        this.setState({
          Alert: result.data,
          // isShowExclusionZones: isShow,
          AlertId: record.Id,
          AlertModalVisible: true,
          TypeIcon: getAlertTypeElement(record, this.props.active),
         });
      // })
    });
  };

  resolveAlert = () => {
    this.getNominalAlerts();
    this.closeModal();
  };

  closeModal = () => {
    this.setState({
      AlertId: 0,
      AlertModalVisible: false,
    });
  };

  onRowBind = (record) => {
    return {
      onClick: () => this.onClickRow(record),
    };
  };

  ChangeIndex = (currentIndex, changeAmount) => {
    const newIndex = currentIndex + changeAmount;
    const alert = this.state.alerts[newIndex];
    this.onClickRow(alert);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  // updateExclusionZone(ezs, callback) {
  //   this.setState({
  //     ExclusionZones: ezs
  //   }, callback)
  // }

  render() {
    const gridColumns = [
      {
        dataIndex: "IsUrgent",
        key: "Urgency",
        width: "2%",
        align: 'center',
        sorter: (a, b) => {return booleanSorter(a, b, "IsUrgent");},
        // ...getAlertGridUrgencyFilter(),
        render: (text, record) =>
          record.IsUrgent ? (
            <>
              <FontAwesomeIcon
                icon={faExclamation}
                style={{
                  color: this.props.active ? "#960000" : "#909090",
                }}
                alt="IsUrgent"
              />
            </>
          ) : null,
      },
      {
        dataIndex: "AlertType",
        key: "AlertType",
        width: "5%",
        align: 'center',
        sorter: (a, b) => {return numericSorter(a.AlertType, b.AlertType, "Id");},
        ...getAlertGridAlertTypeFilter(),
        render: (text, record) => <>{getAlertTypeElement(record, this.props.active)}</>,
      },
      {
        title: "Date",
        dataIndex: "CreatedDate",
        key: "Date",
        width: "30%",
        sorter: (a, b) => {return dateSorter(a, b, "CreatedDate");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "CreatedDate", title: "Date", componentType: DATE}),
        render: (text, record) =>
          record.CreatedDate !== null ? (
            <>
              <span
                style={{
                  color: this.props.active
                    ? "rgba(0, 0, 0, 0.85)"
                    : "#909090",
                  fontStyle: this.props.active ? "normal" : "italic",
                }}
              >
                {moment(record.CreatedDate).local().format("DD-MM-YYYY")}
              </span>
            </>
          ) : null,
      },
      {
        title: "Time",
        dataIndex: "CreatedDate",
        key: "Time",
        width: "30%",
        sorter: (a, b) => {return timeSorter(moment(a.CreatedDate), moment(b.CreatedDate));},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "CreatedDate", title: "Time", componentType: TIME}),
        render: (text, record) =>
          record.CreatedDate !== null ? (
            <>
              <span
                style={{
                  color: this.props.active
                    ? "rgba(0, 0, 0, 0.85)"
                    : "#909090",
                  fontStyle: this.props.active ? "normal" : "italic",
                }}
              >
                {moment(record.CreatedDate).local().format("h:mm:ss A")}
              </span>
            </>
          ) : null,
      },
      {
        title: "Reviewer",
        dataIndex: "Reviewer",
        key: "Reviewer",
        width: "10%",
        sorter: (a, b) => {return stringSorter(a.Reviewer, b.Reviewer, "Name");},
        ...getColumnSearchProps({ dataIndex: "Name", title: "Reviewer", componentType: INPUT, objectName: "Reviewer"}),
        render: (text, record) =>
          record.Reviewer !== null && record.Reviewer.Name !== null ? (
            <>
              <span
                className={
                  this.props.active ? "alert-reviewer" : "alert-reviewer-suspended"
                }
              >
                {record.Reviewer.FirstName} {record.Reviewer.LastName}
              </span>
            </>
          ) : (
            <>
              <span
                className={
                  this.props.active
                    ? "alert-reviewer-none"
                    : "alert-reviewer-none-suspended"
                }
              >
                None
              </span>
            </>
          ),
      },
      {
        title: "Status",
        dataIndex: "AlertState",
        key: "AlertState",
        width: "40%",
        sorter: (a, b) => {return numericSorter(a.AlertState, b.AlertState, "Id")},
        ...getAlertGridAlertStateFilter(),
        render: (text, record) =>
          record.AlertState.Name === AlertStates.Resolved.Name ? (
            <>
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  color: this.props.active ? "#636363" : "#909090",
                }}
                alt="resolved"
              />
              <span
                style={{
                  fontWeight: 300,
                  color: this.props.active ? "#636363" : "#909090",
                  fontStyle: this.props.active ? "normal" : "italic",
                }}
              >
                {record.AlertState.Name}
              </span>
            </>
          ) : (
            [
              record.AlertState.Name === AlertStates.InReview.Name ? (
                <>
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{
                      color: this.props.active ? "#000000" : "#909090",
                    }}
                    alt="in review"
                  />
                  <span
                    style={{
                      fontWeight: 300,
                      color: this.props.active ? "#000000" : "#909090",
                      fontStyle: this.props.active ? "normal" : "italic",
                    }}
                  >
                    {record.AlertState.Name}
                  </span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={faWrench}
                    style={{
                      color: this.props.active ? "#960000" : "#909090",
                    }}
                    alt="action required"
                  />
                  <span
                    style={{
                      fontWeight: 300,
                      color: this.props.active ? "#960000" : "#909090",
                      fontStyle: this.props.active ? "normal" : "italic",
                    }}
                  >
                    {record.AlertState.Name}
                  </span>
                </>
              ),
            ]
          ),
      },
    ];

    const alertIdList = this.state.alerts
      ? this.state.alerts.map((a) => a.Id)
      : [];
    const alertState = this.state.alerts
      ? this.state.alerts.find((a) => a.Id === this.state.AlertId)?.AlertState
          .Id
      : {};
    const { selectedRowKeys } = this.state;
    // eslint-disable-next-line no-unused-vars
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
  
    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
          <Col flex="auto">
            <span className="mosovo-table-header">Alerts</span>
          </Col>
          <Col>
            <Tooltip placement="topRight" title={this.state.numUpdates > 0 ? `Refresh (${this.state.numUpdates}) new alets` : "Refresh alert details"}>
              <Button
                type="text"
                shape="circle"
                size="middle"
                style={{ color: "#243B53" }}
                onClick={this.onRefreshClick}
                className="mosovo-btn-effect"
              >
                <FontAwesomeIcon
                  icon={faSyncAlt}
                  color={this.state.numUpdates > 0 ? "#EF4E4E" : "#243B53"}
                  style={{ display: "inline" }}
                />
              </Button>
            </Tooltip>
          </Col>
          <Col style={{textAlign: "center"}}>
            <AlertCircleIconButton 
              onClick={this.openBulkAlertResolveModal}
              isDisabled={this.state.selectedRowKeys.length === 0}
              tooltipText="Resolve selected alerts"
            />                
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              loading={this.props.loading}
              dataSource={this.state.alerts}
              columns={gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
              onRow={(record) => this.onRowBind(record)}
            />
          </Col>
        </Row>
        <AlertModal
          showAlertModal={this.state.AlertModalVisible}
          Alert={this.state.Alert}
          AlertId={this.state.AlertId}
          AlertIdList={alertIdList}
          TypeIcon={this.state.TypeIcon}
          closeModal={this.closeModal}
          resolveAlert={this.resolveAlert}
          ChangeIndex={this.ChangeIndex}
          AlertStateId={alertState}
          Nominal={this.props.Nominal}
          // ExclusionZones={this.state.ExclusionZones}
          // isShowExclusionZones={this.state.isShowExclusionZones}
        />
        <BulkAlertResolveModal 
          selectedAlerts={this.state.selectedRowKeys}
          refreshGrid={this.getNominalAlerts}
        />
      </div>
    );
  }
}

AlertHistoryGrid.propTypes = {
  dispatch: PropTypes.func,
  alerts: PropTypes.arrayOf(PropTypes.shape({})),
  refreshGrid: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  Nominal: PropTypes.shape(),
  active: PropTypes.bool,
};

AlertHistoryGrid.defaultProps = {
  dispatch: () => {},
  alerts: [],
  loading: false,
  Nominal: {},
  active: true,
};

function mapStateToProps(state) {
  const { loading, Nominal } = state.Nominal;
  return {
    loading,
    Nominal,
  };
}

const connectedAlertHistoryGrid = connect(mapStateToProps)(AlertHistoryGrid);

export { connectedAlertHistoryGrid as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Spin } from "antd";
import { AlertHistoryGrid, NominalAlertSettingsGrid } from '../grids';

class NominalAlerts extends React.Component {
  render() {
    return (
      <>
        <Spin spinning={this.props.loading}>
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col sm={24} md={24} lg={14} className="gutter-row">
              <AlertHistoryGrid alerts={this.props.alerts} refreshGrid={this.props.refreshGrid} active={this.props.active} dispatch={this.props.dispatch} />
            </Col>
            <Col sm={24} md={24} lg={10} className="gutter-row">
              <NominalAlertSettingsGrid alertSettings={this.props.alertSettings} refreshGrid={this.props.refreshGrid} active={this.props.active} />
            </Col>
          </Row>
        </Spin>
      </>
    );
  }
}

NominalAlerts.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  refreshGrid: PropTypes.func.isRequired,
  alerts: PropTypes.arrayOf(PropTypes.shape({})),
  alertSettings: PropTypes.arrayOf(PropTypes.shape({})),
  active: PropTypes.bool,
};

NominalAlerts.defaultProps = {
  dispatch: () => {},
  loading: false,
  alerts: [],
  alertSettings: [],
  active: true,
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;

  return { loading };
}

const connectedNominalAlerts = connect(mapStateToProps)(NominalAlerts);

export { connectedNominalAlerts as default };

import React from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { Row, Col, Form, Input, Divider, Button } from "antd";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { phoneCodeAndNumberFormatter } from "../../../common/utils/phone-number-helper";
import { CancelBtn, SaveBtn } from "../../../common/components/form";

class ProfileBasicInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditable: false,
    };
    this.formIK = React.createRef();
  }

  componentDidUpdate() {
    this.formIK.current.handleReset();
  }

  pivotData = (data) => ({
    Id: this.props.Id,
    FirstName: data.FirstName,
    LastName: data.LastName,
    PhoneNumber: data.PhoneNumber,
    CollarNumber: data.CollarNumber,
    EmailAddress: data.EmailAddress,
    EmailConfirmed: this.props.EmailConfirmed,
    ReceivedTraining: true,
    LawEnforcementAgencyId: this.props.ForceId,
    LawEnforcementAreaId: this.props.AreaId,
    LawEnforcementTeamId: this.props.TeamId,
  });

  edit = () => {
    this.setState((prevState) => ({ isEditable: !prevState.isEditable }));
  };

  checkFormErrors = (errors) => {
    return Object.keys(errors).length > 0;
  };

  render() {
    const editBasicInfoButton = () => (
      <Button
        style={{ backgroundColor: "white", color: "#243b53", margin: "5px" }}
        onClick={this.edit}
      >
        <FontAwesomeIcon
          icon={faEdit}
          id="EditNominalBasicInfo"
          className=""
          alt="basic"
        />
      </Button>
    );

    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          FirstName: this.props.FirstName || "",
          LastName: this.props.LastName || "",
          CollarNumber: this.props.CollarNumber || null,
          EmailAddress: this.props.EmailAddress || "",
          PhoneNumber: this.props.PhoneNumber || "",
        }}
        validationSchema={Yup.object().shape({
          FirstName: Yup.string()
            .typeError("First Name is required")
            .required("First Name is required"),
          LastName: Yup.string()
            .typeError("Last Name is required")
            .required("Last Name is required"),
          CollarNumber: Yup.number(),
          EmailAddress: Yup.string()
            .typeError("Email Address is required")
            .required("Email Address is required"),
          PhoneNumber: Yup.string()
            .required("Phone Number is required")
            .typeError("Phone Number is required")
            .test(
              "phoneNumberValidation",
              "Phone number must be 10 or 11 characters in length",
              (val) => val && (val.length === 10 || val.length === 11)
            ),
        })}
        // onChange={(values) => this.handleChange(values)}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form
            id="ProfileBasicInfoForm"
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <div>
              <Row>
                <Col span={24}>
                  <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
                    <Col span={12} className="gutter-row">
                      <h6>Basic Information</h6>
                    </Col>
                    <Col span={12}>
                      <Row justify="end">
                        <Col>
                          <span>{editBasicInfoButton(this.props.Id)}</span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Divider />
                  <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
                    <Col xs={24} sm={24} md={8}>
                      <span className="required-field">First Name</span>
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                      <Form.Item
                        validateStatus={
                          errors && errors.FirstName && touched.FirstName
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.FirstName && touched.FirstName
                            ? errors.FirstName
                            : null
                        }
                      >
                        {this.state.isEditable ? (
                          <Input
                            id="FirstName"
                            onBlur={handleBlur}
                            value={values.FirstName}
                            onChange={(val) => {
                              setFieldValue("First Name", val.target.value);
                            }}
                          />
                        ) : (
                          <span
                            style={{
                              color: "rgba(0, 0, 0, 0.85)",
                              fontStyle: "normal",
                            }}
                          >
                            {`${this.props.FirstName || ""}`}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
                    <Col xs={24} sm={24} md={8}>
                      <span className="required-field">Last Name</span>
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                      <Form.Item
                        validateStatus={
                          errors && errors.LastName && touched.LastName
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.LastName && touched.LastName
                            ? errors.LastName
                            : null
                        }
                      >
                        {this.state.isEditable ? (
                          <Input
                            id="LastName"
                            onBlur={handleBlur}
                            value={values.LastName}
                            onChange={(val) => {
                              setFieldValue("Last Name", val.target.value);
                            }}
                          />
                        ) : (
                          <span
                            style={{
                              color: "rgba(0, 0, 0, 0.85)",
                              fontStyle: "normal",
                            }}
                          >
                            {`${this.props.LastName || ""}`}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
                    <Col xs={24} sm={24} md={8}>
                      <span className="required-field">Collar Number</span>
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                      <Form.Item
                        validateStatus={
                          errors && errors.CollarNumber && touched.CollarNumber
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.CollarNumber && touched.CollarNumber
                            ? errors.CollarNumber
                            : null
                        }
                      >
                        {this.state.isEditable ? (
                          <Input
                            id="CollarNumber"
                            onBlur={handleBlur}
                            value={values.CollarNumber}
                            onChange={(val) => {
                              setFieldValue("CollarNumber", val.target.value);
                            }}
                          />
                        ) : (
                          <span
                            style={{
                              color: "rgba(0, 0, 0, 0.85)",
                              fontStyle: "normal",
                            }}
                          >
                            {`${this.props.CollarNumber || ""}`}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
                    <Col xs={24} sm={24} md={8}>
                      <span className="required-field">Email Address:</span>
                    </Col>
                    <Col xs={24} sm={20} md={16}>
                      <Form.Item
                        validateStatus={
                          errors && errors.EmailAddress && touched.EmailAddress
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.EmailAddress && touched.EmailAddress
                            ? errors.EmailAddress
                            : null
                        }
                      >
                        <span className="sameLine">
                          {this.state.isEditable ? (
                            <Input
                              id="EmailAddress"
                              onBlur={handleBlur}
                              value={values.EmailAddress}
                              onChange={(val) => {
                                setFieldValue("EmailAddress", val.target.value);
                              }}
                            />
                          ) : (
                            <span
                              style={{
                                color: "rgba(0, 0, 0, 0.85)",
                                fontStyle: "normal",
                              }}
                            >
                              {`${this.props.EmailAddress || ""}`}
                            </span>
                          )}
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
                    <Col xs={24} sm={24} md={8}>
                      <span className="required-field">Phone Number: </span>
                    </Col>
                    <Col xs={24} sm={24} md={16}>
                      <Form.Item
                        validateStatus={
                          errors && errors.PhoneNumber && touched.PhoneNumber
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.PhoneNumber && touched.PhoneNumber
                            ? errors.PhoneNumber
                            : null
                        }
                      >
                        {this.state.isEditable ? (
                          <Input
                            id="PhoneNumber"
                            placeholder="xxx-xxx-xxxx"
                            onBlur={handleBlur}
                            value={phoneCodeAndNumberFormatter(
                              values.PhoneNumber
                            )}
                            onChange={(val) => {
                              setFieldValue("PhoneNumber", val.target.value);
                            }}
                          />
                        ) : (
                          <span
                            style={{
                              color: "rgba(0, 0, 0, 0.85)",
                              fontStyle: "normal",
                            }}
                          >
                            {`${this.props.PhoneNumber || ""}`}
                          </span>
                        )}
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider plain />
              <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                {this.state.isEditable && (
                  <Col xs={24} sm={8} md={6} className="gutter-row">
                    <SaveBtn
                      onClick={() => this.props.onSubmit(this.pivotData(values))}
                      loading={this.props.isLoading}
                      isDisabled={this.checkFormErrors(errors)}
                    />
                  </Col>
                )}
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

ProfileBasicInfoForm.propTypes = {
  onSubmit: PropTypes.func, // .isRequired,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  PhoneNumber: PropTypes.string,
  CollarNumber: PropTypes.number,
  EmailAddress: PropTypes.string,
  Id: PropTypes.number,
  EmailConfirmed: PropTypes.bool,
  ForceId: PropTypes.number,
  AreaId: PropTypes.number,
  TeamId: PropTypes.number,
};

ProfileBasicInfoForm.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  isLoading: false,
  FirstName: null,
  LastName: null,
  PhoneNumber: null,
  CollarNumber: null,
  EmailAddress: null,
  Id: null,
  EmailConfirmed: true,
  ForceId: null,
  AreaId: null,
  TeamId: null,
};

export default ProfileBasicInfoForm;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Row, Col } from "antd";
import moment from "moment";
import { IconButtons } from "../../../common/components";
import {
  getColumnSearchProps,
  dateSorter,
  stringSorter,
  timeSorter,
  INPUT,
  DATE,
  TIME,
} from "../../../common/utils/grid-filter-helper";
import actions from "../actions";

const { SearchBox } = IconButtons;

class CommunicationsGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      totalCount: 0,
      communications: [],
      pagination: {
        position: 'bottom',
        showSizeChanger: true,
        current: 1,
        pageSizeOptions: [10, 20, 50, 100],
        pageSize: 10,
        total: 1,
        hasError: false,
      },
    };
  }

  componentDidMount() {
    if(this.props.Nominal.Id) {
      this.getCommunicationsData();
    }
  }

  handleTableChange = (pagination) => {
    const totalRecords = this.state.totalCount;
    this.setState(prevState => ({
      ...prevState,
      loading: true,
      pagination: prevState.pagination !== false
        ? {
          ...prevState.pagination,
          current: prevState.pagination
            && prevState.pagination.pageSize !== null
            && prevState.pagination.pageSize !== pagination.pageSize
            ? 1 : pagination.current,
          pageSize: pagination.pageSize,
          total: totalRecords,
        } : false,
      }), () => {
        this.getCommunicationsData(pagination.pageSize * (pagination.current - 1), pagination.pageSize);
      }
    );
  }

  getCommunicationsData = (skip, take, callback) => {
    const { dispatch } = this.props;
    dispatch(
      actions.getMyNominalCommunicationsData(
        this.props.Nominal.Id,
        skip,
        take,
        this.setCommunicationData,
        callback
      )
    );
  };

  setCommunicationData = (data, callback) => {
    this.setState((prevState) => ({
      ...prevState,
      communications: data.comms,
      totalCount: data.totalCount,
      pagination: prevState.pagination !== false
        ? {
          ...prevState.pagination,
          total: data.totalCount,
        } : false,
    }));
    if (callback) {
      callback(data.comms);
    }
  };

  setTotalPages = (data) => {
    if (data) {
      if (data.length / this.state.pagination.pageSize !== this.state.pagination.total) {
        const pagObj = this.state.pagination;
        this.setState((prevState) => ({
          pagination: {
            ...prevState.pagination,
            current: 1,
            total: data.length / pagObj.pageSize,
          },
        }));
        }
    }
  }

  render() {
    const gridColumns = [
      {
        title: "Date",
        dataIndex: "CreatedDate",
        key: "Date",
        width: "10%",
        sorter: (a, b) => {return dateSorter(a, b, "CreatedDate");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "CreatedDate", title: "Date", componentType: DATE}),
        render: (text, record) =>
          record.CreatedDate !== null ? (
            <>
              <span
                style={{
                  color: this.props.active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: this.props.active ? "normal" : "italic",
                }}
              >
                {moment(record.CreatedDate).local().format("DD-MM-YYYY")}
              </span>
            </>
          ) : null,
      },
      {
        title: "Time",
        dataIndex: "CreatedDate",
        key: "Time",
        width: "10%",
        sorter: (a, b) => {return timeSorter(moment(a.CreatedDate), moment(b.CreatedDate));},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "CreatedDate", title: "Time", componentType: TIME}),
        render: (text, record) =>
          record.CreatedDate !== null ? (
            <>
              <span
                style={{
                  color: this.props.active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: this.props.active ? "normal" : "italic",
                }}
              >
                {moment(record.CreatedDate).local().format("h:mm:ss A")}
              </span>
            </>
          ) : null,
      },
      {
        title: "Message Detail",
        dataIndex: "MessageDetail",
        key: "MessageDetail",
        width: "80%",
        sorter: (a, b) => {return stringSorter(a, b, "MessageDetails");},
        ...getColumnSearchProps({ dataIndex: "MessageDetails", title: "Message Detail", componentType: INPUT}),
        render: (text, record) =>
          record.MessageDetails !== null ? (
            <>
              <span
                style={{
                  color: this.props.active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: this.props.active ? "normal" : "italic",
                }}
              >
                {record.MessageDetails}
              </span>
            </>
          ) : null,
      },
    ];

    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
          <Col xs={24} sm={24} md={8} lg={10}>
            <span className="mosovo-table-header">Communications</span>
          </Col>
          <Col xs={24} md={14} lg={14}>
            <Row justify="end" gutter={{ xs: 8, sm: 16, md: 10 }}>
              <Col xs={19} sm={20} md={18} lg={12} className="gutter-row">
                <SearchBox
                  placeholder="Search"
                  allowClear
                  onSearch={this.getCommunicationsData}
                  minText={3}
                  loading={this.props.loading}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <Table
              loading={this.props.loading}
              dataSource={this.state.communications}
              columns={gridColumns}
              onChange={this.handleTableChange}
              scroll={{ x: true }}
              rowKey="Id"
              pagination={this.state.pagination}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

CommunicationsGrid.propTypes = {
  dispatch: PropTypes.func,
  Nominal: PropTypes.shape(),
  loading: PropTypes.bool,
  active: PropTypes.bool,
};

CommunicationsGrid.defaultProps = {
  dispatch: () => {},
  Nominal: {},
  loading: false,
  active: true,
};

function mapStateToProps(state) {
  const { loading, Nominal } = state.Nominal;
  return {
    loading,
    Nominal
  };
}

const connectedCommunicationsGrid = connect(mapStateToProps)(
  CommunicationsGrid
);

export { connectedCommunicationsGrid as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin } from "antd";
import { ProfileBasicInfoForm } from "../forms";
import userActions from "../../Users/actions";
import accountActions from "../../account/actions";
import organisationActions from "../../Organisation/actions";
import { ProfileTeamInfo } from "../components";
import ProfileActivityLogs from "../components/ProfileActivityLogs";
import { ProfileManagementContextProvider } from "../contexts/ProfileManagementContext";

class ProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChangePassword: false,
    };
  }

  CloseModal = () => {
    this.setState({
      isChangePassword: false,
    });
    this.props.dispatch(userActions.closeUserModal());
  };

  saveBasicInfo = (data) => {
    this.props.dispatch(userActions.updateUser(data, this.getCurrentUser));
  };

  saveTeamInfo = (data) => {
    this.props.dispatch(
      organisationActions.updateTeam(data, this.props.userUpdateCallback)
    );
  };

  getCurrentUser = () => {
    this.props.dispatch(accountActions.GetCurrentUser(this.CloseModal));
  };

  setChangePassword = (isChangePassword) => {
    this.setState({
      isChangePassword,
    });
  };

  render() {
    return (
      <>
        <Modal
          title="My Profile"
          visible={this.props.showProfileModal}
          footer={null}
          width={1000}
          height={1500}
          onCancel={this.CloseModal}
        >
          <div className="mosovo-content-container">
            <Row>
              {!this.state.isChangePassword && (
                <Col xs={24} sm={24} md={24} lg={24}>
                  <Row gutter={{ xs: 6, sm: 12, md: 8 }}>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div
                        className="nominal-detail-container"
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.25)",
                          borderRadius: "2px",
                        }}
                      >
                        <Spin spinning={this.props.loading}>
                          <ProfileBasicInfoForm
                            onCancel={this.CloseModal}
                            onSubmit={this.saveBasicInfo}
                            {...this.props.currentUser}
                          />
                        </Spin>
                      </div>
                    </Col>
                    <Col xs={12} sm={12} md={12} lg={12}>
                      <div
                        className="nominal-detail-container"
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.25)",
                          borderRadius: "2px",
                        }}
                      >
                        <Spin spinning={this.props.loading}>
                          <ProfileTeamInfo
                            onCancel={this.CloseModal}
                            onSubmit={this.saveTeamInfo}
                            {...this.props.userData}
                          />
                        </Spin>
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 6, sm: 12, md: 8 }}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <div
                        className="nominal-detail-container"
                        style={{
                          border: "1px solid rgba(0, 0, 0, 0.25)",
                          borderRadius: "2px",
                        }}
                      >
                        <Spin spinning={this.props.loading}>
                          <ProfileManagementContextProvider>
                            <ProfileActivityLogs />
                          </ProfileManagementContextProvider>
                        </Spin>
                      </div>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

ProfileModal.propTypes = {
  dispatch: PropTypes.func,
  userUpdateCallback: PropTypes.func,
  loading: PropTypes.bool,
  showProfileModal: PropTypes.bool,
  currentUser: PropTypes.shape({}),
  userData: PropTypes.shape({}),
};

ProfileModal.defaultProps = {
  dispatch: () => {},
  userUpdateCallback: () => {},
  loading: false,
  showProfileModal: false,
  currentUser: {},
  userData: {},
};

function mapStateToProps(state) {
  const { currentUser } = state.Auth;
  const { currentPage } = state.Navigation;
  const { showProfileModal } = state.Users.UserModal;
  return {
    currentUser,
    currentPage,
    showProfileModal,
  };
}

const connectedProfileModal = connect(mapStateToProps)(ProfileModal);

export { connectedProfileModal as default };

import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "antd";
import { faPhoneAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class PhoneAltIconButton extends React.Component {
  render() {
    const phoneAltIcon = (
      <span>
        <FontAwesomeIcon
          icon={faPhoneAlt}
          id={this.props.id}
          className=""
          alt="phone-alt"
        />
      </span>
    );

    return (
      <>
        <Tooltip placement="topRight" title={this.props.tooltip}>
          <Button
            id={this.props.id}
            disabled={this.props.isDisabled}
            icon={phoneAltIcon}
            style={{
              backgroundColor: this.props.isDisabled ? "#F5F5F5" : "white",
              border: this.props.isDisabled ? "none" : "1px solid #243B53",
              color: this.props.isDisabled ? "#868E96" : "#243B53",
              float: "right",
            }}
            onClick={this.props.onClick}
          >
            {` ${this.props.title}`}
          </Button>
        </Tooltip>
      </>
    );
  }
}

PhoneAltIconButton.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  isDisabled: PropTypes.bool,
};

PhoneAltIconButton.defaultProps = {
  onClick: () => {},
  id: "",
  title: null,
  tooltip: null,
  isDisabled: false,
};

export default PhoneAltIconButton;

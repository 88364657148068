import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Layout, Menu } from "antd";
import { withAuth0 } from "@auth0/auth0-react";
import { NavIcons } from '../icon-buttons'
import navigationActions from "../navigation/action";
import { hasUserRight, roles } from "../../utils/role-right-helper";

const { OrganisationIcon, UserIcon, DashboardIcon, HubIcon, MapViewIcon, MyNominalsIcon, TeamNominalsIcon, AdminNominalsIcon, InvestigateIcon } = NavIcons;

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class SideNav extends React.PureComponent {
  onCollapse = (collapsed) => {
    this.props.dispatch(navigationActions.collapseSideNav(collapsed));
  };

  setMobileNav = (isMobile) => {
    const NavSize = isMobile ? 0 : 85;
    this.props.dispatch(navigationActions.setNavSize(NavSize));
  };

  handleSelect = (eventKey) => {
    const { dispatch } = this.props;
    switch (eventKey.key) {
      case "dashboard":
        dispatch(push("/"));
        break;
      case "Organisations":
        dispatch(push("/organisations"));
        break;
      case "Users":
          dispatch(push("/users"));
          break;
      case "Licenses":
        dispatch(push("/allocations"));
        break; 
      case "MyNominals":
        dispatch(push("/mynominals"));
        break; 
      case "MapView":
        dispatch(push("/map"));
        break;
      case "TeamNominals":
        dispatch(push("/teamnominals"));
        break; 
      case "ForceNominals":
        dispatch(push("/forcenominals"));
        break;
      case "AreaNominals":
        dispatch(push("/areanominals"));
        break;
      case "Investigate":
        dispatch(push("/investigate"));
        break;
      default:
        console.log("default side nav");
        // dispatch(push("/login"));
    }
  };

  render() {
    const { Sider } = Layout;
    // const { SubMenu } = Menu;

    let hasOrganisations = false;
    let hasUsers = false;
    let hasForceNominals = false;
    let hasDashboard = false;
    let hasLicenses = false;
    let canViewAsOM = false;
    let canViewAsAreaAdmin = false;

    if (this.props.auth0 && this.props.auth0.isAuthenticated) {
      const userRole = this.props.auth0.user['https://mosovo-primary//roles'];
      hasOrganisations = hasUserRight(roles.SystemAdmin, userRole);
      hasUsers = hasUserRight(roles.SystemAdmin, userRole) || hasUserRight(roles.ForceAdmin, userRole) || hasUserRight(roles.TeamAdmin, userRole) || hasUserRight(roles.AreaAdmin, userRole) || hasUserRight(roles.TeamAdmin, userRole);
      hasForceNominals = hasUserRight(roles.ForceAdmin, userRole);
      hasDashboard = hasUserRight(roles.SystemAdmin, userRole) || hasUserRight(roles.ForceAdmin, userRole);
      hasLicenses = hasUserRight(roles.SystemAdmin, userRole);
      canViewAsOM = hasUserRight(roles.ForceAdmin, userRole) || hasUserRight(roles.AreaAdmin, userRole) || hasUserRight(roles.TeamAdmin, userRole) || hasUserRight(roles.TeamUser, userRole);
      canViewAsAreaAdmin = hasUserRight(roles.ForceAdmin, userRole) || hasUserRight(roles.AreaAdmin, userRole);
    }
  
    return (
      <>
        <Sider
          collapsible
          collapsed={this.props.isCollapsed}
          onCollapse={this.onCollapse}
          breakpoint="md"
          collapsedWidth={this.props.collapsedSize}
          onBreakpoint={broken => {
            this.setMobileNav(broken);
          }}
          zeroWidthTriggerStyle={{color:"white", backgroundColor: '#243b53', top: "-60px"}}
        >
          <Menu
            defaultSelectedKeys={["dashboard"]}
            mode="inline"
            selectedKeys={[this.props.currentPage]}
            onClick={(k) => this.handleSelect(k)}
          >
            { hasDashboard
              && (
              <Menu.Item key="dashboard" icon={DashboardIcon}>
                <span>Dashboard</span>
              </Menu.Item>
            )}  
            { hasOrganisations && (
              <Menu.Item key="Organisations" icon={OrganisationIcon}>
                <span>Organisations</span>
              </Menu.Item>
            )}
            { hasUsers && (
              <Menu.Item key="Users" icon={UserIcon}>
                <span>Users</span>
              </Menu.Item>
            )}  
            { hasLicenses && (
              <Menu.Item key="Licenses" icon={HubIcon}>
                <span>Licenses</span>
              </Menu.Item>
            )}  
            { canViewAsOM && (
              <Menu.Item key="MyNominals" icon={MyNominalsIcon}>
                <span>My Nominals</span>
              </Menu.Item> 
            )}  
            { canViewAsOM && (
              <Menu.Item key="MapView" icon={MapViewIcon}>
                <span>Map View</span>
              </Menu.Item> 
            )}  
            { canViewAsOM && (
              <Menu.Item key="TeamNominals" icon={TeamNominalsIcon}>
                <span>Team Nominals</span>
              </Menu.Item> 
            )}
            { hasForceNominals && (
              <Menu.Item key="ForceNominals" icon={AdminNominalsIcon}>
                <span>Force Nominals</span>
              </Menu.Item> 
            )} 
            { canViewAsAreaAdmin && (
              <Menu.Item key="AreaNominals" icon={AdminNominalsIcon}>
                <span>Area Nominals</span>
              </Menu.Item> 
            )}
            { canViewAsOM && (
              <Menu.Item key="Investigate" icon={InvestigateIcon}>
                <span>Investigate</span>
              </Menu.Item> 
            )}                  
          </Menu>
        </Sider>
      </>
    );
  }
}

SideNav.propTypes = {
  // dispatch: PropTypes.func,
  currentPage: PropTypes.string,
  auth0: PropTypes.shape(),
};

SideNav.defaultProps = {
  // dispatch: () => {},
  currentPage: "",
  auth0: {},
};

function mapStateToProps(state) {
  const { currentPage, isCollapsed, collapsedSize } = state.Navigation;
  return {
    currentPage,
    isCollapsed,
    collapsedSize
  };
}

const connectedSideNav = connect(mapStateToProps)(SideNav);

export default withAuth0(connectedSideNav);

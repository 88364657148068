import apiRequest from "../common/utils/apiRequest";

function getUserList(querystring) {
  return apiRequest()
    .get(`/odata/UserOData?${querystring}`)
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getRoleList() {
  return apiRequest()
    .get(`/odata/RoleListOData?`)
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function createUser(data) {
  return apiRequest()
    .post("/api/user/Create", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getUserById(id) {
  return apiRequest()
    .get(`/api/user/${id}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateUser(id, data) {
  return apiRequest()
    .put(`/api/user/${id}`, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteUser(id) {
  return apiRequest()
    .delete(`/api/user/${id}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function validateUser(userId) {
  return apiRequest()
    .get(`/api/user/Validate/${userId}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getUserDetails(id) {
  return apiRequest()
    .get(`/api/user/GetUserDetails/${id}`)
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getOMUserList() {
  return apiRequest()
    .get("/api/user/om/list")
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getOdataOMList(querystring) {
  return apiRequest()
    .get(`/odata/UserOData?${querystring}`)
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function transferOMBulk(data) {
  return apiRequest()
    .post("/api/user/Transfer/Bulk", data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export {
  getUserList,
  createUser,
  getRoleList,
  getUserById,
  updateUser,
  deleteUser,
  validateUser,
  getUserDetails,
  getOMUserList,
  getOdataOMList,
  transferOMBulk,
};

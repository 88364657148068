import apiRequest from "../common/utils/apiRequest";

function getList(queryString) {
  return apiRequest()
    .get(`odata/RegistrationAllocationOData?$count=true&${queryString}`)
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function getNominalAllocationList(data) {
  return apiRequest()
    .post("/api/RegistrationAllocation/UsedLicenses", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function createAllocation(data) {
  return apiRequest()
    .post("/api/RegistrationAllocation", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getAllocationById(id) {
  return apiRequest()
    .get(`/api/RegistrationAllocation/${id}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateAllocation(data) {
  return apiRequest()
    .put("/api/RegistrationAllocation", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function exportLicenseReport(data) {
  return apiRequest()
    .post("/api/RegistrationAllocation/ExportUsedLicences", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export default {
  getList,
  getNominalAllocationList,
  createAllocation,
  getAllocationById,
  updateAllocation,
  exportLicenseReport,
};

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin, Button, Input, notification } from "antd";
import {
  faComments,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NominalService from "../../../services/Nominal";

const { TextArea } = Input;

class MessageModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Message: '',
    }
  }

  modalTitle = () => {
    if(!this.props.Nominal)
    {
      return null;
    }
    return (
      <span>
        <span className="AlertModalTitle">{`  Message: ${this.props.Nominal.FirstName} ${this.props.Nominal.LastName}`}</span>
      </span>
    )
  }

  sendMessage = () => {
    const ContactNominal = {
      Id: this.props.Nominal.Id,
      IsLocationRequest: false,
      Message: this.state.Message,
    }

    NominalService.contactNominal(ContactNominal).then(() => {
      notification.success({
        message: 'Message Sent',
        description:
          'The message has been sent to the Nominal.',
      });
      this.props.closeModal();

      this.setState({
        Message: '',
      });
    });
  }

  createFooter = () => {
    const footer = (
      <Row>
        <Col span={12} className="DisplayLeft" />
        <Col span={12}>
          <Button
            onClick={this.props.closeModal}
          >
            Close
          </Button>
          <Button
            onClick={this.sendMessage}
            disabled={!this.state.Message}
          >
            <FontAwesomeIcon
              icon={faComments}
              style={{
                color: "#585757",
                marginRight: 5,
              }}
            />
            SendMessage
          </Button>
        </Col>
      </Row>
    )
    return footer;
  }

  render() {
    if(!this.props.Nominal) {
      return null;
    }
    return (
      <Modal
        title={this.modalTitle()}
        className="MessageModal"
        visible={this.props.showMessageModal}
        onCancel={this.props.closeModal}
        footer={this.createFooter()}
        width={500}
        height={1500}
        destroyOnClose
      >
        <div>
          <Row>
            <Col span={24}>
              <Spin spinning={this.props.loading} />
              <span className="FullWidth">Send a message:</span>
              <TextArea
                value={this.state.Message}
                rows={4}
                placeholder="Type your message"
                onChange={(e) => this.setState({
                  Message: e.target.value
                })}
              />
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

MessageModal.propTypes = {
  // dispatch: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  //   showEditModal: PropTypes.bool,
  showMessageModal: PropTypes.bool,
  loading: PropTypes.bool,
  // Name: PropTypes.string,
  Nominal: PropTypes.shape(),
};

MessageModal.defaultProps = {
  // dispatch: () => {},
  //   showEditModal: false,
  // Name: '',
  showMessageModal: false,
  loading: false,
  Nominal: null,
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;
  return { loading };
}

const connectedMessageModal = connect(mapStateToProps)(MessageModal);

export { connectedMessageModal as default };

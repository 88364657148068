import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Input, Divider } from "antd";
import { CancelBtn, SaveBtn } from "../../../common/components/form";
import { DynamicSelect } from '../../../common/components/dynamic-select';
import { OrganisationService } from '../../../services';

class AreaForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
  }

  componentDidUpdate() {
    this.formIK.current.handleReset();    
  }

  // componentWillUnmount() {
  //   console.log('unmounting');
  // }

  pivotData = (data) => ({
    Id: data.Id,
    Name: data.Name,
    LawEnforcementAgencyId: data.ForceId,
  });

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.props.Id || 0,
          Name: this.props.Name || "",
          ForceId: this.props.defaultForce || this.props.LawEnforcementAgencyId,
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          Name: Yup.string()
            .typeError("Area is required")
            .required("Area is required"),
          ForceId: Yup.number()
            .typeError("Force is required")
            .required("Force is required"),
        })}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form id="AreaForm" onSubmit={handleSubmit} onChange={handleChange}>
            <div>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col xs={24} sm={4} md={6}>
                      <span className="required-field">Force</span>
                    </Col>
                    <Col xs={24} sm={20} md={16}>
                      <Form.Item
                        validateStatus={
                          errors && errors.ForceId && touched.ForceId
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.ForceId && touched.ForceId
                            ? errors.ForceId
                            : null
                        }
                      >
                        <DynamicSelect
                          hasNone={false}
                          className=""
                          field="ForcePicker"
                          onBlur={handleBlur}
                          getData={OrganisationService.getForceGridOdata}
                          displayFieldName="Name"
                          valueFieldName="Id"
                          value={values.ForceId}
                          isDisabled={this.props.isEdit || this.props.defaultForce !== null}
                          onChange={(val) => {
                            setFieldValue("ForceId", val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                <Col span={24}>
                  <Row>
                    <Col xs={24} sm={4} md={6}>
                      <span className="required-field">Area Name</span>
                    </Col>
                    <Col xs={24} sm={20} md={16}>
                      <Form.Item
                        validateStatus={
                          errors && errors.Name && touched.Name
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.Name && touched.Name
                            ? errors.Name
                            : null
                        }
                      >
                        <Input
                          id="Name"
                          onBlur={handleBlur}
                          value={values.Name}
                          onChange={(val) => {
                            setFieldValue("Name", val.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider plain />
              <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <SaveBtn
                    onClick={handleSubmit}
                    loading={this.props.isLoading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

AreaForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
  Id: PropTypes.number,
  Name: PropTypes.string,
  LawEnforcementAgencyId: PropTypes.number,
  defaultForce: PropTypes.number,
};

AreaForm.defaultProps = {
  onCancel: () => {},
  isLoading: false,
  isEdit: false,
  Id: 0,
  Name: null,
  LawEnforcementAgencyId: null,
  defaultForce: null,
};

export default AreaForm;

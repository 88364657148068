/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import { Formik } from "formik";
import { Row, Col, Form, Radio, TimePicker, Input, Checkbox, Divider } from "antd";
import moment from "moment";
import * as Yup from "yup";
// import actions from "../actions";
import { faClock, faTreeChristmas } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelBtn, SaveBtn } from "../../../common/components/form";

import Map from "../../MapView/components/Map";
import SearchLocationInput from "../../../common/components/search-location-input";
// import { CancelBtn, SaveBtn } from "../../../common/components/form";
// import { propTypes } from "react-bootstrap/esm/Image";
import "../../../common/components/alert-modal/Alert.scss";

const isHomeLocationValues = {
  Home: 1,
  Other: 2,
};

class CurfewSettingsForm extends React.Component {
  constructor(props) {
    super(props);
    let lat = null;
    let lng = null;
    if (props.NominalCurfewSettings) {
      lat = props.NominalCurfewSettings.Latitude;
      lng = props.NominalCurfewSettings.Longitude;
    }

    this.state = {
      exclusionZone: [
        {
          Latitude: lat,
          Longitude: lng,
          Radius: 100,
        },
      ],
      map: null,
      mapRef: null,
      centerPosition: {
        lat: null,
        lng: null
      }
    };
    if (!lat || !lng) {
      this.getPositionFromAddressParts(props.NominalCurfewSettings.Address1, props.NominalCurfewSettings.Address2, props.NominalCurfewSettings.City, props.NominalCurfewSettings.PostalCode, 100, "Meters");
    }
    this.formIK = React.createRef();
  }

  componentDidMount() {
    this.autocompleteInputRef = React.createRef();
    const exclusionZones = [...this.state.exclusionZone];
    const exclusionZone = exclusionZones[0];
    if (!exclusionZone.Latitude || !exclusionZone.Longitude) {
      this.getPositionFromAddressParts(this.props.NominalCurfewSettings.Address1, this.props.NominalCurfewSettings.Address2, this.props.NominalCurfewSettings.City, this.props.NominalCurfewSettings.PostalCode, 100, "Meters");
    }
  }

  componentDidUpdate(previousProps) {
    if (
      previousProps.NominalCurfewSettings !==
        this.props.NominalCurfewSettings &&
      (this.props.NominalCurfewSettings.Latitude !==
        previousProps.NominalCurfewSettings.Latitude ||
        this.props.NominalCurfewSettings.Longitude !==
          previousProps.NominalCurfewSettings.Longitude)
    ) {
      const position = {
        lat: this.props.NominalCurfewSettings.Latitude,
        lng: this.props.NominalCurfewSettings.Longitude,
        radius: this.props.NominalCurfewSettings.Radius,
      };
      this.updateLocation(position);
    }
  }

  mapRefSetter = (gmap) => {
    if (!this.state.mapRef) {
      this.setState((prevState) => ({
        ...prevState,
        mapRef: gmap,
        map: gmap,
      }));
    }
  };

  updateLocation = (position) => {
    const exclusionZones = [...this.state.exclusionZone];
    const exclusionZone = exclusionZones[0];
    if (position.lat && position.lat !== exclusionZone.Latitude) {
      exclusionZone.Latitude = position.lat;
    }
    if (position.lng && position.lng !== exclusionZone.Longitude) {
      exclusionZone.Longitude = position.lng;
    }
    exclusionZone.Radius = parseInt(position.radius, 10);
    exclusionZones[0] = exclusionZone;
    this.setState(
      (prevState) => ({
        ...prevState,
        exclusionZone: exclusionZones,
        centerPosition: { lat: position.lat, lng: position.lng }
      }),
      this.forceUpdate()
    );
    if (
      this.state.mapRef &&
      this.state.mapRef.state &&
      this.state.mapRef.state.map &&
      position.lat &&
      position.lng
    ) {
      this.state.mapRef.state.map.panTo({
        lat: position.lat,
        lng: position.lng
      });
      // this.state.mapRef.state.map.setZoom(15);
    }
    // if (this.mapRef) {
    //   this.mapRef.panTo({ lat: position.lat, lng: position.lng });
    // }
  };

  updateRadius = (radius, units) => {
    if (this.state.exclusionZone && this.state.exclusionZone.length > 0) {
      const exclusionZone = this.state.exclusionZone[0];
      if (exclusionZone) {
        const convertedRadius = parseInt(radius, 10) * this.getUnitToMetersConversion(units);
        exclusionZone.Radius = convertedRadius;
        this.setState({
          exclusionZone: [exclusionZone],
        });
      }
    }
  }

  pivotData = (data) => ({
    Latitude: this.state.exclusionZone[0].Latitude,
    Longitude: this.state.exclusionZone[0].Longitude,
    StartTime: moment(data.StartTime, "h:mm A").utc(),
    EndTime: moment(data.EndTime, "h:mm A").utc(),
    Address1: data.Address1,
    Address2: data.Address2,
    City: data.City,
    PostalCode: data.PostalCode,
    Radius: this.state.exclusionZone[0].Radius,
    CreateNominalAlertSettings: {
      IsNotifyEmail: data.NotifyByEmail,
      IsNotifyText: data.NotifyByTextMessage,
      IsNotifyTeamEmail: data.NotifyByTeamEmail,
      IsNotifyNominal: data.NotifyNominal,
      IsUrgent: data.IsUrgent,
      IsAutomaticPushNotification: true,
      IsActive: true,
      NominalId: this.props.Nominal.Id,
    },
  });

  // eslint-disable-next-line no-unused-vars
  autocompleteAddressSelection = (result, status, callback) => {
    if (result) {
      let streetNumber = "";
      let route = "";
      let city = "";
      // let state = "";
      let postalCode = "";
      for (let i = 0; i < result.address_components.length; i += 1) {
        for (let j = 0; j < result.address_components[i].types.length; j += 1) {
          switch (result.address_components[i].types[j]) {
            case "street_number":
              streetNumber = result.address_components[i].long_name;
              break;
            case "route":
              route = result.address_components[i].long_name;
              break;
            case "locality":
            case "postal_town":
              city = result.address_components[i].long_name;
              break;
            // case "administrative_area_level_1":
            //   state = result.address_components[i].long_name;
            //   break;
            //   case "country":
            //     country = response.results[0].address_components[i].long_name;
            //     break;
            case "postal_code":
              postalCode = result.address_components[i].long_name;
              break;
            default:
              break;
          }
        }
      }
      this.formIK.current.setFieldValue("Address1", `${streetNumber} ${route}`);
      callback(`${streetNumber} ${route}`);
      this.formIK.current.setFieldValue("City", city);
      this.formIK.current.setFieldValue("PostalCode", postalCode);
      const position = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
        radius: this.formIK.current.values.Radius,
      };
      this.updateLocation(position);
    }
  };

  getUnitToMetersConversion = (unit) => {
    let meters;

    switch (unit) {
      case "Meters":
        meters = 1;
        break;
      case "Feet":
        meters = 0.3048;
        break;
      default:
    }

    return meters;
  };

  getPositionFromAddress = async (location, radius, unit) => {
    let position = {
      lat: null,
      lng: null,
      radius: 0,
    };

    await Geocode.fromAddress(location)
      .then((response) => {
        position = response.results[0].geometry.location;
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.log(error));

    position.radius = radius * this.getUnitToMetersConversion(unit);
    this.updateLocation(position);
  };

  getPositionFromAddressDisplayName = async (result, radius, unit) => {
    if (result && result.display_name) {
      this.getPositionFromAddress(result.display_name, radius, unit);
    }
  };

  getPositionFromAddressParts = async (
    address1,
    address2,
    city,
    postalCode,
    radius,
    unit
  ) => {
    if (address1 && city && postalCode) {
      let fullAddress = address1;
      if (address2) {
        fullAddress = `${fullAddress} ${address2}`;
      }
      fullAddress = `${fullAddress}, ${city} ${postalCode}`;
      await this.getPositionFromAddress(fullAddress, radius, unit);
    }
  };

  addressSelection = (result, callback) => {
    if (result) {
      let streetNumber = "";
      let route = "";
      let city = "";
      // let state = "";
      let postalCode = "";
      // **** google geocoding ****
      // for (let i = 0; i < result.address_components.length; i += 1) {
      //   for (let j = 0; j < result.address_components[i].types.length; j += 1) {
      //     switch (result.address_components[i].types[j]) {
      //       case "street_number":
      //         streetNumber = result.address_components[i].long_name;
      //         break;
      //       case "route":
      //         route = result.address_components[i].long_name;
      //         break;
      //       case "locality":
      //       case "postal_town":
      //         city = result.address_components[i].long_name;
      //         break;
      //       case "postal_code":
      //         postalCode = result.address_components[i].long_name;
      //         break;
      //       default:
      //         break;
      //     }
      //   }
      // }
      // **** end google geocoding ****
      // **** nominatix geocoding ****
      if (result.address) {
        streetNumber = result.address.house_number;
        route = result.address.road;
        if (result.address.city) {
          city = result.address.city;
        } else if (result.address.village) {
          city = result.address.village;
        } else if (result.address.town) {
          city = result.address.town;
        } else if (result.address.hamlet) {
          city = result.address.hamlet;
        } else {
          city = "";
        }
        postalCode = result.address.postcode;
      }
      let address1 = `${route}`;
      if (streetNumber) {
        address1 = `${streetNumber} ${route}`;
      }
      // **** end nominatix geocoding ****
      if (callback) {
        callback({
          Address1: address1,
          City: city,
          PostalCode: postalCode,
        });
      }
    }
  };

  compareToHomeAddress = () => {
    // if (this.props.CurfewSettings && this.props.CurfewSettings)
    if (!this.props.NominalCurfewSettings) return true;
    if (
      !this.props.NominalCurfewSettings.Address1 &&
      !this.props.NominalCurfewSettings.City &&
      !this.props.NominalCurfewSettings.PostalCode
    )
      return true;
    if (
      this.props.NominalCurfewSettings.Address1 !== this.props.Nominal.Address1
    )
      return false;
    if (this.props.NominalCurfewSettings.City !== this.props.Nominal.City)
      return false;
    if (
      this.props.NominalCurfewSettings.PostalCode !==
      this.props.Nominal.PostalCode
    )
      return false;
    return true;
  };

  updateIsHomeLocation(val) {
    this.formIK.current.setFieldValue("IsHomeLocation", val);
    if (val === isHomeLocationValues.Home) {
      this.getPositionFromAddressParts(this.props.Nominal.Address1, this.props.Nominal.Address2, this.props.Nominal.City, this.props.Nominal.PostalCode, 
        this.formIK.current.values.Radius, this.formIK.current.values.Unit);
      this.formIK.current.setFieldValue(
        "Address1",
        this.props.Nominal.Address1
      );
      this.formIK.current.setFieldValue(
        "Address2",
        this.props.Nominal.Address2
      );
      this.formIK.current.setFieldValue(
        "City",
        this.props.Nominal.City
      );
      this.formIK.current.setFieldValue(
        "PostalCode",
        this.props.Nominal.PostalCode
      );
      this.autocompleteInputRef.current.setQuery(
        this.props.Nominal.Address1
      );
    } else {
      this.getPositionFromAddressParts(this.formIK.current.values.Address1, this.formIK.current.values.Address2, this.formIK.current.values.City, this.formIK.current.values.PostalCode,
        this.formIK.current.values.Radius, this.formIK.current.values.Unit);
    }
  }

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          StartTime:
            this.props.NominalCurfewSettings.StartTime ? moment(this.props.NominalCurfewSettings.StartTime).local() : null,
          EndTime:
            this.props.NominalCurfewSettings.EndTime ? moment(this.props.NominalCurfewSettings.EndTime).local() : null,
          IsHomeLocation: this.compareToHomeAddress()
            ? isHomeLocationValues.Home
            : isHomeLocationValues.Other,
          Address1:
            this.props.NominalCurfewSettings.Address1 ||
            this.props.Nominal.Address1,
          Address2:
            this.props.NominalCurfewSettings.Address2 ||
            this.props.Nominal.Address2,
          City:
            this.props.NominalCurfewSettings.City || this.props.Nominal.City,
          PostalCode:
            this.props.NominalCurfewSettings.PostalCode ||
            this.props.Nominal.PostalCode,
          Radius: this.props.NominalCurfewSettings.Radius || "100",
          Unit: "Meters",
          NotifyNominal: this.props.IsNotifyNominal || false,
          NotifyByEmail: this.props.IsNotifyEmail || false,
          NotifyByTextMessage: this.props.IsNotifyText || false,
          NotifyByTeamEmail: this.props.IsNotifyTeamEmail || false,
          IsUrgent: this.props.IsUrgent || false,
        }}
        validationSchema={Yup.object().shape({
          StartTime: Yup.date()
            .typeError("Start Time is required")
            .required("Start Time is required"),
          EndTime: Yup.date()
            .typeError("End Time is required")
            .required("End Time is required"),
          IsHomeLocation: Yup.number()
            .typeError("Curfew Location is required")
            .required("Curfew Location is required"),
          Address1: Yup.string().when("IsHomeLocation", {
            is: isHomeLocationValues.Other,
            then: Yup.string()
              .required("Address 1 is required")
              .typeError("Address 1 is required"),
          }),
          Address2: Yup.string(),
          City: Yup.string().when("IsHomeLocation", {
            is: isHomeLocationValues.Other,
            then: Yup.string()
              .required("City is required")
              .typeError("City is required"),
          }),
          PostalCode: Yup.string().when("IsHomeLocation", {
            is: isHomeLocationValues.Other,
            then: Yup.string()
              .required("Postal Code is required")
              .typeError("Postal Code is required"),
          }),
          Radius: Yup.string()
            .required("Radius is required")
            .typeError("Radius is required"),
          Unit: Yup.string(),
        })}
        onSubmit={(values) => {
          if (
            this.state.exclusionZone[0].Latitude !== null &&
            this.state.exclusionZone[0].Longitude !== null
          ) {
            this.props.onSubmit(this.pivotData(values));
          } else {
            document.getElementById("validAddressMessage").style.display =
              "block";
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} onChange={handleChange}>
            <Row>
              <Col span={12}>
                <Row gutter={[16, 48]} justify="center">
                  <Col span={4}>
                    <span
                      className="anticon"
                      role="img"
                      style={{ textAlign: "center" }}
                    >
                      <FontAwesomeIcon
                        icon={faClock}
                        id="CurfewInfo"
                        style={{
                          color: "#585757",
                          paddingRight: "5px",
                          fontSize: "24px",
                        }}
                        alt="Curfew"
                      />
                    </span>
                  </Col>
                  <Col span={20}>
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        marginTop: "5%",
                        marginBottom: 30,
                        color: "#585757",
                      }}
                    >
                      Alert me when Nominal location away from saved home
                      address has been detected during designated curfew time.
                    </span>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col span={24}>
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        marginTop: "5%",
                        marginBottom: 30,
                        color: "#000000",
                      }}
                    >
                      Designated curfew time period:
                    </span>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={12} sm={12} md={4} className="gutter-row">
                    <span className="required-field">From: </span>
                  </Col>
                  <Col xs={12} sm={12} md={8}>
                    <Form.Item
                      validateStatus={
                        errors && errors.StartTime && touched.StartTime
                          ? "error"
                          : "success"
                      }
                      help={
                        errors && errors.StartTime && touched.StartTime
                          ? errors.StartTime
                          : null
                      }
                    >
                      <TimePicker
                        // use12Hours
                        id="StartTime"
                        onBlur={handleBlur}
                        value={values.StartTime}
                        format="HH:mm"
                        minuteStep={15}
                        onChange={time => setFieldValue('StartTime', time)}
                        inputReadOnly
                      />
                      {/* <Input
                        type="time"
                        id="StartTime"
                        name="StartTime"
                        value={values.StartTime}
                        onChange={(val) => {
                          setFieldValue("StartTime", moment(val.timeStamp));
                        }}
                        onBlur={handleBlur}
                      /> */}
                    </Form.Item>
                  </Col>
                  <Col
                    xs={12}
                    sm={12}
                    md={4}
                    className="gutter-row"
                    style={{ paddingLeft: "5px" }}
                  >
                    <span className="required-field">To: </span>
                  </Col>
                  <Col xs={12} sm={12} md={8}>
                    <Form.Item
                      validateStatus={
                        errors && errors.EndTime && touched.EndTime
                          ? "error"
                          : "success"
                      }
                      help={
                        errors && errors.EndTime && touched.EndTime
                          ? errors.EndTime
                          : null
                      }
                    >
                      <TimePicker
                        // use12Hours
                        id="EndTime"
                        onBlur={handleBlur}
                        value={values.EndTime}
                        format="HH:mm"
                        minuteStep={15}
                        onChange={time => setFieldValue('EndTime', time)}
                        inputReadOnly
                      />
                      {/* <Input
                        type="time"
                        id="EndTime"
                        name="EndTime"
                        value={values.EndTime}
                        onChange={(val) => {
                          setFieldValue("EndTime", val.target.value);
                        }}
                        onBlur={handleBlur}
                      /> */}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <span
                      className="required-field"
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        marginTop: "5%",
                        marginBottom: 30,
                        color: "#000000",
                      }}
                    >
                      Curfew location:
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Item
                      validateStatus={
                        errors && errors.EndTime && touched.EndTime
                          ? "error"
                          : "success"
                      }
                      help={
                        errors && errors.EndTime && touched.EndTime
                          ? errors.EndTime
                          : null
                      }
                    >
                      <Radio.Group
                        onChange={(val) => {
                          this.updateIsHomeLocation(val.target.value);
                        }}
                        value={values.IsHomeLocation}
                      >
                        <Radio
                          name="IsHomeLocation"
                          id="radioHome"
                          value={isHomeLocationValues.Home}
                        >
                          Home Address
                        </Radio>
                        <Radio
                          name="IsHomeLocation"
                          id="radioOther"
                          value={isHomeLocationValues.Other}
                        >
                          Other Address
                        </Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                {values.IsHomeLocation === isHomeLocationValues.Other && (
                  <Row>
                    <Col xs={24} sm={24} md={16} className="gutter-row">
                      <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col xs={24} sm={24} md={24} className="gutter-row">
                          <Form.Item
                            validateStatus={
                              errors && errors.Address1 && touched.Address1
                                ? "error"
                                : "success"
                            }
                            help={
                              errors && errors.Address1 && touched.Address1
                                ? errors.Address1
                                : null
                            }
                          >
                            <SearchLocationInput
                              ref={this.autocompleteInputRef}
                              onSelect={this.autocompleteAddressSelection}
                              hidden={
                                values.IsHomeLocation ===
                                isHomeLocationValues.Other
                              }
                              id="Address1"
                              placeholder="Address line 1"
                              value={values.Address1}
                              name="Address1"
                              onChange={(val) => {
                                this.getPositionFromAddressParts(
                                  val,
                                  values.Address2,
                                  values.City,
                                  values.PostalCode,
                                  values.Radius,
                                  values.Unit
                                );
                              }}
                              isDisabled={
                                !values.IsHomeLocation ===
                                isHomeLocationValues.Other
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} className="gutter-row">
                          <Form.Item
                            validateStatus={
                              errors && errors.Address2 && touched.Address2
                                ? "error"
                                : "success"
                            }
                            help={
                              errors && errors.Address2 && touched.Address2
                                ? errors.Address2
                                : null
                            }
                          >
                            <Input
                              id="Address2"
                              //   style={{width:"100%"}}
                              onBlur={handleBlur}
                              value={values.Address2}
                              placeholder="Address line 2"
                              onChange={(val) => {
                                setFieldValue("Address2", val.target.value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={16} className="gutter-row">
                          <Form.Item
                            validateStatus={
                              errors && errors.City && touched.City
                                ? "error"
                                : "success"
                            }
                            help={
                              errors && errors.City && touched.City
                                ? errors.City
                                : null
                            }
                          >
                            <Input
                              id="City"
                              onBlur={handleBlur}
                              value={values.City}
                              placeholder="Town/City"
                              onChange={(val) => {
                                setFieldValue("City", val.target.value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24} sm={24} md={8} className="gutter-row">
                          <Form.Item
                            validateStatus={
                              errors && errors.PostalCode && touched.PostalCode
                                ? "error"
                                : "success"
                            }
                            help={
                              errors && errors.PostalCode && touched.PostalCode
                                ? errors.PostalCode
                                : null
                            }
                          >
                            <Input
                              id="PostalCode"
                              onBlur={handleBlur}
                              value={values.PostalCode}
                              placeholder="Postal Code"
                              onChange={(val) => {
                                setFieldValue("PostalCode", val.target.value);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col xs={12} sm={12} md={4} className="gutter-row">
                    <span className="required-field">Radius: </span>
                  </Col>
                  <Col xs={12} sm={12} md={8}>
                    <Form.Item
                      style={{ width: "100%" }}
                      validateStatus={
                        errors && errors.Radius && touched.Radius
                          ? "error"
                          : "success"
                      }
                      help={
                        errors && errors.Radius && touched.Radius
                          ? errors.Location
                          : null
                      }
                    >
                      <Input
                        id="Radius"
                        placeholder="Radius"
                        onBlur={handleBlur}
                        value={values.Radius}
                        onChange={(val) => {
                          setFieldValue("Radius", val.target.value);
                          this.updateRadius(val.target.value, values.Unit);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <select
                      onChange={() => {
                        const unit = document.getElementById("radiusUnit")
                          .value;

                        setFieldValue("Unit", unit);
                        this.updateRadius(values.Radius, unit);
                      }}
                      id="radiusUnit"
                      style={{ width: "30%", marginLeft: "10%" }}
                    >
                      {" "}
                      <option value="Meters">Meters</option>
                      <option value="Feet">Feet</option>
                    </select>
                  </Col>
                </Row>
                <Divider plain />
                <Form.Item>
                  <Row>
                    <Col>
                      <Checkbox
                        style={{
                          marginRight: 5,
                        }}
                        id="NotifyNominal"
                        name="NotifyNominal"
                        onBlur={handleBlur}
                        checked={values.NotifyNominal}
                        onChange={(val) => {
                          setFieldValue("NotifyNominal", val.target.checked);
                        }}
                      />
                      <span>Notify nominal when curfew breach detected</span>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item style={{ width: "100%" }}>
                  <Row>
                    <Col xs={12} sm={12} md={6} className="gutter-row">
                      <span className="required-field">Notify me by: </span>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Checkbox
                        style={{
                          marginRight: 5,
                        }}
                        id="NotifyByEmail"
                        onBlur={handleBlur}
                        checked={values.NotifyByEmail}
                        onChange={(val) => {
                          setFieldValue("NotifyByEmail", val.target.value);
                        }}
                      />
                      <span>
                        <label htmlFor="email">Email</label>
                      </span>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Checkbox
                        style={{
                          display: "inline-block",
                          marginRight: 5,
                        }}
                        id="NotifyByTextMessage"
                        onBlur={handleBlur}
                        checked={values.NotifyByTextMessage}
                        onChange={(val) => {
                          setFieldValue(
                            "NotifyByTextMessage",
                            val.target.value
                          );
                        }}
                      />
                      <label htmlFor="textMessage">Text Message</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={6} />
                    <Col xs={12} sm={12} md={8}>
                      <Checkbox
                        style={{
                          display: "inline-block",
                          marginRight: 5,
                        }}
                        id="NotifyByTeamEmail"
                        onBlur={handleBlur}
                        checked={values.NotifyByTeamEmail}
                        onChange={(val) => {
                          setFieldValue("NotifyByTeamEmail", val.target.value);
                        }}
                      />
                      <label htmlFor="teamEmail">Team Email</label>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col xs={12} sm={12} md={8}>
                      <Checkbox
                        style={{ display: "inline-block", marginRight: 5 }}
                        id="IsUrgent"
                        onBlur={handleBlur}
                        checked={values.IsUrgent}
                        onChange={(val) => {
                          setFieldValue("IsUrgent", val.target.value);
                        }}
                      />
                      <label htmlFor="flagAsUrgent"> Flag as Urgent</label>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Map
                  centerPosition={this.state.centerPosition}
                  zoomLevel={15}
                  mapRefSetter={this.mapRefSetter}
                  exclusionZones={this.state.exclusionZone}
                  setExclusionZoneLocation={(result) => {
                    const position = {
                      lat: result.lat,
                      lng: result.lon,
                      radius: this.state.exclusionZone[0].Radius,
                    };
                    this.updateLocation(position);
                    this.formIK.current.setFieldValue(
                      "IsHomeLocation",
                      isHomeLocationValues.Other
                    );
                    this.addressSelection(result, (addressParts) => {
                      this.formIK.current.setFieldValue(
                        "Address1",
                        addressParts.Address1
                      );
                      this.formIK.current.setFieldValue(
                        "City",
                        addressParts.City
                      );
                      this.formIK.current.setFieldValue(
                        "PostalCode",
                        addressParts.PostalCode
                      );
                      this.autocompleteInputRef.current.setQuery(
                        addressParts.Address1
                      );
                    });
                  }}
                />
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
              <Col xs={24} sm={8} md={6} className="gutter-row">
                <CancelBtn onClick={this.props.onCancel} />
              </Col>
              <Col xs={24} sm={8} md={6} className="gutter-row">
                <SaveBtn
                  onClick={handleSubmit}
                  isDisabled={Object.keys(errors).length > 0}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

CurfewSettingsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  Nominal: PropTypes.shape(),
  NominalCurfewSettings: PropTypes.shape({
    StartTime: PropTypes.string,
    EndTime: PropTypes.string,
    Latitude: PropTypes.number,
    Longitude: PropTypes.number,
    Address1: PropTypes.string,
    Address2: PropTypes.string,
    City: PropTypes.string,
    PostalCode: PropTypes.string,
    Radius: PropTypes.number,
  }),
  IsNotifyNominal: PropTypes.bool,
  IsNotifyEmail: PropTypes.bool,
  IsNotifyText: PropTypes.bool,
  IsNotifyTeamEmail: PropTypes.bool,
  IsUrgent: PropTypes.bool,
};

CurfewSettingsForm.defaultProps = {
  onCancel: () => {},
  Nominal: {},
  NominalCurfewSettings: {
    StartTime: null,
    EndTime: null,
    Latitude: 0,
    Longitude: 0,
    Address1: null,
    Address2: null,
    City: null,
    PostalCode: null,
    Radius: null,
  },
  IsNotifyNominal: false,
  IsNotifyEmail: false,
  IsNotifyText: false,
  IsNotifyTeamEmail: false,
  IsUrgent: false,
};

function mapStateToProps(state) {
  const { Nominal } = state.Nominal;
  return { Nominal };
}

const connectCurfewSettingsForm = connect(mapStateToProps)(CurfewSettingsForm);

export { connectCurfewSettingsForm as default };

import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import { Layout, PageHeader } from "../../common/components";
// import { SampleHeader } from "./layout";
import { AllocationIndexPage } from "./pages";

const AllocationHeader = (
  <PageHeader title="License Allocation" />
)

const Allocation = ({ match }) => (
  <Layout PageHeader={AllocationHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={AllocationIndexPage} />
    </Switch>
  </Layout>
);

Allocation.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Allocation.defaultProps = {
  match: {
    url: "",
  },
};

export default withRouter(Allocation);
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button, Divider, Spin, Table, Tooltip } from "antd";

import { faEdit, faPlus, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class AreaDetails extends React.Component {
  render() {
    const addButton = (forceId) => (
      <Button
        shape="circle"
        style={{ backgroundColor: "white", color: "#243b53", margin: "5px" }}
        onClick={() => this.props.onAdd(forceId)}
      >
        <FontAwesomeIcon
          icon={faPlus}
          id="AddArea"
          className=""
          alt="add area"
        />
      </Button>
    );

    const gridColumns = [
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
      },
      {
        title: "",
        dataIndex: "Id",
        key: "Id",
        width: "75px",
        render: (text, record) =>
          this.props.Areas.length >= 1 ? (
            <>
              <span>
                <Row>
                  <Col>
                    <Tooltip title={`Edit ${record.Name}`}>
                      <FontAwesomeIcon
                        onClick={() => this.props.onEdit(record.Id)}
                        size="1x"
                        icon={faEdit}
                        pull="right"
                      />
                    </Tooltip>
                  </Col>
                  <Col>
                    <Tooltip title={`Delete ${record.Name}`}>
                      <FontAwesomeIcon
                        onClick={() => this.props.onDelete(record)}
                        size="1x"
                        icon={faTrash}
                        pull="right"
                      />
                    </Tooltip>
                  </Col>
                </Row>
              </span>
            </>
          ) : null,
      },
    ];

    return (
      <>
        <Spin spinning={this.props.loading}>
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col span={12} className="gutter-row">
              <span>Areas</span>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Col>
                  <span>
                    {addButton(this.props.Force.Id)}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col>
              <span>{`Total ${this.props.Areas.length}`}</span>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Table
                loading={this.props.loading}
                dataSource={this.props.Areas}
                columns={gridColumns}
                size="middle"
                rowKey="Id"
                scroll={{ x: true }}
              />
            </Col>
          </Row>
        </Spin>
      </>
    );
  }
}

AreaDetails.propTypes = {
  //   dispatch: PropTypes.func,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onDelete: PropTypes.func,
  loading: PropTypes.bool,
  Areas: PropTypes.arrayOf(PropTypes.shape({})),
  Force: PropTypes.shape({
    Id: PropTypes.number,
  }),  
};

AreaDetails.defaultProps = {
  //   dispatch: () => {},
  onEdit: () => {},
  onAdd: () => {},
  onDelete: () => {},
  loading: false,
  Areas: [],
  Force: {
    id: 1
  },
};

function mapStateToProps(state) {
  const { loading } = state.Organisation;

  return { loading };
}

const connectedAreaDetails = connect(mapStateToProps)(AreaDetails);

export { connectedAreaDetails as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button, Divider, Spin, Tooltip } from "antd";
import moment from "moment";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class NominalAdditionalInfo extends React.Component {
  render() {
    const editBasicInfoButton = (id) => (
      <Tooltip
        placement="topRight"
        title="Edit Additional Information"
      >
        <Button
          shape="circle"
          style={{ backgroundColor: "white", color: "#243b53", margin: "5px" }}
          onClick={() => this.props.edit(id)}
        >
          <FontAwesomeIcon
            icon={faEdit}
            id="EditNominalBasicInfo"
            className=""
            alt="basic"
          />
        </Button>
      </Tooltip>
    );

    return (
      <>
        <Spin spinning={this.props.loading}>
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col span={12} className="gutter-row">
              <h6>Additional Information</h6>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Col>
                  <span>{editBasicInfoButton(this.props.Id)}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>Registered Date:</span>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
              <span
                style={{
                  color: this.props.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: this.props.Active ? "normal" : "italic",
                }}
              >
                {`${moment(this.props.RegisteredDate).local().format("DD-MM-YYYY hh:mm A")}` }  
              </span>
            </Col>
          </Row>
          <br />
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>PNC ID:</span>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
              <span
                style={{
                  color: this.props.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: this.props.Active ? "normal" : "italic",
                }}
              >
                {`${this.props.PNCId || ""}`}
              </span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>Risk Level:</span>
            </Col>
            {this.props.RiskLevel && (
              <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
                <span
                  style={{
                    color: this.props.Active
                      ? "rgba(0, 0, 0, 0.85)"
                      : "#909090",
                    fontStyle: this.props.Active ? "normal" : "italic",
                  }}
                >
                  {`${this.props.RiskLevel.Name || ""}`}
                </span>
              </Col>
            )}
          </Row>
          <br />
          <Row>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>Nominal Type</span>
            </Col>
            {this.props.NominalType && (
              <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
                <span
                  style={{
                    color: this.props.Active
                      ? "rgba(0, 0, 0, 0.85)"
                      : "#909090",
                    fontStyle: this.props.Active ? "normal" : "italic",
                  }}
                >
                  {`${this.props.NominalType.Name || ""}`}
                </span>
              </Col>
            )}
          </Row>
          <br />
          <Row>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>VISOR ID:</span>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
              <span
                style={{
                  color: this.props.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: this.props.Active ? "normal" : "italic",
                }}
              >
                {`${this.props.VisorId || ""}`}
              </span>
            </Col>
          </Row>
          <br />
        </Spin>
      </>
    );
  }
}

NominalAdditionalInfo.propTypes = {
  // dispatch: PropTypes.func,
  edit: PropTypes.func,
  loading: PropTypes.bool,
  Id: PropTypes.number,
  VisorId: PropTypes.string,
  PNCId: PropTypes.string,
  RegisteredDate: PropTypes.string,
  RiskLevel: PropTypes.shape({
    Name: PropTypes.string,
  }),
  NominalType: PropTypes.shape({
    Name: PropTypes.string,
  }),
  Active: PropTypes.bool,
};

NominalAdditionalInfo.defaultProps = {
  // dispatch: () => {},
  edit: () => {},
  loading: false,
  Id: 0,
  VisorId: "",
  PNCId: "",
  RegisteredDate: "",
  RiskLevel: {
    Name: "",
  },
  NominalType: {
    Name: "",
  },
  Active: true,
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;

  return { loading };
}

const connectedNominalAdditionalInfo = connect(mapStateToProps)(
  NominalAdditionalInfo
);

export { connectedNominalAdditionalInfo as default };

import React from "react";
import { Button, DatePicker, Input, Space, TimePicker } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import locale from "antd/lib/locale/en_GB";
import moment from "moment";

const RiskTypes = {
  VH: 1,
  H: 2,
  M: 3,
  L: 4,
};

const NominalTypes = {
  S: 1,
  V: 2,
  O: 3,
};

const NotificationTypes = {
    E: 1,
    T: 2,
    O: 3,
}

const NotificationStatuses = {
  Sent: 3,
  Pending: 2,
  VerifiedBiometrically: 1,
  VerifiedSelfie: 4,
  VerifiedQuestion: 5,
  Error: 6,
  NotInError: 'NOT-IN-ERROR'
}

const AlertTypes = {
    ExclusionZone: 1,
    LocationFail: 2,
    Curfew: 3,
    Idle: 4,
    CheckIn: 5,
}

const AlertStates = {
  ActionRequired: {
    Id: 1,
    Name: "Action Required"
  },
  InReview: {
    Id: 2,
    Name: "In Review"
  },
  Resolved: {
    Id: 3,
    Name: "Resolved"
  }
}

const DATE = "DATE";
const TIME = "TIME";
const INPUT = "INPUT";

function getUnlocalizedDateFormat(d) {
  if (d) {
    const localized = moment(d, "DD-MM-YYYY");
    const unlocalizedString = localized.format("MM-DD-YYYY");
    return moment(unlocalizedString);
  }
  return moment();
}

function getTimeFormat(t) {
  if (t) {
    const time = moment(t, "h:mm:ss A");
    return moment(time);
  }
  return moment();
}

function handleSearch(selectedKeys, confirm) {
  confirm();
}

function handleReset(clearFilters) {
  clearFilters();
}

const searchInput = {};
// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
function getColumnSearchProps(paramObj) {
  if (!paramObj) return {};
  const { dataIndex, dataIndex2, title, componentType, callback, objectName } = paramObj;
  // const title = paramObj.title;
  // const componentType = paramObj.componentType;
  // const callback = paramObj.callback;
  // const objectName = paramObj.objectName;
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        {componentType === DATE && (
          <DatePicker
            locale={locale}
            ref={(node) => {
              searchInput[dataIndex] = node;
            }}
            onChange={(e) => setSelectedKeys(e ? [e.format("DD-MM-YYYY")] : [])}
            onPressEnter={() => {
              if (callback) {
                callback(selectedKeys, {dataIndex, dataIndex2, componentType, objectName});
              } else {
                handleSearch(selectedKeys, confirm, dataIndex);
              }
            }}
            placeholder={`Search ${title}`}
            value={getUnlocalizedDateFormat(selectedKeys[0])}
            style={{ marginBottom: 8, display: "block" }}
          />
        )}
        {componentType === INPUT && (
          <Input
            ref={(node) => {
              searchInput[dataIndex] = node;
            }}
            placeholder={`Search ${title}`}
            value={selectedKeys[0]}
            onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={() => {
              if (callback) {
                callback(selectedKeys, {dataIndex, dataIndex2, componentType, objectName});
              } else {
                handleSearch(selectedKeys, confirm, dataIndex);
              }
            }}
            style={{ marginBottom: 8, display: "block" }}
          />
        )}
        {componentType === TIME && (
          <TimePicker
            use12Hours
            id="TimeFilter"
            locale={locale}
            ref={(node) => {
              searchInput[dataIndex] = node;
            }}
            onChange={(e) => setSelectedKeys(e ? [e.format("h:mm:ss A")] : [])}
            onPressEnter={() => {
              if (callback) {
                callback(selectedKeys, {dataIndex, dataIndex2, componentType, objectName});
              } else {
                handleSearch(selectedKeys, confirm, dataIndex);
              }
            }}
            value={getTimeFormat(selectedKeys[0])}
            placeholder={`Search ${title}`}
            format="h:mm:ss A"
            inputReadOnly
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => {
              if (callback) {
                callback(selectedKeys, {dataIndex, dataIndex2, componentType, objectName});
              } else {
                handleSearch(selectedKeys, confirm, dataIndex);
              }
            }}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              if (callback) {
                callback([], {dataIndex, dataIndex2, componentType, objectName});
              } else {
                handleReset(clearFilters);
              }
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          {!callback && (
            <Button
              type="link"
              size="small"
              onClick={() => {
                if (callback) {
                  callback(selectedKeys, {dataIndex, dataIndex2, componentType, objectName});
                } else {
                  confirm({ closeDropdown: false });
                }
              }}
            >
              Filter
            </Button>
          )}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) => {
      if (objectName && !record[objectName]) return !value || value === "None";
      const field = objectName ? record[objectName][dataIndex] : record[dataIndex];
      if (field && value) {
        if (componentType === DATE) {
          const d = moment(field).format("DD-MM-YYYY");
          if (d === value) return d;
        }
        if (componentType === TIME) {
          let d = moment(field).format("h:mm:ss A");
          if (!field.includes("AM") && !field.includes("PM")) {
            d = moment(field, 'HH:mm:ss').format("h:mm:ss A");
          }
          if (d === value) return d;
        }
        if (componentType === INPUT) {
          return field
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase());
        }
      }
      return "";
    },
    onFilterDropdownVisibleChange: (visible) => {
      if (visible && componentType === INPUT) {
        setTimeout(() => searchInput[dataIndex].select(), 100);
      }
    },
  };
}

function nominalsGridAlertSorter(a, b) {
  if (
    a.AlertIds &&
    a.AlertIds.length > 0 &&
    (!b.AlertIds || b.AlertIds.length < 1)
  )
    return 1;
  if (a.AlertIds && b.AlertIds && a.AlertIds.length > b.AlertIds.length)
    return 1;
  if (
    b.AlertIds &&
    b.AlertIds.length > 0 &&
    (!a.AlertIds || a.AlertIds.length < 1)
  )
    return -1;
  if (b.AlertIds && a.AlertIds && b.AlertIds.length > a.AlertIds.length)
    return -1;
  return 0;
}

function getNominalsGridNotificationAlertsFilter(hasNotifications) {
  const filterArray = [
    { text: "Has Alerts", value: 1 },
    { text: "Does Not Have Alerts", value: 2 },
  ];
  if (hasNotifications) {
    filterArray.push({ text: "Notification Type: Email", value: 3 });
    filterArray.push({ text: "Notification Type: Text", value: 4 });
    filterArray.push({ text: "Notification Type: Other", value: 5 });
  }

  filterArray.push({ text: "Suspended", value: 6 });
  filterArray.push({ text: "Not Suspended", value: 7 });
  return {
    filters: filterArray,
    onFilter: (value, record) => {
      switch (value) {
        case 1:
          return record.AlertIds && record.AlertIds.length > 0;
        case 2:
          return !record.AlertIds || record.AlertIds.length === 0;
        case 3:
          return record.NominalTypeId === NotificationTypes.E;
        case 4:
          return record.NominalTypeId === NotificationTypes.T;
        case 5:
          return record.NominalTypeId === NotificationTypes.O;
        case 6:
          return !record.Active;
        case 7:
          return record.Active;
        default:
          return true;
      }
    },
  };
}

function getNotifyByFilter(field) {
  return {
    filters: [
      { text: "Notify By: Email", value: 1 },
      { text: "Notify By: Text", value: 2 },
      { text: "Notify By: Team Email", value: 3 },
      { text: "Notify By: Nominal Text", value: 4 },
    ],
    onFilter: (value, record) => {
      switch (value) {
        case 1:
          return field ? record[field][0].IsNotifyEmail : record.IsNotifyEmail;
        case 2:
          return field ? record[field][0].IsNotifyText : record.IsNotifyText;
        case 3:
          return field ? record[field][0].IsNotifyTeamEmail : record.IsNotifyTeamEmail;
        case 4:
          return field ? record[field][0].IsNotifyNominal : record.IsNotifyNominal;
        default:
          return true;
      }
    },
  };
}

function getOMListFilter(OMList) {
  const filterArray = [];
  if (OMList) {
    OMList.map(om => filterArray.push({text: om.FullName, value: om.Id}));
  }
  return {
    filters: filterArray,
    onFilter: (value, record) => record.OffenderManagerId === value,
  };
}

function getNominalsGridRiskTypeFilter() {
  return {
    filters: [
      { text: "VH", value: RiskTypes.VH },
      { text: "H", value: RiskTypes.H },
      { text: "M", value: RiskTypes.M },
      { text: "L", value: RiskTypes.L },
    ],
    onFilter: (value, record) => record.RiskLevelId === value,
  };
}

function getNominalsGridNominalTypeFilter() {
  return {
    filters: [
      { text: "S", value: NominalTypes.S },
      { text: "V", value: NominalTypes.V },
      { text: "O", value: NominalTypes.O },
    ],
    onFilter: (value, record) => record.NominalTypeId === value,
  };
}

function nominalsGridRecentLocationSorter(a, b) {
    if (a.Address && !b.Address) return 1;
    if (
      a.Address &&
      b.Address &&
      a.Address.toLowerCase() > b.Address.toLowerCase()
    )
      return 1;
    if (b.Address && !a.AlertIds) return -1;
    if (
      b.Address &&
      a.Address &&
      b.Address.toLowerCase() > a.Address.toLowerCase()
    )
      return -1;
    return 0;
}

function getNominalsGridBatteryLevelFilter() {
    return {
        filters: [
            { text: "Less than 5%", value: 6 },
            { text: "25% or less", value: 25 },
            { text: "50% or less", value: 50 },
            { text: "75% or less", value: 75 },
          ],
          onFilter: (value, record) => record.BatteryLevel <= value,
    }
}

function getAlertGridUrgencyFilter() {
    return {
        filters: [
            {text: "Urgent", value: true},
            {text: "Not Urgent", value: false}
        ],
        onFilter: (value, record) => record.IsUrgent === value,
    }
}

function getAlertGridAlertTypeFilter() {
    return {
        filters: [
            {text: "Exclusion Zone Breach", value: AlertTypes.ExclusionZone},
            {text: "Location Fail", value: AlertTypes.LocationFail},
            {text: "Missed Curfew", value: AlertTypes.Curfew},
            {text: "Idle Device", value: AlertTypes.Idle},
            {text: "Missed/Incorrect Check-In", value: AlertTypes.CheckIn}
        ],
        onFilter: (value, record) => record.AlertType && record.AlertType.Id === value,
    }
}

function getAlertGridAlertStateFilter() {
  return {
    filters: [
      { text: AlertStates.ActionRequired.Name, value: AlertStates.ActionRequired.Id},
      { text: AlertStates.InReview.Name, value: AlertStates.InReview.Id},
      { text: AlertStates.Resolved.Name, value: AlertStates.Resolved.Id}
    ],
    onFilter: (value, record) => record.AlertState && record.AlertState.Id === value,
  }
}

function notificationStatusFilter(filterArray) {
  return {
    filters: filterArray,
    onFilter: (value, record) => {
      switch (value) {
        case NotificationStatuses.Sent:
          return record.NotificationStatusId !== NotificationStatuses.Sent && record.NotificationStatusId.NotificationStatusId !== NotificationStatuses.Error &&
            moment(record.RunAfterDateTime) < moment.utc();
        case NotificationStatuses.Pending:
          return record.NotificationStatusId !== NotificationStatuses.Sent && record.NotificationStatusId.NotificationStatusId !== NotificationStatuses.Error &&
            moment(record.RunAfterDateTime) >= moment.utc();
        case NotificationStatuses.VerifiedBiometrically:
          return record.NotificationStatusId === NotificationStatuses.Sent && record.MessageType === "Fingerprint";
        case NotificationStatuses.VerifiedSelfie:
          return record.NotificationStatusId === NotificationStatuses.Sent && record.MessageType === "Selfie";
        case NotificationStatuses.VerifiedQuestion:
          return record.NotificationStatusId === NotificationStatuses.Sent && record.MessageType === "Question";
        case NotificationStatuses.Error:
          return record.NotificationStatusId === NotificationStatuses.Error;
        default:
          return true;
      }
    }
  }
}

function getNotificationStatusFilter() {
  const filterArray = [
    { text: "In Error", value: NotificationStatuses.Error},
    { text: "Not in Error", value: 99}
  ];
  return notificationStatusFilter(filterArray);
}

function getCheckinGridMessageTypeFilter() {
  const filterArray = [
    { text: "Pending", value: NotificationStatuses.Pending},
    { text: "Sent", value: NotificationStatuses.Sent},
    { text: "Verified Biometrically", value: NotificationStatuses.VerifiedBiometrically},
    { text: "Verified by Selfie", value: NotificationStatuses.VerifiedSelfie},
    { text: "Verified by Question", value: NotificationStatuses.VerifiedQuestion},
    { text: "In Error", value: NotificationStatuses.Error},
  ];
  return notificationStatusFilter(filterArray);
}

function numericSorter(a, b, field) {
    if (a && !b) return 1;
    if (!a && b) return -1;
    if (!a && !b) return 0;
    if (a[field] && !b[field]) return 1;
    if (!a[field] && b[field]) return -1;
    if (!a[field] && !b[field]) return 0;
    if (a[field] > b[field]) return 1;
    if (a[field] < b[field]) return -1;
    return 0;
}

function dateSorter(a, b, field) {
    if (field) {
      if (a[field] && !b[field]) return 1;
      if (!a[field] && b[field]) return -1;
      if (!a[field] && !b[field]) return 0;
      return new Date(a[field]) - new Date(b[field]);
    }
    return new Date(a) - new Date(b);
}

function timeSorter(a, b, field) {
  let dateA = a;
  let dateB = b;
  if (field) {
    dateA = a[field];
    dateB = b[field];
  }

  if (dateA && !dateB) return 1;
  if (!dateA && dateB) return -1;
  if (!dateA && !dateB) return 0;

  const timeA = moment().hours(dateA.hours()).minute(dateA.minute()).second(dateA.second());
  const timeB = moment().hours(dateB.hours()).minute(dateB.minute()).second(dateB.second());

  return new Date(timeA) - new Date(timeB);
}

function stringSorter(a, b, field) {
  if (a && !b) return 1;
  if (!a && b) return -1;
  if (!a && !b) return 0;
  if (field) {
    if (a[field] && !b[field]) return 1;
    if (!a[field] && b[field]) return -1;
    if (!a[field] && !b[field]) return 0;
    if (a[field].toUpperCase() > b[field].toUpperCase()) return 1;
    if (a[field].toUpperCase() < b[field].toUpperCase()) return -1;
  }
    return 0;
}

function booleanSorter(a, b, field) {
  if (a && !b) return 1;
  if (!a && b) return -1;
  if (!a && !b) return 0;
    if (a[field] && !b[field]) return 1;
    if (!a[field] && b[field]) return -1;
    return 0;
}

function booleanGroupSorter(a, b, fields) {
  let aCount = 0;
  let bCount = 0;
  if (fields) {
    for (let i = 0; i < fields.length; i += 1) {
      const field = fields[i];
      if (a[field]) aCount += 1;
      if (b[field]) bCount += 1;
    }
  }
  return aCount - bCount;
}

export {
  getColumnSearchProps,
  getUnlocalizedDateFormat,
  nominalsGridAlertSorter,
  NotificationTypes,
  getNominalsGridNotificationAlertsFilter,
  getNotifyByFilter,
  RiskTypes,
  getNominalsGridRiskTypeFilter,
  NominalTypes,
  getNominalsGridNominalTypeFilter,
  nominalsGridRecentLocationSorter,
  getNominalsGridBatteryLevelFilter,
  getAlertGridUrgencyFilter,
  getOMListFilter,
  AlertTypes,
  getAlertGridAlertTypeFilter,
  AlertStates,
  getAlertGridAlertStateFilter,
  NotificationStatuses,
  getNotificationStatusFilter,
  getCheckinGridMessageTypeFilter,
  numericSorter,
  dateSorter,
  timeSorter,
  stringSorter,
  booleanSorter,
  booleanGroupSorter,
  DATE,
  TIME,
  INPUT,
};

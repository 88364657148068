import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import { Layout, PageHeader } from "../../common/components";
import { MapViewPage, MapViewPageHeader } from "./page";

const MapViewHeader = (
  <PageHeader title="Map View" />
)

const MapViewNominalHeader = (id) => (
  <PageHeader customHeader={(<MapViewPageHeader Id={id} />)} />
)

const MapViewNominalPageWithHeader = () => (
  <Route render={(ref) => (
    <Layout PageHeader={MapViewNominalHeader(ref.location.pathname.substring(ref.location.pathname.lastIndexOf('/') + 1))}>
      <MapViewPage location={ref.location} />
    </Layout>
  )} 
  />
)

const MapViewPageWithHeader = () => (
  <Route render={() => (
    <Layout PageHeader={MapViewHeader}>
      <MapViewPage />
    </Layout>
  )}
  />
)

const MapView = () => (
  <Switch>
    <Route exact path="/map" component={MapViewPageWithHeader} />
    <Route exact path="/map/:Id" component={MapViewNominalPageWithHeader} />
  </Switch>
);

MapView.propTypes = {
  match: PropTypes.shape({}),
};

MapView.defaultProps = {
  match: {},
};

export default withRouter(MapView);

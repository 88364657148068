import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin } from "antd";

import { AllocationForm } from "../forms";
import actions from "../actions";

class AllocationModal extends React.Component {
  CloseUserModal = () => {
    this.props.dispatch(actions.closeAllocationModal());
    this.props.refreshGrid();
  };

  AllocationUpdate = (data) => {
    this.props.dispatch(actions.UpdateAllocation(data, this.CloseUserModal));
    // console.log("updating", data);
  };

  AllocationCreate = (data) => {
    this.props.dispatch(actions.createAllocation(data, this.CloseUserModal));
  };

  render() {
    return (
      <>
        <Modal
          title="Edit Allocation"
          visible={this.props.showEditModal}
          onCancel={this.CloseUserModal}
          footer={null}
          width={600}
        >
          <div>
            <Row>
              <Col span={24}>
                <Spin spinning={this.props.loading}>
                  <AllocationForm
                    isEdit
                    onCancel={this.CloseUserModal}
                    onSubmit={this.AllocationUpdate}
                    isLoading={this.props.loading}
                    {...this.props.allocation}
                  />
                </Spin>                
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          title="New Allocation"
          visible={this.props.showAddModal}
          onCancel={this.CloseUserModal}
          footer={null}
          width={600}
        >
          <div>
            <Row>
              <Col span={24}>
                <Spin spinning={this.props.loading}>
                  <AllocationForm
                    isEdit={false}
                    onCancel={this.CloseUserModal}
                    onSubmit={this.AllocationCreate}
                    isLoading={this.props.loading}
                    {...this.props.allocation}
                  />
                </Spin>  
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

AllocationModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  showEditModal: PropTypes.bool,
  showAddModal: PropTypes.bool,
  loading: PropTypes.bool,
  allocation: PropTypes.shape({}),
};

AllocationModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  showEditModal: false,
  showAddModal: false,
  loading: false,
  allocation: {},
};

function mapStateToProps(state) {
  const { allocation, loading, showEditModal, showAddModal } = state.Allocation;

  return { showEditModal, showAddModal, allocation, loading };
}

const connectedAllocationModal = connect(mapStateToProps)(AllocationModal);

export { connectedAllocationModal as default };

import apiRequest from "../common/utils/apiRequest";

const getUnassignedAllocationList = () => {
  return apiRequest()
    .get("/odata/UnallocatedForcesOData?$orderby=Name")
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
};

const getNominalTypesList = () => {
  return apiRequest()
    .get("/api/helper/nominaltypes")
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
};

const getRiskLevelList = () => {
  return apiRequest()
    .get("/api/helper/GetRiskLevels")
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
};

const getCheckinQuestions = () => {
  return apiRequest()
    .get("/api/helper/GetCheckinQuestions")
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
};

const exportInvestigation = (data) => {
  return apiRequest()
    .post("/api/helper/ExportInvestigation", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
};

export default {
  getUnassignedAllocationList,
  getNominalTypesList,
  getRiskLevelList,
  getCheckinQuestions,
  exportInvestigation,
};

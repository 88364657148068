import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Input, Divider, Radio } from "antd";
import { CancelBtn, SaveBtn } from "../../../common/components/form";
import { DynamicSelect } from '../../../common/components/dynamic-select';
import { OrganisationService } from '../../../services';

class TeamForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
  }

  componentDidUpdate() {
    this.formIK.current.handleReset();
  }

  pivotData = (data) => ({
    Id: data.Id,
    Name: data.Name,
    LawEnforcementAgencyId: data.ForceId,
    LawEnforcementAreaId: data.AreaId === 0 ? null : data.AreaId,
  });

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.props.Id || 0,
          Name: this.props.Name || "",
          ForceId: this.props.defaultForce || this.props.LawEnforcementAgencyId,
          AreaId: this.props.LawEnforcementAreaId,
          // eslint-disable-next-line no-unneeded-ternary
          HaveAreas: this.props.LawEnforcementAreaId ? true : false,
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          Name: Yup.string()
            .typeError("Team is required")
            .required("Team is required"),
          ForceId: Yup.number()
            .typeError("Force is required")
            .required("Force is required"),
          AreaId: Yup.number(),
        })}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => {
          const onRadioChange = (e) => {
            setFieldValue('AreaId', 0);
            setFieldValue('HaveAreas', e.target.value);
          }

          return (
            <Form id="TeamForm" onSubmit={handleSubmit} onChange={handleChange}>
              <div>
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col xs={24} sm={4} md={6}>
                        <span className="required-field">Force</span>
                      </Col>
                      <Col xs={24} sm={20} md={16}>
                        <Form.Item
                          validateStatus={
                            errors && errors.ForceId && touched.ForceId
                              ? "error"
                              : "success"
                          }
                          help={
                            errors && errors.ForceId && touched.ForceId
                              ? errors.ForceId
                              : null
                          }
                        >
                          <DynamicSelect
                            hasNone={false}
                            className=""
                            field="ForcePicker"
                            getData={OrganisationService.getForceGridOdata}
                            displayFieldName="Name"
                            valueFieldName="Id"
                            value={values.ForceId}
                            onBlur={handleBlur}
                            isDisabled={this.props.isEdit || this.props.defaultForce !== null}
                            onChange={(val) => {
                              setFieldValue("ForceId", val);
                              setFieldValue("AreaId", 0);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <span>Is this Team associated with an Area?</span>
                      </Col>
                      <Col span={24}>
                        <Radio.Group onChange={onRadioChange} value={values.HaveAreas}>
                          {/* eslint-disable-next-line */}
                          <Radio value={true}>Yes</Radio>
                          <Radio value={false}>No</Radio>
                        </Radio.Group>
                      </Col>
                    </Row>
                    {values.HaveAreas && (
                      <Row>
                        <Col xs={24} sm={4} md={6}>
                          <span>Area</span>
                        </Col>
                        <Col xs={24} sm={20} md={16}>
                          <Form.Item
                            validateStatus={
                              errors && errors.AreaId && touched.AreaId
                                ? "error"
                                : "success"
                            }
                            help={
                              errors && errors.ForceId && touched.AreaId
                                ? errors.AreaId
                                : null
                            }
                          >
                            <DynamicSelect
                              hasNone
                              className=""
                              field="AreaPicker"
                              getData={OrganisationService.getAreaGridOdata}
                              filters={`$filter=ForceId eq ${values.ForceId}`}
                              displayFieldName="Name"
                              valueFieldName="Id"
                              value={values.AreaId}
                              onBlur={handleBlur}
                              onChange={(val) => {
                                setFieldValue("AreaId", val);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    <Row>
                      <Col xs={24} sm={4} md={6}>
                        <span className="required-field">Team Name</span>
                      </Col>
                      <Col xs={24} sm={20} md={16}>
                        <Form.Item
                          validateStatus={
                            errors && errors.Name && touched.Name
                              ? "error"
                              : "success"
                          }
                          help={
                            errors && errors.Name && touched.Name
                              ? errors.Name
                              : null
                          }
                        >
                          <Input
                            id="Name"
                            onBlur={handleBlur}
                            value={values.Name}
                            onChange={(val) => {
                              setFieldValue("Name", val.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Divider plain />
                <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                  <Col xs={24} sm={8} md={6} className="gutter-row">
                    <CancelBtn onClick={this.props.onCancel} />
                  </Col>
                  <Col xs={24} sm={8} md={6} className="gutter-row">
                    <SaveBtn
                      onClick={handleSubmit}
                      loading={this.props.isLoading}
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          )}}
      </Formik>
    );
  }
}

TeamForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
  Id: PropTypes.number,
  Name: PropTypes.string,
  LawEnforcementAgencyId: PropTypes.number,
  LawEnforcementAreaId: PropTypes.number,
  defaultForce: PropTypes.number,
};

TeamForm.defaultProps = {
  onCancel: () => {},
  isLoading: false,
  isEdit: false,
  Id: 0,
  Name: null,
  LawEnforcementAgencyId: null,
  LawEnforcementAreaId: null,
  defaultForce: null,
};

export default TeamForm;

import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Row,
  Col,
  Form,
  Divider,
  Checkbox,
  DatePicker,
  InputNumber,
} from "antd";
import moment from "moment";
import { CancelBtn, SaveBtn } from "../../../common/components/form";
import { DynamicSelect } from "../../../common/components/dynamic-select";
import { HelperService, OrganisationService } from "../../../services";

class AllocationForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
  }

  componentDidUpdate() {
    this.formIK.current.handleReset();
  }

  pivotData = (data) => ({
    Id: data.Id,
    Allocations: data.Allocations,
    LawEnforcementAgencyId: data.ForceId,
    StartDate: data.StartDate.format("l"),
    EndDate: data.EndDate ? data.EndDate.format("l") : moment('12/31/9999').format("l"),
    IsRollingContract: data.IsRollingContract,
  });

  render() {
    const dateFormat = ["DD/MM/YYYY", "DD/MM/YY"];

    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.props.Id,
          ForceId: this.props.LawEnforcementAgencyId,
          Allocations: this.props.Allocations,
          StartDate: moment(this.props.StartDate) || moment(),
          EndDate: moment(this.props.EndDate) || moment(),
          IsRollingContract: this.props.IsRollingContract,
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          ForceId: Yup.number(),
          Allocations: Yup.string()
            .typeError("Allocation is required")
            .required("Allocation is required"),
          StartDate: Yup.string()
            .typeError("Start Date is required")
            .required("Start Date is required"),
          EndDate: Yup.string().nullable()
            .when("IsRollingContract", {
              is: false,
              then: Yup.string().typeError("End Date is required")
              .required("End Date is required"),
            })
        })}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form
            id="AllocationForm"
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <div>
              <Row>
                <Col span={24}>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col xs={24} sm={24} md={16} className="gutter-row">
                      <Row>
                        <Col xs={24} sm={24} md={8}>
                          <span className="required-field">Allocations: </span>
                        </Col>
                        <Col xs={24} sm={24} md={16}>
                          <Form.Item
                            validateStatus={
                              errors &&
                              errors.Allocations &&
                              touched.Allocations
                                ? "error"
                                : "success"
                            }
                            help={
                              errors &&
                              errors.Allocations &&
                              touched.Allocations
                                ? errors.Allocations
                                : null
                            }
                          >
                            <InputNumber
                              id="Allocations"
                              style={{width:"100%"}}
                              onBlur={handleBlur}
                              value={values.Allocations}
                              onChange={(val) => {
                                setFieldValue("Allocations", val);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col sm={24} md={8} className="gutter-row">
                      <Form.Item>
                        <Checkbox
                          checked={values.IsRollingContract}
                          value={values.IsRollingContract}
                          onChange={(val) => {
                            setFieldValue(
                              "IsRollingContract",
                              val.target.checked
                            );
                            setFieldValue("EndDate", val.target.checked ? null : moment(values.StartDate).add(2, 'years'));
                          }}
                        >
                          Is Rolling Contract?
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={24} md={4}>
                      <span className="required-field">Force: </span>
                    </Col>
                    <Col xs={24} sm={24} md={20}>
                      <Form.Item
                        validateStatus={
                          errors && errors.ForceId && touched.ForceId
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.ForceId && touched.ForceId
                            ? errors.ForceId
                            : null
                        }
                      >
                        <DynamicSelect
                          isDisabled={this.props.isEdit}
                          hasNone={false}
                          className=""
                          field="ForcePicker"
                          getData={this.props.isEdit === false ? HelperService.getUnassignedAllocationList : OrganisationService.getForceGridOdata}
                          displayFieldName="Name"
                          valueFieldName="Id"
                          value={values.ForceId}
                          onBlur={handleBlur}
                          onChange={(val) => {
                            setFieldValue("ForceId", val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col className="gutter-row" sm={24} md={12}>
                      <Row>
                        <Col xs={24} sm={24} md={24}>
                          <span className="required-field">StartDate: </span>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            validateStatus={
                              errors && errors.StartDate && touched.StartDate
                                ? "error"
                                : "success"
                            }
                            help={
                              errors && errors.StartDate && touched.StartDate
                                ? errors.StartDate
                                : null
                            }
                          >
                            <DatePicker
                              className="p5-form-datepicker-input"
                              value={moment(values.StartDate, dateFormat[0])}
                              format={dateFormat}
                              onChange={(val) => {
                                setFieldValue("StartDate", val);
                                setFieldValue("EndDate", moment(val).add(2, 'years'));
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col className="gutter-row" sm={24} md={12}>
                      <Row>
                        <Col xs={24} sm={24} md={24}>
                          <span className="required-field">EndDate: </span>
                        </Col>
                        <Col xs={24} sm={24} md={24}>
                          <Form.Item
                            validateStatus={
                              errors && errors.EndDate && touched.EndDate
                                ? "error"
                                : "success"
                            }
                            help={
                              errors && errors.EndDate && touched.EndDate
                                ? errors.EndDate
                                : null
                            }
                          >
                            {values.IsRollingContract && (
                              <DatePicker
                                className="p5-form-datepicker-input"
                                placeholder="None"
                                format={dateFormat}
                                disabled={values.IsRollingContract}
                                onChange={(val) => {
                                  setFieldValue("EndDate", val);
                                }}
                              />
                            )}
                            {!values.IsRollingContract && (
                              <DatePicker
                                className="p5-form-datepicker-input"
                                value={moment(values.EndDate, dateFormat[0])}
                                format={dateFormat}
                                disabled={values.IsRollingContract}
                                onChange={(val) => {
                                  setFieldValue("EndDate", val);
                                }}
                              />
                            )}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider plain />
              <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <SaveBtn
                    onClick={handleSubmit}
                    loading={this.props.isLoading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

AllocationForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
  Id: PropTypes.number,
  LawEnforcementAgencyId: PropTypes.number,
  Allocations: PropTypes.number,
  StartDate: PropTypes.string,
  EndDate: PropTypes.string,
  IsRollingContract: PropTypes.bool,
};

AllocationForm.defaultProps = {
  onCancel: () => {},
  isEdit: false,
  isLoading: false,
  Id: 0,
  LawEnforcementAgencyId: null,
  Allocations: 0,
  StartDate: moment().toString(),
  EndDate: moment().add(2, "years").toString(),
  IsRollingContract: false,
};

export default AllocationForm;

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Table, Row, Col, Tooltip } from "antd";
import { IconButtons } from '../../../common/components'

import actions from "../actions";

const { SearchBox, AddButton, EditIcon } = IconButtons;

class ForceGrid extends React.Component {
  OpenBulkModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openBulkCreateModal());
  };

  editForce = (Id) => {
    const { dispatch } = this.props;
    dispatch(actions.getForceDetail(Id, this.openForceModal));
  }

  openForceModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openForceModal());
  }

  render() {
    const gridColumns = [
      {
        title: "Force",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => {          
          if(a.Name.toUpperCase() > b.Name.toUpperCase())
            return -1;

          if(a.Name.toUpperCase() < b.Name.toUpperCase())
            return 1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
        render: (text, record) =>
          this.props.forceList.length >= 1 ? (
            <>
              <span>
                <Link to={`organisations/${record.Id}`}>
                  {record.Name}
                </Link>
              </span>
            </>
          ) : null,   
      },
      {
        title: "Areas",
        dataIndex: "AreaCount",
        key: "AreaCount",
        sorter: (a, b) => a.AreaCount - b.AreaCount,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Teams",
        dataIndex: "TeamCount",
        key: "TeamCount",
        sorter: (a, b) => a.TeamCount - b.TeamCount,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "",
        dataIndex: "Id",
        key: "Id",
        render: (text, record) =>
          this.props.forceList.length >= 1 ? (
            <>
              <span>
                <EditIcon tooltip={`Edit ${record.Name}`} onClick={this.editForce} value={record.Id} pull="right" />
              </span>
            </>
          ) : null,   
      },
    ];

    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row>
          <Col xs={12} md={10} lg={10}>
            <span className="mosovo-table-header">Forces</span>
          </Col>
          <Col xs={24} md={14} lg={14}>
            <Row justify="end" gutter={{ xs: 8, sm: 16, md: 10 }}>
              <Col xs={19} sm={20} md={18} lg={12} className="gutter-row">
                <SearchBox placeholder="force search" allowClear onSearch={this.props.onSearch} minText={3} loading={this.props.loading} />
              </Col>
              <Col className="gutter-row">
                <Tooltip title="Add new force">
                  <span><AddButton id="AddTeam" onClick={this.OpenBulkModal} /></span>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col xs={24}>
            <Table
              loading={this.props.loading}
              dataSource={this.props.forceList}
              columns={gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ForceGrid.propTypes = {
  dispatch: PropTypes.func,
  onSearch: PropTypes.func,
  forceList: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
};

ForceGrid.defaultProps = {
  dispatch: () => {},
  onSearch: () => {},
  forceList: [{}],
  loading: false,
};

function mapStateToProps(state) {
  const { loading } = state.Organisation;
  return {
    loading,
  };
}

const connectedForceGrid = connect(mapStateToProps)(ForceGrid);

export { connectedForceGrid as default };

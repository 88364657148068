import React from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
import { Formik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import { Row, Col, Form, Input, Divider } from "antd";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelBtn, SaveBtn } from "../../../common/components/form";
import { phoneCodeAndNumberFormatter } from "../../../common/utils/phone-number-helper";
import { NominalService } from "../../../services";
import actions from "../actions";

class DeviceInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
    this.state = {
      ErrorText: ""
    }
  }

  nominalDeviceInfoUpdate = (data) => {
    this.props.dispatch(
      actions.updateNominalDeviceInfo(data, this.handleErrors)
    );
  };

  pivotData = (data) => ({
    Id: data.Id,
    DeviceCode: data.DeviceCode,
    ConsumerId: data.ConsumerId,
    NominalId: data.NominalId,
    PhoneNumber: data.PhoneNumber.replace(/[^\d]/g, ""),
  });

  validatePhoneNumber = (phoneNumber) => {
    return NominalService.getPhoneNumber(phoneNumber).then(
      (response) => {
        if (
          response.data.ConsumerId === this.formIK.current.values.ConsumerId
        ) {
          return 0;
        }
        return response.data.ConsumerId ?? 0;
      },
      () => {
        return 0;
      }
    );
  };

  handleErrors = (data) => {
    if (data.Error) {
      this.setState((prevState) => ({
        ...prevState,
        ErrorText: data.Error,
      }));
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          ErrorText: null,
        }),
        this.props.onSubmit()
      );
    }
  };

  deviceLinkedError = () => (
    <>
      <span style={{ color: "red" }}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className=""
          alt="deviceLinkedError"
        />
      </span>
      <span style={{ color: "red" }}> {this.state.ErrorText}</span>
    </>
  );

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.props.Id || 0,
          ConsumerId: this.props.ConsumerId || 0,
          NominalId: this.props.NominalId || 0,
          PhoneNumber: this.props.PhoneNumber || "",
          DeviceCode: this.props.DeviceCode || "",
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          ConsumerId: Yup.number(),
          NominalId: Yup.number(),
          DeviceCode: Yup.string()
            .required("Device Code is required")
            .typeError("Device Code is required")
            .test(
              "deviceCodeLength",
              "Device Code must be 6 characters in length",
              (val) => val && val.length === 6
            ),
          PhoneNumber: Yup.string()
            .required("Phone Number is required")
            .typeError("Phone Number is required")
            .test(
              "phoneNumberValidation",
              "Phone number must be 10 or 11 characters in length",
              (val) => val && (val.length === 10 || val.length === 11)
            )
            .test(
              "phoneNumberInvalid",
              () =>
                "The phone number appears to be already in use. Please check the number and try again.",
              (value) => {
                if (value) {
                  return this.validatePhoneNumber(value).then((consumerId) => {
                    return !(consumerId > 0);
                  });
                }
                return true;
              }
            ),
        })}
        // onChange={(values) => this.handleChange(values)}
        onSubmit={(values) => this.nominalDeviceInfoUpdate(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form
            id="NominalDeviceInfoForm"
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <div>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col sm={24} md={6}>
                      <span>Device Code</span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.DeviceCode && touched.DeviceCode
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.DeviceCode && touched.DeviceCode
                            ? errors.DeviceCode
                            : null
                        }
                      >
                        <Input
                          id="DeviceCode"
                          placeholder="xxxxxx"
                          onBlur={handleBlur}
                          value={values.DeviceCode}
                          onChange={(val) => {
                            setFieldValue("DeviceCode", val.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={24} md={6}>
                      <span className="required-field">Phone Number</span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.PhoneNumber && touched.PhoneNumber
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.PhoneNumber && touched.PhoneNumber
                            ? errors.PhoneNumber
                            : null
                        }
                      >
                        <Input
                          id="PhoneNumber"
                          placeholder="xxx-xxx-xxxx"
                          onBlur={handleBlur}
                          value={phoneCodeAndNumberFormatter(
                            values.PhoneNumber
                          )}
                          onChange={(val) => {
                            setFieldValue("PhoneNumber", val.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
                {this.state.ErrorText && (
                  <Col xs={24} sm={24} md={16}>
                    {this.deviceLinkedError()}
                  </Col>
                )}
              </Row>
              <Divider plain />
              <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <SaveBtn
                    onClick={handleSubmit}
                    loading={this.props.isLoading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

DeviceInfoForm.propTypes = {
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  Id: PropTypes.number,
  NominalId: PropTypes.number,
  ConsumerId: PropTypes.number,
  PhoneNumber: PropTypes.string,
  DeviceCode: PropTypes.string,
};

DeviceInfoForm.defaultProps = {
  dispatch: () => {},
  onCancel: () => {},
  onSubmit: () => {},
  isLoading: false,
  Id: 0,
  NominalId: null,
  ConsumerId: null,
  PhoneNumber: null,
  DeviceCode: "",
};

function mapStateToProps() {
  return {};
}

const connectedDeviceInfoForm = connect(mapStateToProps)(DeviceInfoForm);

export { connectedDeviceInfoForm as default };

import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { UserService } from '../../../services';

const { Option } = Select;

class OMSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getOMList();
  }

  getOMList() {
    UserService.getOMUserList().then((result) => {
      this.setState({
        data: result.data,
      });
    });
  }

  render() {
    return (
      <Select
        placeholder="Offender Managers"
        className={this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        {
          this.state.data.map((opt) => (
            <Option
              key={opt.Id}
              value={opt.Id}
            >
              {`${opt.FullName} - (${opt.TeamName})`}
            </Option>
          ))
        }
      </Select>
    );
  }
}

OMSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  className: PropTypes.string,
};

OMSelect.defaultProps = {
  value: 0,
  className: "",
};

export { OMSelect as default };

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const SaveBtn = (props) => {
  return(
    <Button
      disabled={props.isDisabled}
      block
      type="submit"
      id="saveBtn"
      className="saveBtn"
      onClick={props.onClick}
      loading={props.loading}
      style={{
        backgroundColor: props.isDisabled ? "#F5F5F5" : "#243B53",
        border: props.isDisabled ? "1px solid #D9D9D9" : "1px solid #096dd9",
        color: props.isDisabled ? "#868E96" : "white"
      }}
    >
      {`${props.buttonText}`}
    </Button>
  );
}

SaveBtn.propTypes = {
    onClick: PropTypes.func,
    loading: PropTypes.bool,
    isDisabled: PropTypes.bool,
    buttonText: PropTypes.string
}

SaveBtn.defaultProps = {
    onClick: () => {},
    loading: false,
    isDisabled: false,
    buttonText: "Save"
}

export default SaveBtn;

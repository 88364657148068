import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Tabs } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import actions from "../actions";
import { AllocationGrid, AllocationReportGrid } from '../grids';
import { AllocationModal } from '../modals';

const { TabPane } = Tabs;
class UserIndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allocationList: [],
      nominalAllocationList: [],
      searchQuery: "",
      activeKey: 'allocations',
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("Licenses"));
    this.getAllocationData();
    this.getNominalAllocationData({ StartDate: null, EndDate: null });
  }

  getAllocationData = (searchQuery) => {
    const query = searchQuery ?? "";
    this.setState({ searchQuery: query });
    this.props.dispatch(actions.getAllocationData(query, this.setUserData));
  };

  getNominalAllocationData = (data) => {
    this.props.dispatch(actions.getNominalAllocationData(data, this.setNominalAllocationList));
  }

  setUserData = (data) => {
    this.setState({         
      allocationList: data 
    });   
  }

  setNominalAllocationList = (data) => {
    this.setState({
      nominalAllocationList: data
    });
  }

  RefreshGrid = () => {
    this.getAllocationData(this.state.searchQuery);
  }

  changeTabs = (tab) => {
    this.setState({
      activeKey: tab,
    });
    // if (tab === "communications") {
    //   this.getCommunicationsData();
    // } else if (tab === "alerts") {
    //   this.getNominalAlerts();
    //   this.getNominalAlertSettings();
    // }
  };

  render() {
    return (
      <div className="mosovo-content-container">
        <Row justify="center">
          <Col span={24}>     
            <Tabs
              activeKey={this.state.activeKey}
              size="large"
              onTabClick={(e) => this.changeTabs(e)}
            >
              <TabPane tab="License Allocations" key="allocations">
                <AllocationGrid allocationList={this.state.allocationList} onSearch={this.getAllocationData} />      
              </TabPane>
              <TabPane tab="Reports" key="reports">
                <AllocationReportGrid issuedList={this.state.allocationList} usedList={this.state.nominalAllocationList} onSearchLicensesUsed={this.getNominalAllocationData} onSearchLicensesIssued={this.getAllocationData} />      
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        <AllocationModal refreshGrid={this.RefreshGrid} />
      </div>
    );
  }
}

UserIndexPage.propTypes = {
    dispatch: PropTypes.func,
};

UserIndexPage.defaultProps = {
    dispatch: () => {},
};

function mapStateToProps() {
  return {};
}

const connectUserIndexPage = connect(mapStateToProps)(UserIndexPage);

export { connectUserIndexPage as default };

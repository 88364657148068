import apiRequest from "../common/utils/apiRequest";

function getCurrentUser() {
  return apiRequest()
    .get("/api/Auth/CurrentUser", {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function login(data) {
  return apiRequest()
    .post("/api/Auth/Login", data, {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) =>
      response.status === 202 ? response.status : Promise.resolve(response.data)
    )
    .catch((error) => Promise.reject(error));
}

function verifyMFA(username, code) {
  return apiRequest()
    .get(`/api/Auth/VerifyMFA?username=${username}&code=${code}`, {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function resendMFA(username) {
  return apiRequest()
    .get(`/api/Auth/ResendMFA?username=${username}`, {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function logout() {
  return apiRequest()
    .post("/api/Auth/Logout", null, {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function resetPassword(data) {
  return apiRequest()
    .post("api/Auth/ResetPassword", data, {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function changePassword(data) {
  return apiRequest()
    .post("api/Auth/ChangePassword", data, {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function forgotPassword(data) {
  return apiRequest()
    .post("api/Auth/ForgotPassword", data, {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function registerUser() {
  return apiRequest()
    .get("api/Auth/RegisterUser", {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function confirmEmail(data) {
  return apiRequest()
    .post("/api/Auth/ConfirmUserEmail", data, {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function resendConfirmLink(data) {
  return apiRequest()
    .post("/api/Auth/GenerateConfirmationToken", data, {
      withCredentials: true,
      "Access-Control-Allow-Origin": "*",
    })
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export default {
  getCurrentUser,
  login,
  verifyMFA,
  resendMFA,
  logout,
  resetPassword,
  changePassword,
  forgotPassword,
  registerUser,
  confirmEmail,
  resendConfirmLink,
};

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import Geocode from "react-geocode";
import {
  faChevronLeft,
  faChevronCircleDown,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Row,
  Card,
  Col,
  Button,
} from "antd";
import { Map } from "../../MapView/components";
import "../Investigate.scss";
import actions from "../actions";
import SearchResultsGrid from "../grid/SearchResultsGrid";

class ResultsCard extends React.Component {
  constructor() {
    super();
   
    this.state = {
      searchZone: [
        {
          Latitude: null,
          Longitude: null,
          Radius: 100,
        },
      ],
      mapRef: null,
      centerPosition: {
        lat: null,
        lng: null
      },
      selectedRowKeys: [],
      expandedCard: 0,
    };
  }

  componentDidMount() {
    this.mapComponentRef=React.createRef();
    this.autocompleteInputRef = React.createRef();
  }

  getPositionFromAddress = async (location, radius, unit) => {
    let locationAddress = location;
    if (location.display_name) {
      locationAddress = location.display_name;
    }
    let position = {
      lat: null,
      lng: null,
      radius: 0,
    };

    if (locationAddress !== "") {
      await Geocode.fromAddress(locationAddress)
        .then((response) => {
          position = response.results[0].geometry.location;
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error));
    }
    
    position.radius = radius * this.getUnitToMetersConversion(unit);
    this.updateLocation(position);
  };

  mapRefSetter = (gmap) => {
    if (!this.state.mapRef) {
      this.setState((prevState) => ({
        ...prevState,
        mapRef: gmap,
      }));
    }
  };

  setExpandedCard = (id) => {
    // Expanded card ids: 0 is none, 1 is Nominal List, 2 is Map
    this.setState({
      expandedCard: id,
    });
  }

  onLocationSelectChange = (selectedKeys) =>
  {
    this.setState({
      selectedRowKeys: selectedKeys,
    });
  }

  onInvestigateClick = (StartDate, EndDate) => {
    const zone = this.state.searchZone[0];

    // Location QS
    const coef = zone.Radius * 0.0000089;
    const newLat = zone.Latitude + coef;
    const newLong = zone.Longitude + coef / Math.cos(zone.Latitude * 0.018);

    const coef2 = -zone.Radius * 0.0000089;
    const newLat2 = zone.Latitude + coef2;
    const newLong2 = zone.Longitude + coef2 / Math.cos(zone.Latitude * 0.018);

    const locationQS = `Latitude gt ${newLat2} and Latitude lt ${newLat} and Longitude gt ${newLong2} and Longitude lt ${newLong}`

    // DateQS
    const dateQS = `CreatedDate gt ${encodeURIComponent(moment(StartDate, 'DD/MM/yyyy h:mm A').utc().toISOString())} and CreatedDate lt ${encodeURIComponent(moment(EndDate, 'DD/MM/yyyy h:mm A').utc().toISOString())}`;

    this.props.dispatch(actions.getNominalLocations(`$select=NominalName,DateOfBirth,ForceName&$filter=${locationQS} and ${dateQS}`));
  }

  updateRadius = (radius, unit) => {
    if (this.state.searchZone && this.state.searchZone.length > 0) {
      const searchZone = this.state.searchZone[0];
      if (searchZone) {
        const convertedRadius = radius * this.getUnitToMetersConversion(unit);
        searchZone.Radius = convertedRadius;
        this.setState({
            searchZone: [searchZone],
        });
      }
    }
  }

  getUnitToMetersConversion = (unit) => {
    let meters;

    switch (unit) {
      case "Meters":
        meters = 1;
        break;
      case "Feet":
        meters = 0.3048;
        break;
      default:
    }

    return meters;
  };

  updateLocation = (position) => {
    this.setState({
      searchZone: [
        {
          Latitude: position.lat,
          Longitude: position.lng,
          Radius: position.radius,
        },
      ],
      centerPosition: { lat: position.lat, lng: position.lng }
    });
    if (this.state.mapRef && this.state.mapRef.state && this.state.mapRef.state.map) {
      this.state.mapRef.state.map.panTo({ lat: position.lat, lng: position.lng });
      // this.state.mapRef.state.map.setZoom(15);
    }
  };

  validateDates = (StartDate, EndDate) => {
    if(moment(EndDate, 'DD/MM/yyyy h:mm A').isBefore(moment(StartDate, 'DD/MM/yyyy h:mm A'))
      || moment(StartDate, 'DD/MM/yyyy h:mm A').add(36, 'hours').isBefore(moment(EndDate, 'DD/MM/yyyy h:mm A')))
        return false;
    return true;
  }

  clearSearch = () => {
    this.props.dispatch(actions.resetNominalLocations());
  }

  onExport = () => {
    const data = this.props.searchParams;
    data.Locations = this.props.locations;
    data.ReportCreationTime = moment().local().format("DD/MM/yyyy h:mm A");
    this.props.dispatch(actions.exportLocations(data));
  }

  render() {

    const selectedLocations = this.state.selectedRowKeys.map(id => {
      return {...this.props.locations.find(l => l.Id === id), key: this.props.locations.findIndex(l => l.Id === id)};
    });

    // Calc is height - rough header height.
    return (
      <Card className="InvestigateCard">
        <Row>
          <Col span={13}>
            <Button
              type="text"
              onClick={this.clearSearch}
            >
              <FontAwesomeIcon
                icon={faChevronLeft}
                style={{marginRight: "10px"}}
                id="nominaleBack"
              />
              Back to search criteria
            </Button>
          </Col>
          <Col span={this.props.locations && this.props.locations.length > 0 ? 11 : 0}>
            <Button
              type="text"
              onClick={this.onExport}
            >
              <FontAwesomeIcon
                icon={faChevronCircleDown}
                style={{marginRight: "10px"}}
                id="nominaleBack"
              />
              Export to Excel
            </Button>
          </Col>
        </Row>
        <Row>
          {/* eslint-disable-next-line */}
          <Col span={this.state.expandedCard === 0 ? 11 : (this.state.expandedCard === 1 ? 24 : 0)}>
            <SearchResultsGrid
              locationHistory={this.props.locations}
              onSelectChange={this.onLocationSelectChange}
              setExpandedCard={this.setExpandedCard}
              expandedCard={this.state.expandedCard}
            />
          </Col>
          <Col span={this.state.expandedCard === 0 ? 2 : 0} />
          {/* eslint-disable-next-line */}
          <Col span={this.state.expandedCard === 0 ? 11 : (this.state.expandedCard === 2 ? 24 : 0)}>
            <Row>
              <Col span={24}>
                <Button
                  type="link"
                  style={{float: 'right'}}
                  onClick={() => this.state.expandedCard === 0 ? this.setExpandedCard(2) : this.setExpandedCard(0)}
                >
                  {this.state.expandedCard === 0 ? 'Show full map' : 'Show nominal list'}
                </Button>
              </Col>
            </Row>
            <Row style={{minHeight: '600px', height: '95%' }}>
              <Map
                centerPosition={this.state.centerPosition}
                mapRefSetter={this.mapRefSetter}
                zoomLevel={10}
                dispatch={this.props.dispatch}
                data={selectedLocations}
                selectedIDs={this.state.selectedRowKeys}
                exclusionZones={this.state.searchZone}
                cssClass="InvestigateMap"
                showPinHover
              />
            </Row>
          </Col>
        </Row>
      </Card>
    );
  }
}

ResultsCard.propTypes = {
  dispatch: PropTypes.func,
  locations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  searchParams: PropTypes.shape().isRequired,
  // loading: PropTypes.bool,
};

ResultsCard.defaultProps = {
  dispatch: () => {},
  // loading: false,
};

function mapStateToProps() {
  return {};
}

const connectResultsCard = connect(mapStateToProps)(ResultsCard);

export { connectResultsCard as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin } from "antd";
import { WarningOutlined } from '@ant-design/icons';
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelBtn, SaveBtn } from "../form";

import actions from "./actions";

class BulkAlertResolveModal extends React.Component {
  CloseModal = () => {
    this.props.dispatch(actions.closeBulkAlertModal());
    this.props.refreshGrid();
  };

  CloseBulkAlertResolveModal = () => {    
    const { confirm } = Modal; 
    const closeModal = this.CloseModal;
    const message = (
      <div>
        <p>
          Are you sure you want to cancel?
        </p>
      </div>
      
    );

    confirm({
      title: "Resolve All Alerts",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        closeModal();
      },
      okText: "Yes, Close"
    });
  }

  pivotData = () => ({
    NominalId: this.props.selectedNominals,
    AlertIds: this.props.selectedAlerts
  });

  ResolveAlerts = () => {
      this.props.dispatch(actions.resolveBulkAlerts(this.pivotData(), this.CloseModal));
  };

  createFooter = () => {
    const footer = (
      <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
        <Col xs={24} sm={8} md={6} className="gutter-row">
          <CancelBtn onClick={this.CloseBulkAlertResolveModal} />
        </Col>
        <Col xs={24} sm={8} md={6} className="gutter-row">
          <SaveBtn
            onClick={this.ResolveAlerts}
            loading={this.props.loading}
            buttonText="Yes, Resolve"
            isDisabled={this.props.selectedNominals.length === 0 && this.props.selectedAlerts === 0}
          />
        </Col>
      </Row>
    )
    return footer;
  }

  render() {
    return (
      <>
        <Modal
          title="Resolve All Alerts"
          visible={this.props.showBulkAlertModal}
          // onCancel={this.CloseModal}
          footer={this.createFooter()}
          width={600}
          onCancel={this.CloseBulkAlertResolveModal}
          destroyOnClose
        >
          <div>
            <Row>
              <Col span={24}>
                <Spin spinning={this.props.loading}>
                  <>
                    <Row>
                      <Col span={4}>
                        <div style={{textAlign: "center", color: "#D09600", fontSize: "24px", marginTop: "5px"}}>
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                          />
                        </div>
                      </Col>
                      <Col span={20}>
                        <p>Resolving all alerts will mark all of the alerts for the selected Nominal(s) as resolved. The “bell” icon will be removed until a new alert is triggered. You can view the resolved alerts in the Alerts tab of each nominal. </p>
                        <p>Are you sure you want to resolve the selected Nominal(s) alerts?</p>
                      </Col>
                    </Row>
                  </>
                </Spin>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

BulkAlertResolveModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  selectedNominals: PropTypes.arrayOf(PropTypes.number),
  selectedAlerts: PropTypes.arrayOf(PropTypes.number),
  showBulkAlertModal: PropTypes.bool,
  loading: PropTypes.bool,
};

BulkAlertResolveModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  selectedNominals: [],
  selectedAlerts: [],
  showBulkAlertModal: false,
  loading: false,
};

function mapStateToProps(state) {
  const { loading, showBulkAlertModal } = state.MosovoModal;

  return { loading, showBulkAlertModal };
}

const connectedBulkAlertResolveModal = connect(mapStateToProps)(BulkAlertResolveModal);

export { connectedBulkAlertResolveModal as default };

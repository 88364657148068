import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Divider, Spin } from "antd";
// import { faEdit } from "@fortawesome/pro-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { KnownAssociateGrid } from '../grids'

class KnownAssociates extends React.Component {
  render() {
    // const editBasicInfoButton = (id) => (
    //   <Button
    //     style={{ backgroundColor: "white", color: "#243b53", margin: "5px" }}
    //     onClick={() => this.props.edit(id)}
    //   >
    //     <FontAwesomeIcon
    //       icon={faEdit}
    //       id="EditNominalBasicInfo"
    //       className=""
    //       alt="basic"
    //     />
    //   </Button>
    // );

    return (
      <>
        <Spin spinning={this.props.loading}>
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col span={12} className="gutter-row">
              <h6>Known Associates</h6>
            </Col>
            {/* <Col span={12}>
              <Row justify="end">
                <Col>
                  <span>
                    {editBasicInfoButton(this.props.Id)}
                  </span>
                </Col>
              </Row>
            </Col> */}
          </Row>
          <Divider />
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col xs={24} sm={24} md={24} lg={24} className="gutter-row">
              <KnownAssociateGrid KnownAssociates={this.props.KnownAssociate} />
            </Col>
          </Row>
          <br />
        </Spin>
      </>
    );
  }
}

KnownAssociates.propTypes = {
  // dispatch: PropTypes.func,
//   edit: PropTypes.func,
  loading: PropTypes.bool,
//   Id: PropTypes.number,
  KnownAssociate: PropTypes.arrayOf(PropTypes.shape({})),
};

KnownAssociates.defaultProps = {
  // dispatch: () => {},
//   edit: () => {},
  loading: false,  
//   Id: 0,
  KnownAssociate: [],
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;

  return { loading };
}

const connectedNominalKnownAssociates = connect(mapStateToProps)(KnownAssociates);

export { connectedNominalKnownAssociates as default };
import { toast } from "react-toastify";
import constants from "./constants";
import { NominalService, AlertService } from "../../../services";

function closeBulkMessageModal() {
  function close() {
    return { type: constants.CLOSE_BULK_MESSAGE_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function openBulkMessageModal() {
  function open() {
    return { type: constants.OPEN_BULK_MESSAGE_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}


function sendBulkMessages(data,callback)
{
  function request() {
    return { type: constants.MESSAGE_BULK_REQUEST };
  }
  function success() {
    return { type: constants.MESSAGE_BULK_SUCCESS };
  }
  function failure(error) {
    return { type: constants.MESSAGE_BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.messageNominalBulk(data).then(
      (response) => {
        dispatch(success());
        if(response.data.HasError === true){
          toast.error(response.data.Error);         
        }
        else{
          toast("Successfully created messages");
          callback();          
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Unable to create messages, please try again");
      }
    );
  };
}

function closeBulkAlertModal() {
  function close() {
    return { type: constants.CLOSE_BULK_ALERT_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function openBulkAlertModal() {
  function open() {
    return { type: constants.OPEN_BULK_ALERT_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function resolveBulkAlerts(data,callback)
{
  function request() {
    return { type: constants.ALERT_BULK_REQUEST };
  }
  function success() {
    return { type: constants.ALERT_BULK_SUCCESS };
  }
  function failure(error) {
    return { type: constants.ALERT_BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    AlertService.resolveAlertBulk(data).then(
      (response) => {
        console.log('alert success', response);
        dispatch(success());
        if(response.HasError === true){
          toast.error(response.Error);         
        }
        else{
          toast("Successfully resolved alerts");
          callback();          
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Unable to resolved alerts, please try again");
      }
    );
  };
}


function closeExportModal() {
  function close() {
    return { type: constants.CLOSE_EXPORT_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function openExportModal() {
  function open() {
    return { type: constants.OPEN_EXPORT_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function exportNominalDataToPDF(data,callback)
{
  function request() {
    return { type: constants.EXPORT_REQUEST };
  }
  function success() {
    return { type: constants.EXPORT_SUCCESS };
  }
  function failure(error) {
    return { type: constants.EXPORT_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.exportNominalData(data).then(
      (response) => {
        dispatch(success());
        if(response.data.HasError === true){
          toast.error(response.data.Error);         
        }
        else{
          toast("Nominal data exported");
          callback();          
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Unable to export data, please try again");
      }
    );
  };
}

function closeSelfieReviewModal() {
  function close() {
    return { type: constants.CLOSE_SELFIE_REVIEW_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function openSelfieReviewModal(FileId) {
  function open(fileId) {
    return { type: constants.OPEN_SELFIE_REVIEW_MODAL, fileId };
  }
  return (dispatch) => {
    dispatch(open(FileId));
  };
}

export default {
  closeBulkMessageModal,
  openBulkMessageModal,
  sendBulkMessages,
  closeBulkAlertModal,
  openBulkAlertModal,
  resolveBulkAlerts,
  closeExportModal,
  openExportModal,
  exportNominalDataToPDF,
  closeSelfieReviewModal,
  openSelfieReviewModal,
}
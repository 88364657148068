export default ({
    OPEN_BULK_MESSAGE_MODAL: 'OPEN_BULK_MESSAGE_MODAL',
    CLOSE_BULK_MESSAGE_MODAL: 'CLOSE_BULK_MESSAGE_MODAL',
    MESSAGE_BULK_REQUEST: 'MESSAGE_BULK_REQUEST',
    MESSAGE_BULK_SUCCESS: 'MESSAGE_BULK_SUCCESS',
    MESSAGE_BULK_FAILURE: 'MESSAGE_BULK_FAILURE',
    OPEN_BULK_ALERT_MODAL: 'OPEN_BULK_ALERT_MODAL',
    CLOSE_BULK_ALERT_MODAL: 'CLOSE_BULK_ALERT_MODAL',
    ALERT_BULK_REQUEST: 'ALERT_BULK_REQUEST',
    ALERT_BULK_SUCCESS: 'ALERT_BULK_SUCCESS',
    ALERT_BULK_FAILURE: 'ALERT_BULK_FAILURE',
    OPEN_EXPORT_MODAL: 'OPEN_EXPORT_MODAL',
    CLOSE_EXPORT_MODAL: 'CLOSE_EXPORT_MODAL',
    EXPORT_REQUEST: 'EXPORT_REQUEST',
    EXPORT_SUCCESS: 'EXPORT_SUCCESS',
    EXPORT_FAILURE: 'EXPORT_FAILURE',
    OPEN_SELFIE_REVIEW_MODAL: 'OPEN_SELFIE_REVIEW_MODAL',
    CLOSE_SELFIE_REVIEW_MODAL: 'CLOSE_SELFIE_REVIEW_MODAL',
})
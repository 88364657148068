import {
  isValidNumber,
  getCountries,
  getCountryCallingCode,
} from "libphonenumber-js";

function sortCountries(c1, c2) {
  if (c1 === "UK") {
    return -1;
  }
  if (c2 === "UK") {
    return 1;
  }
  return 0;
}

const phoneNumberCountryCodes = getCountries()
  .map((v) => (v === "GB" ? "UK" : v))
  .sort(sortCountries);

function getModifiedCountryCallingCode(country) {
  return country === "UK"
    ? getCountryCallingCode("GB")
    : getCountryCallingCode(country);
}

function isValidPhoneNumber(value = "") {
  let countryCode = this.parent && this.parent.CountryCode;
  if (countryCode === "UK") countryCode = "GB";
  return isValidNumber(value, countryCode);
}

function phoneNumberFormatter(value) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (cvLength < 4) return currentValue;
  if (cvLength < 7)
    return `${currentValue.slice(0, 3)}${currentValue.slice(3)}`;
  return `${currentValue.slice(0, 3)}${currentValue.slice(
    3,
    6
  )}${currentValue.slice(6, 10)}`;
}

function phoneCodeAndNumberFormatter(value) {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (cvLength < 4) return currentValue;
  if (cvLength < 7)
    return `${currentValue.slice(0, 3)}${currentValue.slice(3)}`;
  if (cvLength < 11)
    return `${currentValue.slice(0, 3)}${currentValue.slice(
      3,
      6
    )}${currentValue.slice(6, 10)}`;
  if (cvLength < 12)
    return `${currentValue.slice(0, 1)} ${currentValue.slice(1, 4)}${currentValue.slice(4,7)}${currentValue.slice(7, 11)}`;
  if (cvLength < 13)
   return `${currentValue.slice(0, 2)} ${currentValue.slice(2, 5)}${currentValue.slice(5,8)}${currentValue.slice(8, 12)}`;
    
  return `${currentValue.slice(0, 3)} ${currentValue.slice(3, 6)}${currentValue.slice(6,9)}${currentValue.slice(9, 13)}`;
  
}

export {
  phoneNumberCountryCodes,
  getModifiedCountryCallingCode as getCountryCallingCode,
  sortCountries,
  isValidPhoneNumber,
  phoneNumberFormatter,
  phoneCodeAndNumberFormatter
};

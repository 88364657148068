import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Row, Col, DatePicker } from "antd";
import moment from "moment";
import navigationActions from "../../../common/components/navigation/action";
import "./HomePage.css";
import actions from "../../Allocation/actions";
import NominalTypesSelect from "../../../common/components/nominal-type-select";
import RiskLevelSelect from "../../../common/components/risk-level-select";

const { RangePicker } = DatePicker;

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      // searchQuery: "$filter=",
      nominalTypeId: null,
      riskLevelId: null,
      nominalAllocationList: [],
      allocationList: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("dashboard"));
    this.getAllocationData();
    this.getNominalAllocationData({
      ForceId: this.props.currentUser.ForceId,
      StartDate: null,
      EndDate: null,
    });
  }

  getFilters = (field) => {
    const fieldFilter = [];
    this.state.nominalAllocationList.map((user) => {
      const force = { text: user[field], value: user[field] || " " };

      if (
        !fieldFilter.filter((e) => e.value === (user[field] || " ")).length > 0
      )
        fieldFilter.push(force);

      return fieldFilter;
    });
    return fieldFilter.sort();
  };

  getAllocationData = (searchQuery) => {
    let query = `$filter=ForceId eq ${this.props.currentUser.ForceId}`;
    if (searchQuery) query = `${query}${searchQuery}`;
    this.props.dispatch(actions.getAllocationData(query, this.setUserData));
  };

  getNominalAllocationData = (data) => {
    this.props.dispatch(
      actions.getNominalAllocationData(data, this.setNominalAllocationList)
    );
  };

  setUserData = (data) => {
    this.setState({
      allocationList: data,
    });
  };

  setNominalAllocationList = (data) => {
    const used = new Map();
    const ul = [];
    const keysList = [];
    for (let i = 0; i < data.length; i += 1) {
      const entry = data[i];
      const keyObj = { ForceName: entry.ForceName };
      if (entry.AreaName) keyObj.AreaName = entry.AreaName;
      if (entry.TeamName) keyObj.TeamName = entry.TeamName;
      let staticKey = keysList.find(
        (k) =>
          k.ForceName === keyObj.ForceName &&
          k.AreaName === keyObj.AreaName &&
          k.TeamName === keyObj.TeamName
      );
      if (!staticKey) {
        keysList.push(keyObj);
        staticKey = keyObj;
      }
      /* eslint-disable-next-line react/prop-types */
      let currentUsed = entry.Used;
      const existingVal = used.get(staticKey);
      if (existingVal) {
        currentUsed += existingVal;
      }
      used.set(staticKey, currentUsed);
    }
    Array.from(used.keys()).map((key) =>
      ul.push({
        ForceName: key.ForceName,
        AreaName: key.AreaName,
        TeamName: key.TeamName,
        Used: used.get(key),
      })
    );
    this.setState({
      nominalAllocationList: ul,
    });
  };

  onSearchDate = (vals) => {
    if (vals && vals.length === 2) {
      const start = vals[0].utc().format("YYYY-MM-DD").replace("+", "%2B");
      const end = vals[1].utc().format("YYYY-MM-DD").replace("+", "%2B");
      this.getAllocationData(
        ` and StartDate ge ${start} and StartDate le ${end}`
      );
      this.getNominalAllocationData({
        ForceId: this.props.currentUser.ForceId,
        StartDate: start,
        EndDate: end,
        NominalTypeId: this.state.nominalTypeId,
        RiskLevelId: this.state.riskLevelId,
      });
      this.setState({
        startDate: start,
        endDate: end,
      });
    } else {
      this.getAllocationData();
      this.getNominalAllocationData({
        ForceId: this.props.currentUser.ForceId,
        StartDate: null,
        EndDate: null,
        NominalTypeId: this.state.nominalTypeId,
        RiskLevelId: this.state.riskLevelId,
      });
      this.setState({
        startDate: null,
        endDate: null,
      });
    }
  };

  onNominalTypeChange = (value) => {
    if (value) {
      this.setState({
        nominalTypeId: value,
      });
    } else {
      this.setState({
        nominalTypeId: null,
      });
    }
    this.getNominalAllocationData({
      ForceId: this.props.currentUser.ForceId,
      StartDate: this.state.startDate,
      EndDate: this.state.endDate,
      NominalTypeId: value,
      RiskLevelId: this.state.riskLevelId,
    });
  };

  onRiskLevelChange = (value) => {
    if (value) {
      this.setState({
        riskLevelId: value,
      });
    } else {
      this.setState({
        riskLevelId: null,
      });
    }
    this.getNominalAllocationData({
      ForceId: this.props.currentUser.ForceId,
      StartDate: this.state.startDate,
      EndDate: this.state.endDate,
      NominalTypeId: this.state.nominalTypeId,
      RiskLevelId: value,
    });
  };

  getTotalIssued = () => {
    let total = 0;
    for (let i = 0; i < this.state.allocationList.length; i += 1) {
      // eslint-disable-next-line react/prop-types
      total += this.state.allocationList[i].Total;
    }
    return total;
  };

  render() {
    const gridColumns = [
      {
        title: "Force",
        dataIndex: "ForceName",
        key: "ForceName",
        sorter: (a, b) => {
          if (a.ForceName.toUpperCase() > b.ForceName.toUpperCase()) return 1;

          if (a.ForceName.toUpperCase() < b.ForceName.toUpperCase()) return -1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
        filters: this.getFilters("ForceName"),
        onFilter: (value, record) => (record.ForceName || " ") === value,
      },
      {
        title: "Area",
        dataIndex: "AreaName",
        key: "AreaName",
        sorter: (a, b) => {
          if (a.AreaName && !b.AreaName) return 1;
          if (!a.AreaName && b.AreaName) return -1;
          if (!a.AreaName && !b.AreaName) return 0;
          if (a.AreaName.toUpperCase() > b.AreaName.toUpperCase()) return 1;

          if (a.AreaName.toUpperCase() < b.AreaName.toUpperCase()) return -1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
        filters: this.getFilters("AreaName"),
        onFilter: (value, record) => (record.AreaName || " ") === value,
      },
      {
        title: "Team",
        dataIndex: "TeamName",
        key: "TeamName",
        sorter: (a, b) => {
          if (a.TeamName && !b.TeamName) return 1;
          if (!a.TeamName && b.TeamName) return -1;
          if (!a.TeamName && !b.TeamName) return 0;
          if (a.TeamName.toUpperCase() > b.TeamName.toUpperCase()) return 1;

          if (a.TeamName.toUpperCase() < b.TeamName.toUpperCase()) return -1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
        filters: this.getFilters("TeamName"),
        onFilter: (value, record) => (record.TeamName || " ") === value,
      },
      {
        title: "Used",
        dataIndex: "Used",
        sorter: (a, b) => {
          if (a.Used > b.Used) return 1;
          if (a.Used < b.Used) return -1;
          return 0;
        },
        sortDirections: ["descend", "ascend"],
      },
    ];

    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row>
          <Col xs={24} md={11}>
            <span className="mosovo-table-header">Allocations</span>
          </Col>
          <Col sm={24} md={2}>
            <span style={{ textAlign: "right", paddingRight: "5px", flex: 1 }}>
              Nominal Type:{" "}
            </span>
          </Col>
          <Col xs={24} md={4}>
            <NominalTypesSelect
              id="NominalTypeId"
              name="NominalTypeId"
              value={this.state.nominalTypeId}
              onChange={this.onNominalTypeChange}
              isAllowClear
            />
          </Col>
          <Col sm={0} md={1} />
          <Col sm={24} md={2}>
            <span
              style={{ textAlign: "right", paddingRight: "5px", width: "100%" }}
            >
              Risk Level:{" "}
            </span>
          </Col>
          <Col xs={24} md={4}>
            <RiskLevelSelect
              id="RiskLevelId"
              name="RiskLevelId"
              value={this.state.riskLevelId}
              onChange={this.onRiskLevelChange}
              isAllowClear
            />
          </Col>
        </Row>
        <br />
        <Row justify="end" gutter={{ xs: 8, sm: 16, md: 12 }}>
          <Col xs={19} sm={20} md={18} lg={12} className="gutter-row">
            <RangePicker
              className="p5-form-datepicker-input"
              format="DD/MM/YYYY"
              separator=" to "
              onChange={this.onSearchDate}
              placeholder={["License Start Date", "License End Date"]}
            />
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <p>
              Total Licenses Issued
              {this.state.startDate && this.state.endDate
                ? ` ${moment(this.state.startDate).format(
                    "DD/MM/YYYY"
                  )} - ${moment(this.state.endDate).format("DD/MM/YYYY")}`
                : ""}
              : {this.getTotalIssued()}
            </p>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <Table
              dataSource={this.state.nominalAllocationList}
              columns={gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

HomePage.propTypes = {
  dispatch: PropTypes.func,
  currentUser: PropTypes.shape({
    ForceId: PropTypes.number,
  }),
};

HomePage.defaultProps = {
  dispatch: () => {},
  currentUser: {},
};

function mapStateToProps(state) {
  const { currentUser } = state.Auth;
  return { currentUser };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);

export { connectedHomePage as default };

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Table } from "antd";
import moment from "moment";
import Geocode from "react-geocode";
import { Map } from ".";
import { DateFilterDropdown } from "../../../common/components/date-filter-dropdown";
import actions from "../../Nominals/actions";
import {
  getColumnSearchProps,
  dateSorter,
  timeSorter,
  stringSorter,
  INPUT,
  DATE,
  TIME,
} from "../../../common/utils/grid-filter-helper";

let hasSelectedNominal = false;

class MyNominalsList extends React.Component {
  nominalId = 0;

	constructor(props) {
		super(props);
		this.state = {
			selectedRowIds: [],
      mySelectedNominalsList: [],
      selectedNominalLocationHistory: [],
      nominalLocationsToShow: [],
      selectedNominalDetail: {},
      mapRef: null,
      exclusionZonesToShow: [],
      homePosition: [],
		};
    this.nominalId = window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1);
    if (this.nominalId > 0) {
      this.initialSetupOfIndividualData();
    }
    this.setOnSearchDateRef(this.setNominalLocationData);
	}

  componentDidUpdate(previousProps) {
    if(previousProps.showAll !== this.props.showAll) {
      this.onSelectedRowKeysChange([]);
    }

    if(!hasSelectedNominal) {
      if (this.nominalId > 0 && !this.state.selectedNominalDetail.ExclusionZones) {
        this.initialSetupOfIndividualData();
      } else {
        this.getNominalDataById();
      }
    } else {
      this.updateTable();
    }
  }

  setExclusionZonesToShow(zones) {
    const existingZones = this.state.exclusionZonesToShow;
    if (zones !== existingZones) {
      this.setState((prevState) => ({
        ...prevState,
        exclusionZonesToShow: zones
      }));
  }
  }

  updateTable = () => {
    if(!this.isDisplayingLocationHistory()) {
      window.location.reload();
    }
  }

	selectRow = (record) => {
    const datasource = !this.isDisplayingLocationHistory() ? this.props.myNominalList : this.state.nominalLocationsToShow;
		// eslint-disable-next-line react/no-access-state-in-setstate
		let selectedRowIds = [...this.state.selectedRowIds];

    if(this.props.showAll) {
      selectedRowIds = datasource.map(x => x.Id);
    } 

		if(selectedRowIds.indexOf(record.Id) >= 0) {
      selectedRowIds.splice(selectedRowIds.indexOf(record.Id), 1);
      if (document.getElementById("viewAllNominals")) document.getElementById("viewAllNominals").checked = false;
      this.props.updateShowAll();
		} else {
			selectedRowIds.push(record.Id);
		}

    this.state.mySelectedNominalsList = 
       datasource
                 .filter(x => selectedRowIds.includes(x.Id));
    
		this.setState((prevState) => ({ 
        ...prevState,
        selectedRowIds: [...selectedRowIds], 
    }));
	}

  // setNominalDetails = (details) => {
  //  this.props.selectedNominalDetail = details; 
  // }

	onSelectedRowKeysChange = (selectedRowIds) => {
    const datasource = !this.isDisplayingLocationHistory() ? this.props.myNominalList : this.state.nominalLocationsToShow;

    if(this.state.mySelectedNominalsList.length === datasource) {
      document.getElementById("viewAllNominals").checked = false;
      this.props.updateShowAll();
    } else if(this.props.showAll) {
      // eslint-disable-next-line no-param-reassign
      selectedRowIds = datasource.map(x => x.Id);
    } 
    
    this.state.mySelectedNominalsList = 
    datasource
    .filter(x => selectedRowIds.includes(x.Id));
    
    // this.props.dispatch(actions.getNominalBasicDetail(datasource[0].Id, this.setNomimalDetails));
    if(this.state.mySelectedNominalsList.length > 0) {
      if (this.nominalId > 0) {
        this.props.dispatch(actions.getNominalDetail(this.nominalId, this.setNominalDetailsData));// (data) => { this.details = data}));
      } else {
        for (let i = 0; i < this.state.mySelectedNominalsList.length; i += 1) {
          this.props.dispatch(actions.getNominalDetail(this.state.mySelectedNominalsList[i].Id, this.setHomePosition));// (data) => { this.details = data}));
        }
      }
    } else {
      this.setState({
        homePosition: []
      });
      this.setExclusionZonesToShow([]);
      //  this.setNominalDetailsData({
      //    ExclusionZones: []
      //  });
    }


    // console.log(`details = ${  JSON.stringify(details)}`);
		this.setState((prevState) => ({ ...prevState, selectedRowIds}));
	}

  addMarker = (data) => {
    if (this.state.mapRef && this.state.mapRef.state && this.state.mapRef.state.map) {
      const mapToMark = this.state.mapRef.state.map;
      // eslint-disable-next-line no-new
      new window.google.maps.Marker({
        position: {lat: data.Latitude, lng: data.Longitude},
        mapToMark,
        title: data.Address,
      });
      mapToMark.panTo({lat: data.Latitude, lng: data.Longitude});
    }
  }

  getNominalDataById = () => {
    // const queryStringRecordId = window.location.toString().match(/recordId=\d+/);
    // const recordId = queryStringRecordId !== null ? queryStringRecordId[0].match(/\d+/) : null;

    // if(recordId !== null) {
    if (this.nominalId > 0) {
      if (!this.state.exclusionZonesToShow || (this.state.selectedNominalDetail.ExclusionZones && 
        this.state.exclusionZonesToShow.length !== this.state.selectedNominalDetail.ExclusionZones.length)) {
        this.setExclusionZonesToShow(this.state.selectedNominalDetail.ExclusionZones);
      }
      hasSelectedNominal = true;
      this.props.dispatch(actions.getMyNominalLocationData(`$filter=NominalId eq ${this.nominalId}`, this.setNominalLocationData));
    }
  }

  setNominalLocationData = (data) => {
    // let selectedTime = this.state.selectDropdownValue;
    // if (!selectedTime) selectedTime = 'Last Location';
    this.setState({
      selectedNominalLocationHistory: data,
      selectedRowIds: [],
      mySelectedNominalsList: [],
    }, function() {
      this.selectLocationsFromTimestamp(undefined, data)
    });
  }

  // setHomePosition = (data) => {
  //   const hps = [...this.state.homePosition];
  //   const foundHp = hps.find(h => h.Address1 === data.Address1 && h.City === data.City && h.PostalCode === data.PostalCode);
  //   if (!foundHp) {
  //     this.state.homePosition = [];
  //     for (let i = 0; i < hps.length; i += 1) {
  //       this.state.homePosition.push({ Address1: hps[i].Address1, City: hps[i].City, PostalCode: hps[i].PostalCode })
  //     }
  //     this.state.homePosition.push({ Address1: data.Address1, City: data.City, PostalCode: data.PostalCode })
  //   }
  // }

  setHomePosition = async (hp) => {
    const address = `${hp.Address1}, ${hp.City} ${hp.PostalCode}`;
    let home = {
      lat: null,
      lng: null,
    };

    let hps = [...this.state.homePosition];
    let foundHp = hps.find(h => h.NominalId === hp.Id && h.FirstName === hp.FirstName && h.LastName === hp.LastName && h.Address1 === hp.Address1 && h.Address2 === hp.Address2 && h.City === hp.City && h.PostalCode === hp.PostalCode);
    if (!foundHp || foundHp.lat === null || foundHp.lng === null || foundHp.lat === undefined || foundHp.lng === undefined) {
      await Geocode.fromAddress(address)
        .then((response) => {
          home = response.results[0].geometry.location;
          hps = [...this.state.homePosition];
          foundHp = hps.find(x => x.lat === home.lat && x.lng === home.lng);
          if (!foundHp) {
            const positions = [];
            for (let i = 0; i < hps.length; i += 1) {
              positions.push({ NominalId: hps[i].Id,FirstName: hps[i].FirstName, LastName: hps[i].LastName,  Address1: hps[i].Address1, Address2: hps[i].Address2, City: hps[i].City, PostalCode: hps[i].PostalCode, lat: hps[i].lat, lng: hps[i].lng});
            }
            positions.push({ NominalId: hp.Id, FirstName: hp.FirstName, LastName: hp.LastName, Address1: hp.Address1, Address2: hp.Address2, City: hp.City, PostalCode: hp.PostalCode, lat: home.lat, lng: home.lng})
            this.setState({
              homePosition: positions
            })
          }
        })
        // eslint-disable-next-line no-console
        .catch((error) => {
          console.log(error);
        });
    }
  };


  setNominalDetailsData = (data, callback) => {
    this.setHomePosition(data);
    this.setState({
      selectedNominalDetail: data,
    }, () => {
      if (callback) { callback() }});
  }

  selectLocationsFromTimestamp = (selectedTime, data) => {
    // const selectedTime = event ? event.target.value : this.state.selectDropdownValue;
    // if (selectedTime !== this.state.selectDropdownValue) {
    //   this.setState((prevState) => ({
    //     ...prevState,
    //     selectDropdownValue: selectedTime
    //   }));
    // }
    // const locationStartDate = new Date();
    // const deltaTime = {days: 0, milliseconds: 0};
    // let isLastLocation = false;

    // switch(selectedTime) {
    //   case 'Last Location':
    //     isLastLocation = true;
    //     break;
    //   case '20 Minutes':
    //     deltaTime.milliseconds = 60 * 20 * 1000;
    //     break;
    //   case 'Hour':
    //     deltaTime.milliseconds = 60 * 60 * 1000;
    //     break;
    //   case 'Day':
    //     deltaTime.days = 1;
    //     break;
    //   case '3 Days':
    //     deltaTime.days = 3;
    //     break;
    //   case 'Week':
    //     deltaTime.days = 7;
    //     break;
    //   default:
    // }

    let allLocations = data;
    if (this.state.selectedNominalLocationHistory && this.state.selectedNominalLocationHistory.length > 0) {
      allLocations = this.state.selectedNominalLocationHistory;
    }

    if (!selectedTime) {
      this.state.nominalLocationsToShow = allLocations;
      this.state.mySelectedNominalsList = allLocations;
    } else if (selectedTime === 'Most Recent') {
      const lastLoc = allLocations[0];
      if (lastLoc) {
        this.state.mySelectedNominalsList = [lastLoc];
        this.state.nominalLocationsToShow = [lastLoc];
      }
    } else {
      const locationStartDate = moment(selectedTime).local().format();
      //   locationStartDate.setDate(locationStartDate.getDate() - deltaTime.days);
      // locationStartDate.setTime(locationStartDate.getTime() - deltaTime.milliseconds);

      this.state.nominalLocationsToShow = 
      allLocations.filter(x => moment(new Date(x.CreatedDate)).local().isAfter(locationStartDate));
      this.state.mySelectedNominalsList = this.state.nominalLocationsToShow;
    }

    // When the locations are loaded, this only needs to get the records for the first page
    // Since the page always defaults to 10 records a page, this manually sets the first 10 as selected
    // Instead of all of them at once.
    this.setState((prevState) => ({
      // eslint-disable-next-line react/no-access-state-in-setstate
      selectedRowIds: prevState.mySelectedNominalsList.map(x => x.Id).slice(0, 10),
      mySelectedNominalsList: prevState.mySelectedNominalsList.slice(0, 10),
    }));
  }

  isDisplayingLocationHistory = () => {
    return this.nominalId > 0;
    // return window.location.toString().indexOf("recordId") !== -1;
  }

  mapRefSetter = (gmap) => {
    if (!this.state.mapRef) {
      this.setState((prevState) => ({
        ...prevState,
        mapRef: gmap,
      }));
    }
  };

  setOnSearchDateRef = (func) => {
    this.props.setListRef(func);
  }
    
  getLocalTime = (record) => {
    const recordDate = moment(record.CreatedDate);
    const localTime = recordDate.local();
    const formattedTime = localTime.format('h:mm:ss A');
    return formattedTime;
  }

  initialSetupOfIndividualData() {
    hasSelectedNominal = true;
    this.props.dispatch(actions.getNominalDetail(this.nominalId, (data => this.setNominalDetailsData(data, this.getNominalDataById))));
  }

  render() {
		const rowSelection = {
			selectedRowKeys: this.state.selectedRowIds,
			onChange: this.onSelectedRowKeysChange,
		}

    const nominalListGridColumns = [
			{
        title: "",
        dataIndex: "Index",
        key: "Index",
        render: (text, record) =>
          record.FullName !== null ? (
            <div className="nominalId">
              {record.key + 1}
            </div>
          ) : null,   
      },
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => {return stringSorter(a, b, "FullName");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "FullName", title: "Name", componentType: INPUT}),
        render: (text, record) =>

          record.FullName !== null ? (
            <>
              <span>
                <Link to={`map/${record.Id}`} onClick={this.getNominalDataById}>
                  {record.FullName}
                </Link>
              </span>
            </>
          ) : null,   
      },
      {
        title: "DOB",
        dataIndex: "DOB",
        key: "DOB",
        sorter: (a, b) => {return dateSorter(a, b, "DateOfBirth");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "DateOfBirth", title: "DateOfBirth", componentType: DATE}),
        render: (text, record) =>
          this.props.myNominalList.length >= 1 ? (
            <>
              <span>{moment(record.DateOfBirth).format('DD-MM-YYYY')}</span>
            </>
          ) : null,
      },
    ];

    const nominalLocationGridColumns = [
      {
        title: "Date",
        dataIndex: "Date",
        key: "Date",
        sorter: (a, b) => {return dateSorter(a, b, "Date");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "Date", title: "Date", componentType: DATE}),
        render: (text, record) =>
          this.isDisplayingLocationHistory() ? (
            <>
              <span>{moment(record.CreatedDate).local().format('DD-MM-YYYY')}</span>
            </>
          ) : null,   
      },
      {
        title: "Time",
        dataIndex: "Time",
        key: "Time",
        sorter: (a, b) => {return timeSorter(moment(a.Time, 'HH:mm:ss'), moment(b.Time, 'HH:mm:ss'));},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "Time", title: "Time", componentType: TIME}),
        render: (text, record) =>
          this.isDisplayingLocationHistory() ? (
            <>
              <span>{this.getLocalTime(record)}</span>
            </>
          ) : null,
      },
    ];

    return (
      <div className="dataDisplay">

        <div className="nominalTable">
          {this.isDisplayingLocationHistory() && 
          (
          <div className="viewLastSelectorContainer">
            {/* <span className="viewLastLabel">View last: </span> */}
            <DateFilterDropdown onChange={this.selectLocationsFromTimestamp} />
            {/* <select value={this.state.selectDropdownValue} onChange={this.selectLocationsFromTimestamp} className="viewLastSelector">
              <option className="viewLastOption" value="Last Location">Last Location</option>
              <option className="viewLastOption" value="20 Minutes">20 Minutes</option>
              <option className="viewLastOption" value="Hour">Hour</option>
              <option className="viewLastOption" value="Day">Day</option>
              <option className="viewLastOption" value="3 Days">3 Days</option>
              <option className="viewLastOption" value="Week">Week</option>
            </select> */}
          </div>
)}
          <Table
            rowKey="Id"
            rowSelection={rowSelection}
            loading={this.props.loading}
            dataSource={this.isDisplayingLocationHistory() ? this.state.nominalLocationsToShow : this.props.myNominalList}
            columns={this.isDisplayingLocationHistory() ? nominalLocationGridColumns : nominalListGridColumns}
            scroll={{ x: true }}
            onRow={(record) => ({
            onClick: () => {
              this.selectRow(record);
            },
					})}
          />
        </div>
        <div style={{ width: "60%", marginRight: "40px" }}>
          <Map
            mapRefSetter={this.mapRefSetter}
            homePosition={this.state.homePosition}
            zoomLevel={10}
            dispatch={this.props.dispatch}
            exclusionZones={this.state.exclusionZonesToShow}
            data={this.state.mySelectedNominalsList}
            selectedIDs={this.state.selectedRowIds}
            showPinHover="true"
          />
        </div>
      </div>
    );
  }
}

MyNominalsList.propTypes = {
  setListRef: PropTypes.func,
  dispatch: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  myNominalList: PropTypes.array,
  currentUser: PropTypes.shape({
    Id: PropTypes.number,
  }),
  loading: PropTypes.bool,
  showAll: PropTypes.bool,
  updateShowAll: PropTypes.func,
};

MyNominalsList.defaultProps = {
  setListRef: () => {},
  dispatch: () => {},
  myNominalList: [],
  currentUser: {
    Id: null
  },
  loading: false,
  showAll: false,
  updateShowAll: () => {},
};

function mapStateToProps(state) {  
  const { loading } = state.Nominal;
  const { selectedIDs } = state.Nominal;
  return {
    loading,
    selectedIDs
  };
}

const connectedMyNominalsList = connect(mapStateToProps)(MyNominalsList);

export { connectedMyNominalsList as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Row,
  Col,
  Form,
  Divider,
  Input,
  Button,
  Checkbox
} from "antd";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CloseBtn } from '../../../common/components/form/button';
import { IconButtons, QuestionSelect } from '../../../common/components';
import actions from '../actions';

const { LocationArrowIconButton } = IconButtons;
const { PhoneAltIconButton } = IconButtons;
const { CommentsIconButton } = IconButtons;
const { TextArea } = Input;

class ContactNominalForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          CheckinRequestQuestionId: null,
          CheckinRequestQuestionAnswerId: null,
          Message: null,
          IsLocationRequest: false,
          IsLocationRequestSuccess: false,
          IsCheckinRequestSuccess: false,
          IsMessageSuccess: false,
          IsMessageSMS: false,
          CheckinRequestType: null,
          Id: this.props.Id
        };
        this.formIK = React.createRef();
      }

pivotData = (data) => ({
    Id: data.Id,
    IsLocationRequest: data.IsLocationRequest,
    CheckinRequestType: data.CheckinRequestType,
    CheckinRequestQuestionId: data.CheckinRequestQuestionId,
    CheckinRequestQuestionAnswerId: data.CheckinRequestQuestionAnswerId,
    Message: data.Message,
    IsMessageSMS: data.IsMessageSMS,
  });

  contactNominal = () => {
    this.props.dispatch(actions.contactNominal(this.state, this.state.IsLocationRequest ? this.handleSuccessCallback : null));
  };

  handleLocationRequestSuccessCallback = () => {
    this.setState((prevState) => ({
      ...prevState,
      IsLocationRequestSuccess: true,
      IsCheckinRequestSuccess: false,
      IsMessageSuccess: false
    }));
    setTimeout(() => this.setState({
      IsLocationRequestSuccess: false,
    }), 5000);
  };

  handleCheckinRequestSuccessCallback = () => {
    this.setState((prevState) => ({
      ...prevState,
      IsLocationRequestSuccess: false,
      IsCheckinRequestSuccess: true,
      IsMessageSuccess: false
    }));
    setTimeout(() => this.setState({
      IsCheckinRequestSuccess: false,
    }), 5000);
  };

  handleMessageSuccessCallback = () => {
    this.setState((prevState) => ({
      ...prevState,
      IsLocationRequestSuccess: false,
      IsCheckinRequestSuccess: false,
      IsMessageSuccess: true
    }));
    setTimeout(() => this.setState({
      IsMessageSuccess: false,
    }), 5000);
  };

  locationRequest = () => {
    this.setState((prevState) => ({
      ...prevState,
      IsLocationRequest: true,
      CheckinRequestQuestionId: null,
      CheckinRequestType: null,
      Message: null
    }), () => {
      this.props.dispatch(actions.contactNominal(this.pivotData(this.state), this.handleLocationRequestSuccessCallback));
    });
  };

  checkinRequest = () => {
    this.setState((prevState) => ({
      ...prevState,
      IsLocationRequest: false,
      Message: null
    }), () => {
      this.props.dispatch(actions.contactNominal(this.pivotData(this.state), this.handleCheckinRequestSuccessCallback));
    });
  }

  messageRequest = () => {
    this.setState((prevState) => ({
      ...prevState,
      IsLocationRequest: false,
      CheckinRequestQuestionId: null,
      CheckinRequestType: null
    }), () => {
      this.props.dispatch(actions.contactNominal(this.pivotData(this.state), this.handleMessageSuccessCallback));
    });
  }

  handleCheckInTypeChange = (changeEvent) => {
    this.setState((prevState) => ({
      ...prevState,
      CheckinRequestType: changeEvent.target.value,
      CheckinRequestQuestionId: null,
    }));
  };

  changeToLocationsTab = () => {
    this.props.goToTab('location');
    this.props.onCancel();
  }

  changeToCheckinsTab = () => {
    this.props.goToTab('checkin');
    this.props.onCancel();
  }

  render() {
    const locationRequestSuccess = (
      <>
        <span style={{color: "#1C5906"}}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className=""
            alt="locationRequestSuccess"
          />
        </span>
        <span style={{color: "#1C5906"}}>Request sent. Go to <Button style={{border: "none", color: "#1890FF", padding: "0px"}} onClick={this.changeToLocationsTab}>Nominal Location tab</Button> to view requested location information.</span>
      </>
    );
    const checkinRequestSuccess = (
      <>
        <span style={{color: "#1C5906"}}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className=""
            alt="checkinRequestSuccess"
          />
        </span>
        <span style={{color: "#1C5906"}}>Request sent. Go to <Button style={{border: "none", color: "#1890FF", padding: "0px"}} onClick={this.changeToCheckinsTab}>Nominal Check-In tab</Button> to view requested check-in information.</span>
      </>
    );
    const messageSuccess = (
      <>
        <span style={{color: "#1C5906"}}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className=""
            alt="checkinRequestSuccess"
          />
        </span>
        <span style={{color: "#1C5906"}}>Message sent.</span>
      </>
    );

    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
            Id: this.props.Id || 0,
            IsLocationRequest: this.props.IsLocationRequest || false,
            CheckinRequestType: this.props.CheckinRequestType || "",
            CheckinRequestQuestionId: this.props.CheckinRequestQuestionId || null,
            Message: this.props.Message || "",
            IsMessageSMS: this.state.IsMessageSuccess || false,
        }}
        validationSchema={Yup.object().shape({
            Id: Yup.number(),
            CheckinRequestType: Yup.string(),
            CheckinRequestQuestionId: Yup.number()
              .when('CheckinRequestType', {
                is: 'Question',
                then: Yup.number().required("Secure Question is required")
              }),
            Message: Yup.string()
        })}
          // onChange={(values) => this.handleChange(values)}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
            // values,
            handleChange,
            handleSubmit,
            handleBlur,
            // setFieldValue,
        }) => (
          <Form id="ContactNominalForm" onSubmit={handleSubmit} onChange={handleChange}>
            <div>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col sm={24} md={6}>
                      <span>Request Location:</span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item>
                        <div style={{ height: "100%", position: "relative" }}>
                          <div style={{ margin: "auto 15px" }}>
                            <LocationArrowIconButton onClick={this.locationRequest} tooltip="Request nominal's location" title="Location Request" />
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  {this.state.IsLocationRequestSuccess && (
                    <Row>
                      <Col sm={24} md={24}>
                        {locationRequestSuccess}
                      </Col>
                    </Row>
                  )}
                  <Divider plain />
                  <Row>
                    <Col sm={24} md={6}>
                      <span>Request Check-In:</span>
                    </Col>
                    <Col>
                      <Row>
                        <Col span={2} className="ContactRadioButton">
                          <input 
                            type="radio"
                            value="Fingerprint" 
                            checked={this.state.CheckinRequestType === 'Fingerprint'} 
                            onChange={this.handleCheckInTypeChange}
                          />
                        </Col>
                        <Col>Require Fingerprint</Col>
                      </Row>
                      <Row>
                        <Col span={2} className="ContactRadioButton">
                          <input 
                            type="radio"
                            value="Selfie"
                            checked={this.state.CheckinRequestType === 'Selfie'} 
                            onChange={this.handleCheckInTypeChange}
                          />
                        </Col>
                        <Col>Selfie Check-In</Col>
                      </Row>
                      { (this.props.Nominal.CheckinSetting && this.props.Nominal.CheckinSetting.Questions.length > 0) && (
                        <div>
                          <Row>
                            <Col span={2} className="ContactRadioButton">
                              <input
                                type="radio"
                                value="Question"
                                checked={this.state.CheckinRequestType === 'Question'} 
                                onChange={this.handleCheckInTypeChange}
                              />
                            </Col>
                            <Col>
                              Secure Question
                            </Col>
                          </Row>
                          <Row>
                            <QuestionSelect
                              id="CheckInQuestion"
                              className="questionToSend"
                              disabled={this.state.CheckinRequestType !== 'Question'}
                              onBlur={handleBlur}
                              questionsToShow={this.props.Nominal.CheckinSetting.Questions.map(x => x.QuestionId)}
                              value={this.state.CheckinRequestQuestionId}
                              onChange={(val) => {
                                this.setState({
                                  CheckinRequestQuestionId: val,
                                  CheckinRequestQuestionAnswerId: this.props.Nominal.CheckinSetting.Questions.find(x => x.QuestionId === val).Id,
                                });
                              }}
                            />
                          </Row>
                        </div>
                      )}
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item>
                        <div style={{ height: "100%", position: "relative" }}>
                          <div style={{ margin: "auto 15px" }}>
                            <PhoneAltIconButton
                              isDisabled={!this.state.CheckinRequestType || (this.state.CheckinRequestType === 'Question' && !this.state.CheckinRequestQuestionId)}
                              onClick={this.checkinRequest} 
                              tooltip="Request nominal to Check-In" 
                              title="Check-In Request" 
                            />
                          </div>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  {this.state.IsCheckinRequestSuccess && (
                    <Row>
                      <Col sm={24} md={24}>
                        {checkinRequestSuccess}
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
              <Divider plain />
              <Row>
                <Col sm={24} md={6}>
                  <span>Send a Message:</span>
                </Col>
                <Col sm={12} md={6}>
                  <Checkbox
                    checked={this.state.IsMessageSMS}
                    onChange={(val) => this.setState({IsMessageSMS: val.target.checked})}
                  > Also send as SMS
                  </Checkbox>
                </Col>
                <Col sm={24} md={12}>
                  <Form.Item>
                    <div style={{ height: "100%", position: "relative" }}>
                      <div style={{ margin: "auto 15px" }}>
                        <CommentsIconButton 
                          isDisabled={!this.state.Message} 
                          onClick={this.messageRequest} 
                          tooltip="Send a message" 
                          title="Send Message"
                        />
                      </div>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              {this.state.IsMessageSuccess && (
                <Row style={{textAlign: 'right', marginRight: '30px', marginBottom: '20px', marginTop: '-20px'}}>
                  <Col sm={24} md={24}>
                    {messageSuccess}
                  </Col>
                </Row>
              )}
              <Row>
                <Col sm={24} md={24}>
                  <TextArea
                    id="Message"
                    rows={4}
                    onBlur={handleBlur}
                    value={this.state.Message}
                    onChange={(val) => {
                      this.setState({
                        Message: val.target.value
                      });
                    }}
                  />
                </Col>
              </Row>
              <Divider plain />
              <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <CloseBtn onClick={this.props.onCancel} />
                </Col>
              </Row>
            </div>
          </Form>
          )}
      </Formik>
      );
    }
}
  
  ContactNominalForm.propTypes = {
    dispatch: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    goToTab: PropTypes.func,
	  IsLocationRequest: PropTypes.bool,
    CheckinRequestType: PropTypes.string,
    CheckinRequestQuestionId: PropTypes.number,
    Message: PropTypes.string,
    Id: PropTypes.number,
    Nominal: PropTypes.shape(),
  };
  
  ContactNominalForm.defaultProps = {
    dispatch: () => {},
    onCancel: () => {},
    goToTab: () => {},
	  IsLocationRequest: false,
    CheckinRequestType: "",
    CheckinRequestQuestionId: null,
    Message: "",
    Id: 0,
    Nominal: null,
  };
  
  function mapStateToProps(state) {
      return { Nominal: state.Nominal.Nominal };
    }
  
    const connectedContactNominalForm = connect(mapStateToProps)(ContactNominalForm);
    
    export { connectedContactNominalForm as default };
  
import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./IconButtons.scss";

class EditIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      size: "1x",
      pull: "",
      className: "mosovo-default-icon",
      tooltip: "Edit",
    };
  }

  handleOnClick = () => {
      this.props.onClick(this.props.value);
  }

  render() {
    return (
      <>
        <Tooltip title={this.props.tooltip || this.state.tooltip}>
          <FontAwesomeIcon
            className={this.props.className || this.state.className}
            id={this.props.id}
            onClick={this.handleOnClick}
            size={this.props.size || this.state.size}
            icon={faEdit}
            pull={this.props.pull || this.state.pull}
          />
        </Tooltip>
      </>
    );
  }
}

EditIcon.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  size: PropTypes.string,
  pull: PropTypes.string,
  className: PropTypes.string,
  value: PropTypes.number,
  tooltip: PropTypes.string,
};

EditIcon.defaultProps = {
  onClick: () => {},
  id: "",
  size: "",
  pull: "",
  className: "",
  value: null,
  tooltip: "",
};

export default EditIcon;

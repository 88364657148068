import React from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
import { Formik, FieldArray } from "formik";
import * as Yup from "yup";

import { uniqueId } from "lodash";

import { Collapse, Row, Col, Form, Input, Button, Select, Divider, Radio } from "antd";
import { faPlus, faMinusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelBtn, SaveBtn } from "../../../common/components/form";

const { Panel } = Collapse;
const { Option } = Select;

class BulkCreateForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
  }

  componentDidUpdate() {
    // this.formIK.current.setFieldValue('ForceName', "")
    // this.formIK.current.setFieldValue('Areas', []);
    // this.formIK.current.setFieldValue('Teams', [])
    this.formIK.current.handleReset();
  }

  handleChange = (e) => {
    const { value, id } = e.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  pivotData = (data) => ({
    Force: {
      Id: data.Id,
      Name: data.ForceName,
    },
    Areas: data.Areas,
    Teams: data.Teams,
  });

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.props.Force.Id || 0,
          ForceName: this.props.Force.Name || "",
          Areas: this.props.Areas || [],
          HaveAreas: false,
          Teams: this.props.Teams || [{
            Id: 0,
            Name: "",
            LawEnforcementAreaId: 0,
          }],
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          ForceName: Yup.string()
            .typeError("Force is required")
            .required("Force is required"),
          Areas: Yup.array().of(
            Yup.object().shape({
              Id: Yup.string(),
              Name: Yup.string(),
            })
          ),
          Teams: Yup.array().of(
            Yup.object().shape({
              Name: Yup.string()
                .typeError("Team is required")
                .required("Team is required"),
              LawEnforcementAreaId: Yup.number(),
            })
          ),
        })}
        onChange={(values) => this.handleChange(values)}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => {
          const onRadioChange = (e) => {
            if(e.target.value) {
              setFieldValue('Areas', [{
                Id: parseInt(uniqueId(), 10),
                Name: "",
              }]);
            } else {
              setFieldValue('Areas', []);
            }
            setFieldValue('HaveAreas', e.target.value);
          }

          return(
            <Form id="BulkForce" onSubmit={handleSubmit} onChange={handleChange}>
              <div>
                <Row>
                  <Col span={24}>
                    <Collapse defaultActiveKey={["1", "2"]}>
                      <Panel header="Force" key="1">
                        <Row>
                          <Col sm={24} md={6}>
                            <span className="required-field">Force Name</span>
                          </Col>
                          <Col sm={24} md={18}>
                            <Form.Item
                              validateStatus={
                                errors && errors.ForceName && touched.ForceName
                                  ? "error"
                                  : "success"
                              }
                              help={
                                errors && errors.ForceName && touched.ForceName
                                  ? errors.ForceName
                                  : null
                              }
                            >
                              <Input
                                id="ForceName"
                                onBlur={handleBlur}
                                value={values.ForceName}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        { values.ForceName.length > 0 && (
                          <Row>
                            <Col span={24}>
                              <span>Does this Force have associated Areas?</span>
                            </Col>
                            <Col span={24}>
                              <Radio.Group onChange={onRadioChange} value={values.HaveAreas}>
                                {/* eslint-disable-next-line */}
                                <Radio value={true}>Yes</Radio>
                                <Radio value={false}>No</Radio>
                              </Radio.Group>
                            </Col>
                          </Row>
                        )}
                      </Panel>
                      {values.HaveAreas && (
                        <Panel
                          header="Area"
                          key="2"
                          collapsible={
                            values.ForceName.length > 0 ? "" : "disabled"
                          }
                        >
                          <FieldArray
                            name="Areas"
                            render={(arrayHelpers) => (
                              <div>
                                {values.Areas.length === 0 && (
                                  <Row justify="end">
                                    <Col xs={4} sm={2} md={2}>
                                      <Button
                                        shape="circle"
                                        className="bulk-form-add-icons"
                                        onClick={() => {
                                          arrayHelpers.push({
                                            Id: parseInt(uniqueId(), 10),
                                            Name: "",
                                          });
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={faPlus}
                                          id=""
                                          className=""
                                          alt=""
                                        />
                                      </Button>
                                    </Col>
                                  </Row>
                                )}
                                {(values.Areas &&
                                  values.Areas.length > 0) && 
                                  values.Areas.map((area, index) => (
                                    <div id={index} key={`${index + 0}`}>
                                      <Row gutter={[24, 16]}>
                                        <Col xs={24} sm={24} md={6} lg={6}>
                                          <span>
                                            Area Name
                                          </span>
                                        </Col>
                                        <Col xs={16} sm={20} md={14} lg={14}>
                                          <Form.Item
                                            touched={`Areas.[${index}].Name`}
                                            key={`Areas.${index + 0}`}
                                            id={`Areas.[${index}].Name`}
                                            validateStatus={
                                              errors &&
                                              touched &&
                                              touched.Areas &&
                                              errors.Areas &&
                                              touched.Areas[index] &&
                                              errors.Areas[index]
                                                ? "error"
                                                : "success"
                                            }
                                            help={
                                              errors &&
                                              touched &&
                                              touched.Areas &&
                                              errors.Areas &&
                                              touched.Areas[index] &&
                                              errors.Areas[index]
                                                ? errors.Areas[index].Name
                                                : null
                                            }
                                          >
                                            <Input
                                              key={`Areas.${index + 0}`}
                                              id={`Areas.[${index}].Name`}
                                              value={area.Name}
                                              onBlur={handleBlur}
                                              onChange={handleChange}
                                            />
                                          </Form.Item>
                                        </Col>
                                        {values.Areas.length > 1 && (
                                          <Col xs={4} sm={2} md={2}>
                                            <Button
                                              shape="circle"
                                              key={`Areas.remove.${index + 0}`}
                                              className="bulk-form-remove-icons"
                                              onClick={() => {
                                                arrayHelpers.remove(index);
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faMinusCircle}
                                                id=""
                                                className=""
                                                alt=""
                                              />
                                            </Button>
                                          </Col>
                                        )}
                                        {values.Areas.length === index + 1 && (
                                          <Col xs={4} sm={2} md={2}>
                                            <Button
                                              shape="circle"
                                              key={`Areas.${index + 0}`}
                                              className="bulk-form-add-icons"
                                              onClick={() => {
                                                arrayHelpers.push({
                                                  Id: parseInt(uniqueId(), 10),
                                                  Name: "",
                                                });
                                              }}
                                            >
                                              <FontAwesomeIcon
                                                icon={faPlus}
                                                id="SearchBtn"
                                                className=""
                                                alt="Search"
                                              />
                                            </Button>
                                          </Col>
                                        )}
                                      </Row>
                                    </div>
                                  ))}
                              </div>
                            )}
                          />
                        </Panel>
                      )}
                      <Panel
                        header="Team"
                        key="3"
                        collapsible={
                          values.ForceName.length > 0 ? "" : "disabled"
                        }
                      >
                        <FieldArray
                          name="Teams"
                          render={(arrayHelpers) => (
                            <div>
                              {values.Teams.length === 0 && (
                                <Row justify="end">
                                  <Col xs={4} sm={2} md={2}>
                                    <Button
                                      shape="circle"
                                      className="bulk-form-add-icons"
                                      onClick={() => {
                                        arrayHelpers.push({
                                          Id: 0,
                                          Name: "",
                                          LawEnforcementAreaId: 0,
                                        });
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        icon={faPlus}
                                        id=""
                                        className=""
                                        alt=""
                                      />
                                    </Button>
                                  </Col>
                                </Row>
                              )}
                              {values.Teams &&
                                values.Teams.length > 0 &&
                                values.Teams.map((team, index) => (
                                  <div id={index} key={`team${index + 1}`}>
                                    <Row gutter={{ xs: 12, sm: 20, md: 14 }}>
                                      <Col xs={24} md={4} lg={4}>
                                        <span className="required-field">
                                          Team Name
                                        </span>
                                      </Col>
                                      <Col xs={16} sm={20} md={8} lg={8} className="gutter-row">
                                        <Form.Item
                                          key={`Teams.${index + 1}.Name`}
                                          id={`Teams.${index}.Name`}
                                          validateStatus={
                                            errors &&
                                            touched &&
                                            touched.Teams &&
                                            errors.Teams &&
                                            touched.Teams[index] &&
                                            errors.Teams[index]
                                              ? "error"
                                              : "success"
                                          }
                                          help={
                                            errors &&
                                            touched &&
                                            touched.Teams &&
                                            errors.Teams &&
                                            touched.Teams[index] &&
                                            errors.Teams[index]
                                              ? errors.Teams[index].Name
                                              : null
                                          }
                                        >
                                          <Input
                                            key={`Teams.${index + 1}`}
                                            id={`Teams.[${index}].Name`}
                                            value={team.Name}
                                            onBlur={handleBlur}
                                          />
                                        </Form.Item>
                                      </Col>
                                      <Col xs={16} sm={20} md={8} lg={8} className="gutter-row">
                                        <Form.Item>
                                          <Select
                                            id={`Teams.[${index}].LawEnforcementAreaId`}
                                            key={`Teams.AreaSelect.${index + 1}`}
                                            placeholder="Please select a value"
                                            onChange={(val) => {
                                              setFieldValue(
                                                `Teams[${index}].LawEnforcementAreaId`,
                                                val
                                              );
                                            }}
                                            size="middle"
                                            value={team.LawEnforcementAreaId}
                                          >
                                            <Option
                                              style={{ borderRadius: 5 }}
                                              key="Teams.AreaSelect.items.none"
                                              value={0}
                                            >
                                              None
                                            </Option>
                                            {Array.from(values.Areas).map(
                                              (opt) => (
                                                <Option
                                                  style={{ borderRadius: 5 }}
                                                  key={`Teams.AreaSelect.items.${opt.Id}`}
                                                  value={opt.Id}
                                                >
                                                  {opt.Name}
                                                </Option>
                                              )
                                            )}
                                          </Select>
                                        </Form.Item>
                                      </Col>
                                      {values.Teams.length > 1 && (
                                        <Col xs={4} sm={2} md={2}>
                                          <Button
                                            shape="circle"
                                            key={`Teams.remove.${index + 0}`}
                                            className="bulk-form-remove-icons"
                                            onClick={() => {
                                              arrayHelpers.remove(index);
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faMinusCircle}
                                              id=""
                                              className=""
                                              alt=""
                                            />
                                          </Button>
                                        </Col>
                                      )}
                                      {values.Teams.length === index + 1 && (
                                        <Col xs={4} sm={2} md={2}>
                                          <Button
                                            shape="circle"
                                            key={`Teams.${index + 0}`}
                                            className="bulk-form-add-icons"
                                            onClick={() => {
                                              arrayHelpers.push({
                                                Id: 0,
                                                Name: "",
                                                LawEnforcementAreaId: 0,
                                              });
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faPlus}
                                              id=""
                                              className=""
                                              alt=""
                                            />
                                          </Button>
                                        </Col>
                                      )}
                                    </Row>
                                  </div>
                                ))}
                            </div>
                          )}
                        />
                      </Panel>
                    </Collapse>
                  </Col>
                </Row>
                <Divider plain />
                <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                  <Col xs={24} sm={8} md={4} className="gutter-row">
                    <CancelBtn onClick={this.props.onCancel} />
                  </Col>
                  <Col xs={24} sm={8} md={4} className="gutter-row">
                    <SaveBtn
                      onClick={handleSubmit}
                      loading={this.props.isLoading}
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          )}}
      </Formik>
    );
  }
}

BulkCreateForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  Force: PropTypes.shape({
    Id: PropTypes.number,
    Name: PropTypes.string,
  }),
  Areas: PropTypes.arrayOf(PropTypes.shape({})),
  Teams: PropTypes.arrayOf(PropTypes.shape({})),
};

BulkCreateForm.defaultProps = {
  onCancel: () => {},
  isLoading: false,
  Force: {
    Id: 0,
    Name: "",
  },
  Areas: [],
  Teams: null,
};

export default BulkCreateForm;
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Row, Col, Tooltip } from "antd";
import { IconButtons } from '../../../common/components'

import actions from "../actions";

const { SearchBox, AddButton, EditIcon } = IconButtons;

class AreaGrid extends React.Component {
  editArea = (Id) => {
    const { dispatch } = this.props;
    dispatch(actions.getAreaDetail(Id, this.openAreaModal));
  }

  openAreaModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openAreaModal());
  }

  OpenAddModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openAddAreaModal());
  };
  
  render() {
    const gridColumns = [
      {
        title: "Area",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => {          
          if(a.Name.toUpperCase() > b.Name.toUpperCase())
            return -1;

          if(a.Name.toUpperCase() < b.Name.toUpperCase())
            return 1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Teams",
        dataIndex: "TeamCount",
        key: "TeamCount",
        sorter: (a, b) => a.TeamCount - b.TeamCount,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Force",
        dataIndex: "ForceName",
        key: "ForceName",
        sorter: (a, b) => {          
          if(a.ForceName.toUpperCase() > b.ForceName.toUpperCase())
            return -1;

          if(a.ForceName.toUpperCase() < b.ForceName.toUpperCase())
            return 1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "",
        dataIndex: "Id",
        key: "Id",
        render: (text, record) =>
          this.props.areaList.length >= 1 ? (
            <>
              <span>
                <EditIcon tooltip={`Edit ${record.Name}`} onClick={this.editArea} value={record.Id} pull="right" />
              </span>
            </>
          ) : null,   
      },
    ];

    return (
      <div style={{backgroundColor: "white", margin: "10px", padding: "15px"}}>
        <Row>
          <Col xs={12} md={10} lg={10}>
            <span className="mosovo-table-header">Areas</span>
          </Col>
          <Col xs={24} md={14} lg={14}>
            <Row justify="end" gutter={{ xs: 8, sm: 16, md: 10 }}>
              <Col xs={19} sm={20} md={18} lg={12} className="gutter-row">
                <SearchBox placeholder="area search" allowClear onSearch={this.props.onSearch} minText={3} loading={this.props.loading} />
              </Col>
              <Col className="gutter-row">
                <Tooltip title="Add new area">
                  <span><AddButton id="AddArea" onClick={this.OpenAddModal} /></span>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <Table
              loading={this.props.loading}
              dataSource={this.props.areaList}
              columns={gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

AreaGrid.propTypes = {
  dispatch: PropTypes.func,
  onSearch: PropTypes.func,
  areaList: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
};

AreaGrid.defaultProps = {
  dispatch: () => {},
  onSearch: () => {},
  areaList: [{}],
  loading: false,
};

function mapStateToProps(state) {
  const { loading } = state.Organisation;
  return {
    loading,
  };
}

const connectedAreaGrid = connect(mapStateToProps)(AreaGrid);

export { connectedAreaGrid as default };

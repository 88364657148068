export default ({
    MY_NOMINAL_REQUEST: 'MY_NOMINAL_REQUEST',
    MY_NOMINAL_SUCCESS: 'MY_NOMINAL_SUCCESS',
    MY_NOMINAL_FAILURE: 'MY_NOMINAL_FAILURE',
    MY_NOMINAL_CREATE_REQUEST: 'MY_NOMINAL_CREATE_REQUEST',
    MY_NOMINAL_CREATE_SUCCESS: 'MY_NOMINAL_CREATE_SUCCESS',
    MY_NOMINAL_CREATE_FAILURE: 'MY_NOMINAL_CREATE_FAILURE',
    MY_NOMINAL_GET_REQUEST: 'MY_NOMINAL_GET_REQUEST',
    MY_NOMINAL_GET_SUCCESS: 'MY_NOMINAL_GET_SUCCESS',
    MY_NOMINAL_GET_FAILURE: 'MY_NOMINAL_GET_FAILURE',
    MY_NOMINAL_UPDATE_REQUEST: 'MY_NOMINAL_UPDATE_REQUEST',
    MY_NOMINAL_UPDATE_SUCCESS: 'MY_NOMINAL_UPDATE_SUCCESS',
    MY_NOMINAL_UPDATE_FAILURE: 'MY_NOMINAL_UPDATE_FAILURE',
    OPEN_ADD_NOMINAL_MODAL: 'OPEN_ADD_NOMINAL_MODAL',
    OPEN_EDIT_NOMINAL_MODAL: 'OPEN_EDIT_NOMINAL_MODAL',
    CLOSE_NOMINAL_MODAL: 'CLOSE_NOMINAL_MODAL',
    LINK_DEVICE_REQUEST: 'LINK_DEVICE_REQUEST',
    LINK_DEVICE_SUCCESS: 'LINK_DEVICE_SUCCESS',
    LINK_DEVICE_FAILURE: 'LINK_DEVICE_FAILURE',
    OPEN_TRANSFER_NOMINAL_MODAL: 'OPEN_TRANSFER_NOMINAL_MODAL',
    CLOSE_TRANSFER_NOMINAL_MODAL: 'CLOSE_TRANSFER_NOMINAL_MODAL',
    TRANSFER_BULK_REQUEST: 'TRANSFER_BULK_REQUEST',
    TRANSFER_BULK_SUCCESS: 'TRANSFER_BULK_SUCCESS',
    TRANSFER_BULK_FAILURE: 'TRANSFER_BULK_FAILURE',
    OPEN_BULK_MESSAGE_MODAL: 'OPEN_BULK_MESSAGE_MODAL',
    CLOSE_BULK_MESSAGE_MODAL: 'CLOSE_BULK_MESSAGE_MODAL',
    MESSAGE_BULK_REQUEST: 'MESSAGE_BULK_REQUEST',
    MESSAGE_BULK_SUCCESS: 'MESSAGE_BULK_SUCCESS',
    MESSAGE_BULK_FAILURE: 'MESSAGE_BULK_FAILURE',
})
import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import moment from "moment";

const { Option } = Select;

class DateFilterDropdown extends React.Component {
  render() {
    return (
      <Select
        style={{ width: "100%" }}
        className={this.props.className}
        onChange={this.props.onChange}
        placeholder="View Last..."
        optionLabelProp="label"
        allowClear
      >
        <Option key="date-filter-1" value="Most Recent" label="Most Recent">
          Most Recent
        </Option>
        <Option
          key="date-filter-2"
          value={`${moment().local().subtract(20, "minutes").local()}`}
          label="Last 20 Minutes"
        >
          Last 20 minutes
        </Option>
        <Option
          key="date-filter-3"
          value={`${moment().local().subtract(1, "hours").local()}`}
          label="Last hour"
        >
          Last hour
        </Option>
        <Option
          key="date-filter-4"
          value={`${moment().local().subtract(12, "hours").local()}`}
          label="Last 12 hours"
        >
          Last 12 hours
        </Option>
        <Option
          key="date-filter-5"
          value={`${moment().local().subtract(24, "hours").local()}`}
          label="Last 24 hours"
        >
          Last 24 hours
        </Option>
        <Option
          key="date-filter-6"
          value={`${moment().local().subtract(3, "days").local()}`}
          label="Last 3 Days"
        >
          Last 3 Days
        </Option>
        <Option
          key="date-filter-7"
          value={`${moment().local().subtract(7, "days").local()}`}
          label="Last Week"
        >
          Last Week
        </Option>
      </Select>
    );
  }
}

DateFilterDropdown.propTypes = {
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
};

DateFilterDropdown.defaultProps = {
  className: "",
};

export default DateFilterDropdown;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col } from "antd";
import { WarningOutlined } from '@ant-design/icons';
import actions from "../actions";
import { CheckinForm } from "../forms";

class CheckinModal extends React.Component {
  CloseModal = () => {
    this.props.CloseModal();
  };

  CloseNominalModal = () => {    
    const { confirm } = Modal; 
    // const { dispatch } = this.props;
    const closeModal = this.CloseModal;
    const message = (
      <div>
        <p>
          Are you sure you want to cancel?
        </p>
        <p>You will lose all data on this form.</p>
      </div>
      
    );

    confirm({
      title: "Close New Nominal",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        closeModal();
      },
      okText: "Yes, Close"
    });
  }

  //   AllocationUpdate = (data) => {
  //     this.props.dispatch(actions.UpdateAllocation(data, this.CloseUserModal));
  //     // console.log("updating", data);
  //   };

    NominalCreate = (data) => {
      this.props.dispatch(actions.createNominal(data, this.CloseModal));
    };

  render() {
    return (
      <>
        <Modal
          title="Check-in Settings"
          visible={this.props.showModal}
          // onCancel={this.CloseModal}
          footer={null}
          width={600}
          onCancel={this.CloseNominalModal}
          destroyOnClose
        >
          <div>
            <Row>
              <Col span={24}>
                <CheckinForm 
                  onCancel={this.CloseNominalModal}
                  onClose={this.CloseModal}
                  {...this.props.Settings}
                />
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

CheckinModal.propTypes = {
  dispatch: PropTypes.func,
  showModal: PropTypes.bool,
  CloseModal: PropTypes.func,
  Settings: PropTypes.shape(),
};

CheckinModal.defaultProps = {
  dispatch: () => {},
  showModal: false,
  CloseModal: () => {},
  Settings: null,
};

function mapStateToProps(state) {
  const { loading, showAddModal, Nominal } = state.Nominal;

  return { loading, showAddModal, Nominal };
}

const connectedCheckinModal = connect(mapStateToProps)(CheckinModal);

export { connectedCheckinModal as default };

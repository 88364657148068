import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router";

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
const PublicRoute = ({ component: Component, authenticated, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        authenticated === true ? (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        ) : (
          <Component {...props} />
        )}
    />
  );
};

export default PublicRoute;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col } from "antd";
import { WarningOutlined } from '@ant-design/icons';

import { UserForm } from "../forms";
import actions from "../actions";

class UserModal extends React.Component {
  CloseUserModal = () => {
    this.props.dispatch(actions.closeUserModal());
    this.props.refreshGrid();
  };

  UserUpdate = (data) => {
    this.props.dispatch(actions.updateUser(data, this.CloseUserModal));
    // console.log("updating", data);
  };

  UserCreate = (data) => {
    this.props.dispatch(actions.createUser(data, this.CloseUserModal));
  };

  UserDelete = () => {    
    const { confirm } = Modal; 
    const { dispatch } = this.props;
    const { Id, UserName } = this.props.user;
    const closeModal = this.CloseUserModal;
    const message = (
      <div>
        <p>
          Deleting a user will remove all record of this user.
        </p>
        <p>Are you sure you want to delete the following user?</p>
        <p>{UserName}</p>
      </div>
      
    );

    confirm({
      title: "Delete User",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        dispatch(actions.deleteUser(Id, closeModal));
      },
      okText: "Yes, Delete"
    });
  }

  render() {
    return (
      <>
        <Modal
          title="Edit User"
          visible={this.props.showEditModal}
          onCancel={this.CloseUserModal}
          footer={null}
          width={600}
        >
          <div>
            <Row>
              <Col span={24}>
                <UserForm
                  isEdit
                  onDelete={this.UserDelete}
                  onCancel={this.CloseUserModal}
                  onSubmit={this.UserUpdate}
                  isLoading={this.props.loading}
                  {...this.props.user}
                />
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal
          title="New User"
          visible={this.props.showAddModal}
          onCancel={this.CloseUserModal}
          footer={null}
          width={600}
        >
          <div>
            <Row>
              <Col span={24}>
                <UserForm
                  onCancel={this.CloseUserModal}
                  onSubmit={this.UserCreate}
                  isLoading={this.props.loading}
                  {...this.props.user}
                />
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

UserModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  showEditModal: PropTypes.bool,
  showAddModal: PropTypes.bool,
  loading: PropTypes.bool,
  user: PropTypes.shape({
      Id: PropTypes.number,
      UserName: PropTypes.string,
  }),
};

UserModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  showEditModal: false,
  showAddModal: false,
  loading: false,
  user: {},
};

function mapStateToProps(state) {
  const { user, loading } = state.Users;
  const { showEditModal, showAddModal } = state.Users.UserModal;

  return { showEditModal, showAddModal, user, loading };
}

const connectedUserModal = connect(mapStateToProps)(UserModal);

export { connectedUserModal as default };

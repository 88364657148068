import Constants from './constants';

const initialState = ({ loading: false, hasError: false, nominalTypes: [] });

export default function nominalTypePicker(state = initialState, action) {
  switch (action.type) {
    case Constants.LIST_REQUEST:
        return {
            ...state,
            loading: true,
            hasError: false,
          };
    case Constants.LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            hasError: false,
            nominalTypes: action.nominalTypes
          };
    case Constants.LIST_FAILURE:
        return {
            ...state,
            loading: false,
            hasError: true,
          };
    default:
      return state;
  }
}
import { toast } from "react-toastify";
import constants from "./constants";
import { AllocationService } from "../../services";

function getAllocationData(querystring ,callback) {
  function request() {
    return { type: constants.HUB_REQUEST };
  }
  function success() {
    return { type: constants.HUB_SUCCESS };
  }
  function failure(error) {
    return { type: constants.HUB_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    AllocationService.getList(querystring).then(
      (hub) => {
        dispatch(success());
        callback(hub.data.value);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function getNominalAllocationData(data ,callback) {
  function request() {
    return { type: constants.HUB_REQUEST };
  }
  function success() {
    return { type: constants.HUB_SUCCESS };
  }
  function failure(error) {
    return { type: constants.HUB_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    AllocationService.getNominalAllocationList(data).then(
      (response) => {
        dispatch(success(response.data));
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function createAllocation(data, callback) {
  function request() {
    return { type: constants.HUB_CREATE_REQUEST };
  }
  function success(hub) {
    return { type: constants.HUB_CREATE_SUCCESS, hub };
  }
  function failure(error) {
    return { type: constants.HUB_CREATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    AllocationService.createAllocation(data).then(
      (hub) => {
        dispatch(success(hub));
        toast("Successfully created a new Allocation");
        callback(hub.data.Id);
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

function GetAllocationById(Id, callback) {
  function request() {
    return { type: constants.HUB_GET_REQUEST };
  }
  function success(hub) {
    return { type: constants.HUB_GET_SUCCESS, hub };
  }
  function failure(error) {
    return { type: constants.HUB_GET_FAILURE, error };
  }
  return (dispatch) => {
    dispatch(request());
    AllocationService.getAllocationById(Id).then(
      (hub) => {
        dispatch(success(hub.data));
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function UpdateAllocation(data, callback) {
  function request() {
    return { type: constants.HUB_UPDATE_REQUEST };
  }
  function success() {
    return { type: constants.HUB_UPDATE_SUCCESS };
  }
  function failure(error) {
    return { type: constants.HUB_UPDATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    AllocationService.updateAllocation(data).then(
      () => {        
        dispatch(success());
        toast("Successfully updated Allocation");    
        callback();   
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

function openAddAllocationModal() {
  function open() {
    return { type: constants.OPEN_ADD_ALLOCATION_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function openEditAllocationModal() {
  function open() {
    return { type: constants.OPEN_EDIT_ALLOCATION_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function closeAllocationModal() {
  function close() {
    return { type: constants.CLOSE_ALLOCATION_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function exportLicenseReport(data) {
  function request() {
    return { type: constants.HUB_CREATE_REQUEST };
  }
  function success(hub) {
    return { type: constants.HUB_CREATE_SUCCESS, hub };
  }
  function failure(error) {
    return { type: constants.HUB_CREATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    AllocationService.exportLicenseReport(data).then(
      (hub) => {
        dispatch(success(hub));
        toast("Successfully exported report to Excel");
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

export default {
  getAllocationData,
  getNominalAllocationData,
  createAllocation,
  GetAllocationById,
  UpdateAllocation,  
  openAddAllocationModal,
  openEditAllocationModal,
  closeAllocationModal,
  exportLicenseReport,
};

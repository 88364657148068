import React from "react";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { Select } from "antd";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;

class DynamicSelect extends React.Component {
  // eslint-disable-next-line no-underscore-dangle
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      filters: "",
      field: "",
      placeholder: "Please select a value",
      isPureData: false,
    };
  }

  componentDidMount() {
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = true;
    this.loadData();
    // console.log(`val:${  this.props.value}`);
  }

  // eslint-disable-next-line consistent-return
  async shouldComponentUpdate(nextProps, nextState) {
    if (
      nextProps.filters !== nextState.filters &&
      nextProps.field === nextState.field
    ) {
      return true;
    }

    // console.log('Should', nextProps, nextState);

    // nextProps.getData(nextProps.filters).then((result) => {
    //   const list1 = [{ Id: 0, Name: "None" }, ...result.data.value];
    //   const list2 = result.data.value;

    //   if(nextProps.hasNone && list1 !== nextState.data)
    //   {
    //     return true;
    //   }

    //   if(list2 !== nextState.data){
    //     return true;
    //   }

    //   return false;
    // });
  }

  componentDidUpdate(prevProps) {
    if (isEqual(prevProps.filters, this.props.filters) === false) {
      this.loadData();
    }
  }

  componentWillUnmount() {
    // eslint-disable-next-line no-underscore-dangle
    this._isMounted = false;
  }

  async loadData() {
    this.props.getData(this.props.filters).then((result) => {
      const list1 = result.data ? [{ Id: 0, Name: "None" }, ...result.data.value] : ["None", ...result];
      const list2 = result.data ? result.data.value : result;

      this.setState((prevState) => ({
        ...prevState,
        field: this.props.field,
        filters: this.props.filters,
        data: this.props.hasNone ? list1 : list2,
        isPureData: result.data,
      }));
    });
  }

  render() {
    return (
      <Select
        className={`${this.props.className}`}
        key={uuidv4()}
        placeholder={this.props.placeholder || this.state.placeholder}
        onChange={this.props.onChange}
        onBlur={this.props.onBlur}
        size="middle"
        style={{ borderRadius: 5 }}
        value={this.props.value}
        disabled={this.props.isDisabled}
      >
        {this.state.isPureData ? (Array.from(this.state.data).map((opt) => (
          
          <Option
            style={{ borderRadius: 5 }}
            key={uuidv4()}
            value={opt.Id}
            disabled={opt.Disabled}
          >
            {opt.Name}
          </Option>
        ))) : (Array.from(this.state.data).map((opt) => (
          <Option style={{ borderRadius: 5 }} key={uuidv4()} value={opt}>
            {opt}
          </Option>
        )))}
      </Select>
    );
  }
}

DynamicSelect.propTypes = {
  placeholder: PropTypes.string,
  getData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.number,
  // data: PropTypes.PropTypes.arrayOf(PropTypes.shape({})),
  hasNone: PropTypes.bool,
  className: PropTypes.string,
  filters: PropTypes.string,
  field: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
};

DynamicSelect.defaultProps = {
  placeholder: "",
  onBlur: () => {},
  value: null,
  // data: [{}],
  hasNone: true,
  className: "",
  filters: "",
  isDisabled: false,
};

export { DynamicSelect as default };

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Geocode from "react-geocode";
import { MapView } from ".";

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      center: {
        lat: process.env.MAP_DEFAULT_CENTER_LAT,
        lng: process.env.MAP_DEFAULT_CENTER_LNG,
      },
      selectedIds: [],
    //   homePosition: {
    //     lat: null,
    //     lng: null,
    //   },
    };
  }

  componentDidMount() {
    Geocode.setApiKey("AIzaSyCDxbMgUYNhSnvv1RdZI6MTM_mrb_Q-R1U");
    Geocode.setLanguage("en");

    this.state.selectedIds = this.props.selectedIDs;
    this.getDefaultCenterLocation(this.props.data);
  }

//   componentDidUpdate(previousProps) {
//     if (
//       previousProps.details !== this.props.details &&
//       this.props.selectedIDs.length === 1
//     ) {
//       // eslint-disable-next-line react/prop-types
//       const { Address1, City, PostalCode } = this.props.details;
//       this.getPositionFromAddress(`${Address1}, ${City} ${PostalCode}`);
//     }
//   }

  getDefaultCenterLocation = () => {
    const position = {
      lat: process.env.MAP_DEFAULT_CENTER_LAT,
      lng: process.env.MAP_DEFAULT_CENTER_LNG,
    };

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.setCenterLocation);
    }
    this.setCenterLocation(position);

    return position;
  };

//   getPositionFromAddress = async (address) => {
//     let homePosition = {
//       lat: null,
//       lng: null,
//     };

//     await Geocode.fromAddress(address)
//       .then((response) => {
//         homePosition = response.results[0].geometry.location;
//       })
//       // eslint-disable-next-line no-console
//       .catch((error) => console.log(error));

//     this.setState({ homePosition });

//     return homePosition;
//   };

  setCenterLocation = (position) => {
    let newCenter;

    // eslint-disable-next-line eqeqeq
    if (position.coords != undefined) {
      newCenter = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      };
    } else {
      newCenter = {
        lat: position.lat,
        lng: position.lng,
      };
    }

    this.setState({
      center: newCenter,
      selectedIds: this.props.selectedIDs,
    });
  };

  //   getMap = () => {
  //     return this.mapView.getMap();
  //   }

  render() {
    return (
      <>
        {" "}
        <MapView
          mapRefSetter={this.props.mapRefSetter}
          dispatch={this.props.dispatch}
          data={this.props.data}
          centerPosition={this.props.centerPosition}
          homePosition={this.props.homePosition}
          zoomLevel={this.props.zoomLevel}
          center={this.state.center}
          selectedIDs={this.state.selectedIds}
          exclusionZones={this.props.exclusionZones}
          setExclusionZoneLocation={this.props.setExclusionZoneLocation}
          cssClass={this.props.cssClass}
          showPinHover={this.props.showPinHover}
          isShowExclusionZones={this.props.isShowExclusionZones}
        />
      </>
    );
  }
}

Map.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  data: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  selectedIDs: PropTypes.array,
  // eslint-disable-next-line react/forbid-prop-types
  exclusionZones: PropTypes.array,
  setExclusionZoneLocation: PropTypes.func,
  dispatch: PropTypes.func,
  mapRefSetter: PropTypes.func,
  cssClass: PropTypes.string,
  homePosition: PropTypes.arrayOf(PropTypes.shape({})),
  centerPosition: PropTypes.shape({}),
  zoomLevel: PropTypes.number,
  showPinHover: PropTypes.bool,
  isShowExclusionZones: PropTypes.bool,
};

Map.defaultProps = {
  data: [],
  selectedIDs: [],
  exclusionZones: [],
  setExclusionZoneLocation: () => {},
  dispatch: () => {},
  mapRefSetter: () => {},
  cssClass: "googleMap",
  homePosition: [],
  centerPosition: {},
  zoomLevel: 10,
  showPinHover: false,
  isShowExclusionZones: true,
};

function mapStateToProps(state) {
  return { state };
}

const connectedMap = connect(mapStateToProps)(Map);

export { connectedMap as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Row, Col } from "antd";
import moment from "moment";
import { IconButtons } from "../../../common/components";

import actions from "../actions";

const { SearchBox, EditIcon } = IconButtons;

class AllocationGrid extends React.Component {
  getForceFilters = () => {
    const forceFilter = []; 
    this.props.allocationList.map((user) => {
      const force = {text: user.ForceName, value: user.ForceName || " "};
      
      if(!forceFilter.filter(e => e.value === (user.ForceName || " ")).length > 0)
        forceFilter.push(force);

        return forceFilter;
    });
    return forceFilter.sort();
  }

  editAllocation = (Id) => {
    const { dispatch } = this.props;
    dispatch(actions.GetAllocationById(Id, this.openEditModal));
  };

  openEditModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openEditAllocationModal());
  }

  OpenAddModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openAddAllocationModal());
  };

  onSearchAllocations = (value = "") => {
    const searchQuery =
    value !== ""
        ? `$filter=contains(ForceName,'${value}')`
        : "";
    this.props.onSearch(searchQuery);
  }

  render() {
    const gridColumns = [
      {
        title: "Force",
        dataIndex: "ForceName",
        key: "ForceName",
        sorter: (a, b) => 
        {          
          if(a.ForceName.toUpperCase() > b.ForceName.toUpperCase())
            return -1;

          if(a.ForceName.toUpperCase() < b.ForceName.toUpperCase())
            return 1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
        filters: this.getForceFilters(),
        onFilter: (value, record) => (record.ForceName || " ") === value,
      },
      {
        title: "Used",
        dataIndex: "Used",
        key: "Used",
      },  
      {
        title: "Remaining",
        dataIndex: "Remaining",
        key: "Remaining",
      }, 
      {
        title: "StartDate",
        dataIndex: "StartDate",
        key: "StartDate",
        sorter: (a, b) => new Date(a.StartDate) - new Date(b.StartDate),
        sortDirections: ["descend", "ascend"],
        render: (text, record) =>
          this.props.allocationList.length >= 1 ? (
            <>
              <span>{moment(record.StartDate).format('DD MMM, YYYY')}</span>
            </>
          ) : null,
      }, 
      {
        title: "EndDate",
        dataIndex: "EndDate",
        key: "EndDate",
        sorter: (a, b) => new Date(a.EndDate) - new Date(b.EndDate),
        sortDirections: ["descend", "ascend"],
        render: (text, record) =>
          this.props.allocationList.length >= 1 ? (
            <>
              <span>{moment(record.EndDate).format('DD MMM, YYYY')}</span>
            </>
          ) : null,
      }, 
      {
        title: "",
        dataIndex: "Id",
        key: "Id",
        render: (text, record) =>
          this.props.allocationList.length >= 1 ? (
            <>
              <span>
                <EditIcon
                  tooltip={`Edit ${record.ForceName}`}
                  onClick={this.editAllocation}
                  value={record.Id}
                  pull="right"
                />
              </span>
            </>
          ) : null,
      },
    ];

    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row>
          <Col xs={12} md={10} lg={10}>
            <span className="mosovo-table-header">Allocations</span>
          </Col>
          <Col xs={24} md={14} lg={14}>
            <Row justify="end" gutter={{ xs: 8, sm: 16, md: 10 }}>
              <Col xs={19} sm={20} md={18} lg={12} className="gutter-row">
                <SearchBox
                  placeholder="search force"
                  allowClear
                  onSearch={this.onSearchAllocations}
                  minText={3}
                  loading={this.props.loading}
                />
              </Col>
              {/* <Col className="gutter-row">
                <Tooltip title="Add Allocations for Force">
                  <span>
                    <AddButton id="AddAllocations" onClick={this.OpenAddModal} />
                  </span>
                </Tooltip>
              </Col> */}
            </Row>
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <Table
              loading={this.props.loading}
              dataSource={this.props.allocationList}
              columns={gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

AllocationGrid.propTypes = {
  dispatch: PropTypes.func,
  onSearch: PropTypes.func,
  allocationList: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
};

AllocationGrid.defaultProps = {
  dispatch: () => {},
  onSearch: () => {},
  allocationList: [{}],
  loading: false,
};

function mapStateToProps(state) {
  const { loading } = state.Allocation;
  return {
    loading,
  };
}

const connectedAllocationGrid = connect(mapStateToProps)(AllocationGrid);

export { connectedAllocationGrid as default };

import c from "./constants";
import AuthService from "../../services/Auth";
import { history } from "../../store";

function GetCurrentUser(onComplete) {
  function request() {
    return { type: c.GET_CURRENT_USER_REQUEST };
  }
  function success(profile) {
    return { type: c.GET_CURRENT_USER_SUCCESS, profile };
  }
  function failure(error) {
    return { type: c.GET_CURRENT_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(success());
    dispatch(request());
    AuthService.getCurrentUser().then(
      (profile) => {
        dispatch(success(profile));
        if (onComplete) {
          onComplete();
        }
        
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function Login(userInput) {
  // function request() { return { type: c.LOGIN_REQUEST }; }
  function success() {
    return { type: c.LOGIN_SUCCESS };
  }
  // function failure(error) { return { type: c.LOGIN_FAILURE, error }; }

  // function profileRequest() { return { type: c.GET_CURRENT_USER_REQUEST }; }
  // function profileSuccess(profile) {
  //   return { type: c.GET_CURRENT_USER_SUCCESS, profile };
  // }
  // function profileFailure(error) { return { type: c.GET_CURRENT_USER_FAILURE, error }; }

  return (dispatch) => {
    // dispatch(request());
    AuthService.login(userInput).then(() => {
      // dispatch(profileRequest());
      dispatch(success());
      history.push("/");
      //   AuthService.getCurrentUser().then(
      //     (profile) => {
      //       dispatch(profileSuccess(profile));
      //       dispatch(success());
      //       history.push('/');
      //     },
      //     (error) => {
      //       dispatch(profileFailure(error.toString()));
      //     },
      //   )
      // },
      // (error) => {
      //   dispatch(failure(error.toString()));
      //   history.push('/login');
    });
  };
}

function Logout() {
  function request() {
    return { type: c.LOGOUT_REQUEST };
  }
  function success() {
    return { type: c.LOGOUT_SUCCESS };
  }

  return (dispatch) => {
    dispatch(request());
    AuthService.logout().then(() => {
      dispatch(success());
    });
  };
}

export default {
  GetCurrentUser,
  Login,
  Logout,
};

import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Form, Row, Col, Input, Button, Spin } from "antd";
import * as Yup from "yup";
import Actions from "../actions";
import "./ResetForm.css";

class ResetForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: "",
      NewPassword: "",
      Token: "",
    };
  }

  handleChange = (data) => {
    const { value, id } = data.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    if (!this.props.hasError) {
      const parsedToken = this.getTokenFromQueryString();

      this.setState({ Token: parsedToken });
      dispatch(Actions.ResetPassword(this.state));
    }
  };

  getTokenFromQueryString = () => {
    let parsedToken = "";

    const urlToParse = window.location.href;

    const tokenQueryStringIndicator = "token=";
    const tokenStartIndex = urlToParse.indexOf(tokenQueryStringIndicator);
    const tokenValueStartIndex =
      tokenStartIndex + tokenQueryStringIndicator.length;

    parsedToken = urlToParse.substring(tokenValueStartIndex);

    return parsedToken;
  };

  render() {
    const TokenRegex = /(?=^\/account\/reset\/$)/g;

    if (TokenRegex.test(window.location.href)) {
      const Token = TokenRegex.exec(window.location.href);
      const newLocation = window.location.href.split(`/${Token}`)[0];

      this.setState(
        (prevState) => ({
          ...prevState,
          Token,
        }),
        (window.location.href = newLocation)
      );
    }
    return (
      <div className="login-layout-container">
        <Row justify="center">
          <Col>
            <div className="login-layout-form-container">
              <Spin spinning={this.props.loading}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    UserName: this.state.UserName,
                    NewPassword: this.state.NewPassword,
                  }}
                  validationSchema={Yup.object().shape({
                    UserName: Yup.string()
                      .typeError("Username is required")
                      .required("Username is required"),
                    NewPassword: Yup.string()
                      .typeError("Password is required")
                      .test("passwordValidation", "Invalid password", (value) => {
                        function isLowerCase(charCode) {
                          return charCode > 96 && charCode < 123; // lower alpha (a-z)
                        }

                        function isUpperCase(charCode) {
                          return charCode > 64 && charCode < 91; // upper alpha (A-Z)
                        }

                        let isValid = false;
                        let hasUpperCase = false;
                        let hasLowerCase = false;
                        let hasNonAlphaNumericChar = false;

                        if (value) {
                          for (let i = 0; i <= value.length; i += 1) {
                            const currentCharacterCode = value.charCodeAt(i);
                            if (!hasUpperCase) {
                              hasUpperCase = isUpperCase(currentCharacterCode);
                            }

                            if (!hasLowerCase) {
                              hasLowerCase = isLowerCase(currentCharacterCode);
                            }

                            if (!hasNonAlphaNumericChar) {
                              hasNonAlphaNumericChar =
                                !(
                                  currentCharacterCode > 47 &&
                                  currentCharacterCode < 58
                                ) && // numeric (0-9)
                                !isUpperCase(currentCharacterCode) &&
                                !isLowerCase(currentCharacterCode);
                            }

                            isValid =
                              hasUpperCase &&
                              hasNonAlphaNumericChar &&
                              hasLowerCase;

                            if (isValid) {
                              break;
                            }
                          }
                        }

                        return isValid;
                      })
                      .required("Password is required"),
                  })}
                  onSubmit={(values) => this.handleSubmit(values)}
                  validateOnChange
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <div className="account-layout-form-container">
                      <Form
                        name="Reset"
                        className="reset-form"
                        id="Reset"
                        onSubmit={handleSubmit}
                        onChange={handleChange}
                        autoComplete="off"
                      >
                        <Row type="flex" justify="Center">
                          <Col span={24}>
                            <Row>
                              <h1 className="p5-form-h1-header">
                                {" "}
                                Reset Password{" "}
                              </h1>
                            </Row>
                          </Col>
                        </Row>
                        <Row type="flex" justify="Center">
                          <Col span={24}>
                            <Form.Item
                              validateStatus={
                                errors && errors.UserName && touched.UserName
                                  ? "error"
                                  : null
                              }
                              help={errors && touched.UserName && errors.UserName}
                            >
                              <div className="p5-form-label"> Username</div>
                              <Input
                                type="text"
                                id="UserName"
                                className="p5-form-input"
                                name="UserName"
                                placeholder="Username"
                                value={values.UserName}
                                onChange={this.handleChange}
                                autoComplete="UserName"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row justify="Center">
                          <Col span={24}>
                            <Form.Item
                              validateStatus={
                                errors &&
                                errors.NewPassword &&
                                touched.NewPassword
                                  ? "error"
                                  : null
                              }
                              help={
                                errors &&
                                touched.NewPassword &&
                                errors.NewPassword
                              }
                            >
                              <div className="p5-form-label"> New Password </div>
                              <Input.Password
                                id="NewPassword"
                                className="p5-form-input"
                                name="NewPassword"
                                placeholder="NewPassword"
                                value={values.NewPassword}
                                onChange={this.handleChange}
                                autoComplete="NewPassword"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row
                          style={{
                            paddingTop: 0,
                            paddingBottom: 20,
                            marginTop: 10,
                            marginBottom: -5,
                          }}
                        >
                          <div className="form-label" style={{ fontSize: 14 }}>
                            Passwords must have at least one lowercase letter, one
                            uppercase letter and one non-alphanumeric character.
                          </div>
                        </Row>
                        <Row className="btn-row">
                          <Button
                            onClick={handleSubmit}
                            type="submit"
                            className="resetBtn"
                          >
                            Reset
                          </Button>
                        </Row>
                      </Form>
                    </div>
                  )}
                />
              </Spin>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loading, hasError } = state.Account;
  return {
    loading,
    hasError,
  };
}

const connectedResetForm = connect(mapStateToProps)(ResetForm);

ResetForm.propTypes = {
  dispatch: () => {},
  hasError: PropTypes.bool,
  loading: PropTypes.bool,
};

ResetForm.defaultProps = {
  dispatch: () => {},
  hasError: false,
  loading: false,
};

export default connectedResetForm;

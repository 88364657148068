import React from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { Row, Col, Form, Input, Divider } from "antd";
import { CancelBtn, SaveBtn } from "../../../common/components/form";
import NominalTypesSelect from '../../../common/components/nominal-type-select';
import RiskLevelSelect from '../../../common/components/risk-level-select';

class AdditionalInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
  }

  componentDidUpdate() {
    this.formIK.current.handleReset();
  }

  pivotData = (data) => ({
    Id: data.Id,
    PNCId: data.PNCId,
    VisorId: data.VisorId,
    RiskLevelId: data.RiskLevelId,
    NominalTypeId: data.NominalTypeId,
  });

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.props.Id || 0,
          PNCId: this.props.PNCId || "",
          VisorId: this.props.VisorId || "",
          RiskLevelId: this.props.RiskLevel && this.props.RiskLevel.Id ? this.props.RiskLevel.Id : null,
          NominalTypeId: this.props.NominalType && this.props.NominalType.Id ? this.props.NominalType.Id : null,
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          PNCId: Yup.string().required("PNC Id is required"),
          VisorId: Yup.string().required("Visor Id is required"),
          RiskLevelId: Yup.number().required("Risk Level is required").typeError("Risk Level is required"),
          NominalTypeId: Yup.number().required("Nominal Type is required").typeError("Nominal Type is required"),
        })}
        // onChange={(values) => this.handleChange(values)}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form id="NominalDeviceInfoForm" onSubmit={handleSubmit} onChange={handleChange}>
            <div>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col sm={24} md={6}>
                      <span className="required-field">PNC ID: </span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.PNCId && touched.PNCId
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.PNCId && touched.PNCId
                            ? errors.PNCId
                            : null
                        }
                      >
                        <Input
                          id="PNCId"
                          onBlur={handleBlur}
                          value={values.PNCId}
                          onChange={(val) => {
                            setFieldValue("PNCId", val.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row> 
                  <Row>
                    <Col sm={24} md={6}>
                      <span className="required-field">Risk Level: </span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.RiskLevelId && touched.RiskLevelId
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.RiskLevelId && touched.RiskLevelId
                            ? errors.RiskLevelId
                            : null
                        }
                      >
                        <RiskLevelSelect
                          id="RiskLevelId"
                          name="RiskLevelId"
                          value={values.RiskLevelId}
                          onBlur={handleBlur}
                          onChange={(val) => {
                            setFieldValue("RiskLevelId", val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row> 
                  <Row>
                    <Col sm={24} md={6}>
                      <span className="required-field">Nominal Type: </span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.NominalTypeId && touched.NominalTypeId
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.NominalTypeId && touched.NominalTypeId
                            ? errors.NominalTypeId
                            : null
                        }
                      >
                        <NominalTypesSelect
                          id="NominalTypeId"
                          name="NominalTypeId"
                          value={values.NominalTypeId}
                          onBlur={handleBlur}
                          onChange={(val) => {
                            setFieldValue("NominalTypeId", val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row> 
                  <Row>
                    <Col sm={24} md={6}>
                      <span className="required-field">VISOR ID: </span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.VisorId && touched.VisorId
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.VisorId && touched.VisorId
                            ? errors.VisorId
                            : null
                        }
                      >
                        <Input
                          id="VisorId"
                          onBlur={handleBlur}
                          value={values.VisorId}
                          onChange={(val) => {
                            setFieldValue("VisorId", val.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row> 
                </Col>
              </Row>
              <Divider plain />
              <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <SaveBtn
                    onClick={handleSubmit}
                    loading={this.props.isLoading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

AdditionalInfoForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  Id: PropTypes.number,
  VisorId: PropTypes.string,
  PNCId: PropTypes.string,
  RiskLevel: PropTypes.shape({
    Id: PropTypes.number,
  }),
  NominalType: PropTypes.shape({
    Id: PropTypes.number,
  }),
};

AdditionalInfoForm.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  isLoading: false,
  Id: 0,
  VisorId: "",
  PNCId: "",
  RiskLevel: {
    Id: null
  },
  NominalType: {
    Id: 0
  },
};

export default AdditionalInfoForm;
import React from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
import moment from "moment";
import { Formik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Row, Col, Form, Divider, InputNumber, TimePicker, Checkbox, Button, Input } from "antd";
import { faTimesCircle, faPlus, faMinus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelBtn, SaveBtn } from "../../../common/components/form";
import QuestionSelect from "../../../common/components/question-select";
import actions from "../actions";

class CheckinForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
    this.state = {
      ErrorText: ""
    }
  }

  checkinSettingsUpdate = (data) => {
    this.props.dispatch(
      actions.createCheckInSettings(data, this.handleErrors)
    );
    this.props.onClose();
  };

  pivotData = (data) => ({
    Id: data.Id,
    NominalId: this.props.Nominal.Id,
    StartTime: moment(data.StartTime).utc().format('HH:mm'),
    EndTime: moment(data.EndTime).utc().format('HH:mm'),
    Frequency: data.Frequency,
    CheckinTypeIds: data.CheckinTypeIds,
    Questions: data.Questions,
  });

  handleErrors = (data) => {
    if (data.Error) {
      this.setState((prevState) => ({
        ...prevState,
        ErrorText: data.Error,
      }));
    } else {
      this.setState(
        (prevState) => ({
          ...prevState,
          ErrorText: null,
        }),
        this.props.onSubmit()
      );
    }
  };

  deviceLinkedError = () => (
    <>
      <span style={{ color: "red" }}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className=""
          alt="deviceLinkedError"
        />
      </span>
      <span style={{ color: "red" }}> {this.state.ErrorText}</span>
    </>
  );

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.props.Id || 0,
          NominalId: this.props.Nominal.Id || 0,
          StartTime: this.props.StartTime ? moment(`${moment(Date()).format('MM/DD/yyyy')} ${this.props.StartTime}Z`).local() : null,
          EndTime: this.props.EndTime ? moment(`${moment(Date()).format('MM/DD/yyyy')} ${this.props.EndTime}Z`).local() : null,
          Frequency: this.props.Frequency || 0,
          CheckinTypeIds: this.props.CheckinTypeIds || [],
          Questions: this.props.Questions || []
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          StartTime: Yup.date().typeError('Start time is requred').required('Start time is requred'),
          EndTime: Yup.date().typeError('End time is requred').required('End time is requred'),
          Frequency: Yup.number().min(1, 'Please enter an number greater than 0').max(20, 'Frequency is too large. Please use a smaller number'),
          CheckinTypeIds: Yup.array().nullable().test('typeIdTest', 'A type must be selected',
          (val) => {
            return val.length > 0;
          }),
          Questions: Yup.array().of(Yup.object().shape({
            QuestionId: Yup.number().typeError('A question must be selected').required('A question must be selected'),
            Answer: Yup.string().typeError('An Answer is reqired for this question').required('An Answer is reqired for this question'),
          })).nullable(),
        })}
        // onChange={(values) => this.handleChange(values)}
        onSubmit={(values) => this.checkinSettingsUpdate(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
          submitForm,
        }) => (
          <Form
            id="NominalDeviceInfoForm"
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <div>
              <Row className="CheckinForm">
                <Col span={24}>
                  <Row gutter={[16,16]}>
                    <Col sm={24} md={24}>
                      <span className="required-field">Check-in Time Period:</span>
                    </Col>
                    <Col sm={24} md={24}>
                      <Row>
                        <Col span={12}>
                          <Row>
                            <Col span={6}>
                              <span className="TimespanTitle">From:</span>
                            </Col>
                            <Col span={18}>
                              <Form.Item
                                validateStatus={
                                  errors && errors.StartTime && touched.StartTime
                                    ? "error"
                                    : "success"
                                }
                                help={
                                  errors && errors.StartTime && touched.StartTime
                                    ? errors.StartTime
                                    : null
                                }
                              >
                                <TimePicker
                                  // use12Hours
                                  id="StartTime"
                                  onBlur={handleBlur}
                                  value={values.StartTime}
                                  format="HH:mm"
                                  minuteStep={15}
                                  onChange={times => setFieldValue('StartTime', times)}
                                  inputReadOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                        <Col span={12}>
                          <Row>
                            <Col span={6}>
                              <span className="TimespanTitle">To:</span>
                            </Col>
                            <Col span={18}>
                              <Form.Item
                                validateStatus={
                                  errors && errors.EndTime && touched.EndTime
                                    ? "error"
                                    : "success"
                                }
                                help={
                                  errors && errors.EndTime && touched.EndTime
                                    ? errors.EndTime
                                    : null
                                }
                              >
                                <TimePicker
                                  // use12Hours
                                  id="EndTime"
                                  onBlur={handleBlur}
                                  value={values.EndTime}
                                  format="HH:mm"
                                  minuteStep={15}
                                  onChange={times => setFieldValue('EndTime', times)}
                                  inputReadOnly
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} md={10}>
                      <span className="required-field">Check-in Frequency:</span>
                    </Col>
                    <Col sm={24} md={14}>
                      <Form.Item
                        validateStatus={
                          errors && errors.Frequency && touched.Frequency
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.Frequency && touched.Frequency
                            ? errors.Frequency
                            : null
                        }
                      >
                        <InputNumber
                          id="Frequency"
                          placeholder="number"
                          step={1}
                          onBlur={handleBlur}
                          value={values.Frequency}
                          onChange={(val) =>  setFieldValue('Frequency', parseInt(val, 10))}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} md={10}>
                      <span className="required-field">Check-in Type:</span>
                    </Col>
                    <Col sm={24} md={14}>
                      <Form.Item
                        validateStatus={
                          errors && errors.CheckinTypeIds && touched.CheckinTypeIds
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.CheckinTypeIds && touched.CheckinTypeIds
                            ? errors.CheckinTypeIds
                            : null
                        }
                      >
                        <Row>
                          <Checkbox
                            checked={values.CheckinTypeIds.includes(1)}
                            className="TypeCheckbox"
                            onChange={(e) => setFieldValue('CheckinTypeIds', e.target.checked
                                ? [...values.CheckinTypeIds, 1] : values.CheckinTypeIds.filter(type => type !== 1))}
                          />
                          <span>Biometric ID</span>
                        </Row>
                        <Row>
                          <Checkbox
                            checked={values.CheckinTypeIds.includes(2)}
                            className="TypeCheckbox"
                            onChange={(e) => setFieldValue('CheckinTypeIds', e.target.checked
                                ? [...values.CheckinTypeIds, 2] : values.CheckinTypeIds.filter(type => type !== 2))}
                          />
                          <span>Selfie</span>
                        </Row>
                        <Row>
                          <Checkbox
                            checked={values.CheckinTypeIds.includes(3)}
                            className="TypeCheckbox"
                            onChange={(e) => {
                              setFieldValue('CheckinTypeIds', e.target.checked
                                ? [...values.CheckinTypeIds, 3] : values.CheckinTypeIds.filter(type => type !== 3));
                              setFieldValue('Questions', e.target.checked ? [{Id: null, Answer: ''}] : []);
                            }}
                          />
                          <span>Personal Questions</span>
                        </Row>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    {values.CheckinTypeIds.includes(3) && (
                      <>
                        <Col span={10}>
                          <span>Check-in Questions:</span>
                        </Col>
                        <Col span={14}>
                          <Form.List name="Questions">
                            {(questions, {add, remove}) => (
                              <>
                                {values.Questions.map((question, index) => (
                                  <Row>
                                    <Col span={20}>
                                      <Form.Item
                                        validateStatus={
                                          errors && errors.Questions && touched.Questions && errors.Questions[index].QuestionId && touched.Questions[index].QuestionId
                                            ? "error"
                                            : "success"
                                        }
                                        help={
                                          errors && errors.Questions && touched.Questions && errors.Questions[index].QuestionId && touched.Questions[index].QuestionId
                                            ? errors.Questions[index].QuestionId
                                            : null
                                        }
                                      >
                                        <QuestionSelect
                                          value={values.Questions[index].QuestionId}
                                          className="FullWidth"
                                          onChange={value => setFieldValue(`Questions[${index}].QuestionId`, value)}
                                          currentQuestionList={values.Questions.map((q, i) => { return (i !== index ? q.QuestionId : 0) })}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={20}>
                                      <Form.Item
                                        validateStatus={
                                          errors && errors.Questions && touched.Questions && errors.Questions[index].Answer && touched.Questions[index].Answer
                                            ? "error"
                                            : "success"
                                        }
                                        help={
                                          errors && errors.Questions && touched.Questions && errors.Questions[index].Answer && touched.Questions[index].Answer
                                            ? errors.Questions[index].Answer
                                            : null
                                        }
                                      >
                                        <Input
                                          value={values.Questions[index].Answer}
                                          className="FullWidth"
                                          placeholder="Answer"
                                          onChange={e => setFieldValue(`Questions[${index}].Answer`, e.target.value)}
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                      {values.Questions.length !== 0 && (
                                        <Button
                                          type="link"
                                          onClick={() => {
                                            setFieldValue('Questions', values.Questions.filter((q, i) => i !== index));
                                            remove(index);
                                          }}
                                          style={{ width: '60%', color: "black" }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faMinus}
                                            className=""
                                            alt="deviceLinkedError"
                                          />
                                        </Button>
                                      )}
                                      {values.Questions.length === (index + 1) && (
                                        <Button
                                          type="link"
                                          onClick={() => {
                                            setFieldValue('Questions', [...values.Questions, { Id: null, Answer: '' }]);
                                            add();
                                          }}
                                          style={{ width: '60%' }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faPlus}
                                            className=""
                                            alt="deviceLinkedError"
                                          />
                                        </Button>
                                      )}
                                    </Col>
                                  </Row>
                                ))}
                              </>
                            )}
                          </Form.List>
                        </Col>
                      </>
                    )}
                  </Row>
                </Col>
              </Row>
              <Divider plain />
              <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <SaveBtn
                    onClick={() => submitForm()}
                    isDisabled={Object.keys(errors).length > 0}

                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

CheckinForm.propTypes = {
  dispatch: PropTypes.func,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  Id: PropTypes.number,
  StartTime: PropTypes.number,
  EndTime: PropTypes.number,
  Frequency: PropTypes.number,
  Questions: PropTypes.shape(),
  CheckinTypeIds: PropTypes.arrayOf(PropTypes.number),
  Nominal: PropTypes.shape(),
};

CheckinForm.defaultProps = {
  dispatch: () => {},
  onSubmit: () => {},
  onClose: () => {},
  onCancel: () => {},
  Id: 0,
  StartTime: null,
  EndTime: null,
  Frequency: null,
  CheckinTypeIds: [],
  Questions: null,
  Nominal: null,
};

function mapStateToProps(state) {
  return { Nominal: state.Nominal.Nominal };
}

const connectedCheckinForm = connect(mapStateToProps)(CheckinForm);

export { connectedCheckinForm as default };

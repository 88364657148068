import React from "react";
import { Switch } from "react-router";
import { ToastContainer, toast } from "react-toastify";
import { Auth0Provider } from "@auth0/auth0-react";
import { rights } from "./common/utils/role-right-helper";
import Routes from "./features/navigation/Routes";
import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import "./App.css";
import "./OverrideStyle.scss";
import "./OverrideStyle.css";

const App = () => {
  const onRedirectCallback = (appState) => {
    window.history.replaceState(
      {},
      document.title,
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    );
  };

  return (
    <Auth0Provider
      domain={process.env.AUTH0_DOMAIN}
      clientId={process.env.AUTH0_CLIENTID}
      audience="https://mosovo-primary/"
      scope={`${rights.GlboalAdmin} ${rights.CanManageForceAdmins} ${rights.CanViewForceAdmins} ${rights.CanManageAreaAdmins} ${rights.CanViewAreaAdmins} ${rights.CanManageTeamAdmins} ${rights.CanViewTeamAdmins} ${rights.CanManageTeamUsers} ${rights.CanViewTeamUsers} ${rights.CanManageHub} ${rights.CanViewHub} ${rights.CanViewAsAreaAdmin} ${rights.CanViewAsForceAdmin} ${rights.CanViewAsOM}`}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      useRefreshTokens="true"
      cacheLocation="localstorage"
    >
      <ToastContainer autoclose={3000} position={toast.POSITION.TOP_RIGHT} />
      <Switch>
        <Routes />
      </Switch>
    </Auth0Provider>
  );
};

export { App as default };

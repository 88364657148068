import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { push } from "connected-react-router";

import { Row, Col, Modal } from "antd";
import { WarningOutlined } from '@ant-design/icons';
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import navigationActions from "../../../common/components/navigation/action";
import { ForceHeader, AreaDetails, TeamDetails } from "../components";
import { ForceEditModal, AreaModal, TeamModal } from '../modals';
import actions from "../actions";
import { AllocationModal } from "../../Allocation/modals"

import "./Organisation.scss";

class OrganisationDetailPage extends React.Component {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("Organisations"));
    this.props.dispatch(actions.getOrganisationDetail(this.props.match.params.Id));
  }

  RefreshGrid = () => {
    this.props.dispatch(actions.getOrganisationDetail(this.props.match.params.Id));
  }

  GoBack =(e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(push('/organisations'));
  }

  editForce = (Id) => {
    const { dispatch } = this.props;
    dispatch(actions.getForceDetail(Id, this.openForceModal));
  }

  deleteForce = (Id, Name) => {
    const { confirm } = Modal; 
    const { dispatch } = this.props;
    const message = (
      <div>
        <p>
          Deleting the force will remove all areas, teams and users under this force. You will need to delete or reassign each user to a new force.
        </p>
        <p>Are you sure you want to delete the following force?</p>
        <p>{Name}</p>
      </div>
      
    );

    confirm({
      title: "Delete Force",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        dispatch(actions.deleteForce(Id));
      },
      okText: "Yes, Delete"
    });
    
  }

  editArea = (Id) => {
    const { dispatch } = this.props;
    dispatch(actions.getAreaDetail(Id, this.openAreaModal));
  }

  deleteArea = (record) => {
    const { confirm } = Modal; 
    const { dispatch } = this.props;
    const refreshPage = this.RefreshPage;
    const message = (
      <div>
        <p>
          Deleting the Area will remove all teams and users under this area. You will need to delete or reassign each user to a new area.
        </p>
        <p>Are you sure you want to delete the following area?</p>
        <p>{record.Name}</p>
      </div>
      
    );

    confirm({
      title: "Delete Area",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        dispatch(actions.deleteArea(record.Id, refreshPage));
      },
      okText: "Yes, Delete"
    });
  }

  editTeam = (Id) => {
    const { dispatch } = this.props;
    dispatch(actions.getTeamDetail(Id, this.openTeamModal));
  }

  deleteTeam = (record) => {
    const { confirm } = Modal; 
    const { dispatch } = this.props;
    const refreshPage = this.RefreshPage;
    const message = (
      <div>
        <p>
          Deleting the Team will remove all users under this team. You will need to delete or reassign each user to a new team.
        </p>
        <p>Are you sure you want to delete the following team?</p>
        <p>{record.Name}</p>
      </div>
      
    );

    confirm({
      title: "Delete Team",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        dispatch(actions.deleteTeam(record.Id, refreshPage));
      },
      okText: "Yes, Delete"
    });
  }

  openForceModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openForceModal());
  }

  openAreaModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openAreaModal());
  }

  openTeamModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openTeamModal());
  }

  OpenAddAreaModal = (forceId) => {
    const { dispatch } = this.props;
    dispatch(actions.openAddAreaModal(forceId));
  };
  
  OpenAddTeamModal = (forceId) => {
    const { dispatch } = this.props;
    dispatch(actions.openAddTeamModal(forceId));
  };  

  render() {
    const chevronLeftIcon = (
      <span>
        <FontAwesomeIcon
          icon={faChevronLeft}
          style={{marginRight: "10px"}}
          id="forceBack"
          alt="Back to Force"
        />
      </span>      
    );

    return (
      <>
        <div>
          <Row>
            <Col span={24}>
              <div style={{margin: "15px 15px 0"}}>
                <a className="go-back-anchor" href="true" onClick={this.GoBack}>   
                  {chevronLeftIcon}               
                  {`Back to force`}
                </a>       
              </div>        
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className="organisation-header-container">
                <ForceHeader edit={this.editForce} onDelete={this.deleteForce} {...this.props.organisation} />
              </div>              
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="organisation-detail-container">
                <AreaDetails onAdd={this.OpenAddAreaModal} onEdit={this.editArea} onDelete={this.deleteArea} {...this.props.organisation} />
              </div>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
              <div className="organisation-detail-container">
                <TeamDetails onAdd={this.OpenAddTeamModal} onEdit={this.editTeam} onDelete={this.deleteTeam} {...this.props.organisation} />
              </div>
            </Col>
          </Row>
          <ForceEditModal refreshGrid={this.RefreshGrid} />
          <AreaModal refreshGrid={this.RefreshGrid} />
          <TeamModal refreshGrid={this.RefreshGrid} />
          <AllocationModal refreshGrid={this.RefreshGrid} />
        </div>
      </>
    );
  }
}

OrganisationDetailPage.propTypes = {
  dispatch: PropTypes.func,
  editForce: PropTypes.func,
  // handleGoBack: PropTypes.func,
  match: PropTypes.shape(),
  organisation: PropTypes.shape(),
  
};

OrganisationDetailPage.defaultProps = {
  dispatch: () => {},
  editForce: () => {},
  // handleGoBack: () => {},
  match: {},
  organisation: {},  
};

function mapStateToProps(state) {
  const { organisation } = state.Organisation;
  return {organisation};
}

const connectOrganisationDetailPage = connect(mapStateToProps)(
  OrganisationDetailPage
);

export { connectOrganisationDetailPage as default };

/* eslint-disable camelcase */
import jwt_decode from "jwt-decode";
import api from "./api";

const apiRequest = () => {
  const audience = "https://mosovo-primary/";

  const getToken = (type) => {
    window.lastApiAction = new Date();
    const maxSearchLimit = localStorage.length;
    let accessToken;
    for (let i = 0; i < maxSearchLimit; i += 1) {
      const currentKey = localStorage.key(i);
      if (currentKey.indexOf(audience) !== -1) {
        accessToken = JSON.parse(localStorage.getItem(currentKey))?.body[type];
      }
    }

    return accessToken;
  };

  const parseAccessToken = () => {
    const accessToken = getToken("access_token");
    const parsedAccessToken = jwt_decode(accessToken);

    return parsedAccessToken;
  };

  const parseIdToken = () => {
    const accessToken = getToken("id_token");
    const parsedAccessToken = jwt_decode(accessToken);

    const idKey = "https://mosovo-primary//mosovoId";
    if (parsedAccessToken && parsedAccessToken[idKey]) {
      parsedAccessToken.id = parsedAccessToken[idKey];
      parseAccessToken.id = parsedAccessToken[idKey];
    }

    return parsedAccessToken;
  };

  const buildOptions = (token, addtlOptions) => {
    return {
      ...addtlOptions,
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    };
  };

  const get = (uri, addtlOptions) => {
    const accessToken = getToken("access_token");
    return api.get(uri, buildOptions(accessToken, addtlOptions));
  };
  const post = (uri, body, addtlOptions) => {
    const accessToken = getToken("access_token");
    return api.post(uri, body, buildOptions(accessToken, addtlOptions));
  };
  const put = (uri, body, addtlOptions) => {
    const accessToken = getToken("access_token");
    return api.put(uri, body, buildOptions(accessToken, addtlOptions));
  };
  const apiDelete = (uri, addtlOptions) => {
    const accessToken = getToken("access_token");
    return api.delete(uri, buildOptions(accessToken, addtlOptions));
  };

  return {
    get,
    post,
    put,
    delete: apiDelete,
    parseAccessToken,
    parseIdToken,
  };
};
export default apiRequest;


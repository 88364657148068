import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import actions from "../../Allocation/actions";
import { AllocationGrid } from '../../Allocation/grids';
import { AllocationModal } from '../../Allocation/modals';

class LicenseReportIndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      allocationList: [],
      forceSearch: "",
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("Licenses"));
    this.getAllocationData();
  }

  getAllocationData = (value = "") => {
    this.setState({ forceSearch: value });
    const searchQuery =
    value !== ""
        ? `$filter=contains(ForceName,'${value}')`
        : "";
    this.props.dispatch(actions.getAllocationData(searchQuery, this.setUserData));
  };

  setUserData = (data) => {
    this.setState({         
      allocationList: data 
    });   
  }

  RefreshGrid = () => {
    this.getAllocationData(this.state.forceSearch);
  }

  render() {
    return (
      <div className="mosovo-content-container">
        <Row justify="center">
          <Col span={24}>     
            <AllocationGrid allocationList={this.state.allocationList} onSearch={this.getAllocationData} />      
          </Col>
        </Row>
        <AllocationModal refreshGrid={this.RefreshGrid} />
      </div>
    );
  }
}

LicenseReportIndexPage.propTypes = {
    dispatch: PropTypes.func,
};

LicenseReportIndexPage.defaultProps = {
    dispatch: () => {},
};

function mapStateToProps() {
  return {};
}

const connectLicenseReportIndexPage = connect(mapStateToProps)(LicenseReportIndexPage);

export { connectLicenseReportIndexPage as default };

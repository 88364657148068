/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { connect } from "react-redux";

class MapViewTabs extends React.Component {
  render() {
    return (
      <div>
        <ul className="tabList">
          <li>
            <input id="mapView" className="mapViewTabRadioButton" type="radio" name="tab" onChange={() => {}} checked />
            <label htmlFor="mapView">Map View</label>
          </li>
          {/* <li>
            <input id="exclusionZonesLibrary" className="mapViewTabRadioButton" type="radio" name="tab" onChange={() => {}} />
            <label htmlFor="exclusionZonesLibrary">Exclusion Zones Library</label>
          </li> */}
        </ul>
      </div>
    );
  }
}

MapViewTabs.propTypes = {
  
};

MapViewTabs.defaultProps = {
  
};

function mapStateToProps(state) {
  return { state };
}

const connectedMapViewTabs = connect(mapStateToProps)(MapViewTabs);

export { connectedMapViewTabs as default };

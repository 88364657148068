import apiRequest from "../common/utils/apiRequest";

function getNominalById(id) {
  return apiRequest()
    .get(`/api/Nominal/GetNominal/${id}`)
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function validateConsumerId(code) {
  return apiRequest()
    .get(`/api/Consumer/linkdevice?code=${code}`)
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function createNominal(data) {
  return apiRequest()
    .post("/api/Nominal/Create", data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function removeNominal(id) {
  return apiRequest()
    .delete(`/api/Nominal/Delete/${id}`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function suspendNominal(id) {
  return apiRequest()
    .get(`/api/Nominal/SuspendNominal/${id}`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function unsuspendNominal(id) {
  return apiRequest()
    .get(`/api/Nominal/EnableNominal/${id}`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function contactNominal(data) {
  return apiRequest()
    .post("/api/Nominal/Contact", data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function addNominalLocation(data) {
  return apiRequest()
    .post("/api/ConsumerLocation", data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function updateNominalBasicInfo(data) {
  return apiRequest()
    .post("/api/Nominal/Update", data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function updateNominalDeviceInfo(data) {
  return apiRequest()
    .post("/api/Nominal/Update/DeviceInfo", data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function updateNominalAdditionalInfo(data) {
  return apiRequest()
    .post("/api/Nominal/Update/AdditionalInfo", data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function getNominalOdata(querystring = "") {
  return apiRequest()
    .get(`/odata/NominalOData?${querystring}`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function getNominalLocationOdata(querystring = "") {
  return apiRequest()
    .get(`/odata/NominalLocationOData?${querystring}&$orderby=CreatedDate desc`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function getNominalCommunications(id, skip, take) {
  let url = `/api/Notification/GetNominalNotifications?NominalId=${id}`;
  if (skip) url = `${url}&skip=${skip}`;
  if (take) url = `${url}&take=${take}`;
  return apiRequest()
    .get(url)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function createNominalExclusionZone(data) {
  return apiRequest()
    .post(`/api/ExclusionZone/Create`, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateNominalExclusionZone(data) {
  return apiRequest()
    .put(`/api/ExclusionZone/Update`, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getNominalExclusionZoneTypes() {
  return apiRequest()
    .get(`/api/ExclusionZone/GetExclusionZoneTypes/`)
    .then((data) => Promise.resolve(data))
    .catch((error) => Promise.reject(error));
}

function createNominalCurfew(data) {
  return apiRequest()
    .post(`/api/NominalCurfewSettings/Create`, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateNominalCurfew(data) {
  return apiRequest()
    .put(`/api/NominalCurfewSettings/Update`, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getNominalAlerts(id) {
  return apiRequest()
    .get(`/api/Alert/GetNominalAlerts/${id}`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function getAlertSettings(querystring) {
  return apiRequest()
    .get(`/api/NominalAlertSettings/GetAlertSettings?${querystring}`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function getNominalAlertSettings(id) {
  return apiRequest()
    .get(`/api/NominalAlertSettings/GetNominalAlertSettings/${id}`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function getPhoneNumber(phoneNumber) {
  return apiRequest()
    .get(`/api/Nominal/ValidatePhoneNumber/${phoneNumber}`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function deleteExclusionZoneFromNominal(data) {
  return apiRequest()
    .post(`/api/ExclusionZone/Delete`, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteCurfewFromNominal(id) {
  return apiRequest()
    .delete(`/api/NominalCurfewSettings/Delete/${id}`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function createLocationFailSettings(data) {
  return apiRequest()
    .post(`/api/LocationFailSettings/Create`, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateLocationFailSettings(data) {
  return apiRequest()
    .put(`/api/LocationFailSettings/Update`, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteLocationFailSettings(id) {
  return apiRequest()
    .delete(`/api/LocationFailSettings/Delete/${id}`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function createIdleSettings(data) {
  return apiRequest()
    .post(`/api/NominalIdleSettings/Create`, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateIdleSettings(data) {
  return apiRequest()
    .put(`/api/NominalIdleSettings/Update`, data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteIdleSettings(id) {
  return apiRequest()
    .delete(`/api/NominalIdleSettings/Delete/${id}`)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function transferNominal(data) {
  return apiRequest()
    .post("/api/Nominal/Transfer", data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function transferNominalBulk(data) {
  return apiRequest()
    .post("/api/Nominal/Transfer/Bulk", data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function messageNominalBulk(data) {
  return apiRequest()
    .post("/api/Message/Bulk", data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function suspendDevice(id) {
  return apiRequest()
    .post(`/api/Nominal/Suspend/Device/${id}`, id)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function unsuspendDevice(id) {
  return apiRequest()
    .post(`/api/Nominal/Unsuspend/Device/${id}`, id)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function exportNominalData(data) {
  return apiRequest()
    .post("/api/Nominal/Export", data)
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export default {
  getNominalById,
  validateConsumerId,
  createNominal,
  removeNominal,
  contactNominal,
  addNominalLocation,
  updateNominalBasicInfo,
  getNominalOdata,
  getNominalLocationOdata,
  updateNominalDeviceInfo,
  updateNominalAdditionalInfo,
  getNominalCommunications,
  createNominalExclusionZone,
  updateNominalExclusionZone,
  getNominalExclusionZoneTypes,
  createNominalCurfew,
  updateNominalCurfew,
  getNominalAlerts,
  getNominalAlertSettings,
  getAlertSettings,
  getPhoneNumber,
  deleteExclusionZoneFromNominal,
  deleteCurfewFromNominal,
  suspendNominal,
  unsuspendNominal,
  createLocationFailSettings,
  updateLocationFailSettings,
  deleteLocationFailSettings,
  createIdleSettings,
  updateIdleSettings,
  deleteIdleSettings,
  transferNominal,
  transferNominalBulk,
  messageNominalBulk,
  suspendDevice,
  unsuspendDevice,
  exportNominalData,
};

export default {
    USERS_GRID_REQUEST: 'USERS_GRID_REQUEST',
    USERS_GRID_SUCCESS: 'USERS_GRID_SUCCESS',
    USERS_GRID_FAILURE: 'USERS_GRID_FAILURE', 
    OPEN_TRANSFER_OM_MODAL: 'OPEN_TRANSFER_OM_MODAL',
    CLOSE_TRANSFER_OM_MODAL: 'CLOSE_TRANSFER_OM_MODAL',
    OPEN_ADD_USERS_MODAL: 'OPEN_ADD_USERS_MODAL',
    OPEN_EDIT_USERS_MODAL: 'OPEN_EDIT_USERS_MODAL',
    OPEN_PROFILE_MODAL: 'OPEN_PROFILE_MODAL',
    CLOSE_USERS_MODAL: 'CLOSE_USERS_MODAL',
    USERS_CREATE_REQUEST: 'USERS_CREATE_REQUEST',
    USERS_CREATE_SUCCESS: 'USERS_CREATE_SUCCESS',
    USERS_CREATE_FAILURE: 'USERS_CREATE_FAILURE', 
    GET_USER_REQUEST: 'GET_USER_REQUEST',
    GET_USER_SUCCESS: 'GET_USER_SUCCESS',
    GET_USER_FAILURE: 'GET_USER_FAILURE', 
    UPDATE_USER_REQUEST: 'UPDATE_USER_REQUEST',
    UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
    UPDATE_USER_FAILURE: 'UPDATE_USER_FAILURE', 
    DELETE_USER_REQUEST: 'DELETE_USER_REQUEST',
    DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
    DELETE_USER_FAILURE: 'DELETE_USER_FAILURE',
    USER_DETAILS_REQUEST: 'USER_DETAILS_REQUEST',
    USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
    USER_DETAILS_FAILURE: 'USER_DETAILS_FAILURE',
    TRANSFER_OM_REQUEST: 'TRANSFER_OM_REQUEST',
    TRANSFER_OM_SUCCESS: 'TRANSFER_OM_SUCCESS',
    TRANSFER_OM_FAILURE: 'TRANSFER_OM_FAILURE',
}
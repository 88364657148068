import React from "react";
import { Tooltip, Row, Col } from "antd";
import {
  faMapMarkerTimes,
  faBan,
  faClock,
  faPhoneSlash,
  faMailBulk,
  faEnvelope,
  faComments,
  faPauseCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  AlertTypes
} from "./grid-filter-helper";

function getNotifyByElements(record, active) {
  if (record) {
    return (
      <>
        {record && record.IsNotifyEmail ? (
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{
              color: active ? "#585757" : "#909090",
              fontWeight: 300,
              alignItems: "center",
              letterSpacing: "0.25px",
              padding: "2px",
            }}
            alt="email"
          />
        ) : null}
        {record && record.IsNotifyTeamEmail ? (
          <FontAwesomeIcon
            icon={faMailBulk}
            style={{
              color: active ? "#585757" : "#909090",
              fontWeight: 300,
              alignItems: "center",
              letterSpacing: "0.25px",
              padding: "2px",
            }}
            alt="team email"
          />
        ) : null}
        {record && record.IsNotifyText ? (
          <FontAwesomeIcon
            icon={faComments}
            style={{
              color: active ? "#585757" : "#909090",
              fontWeight: 300,
              alignItems: "center",
              letterSpacing: "0.25px",
              padding: "2px",
            }}
            alt="text"
          />
        ) : null}
      </>
    );
  }
  return null;
}

function getAlertTypeElement(record, active, includeText) {
  if (record && record.AlertType) {
    let iconType = faBan;
    switch (record.AlertType.Id) {
      case AlertTypes.ExclusionZone:
        iconType = faMapMarkerTimes;
        break;
      case AlertTypes.LocationFail:
        iconType = faBan;
        break;
      case AlertTypes.Curfew:
        iconType = faClock;
        break;
      case AlertTypes.Idle:
        iconType = faPauseCircle;
        break;
      case AlertTypes.CheckIn:
        iconType = faPhoneSlash;
        break;
      default:
        return null;
    }
    return (
      <>
        <Row justify="center">
          <Col>
            <Tooltip placement="topRight" title={record.AlertType.Name}>
              <FontAwesomeIcon
                icon={iconType}
                style={{
                  color: active ? "#585757" : "#909090",
                }}
                alt={record.AlertType.Name}
              />
            </Tooltip>
          </Col>
          {includeText && (
            <Col>
              <span
                style={{
                  color: active ? "#585757" : "#909090",
                  fontStyle: active ? "normal" : "italic",
                  marginLeft: "10px",
                  textAlign: "center",
                }}
              >
                {record.AlertType.Name === 'Checkin Fail' ? ' Check-In' : ` ${record.AlertType.Name}`}
              </span>
            </Col>
          )}
        </Row>
      </>
    );
  }
  return null;
}

export { getAlertTypeElement, getNotifyByElements };

import React from "react";
// import { Redirect } from 'react-router-dom';
import { Route } from "react-router";
import { withAuthenticationRequired } from "@auth0/auth0-react";

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />;
};

export default withAuthenticationRequired(PrivateRoute);

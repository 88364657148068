import React from "react";
import PropTypes from "prop-types";
import { faUser, faBellSlash } from "@fortawesome/pro-regular-svg-icons";
import { faUserCog } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdminIcon = (props) => {
  return (
    <span className="anticon">
      <FontAwesomeIcon
        icon={faUserCog}
        id={props.Id}
        className={props.className}
        alt="AdminIcon"
        onClick={() => props.OnClick()}
      />
    </span>
  );
};

AdminIcon.propTypes = {
  OnClick: PropTypes.func,
  Id: PropTypes.string,
  className: PropTypes.string,
};

AdminIcon.defaultProps = {
  OnClick: () => {},
  Id: "",
  className: "",
};

const UserIcon = (props) => {
  return (
    <span className="anticon">
      <FontAwesomeIcon
        icon={faUser}
        id={props.Id}
        className={props.className}
        alt="UserIcon"
        onClick={() => props.OnClick()}
      />
    </span>
  );
};

UserIcon.propTypes = {
  OnClick: PropTypes.func,
  Id: PropTypes.string,
  className: PropTypes.string,
};

UserIcon.defaultProps = {
  OnClick: () => {},
  Id: "",
  className: "",
};

const AlertSlashedIcon = (props) => {
  return (
    <span className="anticon">
      <FontAwesomeIcon
        icon={faBellSlash}
        id={props.Id}
        className={props.className}
        alt="AlertSlashIcon"
        onClick={() => props.OnClick()}
      />
    </span>
  );
};

AlertSlashedIcon.propTypes = {
  OnClick: PropTypes.func,
  Id: PropTypes.string,
  className: PropTypes.string,
};

AlertSlashedIcon.defaultProps = {
  OnClick: () => {},
  Id: "",
  className: "",
};

export default {
  AdminIcon,
  UserIcon,
  AlertSlashedIcon
};

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Divider, Spin } from "antd";

class ProfileAccountInfo extends React.Component {
  onChangePasswordClick(event, val) {
    event.preventDefault();
    this.props.setChangePassword(val);
  }

  render() {
    return (
      <>
        <Spin spinning={this.props.loading}>
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col span={12} className="gutter-row">
              <h6>Account Information</h6>
            </Col>
          </Row>
          <Divider />
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>Username:</span>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
              <span
                style={{
                  color: "rgba(0, 0, 0, 0.85)",
                  fontStyle: "normal",
                }}
              >
                {`${this.props.EmailAddress || ""}`}
              </span>
            </Col>
          </Row>
        </Spin>
      </>
    );
  }
}

ProfileAccountInfo.propTypes = {
  loading: PropTypes.bool,
  EmailAddress: PropTypes.string,
  setChangePassword: PropTypes.func,
};

ProfileAccountInfo.defaultProps = {
  loading: false,
  EmailAddress: "",
  setChangePassword: () => {},
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;

  return { loading };
}

const connectedProfileAccountInfo =
  connect(mapStateToProps)(ProfileAccountInfo);

export { connectedProfileAccountInfo as default };

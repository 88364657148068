import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col } from "antd";

import { NominalBasicInfoForm, DeviceInfoForm, AdditionalInfoForm } from "../forms";
import actions from "../actions";

class NominalBasicInfoModal extends React.Component {
  CloseBasicInfoModal = () => {
      this.props.dispatch(actions.closeNominalBasicInfoModal());
      this.props.refreshGrid();
  }

  nominalBasicInfoUpdate = (data) => {
      this.props.dispatch(actions.updateNominalBasicInfo(data, this.CloseBasicInfoModal))
  }

 nominalAdditionalInfoUpdate = (data) => {
  this.props.dispatch(actions.updateNominalAdditionalInfo(data, this.CloseBasicInfoModal))
}


  render() {
      return (
        <>
          <Modal 
            title="Edit Nominal" 
            visible={this.props.showEditModal} 
            onCancel={this.CloseBasicInfoModal} 
            footer={null} 
            width={500} 
            destroyOnClose
          >
            <div>
              <Row>
                <Col span={24}>
                  <NominalBasicInfoForm 
                    onCancel={this.CloseBasicInfoModal} 
                    onSubmit={this.nominalBasicInfoUpdate} 
                    isLoading={this.props.loading} 
                    {...this.props.Nominal}
                  />
                </Col>  
              </Row>
            </div>
          </Modal>
          <Modal 
            title="Edit Device Information" 
            visible={this.props.showDeviceInfoModal} 
            onCancel={this.CloseBasicInfoModal} 
            footer={null} 
            width={500} 
            destroyOnClose
          >
            <div>
              <Row>
                <Col span={24}>
                  <DeviceInfoForm 
                    dispatch={this.props.dispatch}
                    onCancel={this.CloseBasicInfoModal} 
                    onSubmit={this.CloseBasicInfoModal} 
                    isLoading={this.props.loading} 
                    {...this.props.Nominal.DeviceInfo}
                  />
                </Col>  
              </Row>
            </div>
          </Modal>
          <Modal 
            title="Edit Additional Information" 
            visible={this.props.showAdditionalInfoModal} 
            onCancel={this.CloseBasicInfoModal} 
            footer={null} 
            width={500} 
            destroyOnClose
          >
            <div>
              <Row>
                <Col span={24}>
                  <AdditionalInfoForm 
                    onCancel={this.CloseBasicInfoModal} 
                    onSubmit={this.nominalAdditionalInfoUpdate} 
                    isLoading={this.props.loading} 
                    {...this.props.Nominal}
                  />
                </Col>  
              </Row>
            </div>
          </Modal>
        </>
      )
  }
}

NominalBasicInfoModal.propTypes = {
    dispatch: PropTypes.func,
    refreshGrid: PropTypes.func,
    showEditModal: PropTypes.bool,
    loading: PropTypes.bool,
    showDeviceInfoModal: PropTypes.bool,
    showAdditionalInfoModal: PropTypes.bool,
    Nominal: PropTypes.shape({
      DeviceInfo: PropTypes.shape({}),
    }),
  };
  
  NominalBasicInfoModal.defaultProps = {
    dispatch: () => {},
    refreshGrid: () => {},
    showEditModal: false,
    loading: false,
    showDeviceInfoModal: false,
    showAdditionalInfoModal: false,
    Nominal: {
      DeviceInfo: {},
    },
  };
  
  function mapStateToProps(state) {
    const { loading, showEditModal, Nominal, showDeviceInfoModal, showAdditionalInfoModal } = state.Nominal;
    
    return { loading, showEditModal, Nominal, showDeviceInfoModal, showAdditionalInfoModal };
  }
  
  const connectedNominalBasicInfoModal = connect(mapStateToProps)(NominalBasicInfoModal);
  
  export { connectedNominalBasicInfoModal as default };
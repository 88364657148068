import { toast } from "react-toastify";
import constants from "./constants";
import { NominalService } from "../../services";

function getMyNominalData(querystring, callback) {
  function request() {
    return { type: constants.MY_NOMINAL_REQUEST };
  }
  function success() {
    return { type: constants.MY_NOMINAL_SUCCESS };
  }
  function failure(error) {
    return { type: constants.MY_NOMINAL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.getNominalOdata(querystring).then(
      (response) => {
        dispatch(success());
        callback(response.data.value);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function closeNominalModal() {
  function close() {
    return { type: constants.CLOSE_NOMINAL_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function openAddNominalModal() {
  function open() {
    return { type: constants.OPEN_ADD_NOMINAL_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function linkDevice(code, callback) {
  function request() {
    return { type: constants.LINK_DEVICE_REQUEST };
  }
  function success() {
    return { type: constants.LINK_DEVICE_SUCCESS };
  }
  function failure(error) {
    return { type: constants.LINK_DEVICE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.validateConsumerId(code).then(
      (response) => {
        dispatch(success());
        callback(response.data);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function createNominal(data, callback) {
  function request() {
    return { type: constants.MY_NOMINAL_CREATE_REQUEST };
  }
  function success() {
    return { type: constants.MY_NOMINAL_CREATE_SUCCESS };
  }
  function failure(error) {
    return { type: constants.MY_NOMINAL_CREATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.createNominal(data).then(
      () => {
        dispatch(success());
        toast("Successfully create nominal");
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function closeTransferNominalModal() {
  function close() {
    return { type: constants.CLOSE_TRANSFER_NOMINAL_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function openTransferNominalModal() {
  function open() {
    return { type: constants.OPEN_TRANSFER_NOMINAL_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}


function transferNominalBulk(data,callback)
{
  function request() {
    return { type: constants.TRANSFER_BULK_REQUEST };
  }
  function success() {
    return { type: constants.TRANSFER_BULK_SUCCESS };
  }
  function failure(error) {
    return { type: constants.TRANSFER_BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.transferNominalBulk(data).then(
      (response) => {
        dispatch(success());
        if(response.data.HasError === true){
          toast.error(response.data.Error);         
        }
        else{
          toast("Successfully assigned to Offender Manager");
          callback();          
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Unable to assign Offender Manager, please try again");
      }
    );
  };
}

function closeBulkMessageModal() {
  function close() {
    return { type: constants.CLOSE_BULK_MESSAGE_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function openBulkMessageModal() {
  function open() {
    return { type: constants.OPEN_BULK_MESSAGE_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}


function sendBulkMessages(data,callback)
{
  function request() {
    return { type: constants.MESSAGE_BULK_REQUEST };
  }
  function success() {
    return { type: constants.MESSAGE_BULK_SUCCESS };
  }
  function failure(error) {
    return { type: constants.MESSAGE_BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.messageNominalBulk(data).then(
      (response) => {
        dispatch(success());
        if(response.data.HasError === true){
          toast.error(response.data.Error);         
        }
        else{
          toast("Successfully created messages");
          callback();          
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Unable to create messages, please try again");
      }
    );
  };
}

export default {
  getMyNominalData,
  closeNominalModal,
  openAddNominalModal,
  linkDevice,
  createNominal,
  closeTransferNominalModal,
  openTransferNominalModal,
  transferNominalBulk,
  closeBulkMessageModal,
  openBulkMessageModal,
  sendBulkMessages,
}
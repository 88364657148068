import apiRequest from "./apiRequest";

const rights = {
  GlboalAdmin: "view:asGlobalAdmin",
  CanManageOrganisations: "manage:Organisations",
  CanManageForceAdmins: "manage:ForceAdmins",
  CanViewForceAdmins: "view:ForceAdmins",
  CanManageAreaAdmins: "manage:AreaAdmins",
  CanViewAreaAdmins: "view:AreaAdmins",
  CanManageTeamAdmins: "manage:TeamAdmins",
  CanViewTeamAdmins: "view:TeamAdmins",
  CanManageTeamUsers: "manage:TeamUsers",
  CanViewTeamUsers: "view:TeamUsers",
  CanViewAsOM: "view:asOM",
  CanManageHub: "manage:Hub",
  CanViewHub: "view:Hub",
  CanViewAsForceAdmin: "view:asForceAdmin",
  CanViewAsAreaAdmin: "view:asAreaAdmin",
};

const roles = {
  SystemAdmin: "System Admin",
  ForceAdmin: "Force Admin",
  AreaAdmin: "Area Admin",
  TeamAdmin: "Team Admin",
  TeamUser: "Team User",
};

function hasUserRight(rt, userRights) {
  if (userRights) {
    const found = userRights.indexOf(rt) >= 0;
    return found;
  }

  return false;
}

async function hasRights(rts) {
  const accessTokenJson = apiRequest().parseAccessToken();
  const userRights = accessTokenJson?.permissions;
  if (userRights) {
    const found = rts.some((r) => {
      const isRight = userRights.indexOf(r) >= 0;
      return isRight;
    });
    return found;
  }

  return false;
}

function inRoles(role) {
  const accessTokenJson = apiRequest().parseAccessToken();
  const accessTokenRoles = accessTokenJson
    ? accessTokenJson["https://mosovo-primary//roles"]
    : undefined;
  if (accessTokenRoles) {
    const found = role.some((right) => accessTokenRoles.indexOf(right) >= 0);
    return found;
  }

  return false;
}

export { hasRights, hasUserRight, inRoles, rights, roles };

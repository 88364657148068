import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import { Layout, PageHeader } from "../../common/components";
// import { SampleHeader } from "./layout";
import { LicenseReportIndexPage } from "./pages";

const ReportsHeader = (
  <PageHeader title="Reports" />
)

const Reports = ({ match }) => (
  <Layout PageHeader={ReportsHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={LicenseReportIndexPage} />
    </Switch>
  </Layout>
);

Reports.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Reports.defaultProps = {
  match: {
    url: "",
  },
};

export default withRouter(Reports);
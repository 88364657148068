import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";

import { Modal, Row, Col, Spin, Checkbox, DatePicker } from "antd";
import { WarningOutlined } from '@ant-design/icons';
import { CancelBtn, SaveBtn } from "../form";

import actions from "./actions";

const { RangePicker } = DatePicker;
class ExportModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        includeAlerts: true,
        includeAlertSettings: true,
        includeLocationHistory: true,
        ReceiveByEmail: true,
        outputFormat: "pdf",
        startDate: moment().subtract(6, 'months'),
        endDate: moment(),
    };
  }

  CloseModal = () => {
    this.props.dispatch(actions.closeExportModal());
    this.props.refreshGrid();
  };

  CloseExportModal = () => {    
    const { confirm } = Modal; 
    const closeModal = this.CloseModal;
    const message = (
      <div>
        <p>
          Are you sure you want to cancel?
        </p>
      </div>
      
    );

    confirm({
      title: "Export Nominal Data",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        closeModal();
      },
      okText: "Yes, Close"
    });
  }

  pivotData = (state) => ({
    NominalId: this.props.NominalId,
    IncludeAlert: state.includeAlerts,
    IncludeAlertSettings: state.includeAlertSettings,
    includeLocationHistory: state.includeLocationHistory,
    email: state.ReceiveByEmail,
    outputFormat: state.outputFormat,
    startDate: state.startDate ? state.startDate.utc() : null,
    endDate: state.endDate ? state.endDate.utc() : null,
  });

  ExportData = () => {
      if(this.props.NominalId !== null)
        this.props.dispatch(actions.exportNominalDataToPDF(this.pivotData(this.state), this.CloseModal));
  };

  createFooter = () => {
    const footer = (
      <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">          
        <Col xs={24} sm={8} md={6} className="gutter-row">
          <CancelBtn onClick={this.CloseExportModal} />
        </Col>
        <Col xs={24} sm={8} md={6} className="gutter-row">
          <SaveBtn
            onClick={this.ExportData}
            loading={this.props.loading}
            buttonText="Export"
          />
        </Col>
      </Row>
    )
    return footer;
  }

  disabledDate = (current) => {
    const now = moment();
    const timeDiff = current.diff(now, 'months');
    const tooLate = timeDiff > 6 || timeDiff <= -6;
    const tooEarly = now.isBefore(current);
    return tooEarly || tooLate;
  }

  render() {
    return (
      <>
        <Modal
          title="Export Nominal Data"
          visible={this.props.showExportModal}
          footer={this.createFooter()}
          width={600}
          onCancel={this.CloseExportModal}
          destroyOnClose
        >
          <div>
            <Row>
              <Col span={24}>
                <Spin spinning={this.props.loading}>
                  <>
                    <Row>
                      <Col span={24}>
                        <p>Please select what options to include into the export.</p>
                      </Col>
                      <Col span={24}>
                        <Checkbox 
                          checked={this.state.outputFormat === 'xlsx'}
                          onChange={(val) => this.setState({outputFormat: val.target.checked ? 'xlsx' : 'pdf'})}
                        >
                          Export as Excel File
                        </Checkbox>
                      </Col>
                      <Col span={24}>
                        <p>The {this.state.outputFormat} file will be emailed to you. </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <RangePicker
                          className="p5-form-datepicker-input"
                          format="DD/MM/YYYY"
                          separator=" to "
                          disabledDate={this.disabledDate}
                          onChange={(val) => {
                            if (val && val.length === 2) {
                              this.setState({
                                startDate: val[0],
                                endDate: val[1]
                              });
                            }
                          }}
                          placeholder={['Export Start Date', 'Export End Date']}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={12}>
                        <Row>
                          <Col span={24}>
                            <span>Include: </span>
                          </Col>
                          <Col span={24}>
                            <Checkbox 
                              checked={this.state.includeAlerts}
                              onChange={(val) => this.setState({includeAlerts: val.target.checked})}
                            >
                              Alerts
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox 
                              checked={this.state.includeAlertSettings}
                              onChange={(val) => this.setState({includeAlertSettings: val.target.checked})}
                            >
                              Alert Settings
                            </Checkbox>
                          </Col>
                          <Col span={24}>
                            <Checkbox 
                              checked={this.state.includeLocationHistory}
                              onChange={(val) => this.setState({includeLocationHistory: val.target.checked})}
                            >
                              Location History
                            </Checkbox>
                          </Col>
                        </Row>
                      </Col>   
                    </Row>
                  </>
                </Spin>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

ExportModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  NominalId: PropTypes.number,
  showExportModal: PropTypes.bool,
  loading: PropTypes.bool,
};

ExportModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  NominalId: null,
  showExportModal: false,
  loading: false,
};

function mapStateToProps(state) {
  const { loading, showExportModal } = state.MosovoModal;

  return { loading, showExportModal };
}

const connectedExportModal = connect(mapStateToProps)(ExportModal);

export { connectedExportModal as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import { Layout, Row, Col } from "antd";
import actions from "../../Nominals/actions";

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class MapViewPageHeader extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      IsNominalLoaded: false,
    };
  }

  componentDidMount() {
    if (!this.state.IsNominalLoaded) {
      this.props.dispatch(actions.getNominalDetail(this.props.Id));
      this.setState({
        IsNominalLoaded: true,
      });
    }
  }

  render() {
    const { Content } = Layout;

    return (
      <div className="PageHeader">
        <Content>
          <Row>
            <Col xs={20} sm={12} md={10} lg={6}>
              <div className="pg-header-title">
                <span>
                  {this.props.Nominal.FirstName} {this.props.Nominal.LastName}
                </span>
              </div>
            </Col>
          </Row>
        </Content>
      </div>
    );
  }
}

MapViewPageHeader.propTypes = {
  dispatch: PropTypes.func,
  Nominal: PropTypes.shape(),
  Id: PropTypes.string,
};

MapViewPageHeader.defaultProps = {
  dispatch: () => {},
  Nominal: {},
  Id: 0,
};

const mapStateToProps = (state) => {
  const { Nominal } = state.Nominal;
  return { Nominal };
};

const connectedMapViewPageHeader = connect(mapStateToProps)(MapViewPageHeader);

export { connectedMapViewPageHeader as default };

/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Row, Col, Button, Tooltip, Tag } from "antd";
import moment from "moment";
import { IconButtons } from "../../../common/components";
import actions from "../actions";
import {
  getColumnSearchProps,
  dateSorter,
  getNominalsGridRiskTypeFilter,
  numericSorter,
  stringSorter,
  DATE,
  INPUT,
  RiskTypes,
} from "../../../common/utils/grid-filter-helper";

const { SearchBox } = IconButtons;
class SearchResultsGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      locationHistory: props.locationHistory,
      filterdLocationHistory: props.locationHistory,
      IsLocationRequestSuccess: false,
      pendingLocationHistory: [],
      numUpdates: 0,
      dateFilterVal: null,
      // locationAddresses: [],
      // isLocationAddressesUpdate: false,
    };
  }

  // getLocationData = () => {
  //   const searchQuery = `$filter=NominalId eq ${this.props.Nominal.Id}`;

  //   const { dispatch } = this.props;
  //   dispatch(
  //     actions.getMyNominalLocationData(searchQuery, this.setLocationData)
  //   );
  // };

  setLocationData = (data) => {
    this.setState(
      {
        locationHistory: data,
      },
      this.forceUpdate()
    );
  };

  getMarkerColor = (typeId) => {
    switch (typeId) {
      case 1:
        return "#000000";
      case 2:
        return "#9B0000";
      case 3:
        return "#B68300";
      default:
        return "#585757";
    }
  };

  setPendingLocationData = (data) => {
    let updates = 0;
    if (data && data.length > this.state.locationHistory.length) {
      updates = data.length - this.state.locationHistory.length;
    }
    this.setState((prevState) => ({
      ...prevState,
      pendingLocationHistory: data,
      numUpdates: updates
    }));
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
    this.props.onSelectChange(selectedRowKeys);
  };

  // getAddresses = () => {
  //   if (this.props.locationHistory.length > 0) {
  //     for (let i = 0; i < this.props.locationHistory.length; i += 1) {
  //       const location = this.props.locationHistory[i];
  //       // const key = `${this.props.locationHistory[i].Latitude},${this.props.locationHistory[i].Longitude}`
  //       if (!this.state.locationAddresses[i]) {
  //         AddressService.getAddressFromPosition(
  //           location.Latitude,
  //           location.Longitude
  //         )
  //           .then((response) => this.setAddress(response, i))
  //           .catch((error) => console.log(error));
  //       }
  //     }
  //     this.setState((prevState) => ({
  //       ...prevState,
  //       isLocationAddressesUpdate: true,
  //     }));
  //   }
  // };

  getAddressFromPosition = (record) => {
    // AddressService.getAddressFromPosition(record.Latitude, record.Longitude, result => {
    //   const address = result && result.length > 0 ? result[0].name : "";
    //   this.setAddress(address, record.Id);
    // });
    this.props.dispatch(
      actions.getLocationAddress(
        { Latitude: record.Latitude, Longitude: record.Longitude },
        this.props.onLocationSearch
      )
    );
  };

  setAddress = (newAddress, recordId) => {
    const addresses = [...this.state.locationHistory];
    const address = addresses.find((a) => a.Id === recordId);
    address.Address = newAddress;
    this.setState(
      (prevState) => ({
        ...prevState,
        locationHistory: addresses,
      }),
      this.forceUpdate()
    );
  };

  handleLocationRequestSuccessCallback = () => {
    this.setState((prevState) => ({
      ...prevState,
      IsLocationRequestSuccess: true,
    }));
  };

  onSearchDate = (value) => {
    if (value && value.length === 2) {
      const startDate = value[0].utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ").replace('+', '%2B');
      const endDate = value[1].utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ").replace('+', '%2B');
      this.props.onLocationSearch({startDate, endDate, dateField: 'CreatedDate', isSearch: true});
    } else {
      // This is needed as the filters persist incase one is cleared. This just makes the date filter range large. 
      const startDate = moment('1/1/1900').format("YYYY-MM-DD");
      const endDate = moment('1/1/3000').format("YYYY-MM-DD");
      this.props.onLocationSearch({startDate, endDate, dateField: 'CreatedDate', isSearch: false});
    }
  }

  onDateFilterChange = (value) => {
    if (!value) {
      this.props.onLocationSearch();
    } else if (value === 'Most Recent') {
      this.props.onLocationSearch(null, true);
    } else {
      const startDate = moment(value).format();
      const endDate = moment().local().format();
      this.props.onLocationSearch({startDate, endDate, dateField: 'CreatedDate', value});
    }
  }

  getRiskType = (record) => {
    const riskId = record.RiskLevelId;
    if (riskId === RiskTypes.VH) {
      return (
        <span>
          <Tooltip title="Very High">
            <Tag className={record.Active ? "tag-vh" : "tag-nt"}>VH</Tag>
          </Tooltip>
        </span>
      );
    }

    if (riskId === RiskTypes.H) {
      return (
        <span>
          <Tooltip title="High">
            <Tag className={record.Active ? "tag-vh" : "tag-nt"}>H</Tag>
          </Tooltip>
        </span>
      );
    }

    if (riskId === RiskTypes.M) {
      return (
        <span>
          <Tooltip title="Medium">
            <Tag className={record.Active ? "tag-m" : "tag-nt"}>M</Tag>
          </Tooltip>
        </span>
      );
    }

    return (
      <span>
        <Tooltip title="Low">
          <Tag className={record.Active ? "tag-l" : "tag-nt"}>L</Tag>
        </Tooltip>
      </span>
    );
  };
  
  getNominalData = (value = "") => {
    this.setState((prevState) => ({
      filterdLocationHistory: prevState.locationHistory.filter(lh => lh.NominalName.includes(value)),
    }));
  };

  render() {
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const gridColumns = [
      {
        title: "",
        dataIndex: "Index",
        key: "Index",
        render: (text, record) =>
          record.FullName !== null ? (
            <div className="nominalId" style={{backgroundColor: this.getMarkerColor(record.NominalTypeId)}}>
              {record.key + 1}
            </div>
          ) : null,   
      },
      {
        title: "Name",
        dataIndex: "NominalName",
        key: "NominalName",
        width: "40%",
      },
      {
        title: "DateOfBirth",
        dataIndex: "DateOfBirth",
        key: "DateOfBirth",
        sorter: (a, b) => {return dateSorter(a, b, "DateOfBirth");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "DateOfBirth", title: "DateOfBirth", componentType: DATE}),
        render: (text, record) => (
          <span
            style={{
              color: record.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
              fontStyle: record.Active ? "normal" : "italic",
            }}
          >
            {moment(record.DateOfBirth).format("DD-MM-YYYY")}
          </span>
        )
      },
      {
        title: "Force",
        dataIndex: "ForceName",
        key: "ForceName",
        width: "30%",
      },
    ];

    const gridFullListColumns = [
      {
        title: "",
        dataIndex: "Index",
        key: "Index",
        render: (text, record) =>
          record.FullName !== null ? (
            <div className="nominalId" style={{backgroundColor: this.getMarkerColor(record.NominalTypeId)}}>
              {record.key + 1}
            </div>
          ) : null,   
      },
      {
        title: "Name",
        dataIndex: "NominalName",
        key: "NominalName",
      },
      {
        title: "DateOfBirth",
        dataIndex: "DateOfBirth",
        key: "DateOfBirth",
        sorter: (a, b) => {return dateSorter(a, b, "DateOfBirth");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "DateOfBirth", title: "DateOfBirth", componentType: DATE}),
        render: (text, record) => (
          <span
            style={{
              color: record.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
              fontStyle: record.Active ? "normal" : "italic",
            }}
          >
            {moment(record.DateOfBirth).format("DD-MM-YYYY")}
          </span>
        )
      },
      {
        title: "Risk",
        dataIndex: "RiskLevel",
        key: "RiskLevel",
        sorter: (a, b) => {return numericSorter(a, b, "RiskLevelId");},
        sortDirections: ["descend", "ascend"],
        ...getNominalsGridRiskTypeFilter(),
        render: (text, record) =>
          record.RiskLevelId !== null ? <>{this.getRiskType(record)}</> : null,
      },
      {
        title: "Offender Manager",
        dataIndex: "OffenderManager",
        key: "OffenderManager",
        sorter: (a, b) => {return stringSorter(a, b, "OffenderManager");},
        ...getColumnSearchProps({ dataIndex: "OffenderManager", title: "OM", componentType: INPUT}),
        render: (text,record) => (
          <>
            <span
              style={{
                color: record.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                fontStyle: record.Active ? "normal" : "italic",
              }}
            >
              {record.OffenderManager}
            </span>
          </>
        ),
      },
      {
        title: "Force",
        dataIndex: "ForceName",
        key: "ForceName",
      },
      {
        title: "Team",
        dataIndex: "TeamName",
        key: "TeamName",
        sorter: (a, b) => {return stringSorter(a, b, "TeamName");},
        ...getColumnSearchProps({ dataIndex: "TeamName", title: "Team", componentType: INPUT}),
        render: (text,record) => (
          <>
            <span
              style={{
                color: record.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                fontStyle: record.Active ? "normal" : "italic",
              }}
            >
              {record.TeamName}
            </span>
          </>
        ),
      },
      {
        title: "Area",
        dataIndex: "AreaName",
        key: "AreaName",
        sorter: (a, b) => {return stringSorter(a, b, "AreaName");},
        ...getColumnSearchProps({ dataIndex: "AreaName", title: "Area", componentType: INPUT}),
        render: (text,record) => (
          <>
            <span
              style={{
                color: record.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                fontStyle: record.Active ? "normal" : "italic",
              }}
            >
              {record.AreaName}
            </span>
          </>
        ),
      },
    ];

    const rowsWithKeys = this.state.filterdLocationHistory.map((lh, index) => {
      return {...lh, key: index }
    });

    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
          <Col xs={24} sm={24} md={6} lg={6}> 
            <span className="mosovo-table-header">Filter Results</span>
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
            <SearchBox 
              placeholder="Search Nominal"
              allowClear
              onSearch={this.getNominalData}
              minText={3}
              loading={false}
            />
          </Col>
          <Col xs={24} sm={24} md={12}>
            <Button
              type="link"
              style={{float: 'right'}}
              onClick={() => this.props.expandedCard === 0 ? this.props.setExpandedCard(1) : this.props.setExpandedCard(0)}
            >
              {this.props.expandedCard === 0 ? 'Show full table' : 'Show map'}
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <span>{`Locations Selected: ${selectedRowKeys.length}`}</span>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              loading={this.props.loading}
              dataSource={rowsWithKeys}
              columns={this.props.expandedCard === 1 ? gridFullListColumns : gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

SearchResultsGrid.propTypes = {
  dispatch: PropTypes.func,
  onLocationSearch: PropTypes.func,
  // filterLocationData: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  locationHistory: PropTypes.array,
  onSelectChange: PropTypes.func,
  setExpandedCard: PropTypes.func,
  expandedCard: PropTypes.number,
  // locationHistory: PropTypes.arrayOf(
  //   PropTypes.shape({
  //     Latitude: PropTypes.number,
  //     Longitude: PropTypes.number,
  //     Address: PropTypes.string,
  //   })
  // ),
  loading: PropTypes.bool,
  // Nominal: PropTypes.shape(),
};

SearchResultsGrid.defaultProps = {
  dispatch: () => {},
  onLocationSearch: () => {},
  // filterLocationData: () => {},
  locationHistory: [],
  setExpandedCard: () => {},
  expandedCard: 0,
  loading: false,
  onSelectChange: () => {},
  // Nominal: {},
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;
  return {
    loading,
  };
}

const connectedSearchResultsGrid = connect(mapStateToProps)(
  SearchResultsGrid
);

export { connectedSearchResultsGrid as default };

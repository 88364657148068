import React from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { Row, Col, Form, Button, Tooltip } from "antd";

import {
    faCheckCircle,
    faTimesCircle
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { OMSelect } from '../../../common/components';

class NominalTransferInlineForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
  }

  componentDidUpdate() {
    this.formIK.current.handleReset();
  }

  pivotData = (data) => ({
    NominalId: data.Id,
    OMId: data.OffenderManagerId,
  });

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.props.Id,
          OffenderManagerId: this.props.OffenderManagerId,         
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          OffenderManagerId: Yup.number().required("Offender Manager is required").typeError("Offender Manager is required"),
        })}
        // onChange={(values) => this.handleChange(values)}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form id="NominalTransferInlineForm" onSubmit={handleSubmit} onChange={handleChange}>
            <div>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col xs={24} sm={20} md={20}>
                      <Form.Item
                        validateStatus={
                          errors && errors.OffenderManagerId && touched.OffenderManagerId
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.OffenderManagerId && touched.OffenderManagerId
                            ? errors.OffenderManagerId
                            : null
                        }
                      >
                        <OMSelect 
                          id="OffenderManagerId"
                          onBlur={handleBlur}
                          value={values.OffenderManagerId}
                          onChange={(val) => {
                            setFieldValue("OffenderManagerId", val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={4} sm={2} md={2}>
                      <Tooltip title="Save">  
                        <Button type="link" size="middle" loading={this.props.isLoading} onClick={handleSubmit}>
                          <span className="anticon" role="img">
                            {!this.props.isLoading && (
                              <FontAwesomeIcon
                                icon={faCheckCircle}
                                alt="Save"
                                className="inline-btn inline-btn-save"
                              />
                            )}
                          </span>
                        </Button>
                      </Tooltip>
                    </Col>
                    <Col xs={4} sm={2} md={2}>
                      <Tooltip title="Cancel"> 
                        <Button type="link" size="middle" onClick={this.props.onCancel}>
                          <span className="anticon" role="img">
                            <FontAwesomeIcon
                              icon={faTimesCircle}
                              alt="Cancel"
                              className="inline-btn inline-btn-cancel"
                            />
                          </span>
                        </Button>
                      </Tooltip>
                    </Col>
                  </Row> 
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

NominalTransferInlineForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  Id: PropTypes.number,
  OffenderManagerId: PropTypes.number,
};

NominalTransferInlineForm.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  isLoading: false,
  Id: 0,
  OffenderManagerId: null,
};

export default NominalTransferInlineForm;
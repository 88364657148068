import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Spin } from "antd";
import { LocationHistoryGrid, ExclusionZoneGrid } from "../grids";
// import actions from "../actions";

class LocationHistory extends React.Component {
  // constructor(props) {
  //   super(props);

  //   // this.state = {
  //   //   hasUpdates: false,
  //   // };
  // }
  
  // componentDidMount() {
  //   this.getLocationData();
  // }

  // getLocationData = () => {
  //   const searchQuery = `$filter=NominalId eq ${this.props.Nominal.Id}`;

  //   const { dispatch } = this.props;
  //   dispatch(
  //     actions.getMyNominalLocationData(searchQuery, this.setLocationData)
  //   );
  // };

  // setLocationData = (data) => {
  //   this.setState(
  //     {
  //       locationHistory: data,
  //     },
  //     this.forceUpdate()
  //   );
  // };

  // setPendingLocationData = (data) => {
  //   let updates = false;
  //   if (data && data.length !== this.props.locationHistory.length) {
  //     updates = true;
  //   }
  //   this.setState((prevState) => ({
  //     ...prevState,
  //     pendingLocationHistory: data,
  //     hasUpdates: updates
  //   }));
  // }

  render() {
    return (
      <>
        <Spin spinning={this.props.loading}>
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col sm={24} md={24} lg={14} className="gutter-row">
              <LocationHistoryGrid
                dispatch={this.props.dispatch}
                locationHistory={this.props.locationHistory}
                onLocationSearch={this.props.onLocationSearch}
                Nominal={this.props.Nominal}
              />
            </Col>
            <Col sm={24} md={24} lg={10} className="gutter-row">
              <ExclusionZoneGrid
                exclusionZones={this.props.exclusionZones}
                setShowAddExclusionZoneForm={this.setShowAddExclusionZoneForm}
                locationHistory={this.props.locationHistory}
                refreshGrid={this.props.refreshGrid}
              />
            </Col>
          </Row>
        </Spin>
      </>
    );
  }
}

LocationHistory.propTypes = {
  dispatch: PropTypes.func,
  onLocationSearch: PropTypes.func,
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  locationHistory: PropTypes.array,
  exclusionZones: PropTypes.arrayOf(PropTypes.shape({})),
  Nominal: PropTypes.shape(),
  refreshGrid: PropTypes.func,
};

LocationHistory.defaultProps = {
  dispatch: () => {},
  onLocationSearch: () => {},
  loading: false,
  locationHistory: [],
  exclusionZones: [],
  Nominal: {},
  refreshGrid: () => {},
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;

  return { loading };
}

const connectedLocationHistory = connect(mapStateToProps)(LocationHistory);

export { connectedLocationHistory as default };

import React from "react";
import PropTypes from "prop-types";
import { Spin, Image } from "antd";
import { FileService } from '../../../services';

class ImageThumbnail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      src: null,
    }
  }

  componentDidMount() {
    const { Id } = this.props;
    if (Id !== null && Id > 0) {
      FileService.getImageResize(Id).then(res => {
        const base64 = btoa(
          new Uint8Array(res.data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ""
          )
        );
        const url = `data:${res.headers["content-type"]};base64,${base64}`;
        this.handleStateChange(url);
      });
    }
  }

  handleStateChange = (url) => {
    this.setState(prevState => ({
      ...prevState,
      loading: false,
      src: url,
    }));
  }
  
  render() {
    return (
      this.state.loading ? (
        <Spin size="medium" />
      ) : (
        <span>
          <Image alt="Image" onClick={this.props.OnClick} src={this.state.src} width={this.props.Width} preview={this.props.CanPreview} height={this.props.Height} />
        </span>
      )
    );
  }
}

ImageThumbnail.propTypes = {
  Id: PropTypes.number,
  photo: PropTypes.shape({
    src: PropTypes.string,
    selected: PropTypes.bool,
  }),
  CanPreview: PropTypes.bool,
  Width: PropTypes.number,
  Height: PropTypes.number,
  OnClick: PropTypes.func
};

ImageThumbnail.defaultProps = {
  Id: 0,
  photo: null,
  CanPreview: true,
  Width: 75,
  Height: null,
  OnClick: () => {}
};

export { ImageThumbnail as default };

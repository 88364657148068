const auth0Codes = {
  admin_update_launch: "",
  api_limit: "",
  cls: "Code/Link Sent",
  cs: "Code Sent",
  depnote: "Deprecation Notice",
  du: "Deleted User",
  f: "Failed Login",
  fapi: "Operation on API failed",
  fc: "failed by Connector",
  fce: "Failed Change Email",
  fco: "Failed by CORS",
  fcoa: "Failed cross-origin authentication",
  fcp: "Failed Change Password",
  fcph: "Failed Post Change Password Hook",
  fcpn: "Failed Change Phone Number",
  fcpro: "Failed Connector Provisioning",
  fcu: "Failed Change Username",
  fd: "Failed Delegation",
  fceac: "Failed Device Activation",
  fdeaz: "Failed Device Authorization Request",
  fdecc: "User Canceled Device Confirmation",
  fdu: "Failed User Deletion",
  feacft: "Failed to exchange authorization code for Access Token",
  feccft: "Failed exchange of Access Token for a Client Credentials Grant",
  fede: "Failed to exchange Device Code for Access Token",
  fens: "Failed exchange for Native Social Login",
  feoobft: "Failed exchange of Password and OOB Challenge for Access Token",
  feotpft: "Failed exchange of Password and OTP Challenge for Access Token",
  fepft: "Failed exchange of Password for Access Token",
  fepotpft: "Failed exchange of Passwordless OTP for Access Token",
  fercft: "Failed Exchange of Password and MFA Recovery code for Access Token",
  fertft: "Failed Exchange of Refresh Token for Access Token",
  ferrt: "Failed Exchange of Rotating Refresh Token",
  fi: "Failed invite accept",
  flo: "Failed Logout",
  fn: "Failed Login",
  fs: "Failed Signup",
  fsa: "Failed Silent Auth",
  fu: "Failed Login (Invalid Email/Password)",
  fui: "Failed to import users",
  fv: "Failed Verification Email",
  fvr: "Failed Verification Email Request",
  gd_auth_failed:
    "Multi-factor authentication failed. This could happen due to a wrong code entered for SMS/Voice/Email/TOTP factors, or a system failure.",
  gd_auth_rejected:
    "A user rejected a Multi-factor authentication request via push-notification.",
  gd_auth_succeed: "Multi-factor authentication success.",
  gd_enrollment_complete:
    "A first time MFA user has successfully enrolled using one of the factors.",
  gd_otp_rate_limit_exceed:
    "A user, during enrollment or authentication, enters an incorrect code more than the maximum allowed number of times. Ex: A user enrolling in SMS enters the 6-digit code wrong more than 10 times in a row.",
  gd_recovery_failed:
    "A user enters a wrong recovery code when attempting to authenticate.",
  gd_recovery_rate_limit_exceed:
    "A user enters a wrong recovery code too many times.",
  gd_recovery_succeed:
    "A user successfully authenticates with a recovery code.",
  gd_send_pn: "Push notification for MFA sent successfully sent.",
  gd_send_sms: "SMS for MFA successfully sent.",
  gd_send_sms_failure: "Attempt to send SMS for MFA failed.",
  gd_send_voice: "Voice call for MFA successfully made.",
  gd_send_voice_failure: "Attempt to make Voice call for MFA failed.",
  gd_start_auth: "Second factor authentication event started for MFA.",
  gd_start_enroll: "Multi-factor authentication enroll has started.",
  gd_tenant_update: "Guardian tenant update",
  gd_unenroll: "Unenroll device account",
  gd_update_device_account: "Update device account",
  limit_delegation: "	Too Many Calls to /delegation",
  limit_mu: "Blocked IP Address",
  limit_wc: "Blocked Account",
  limit_sul: "Blocked Account",
  mfar: "MFA Required",
  mgmt_api_read: "Management API read Operation",
  pla: "Pre-login assessment",
  pwd_leak: "Breached password",
  s: "Successful Login",
  sapi: "Successful API Operation",
  sce: "Successful Change Email",
  scoa: "Successful cross-origin authentication",
  scp: "Successful Change Password",
  scph: "Success Post Change Password Hook",
  scpn: "Success Change Phone Number",
  scpr: "Successful Change Password Request",
  scu: "Success Change Username",
  sd: "Successful Delegation",
  sdu: "Success User Delegation",
  seacft: "Success Exchange",
  seccft: "Success Exchange",
  sede: "Success Exchange",
  sens: "Success Exchange",
  seoobft: "Success Exchange",
  seotpft: "Success Exchange",
  sepft: "Success Exchange",
  sercft: "Success Exchange",
  sertft: "Success Exchange",
  si: "Successful invite accept",
  srrt: "Success Revocation",
  slo: "Success Logout",
  ss: "Success Signup",
  ssa: "Success Silent Auth",
  sui: "Success users import",
  sv: "Success Verification Email",
  svr: "Success Verification Email Request",
  sys_os_update_end: "Auth0 OS Update Ended",
  sys_os_update_start: "Auth0 OS Update Started",
  sys_update_end: "Auth0 Update Ended",
  sys_update_start: "Auth0 Update Started",
  ublkdu: "User login block released",
  w: "Warnings During Login",
};

const lookupAuth0Code = (code) => {
  const codeDescription = auth0Codes[code];

  if (codeDescription == null) {
    return code;
  }
  return codeDescription;
};

export default lookupAuth0Code;

import constants from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  UserModal: {
    showAddModal: false,
    showEditModal: false,
    showProfileModal: false,
    showOMTransferModal: false,
  },
  user: {},
};

export default function UserReducer(state = initialState, action) {
  switch (action.type) {
    case constants.USERS_GRID_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.USERS_GRID_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.USERS_GRID_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.OPEN_TRANSFER_OM_MODAL:
      return {
        ...state,
        UserModal: {
          showOMTransferModal: true,
          showAddModal: false,
          showEditModal: false,
          showProfileModal: false,
        }
      };
    case constants.OPEN_ADD_USERS_MODAL:
      return {
        ...state,
        UserModal: {
          showAddModal: true,
          showEditModal: false,
          showProfileModal: false,
          showOMTransferModal: false,
        },
      };
    case constants.OPEN_EDIT_USERS_MODAL:
      return {
        ...state,
        UserModal: {
          showAddModal: false,
          showEditModal: true,
          showProfileModal: false,
          showOMTransferModal: false,
        },
      };
    case constants.CLOSE_USERS_MODAL:
      return {
        ...state,
        UserModal: {
          showAddModal: false,
          showEditModal: false,
          showProfileModal: false,
          showOMTransferModal: false,
        },
        user: {},
      };
      case constants.OPEN_PROFILE_MODAL:
        return {
          ...state,
          UserModal: {
            showAddModal: false,
            showEditModal: false,
            showProfileModal: true,
            showOMTransferModal: false,
          },
        };
      case constants.USERS_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.USERS_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.USERS_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        user: {},
      };
    case constants.GET_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        user: action.user,
      };
    case constants.GET_USER_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        user: action.user,
      };
    case constants.UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        user: action.user,
      };
    case constants.UPDATE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.DELETE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}

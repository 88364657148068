import React from "react";
import PropTypes from "prop-types";
import { Button } from "antd";
import { faPlus } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class AddButton extends React.Component {
  render() {
    return (
      <>
        <Button
          style={{ backgroundColor: "#243B53", color: "white" }}
          onClick={this.props.onClick}
        >
          <FontAwesomeIcon
            icon={faPlus}
            id={this.props.id}
            className=""
            alt="Add"
          />
        </Button>
      </>
    );
  }
}

AddButton.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
};

AddButton.defaultProps = {
  onClick: () => {},
  id: "",
};

export default AddButton;

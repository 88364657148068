import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Row, Col, Button, Divider, Spin, Tooltip } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import moment from "moment";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NominalTransferInlineForm } from '../forms';
import actions from "../actions";
import { hasRights, rights } from "../../../common/utils/role-right-helper";

class NominalBasicInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      OMTransferMode: false
    }
  }

  NominalOMTransferModal = (data) => {
    const { confirm } = Modal;
    const nominalOMTransfer = this.NominalOMTranser;
    const cancelNominalOMTranser = this.CancelNominalOMTranser;
    const message = (
      <div>
        <p>
          <b>Are you sure you want to re-assign the following nominal from {this.props.OffenderManagerName}?</b>
        </p>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Name:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {this.props.FirstName} {this.props.LastName}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Date of Birth:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {this.props.DateOfBirth
              ? moment(this.props.DateOfBirth).format("DD-MM-YYYY")
              : ""}
          </Col>
        </Row>
      </div>
    );

    confirm({
      title: "Re-assign Nominal",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      closable: true, 
      onOk() {
        nominalOMTransfer(data);
      },
      onCancel() {
        cancelNominalOMTranser();
      },
      okText: "Yes, Re-Assign",
    });
  };

  NominalOMTranser = (data) => {
    this.props.dispatch(actions.transferNominal(data, this.RefreshGrid));
  }

  CancelNominalOMTranser = () => {
    this.setState({
      OMTransferMode: false,
    });
  }

  RefreshGrid = () => {
    this.props.dispatch(actions.getNominalDetail(this.props.Id));
    this.setState({
      OMTransferMode: false,
    });
  };

  EditNominalOM = () => {
    this.setState({
      OMTransferMode: true,
    });
  }

  render() {
    const editBasicInfoButton = (id) => (
      <Tooltip
        placement="topRight"
        title="Edit Basic Information"
      >
        <Button
          shape="circle"
          style={{ backgroundColor: "white", color: "#243b53", margin: "5px" }}
          onClick={() => this.props.edit(id)}
        >
          <FontAwesomeIcon
            icon={faEdit}
            id="EditNominalBasicInfo"
            className=""
            alt="basic"
          />
        </Button>
      </Tooltip>
    );    

    return (
      <>
        <Spin spinning={this.props.loading}>
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col span={12} className="gutter-row">
              <h6>Basic Information</h6>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Col>
                  <span>{editBasicInfoButton(this.props.Id)}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>Name:</span>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
              <span
                style={{
                  color: this.props.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: this.props.Active ? "normal" : "italic",
                }}
              >
                {`${this.props.FirstName} ${this.props.LastName}`}
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>Date of Birth:</span>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
              <span
                style={{
                  color: this.props.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: this.props.Active ? "normal" : "italic",
                }}
              >
                {`${
                  this.props.DateOfBirth
                    ? moment(this.props.DateOfBirth).format("DD-MM-YYYY")
                    : ""
                }`}
              </span>
            </Col>
          </Row>
          <Row>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>Home Address:</span>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
              <span
                style={{
                  color: this.props.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: this.props.Active ? "normal" : "italic",
                }}
              >
                {`${this.props.Address1}${
                  this.props.Address2 ? ` ${this.props.Address2}` : ""
                }, ${this.props.City}, ${this.props.PostalCode}`}
              </span>
            </Col>
          </Row>
          <Divider />
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col span={24} className="gutter-row">
              <h6>Assigned Offender Manager</h6>
            </Col>
          </Row>
          <br />
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>Name: </span>
            </Col>
            <Col xs={20} sm={22} md={16} lg={16} className="gutter-row">
              {this.state.OMTransferMode ? (
                <>
                  <NominalTransferInlineForm 
                    {...this.props} 
                    isLoading={this.props.loading}
                    onSubmit={this.NominalOMTransferModal} 
                    onCancel={this.CancelNominalOMTranser}
                  />                  
                </>
              ) : (
                <span
                  style={{
                    color: this.props.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                    fontStyle: this.props.Active ? "normal" : "italic",
                  }}
                >
                  {`${this.props.OffenderManagerName}`}
                  {(!this.state.OMTransferMode && (hasRights([rights.CanManageForceAdmins])
                    || hasRights([rights.CanManageAreaAdmins])
                    || hasRights([rights.CanManageTeamAdmins])
                    || hasRights([rights.CanManageTeamUsers])))
                    && (
                      <Tooltip title="Change OM">
                        <Button shape="circle" size="middle" style={{ backgroundColor: "white", color: "#243b53", margin: "5px" }} onClick={this.EditNominalOM}>
                          <span className="anticon" role="img">
                            <FontAwesomeIcon
                              style={{fontSize:"14px"}}
                              icon={faEdit}
                              alt="Edit"
                            />
                          </span>
                        </Button>
                      </Tooltip>
                  )}
                </span>                
              )}
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>Phone Number: </span>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
              <span
                style={{
                  color: this.props.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: this.props.Active ? "normal" : "italic",
                }}
              >
                {`${this.props.OffenderManagerPhoneNumber || ""}`}
              </span>
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>Collar Number: </span>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
              <span
                style={{
                  color: this.props.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: this.props.Active ? "normal" : "italic",
                }}
              >
                {`${this.props.OffenderManagerCollarNumber || ""}`}
              </span>
            </Col>
          </Row>
        </Spin>
      </>
    );
  }
}

NominalBasicInfo.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  edit: PropTypes.func,
  loading: PropTypes.bool,
  DateOfBirth: PropTypes.string,
  Address1: PropTypes.string,
  Address2: PropTypes.string,
  City: PropTypes.string,
  PostalCode: PropTypes.string,
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  Id: PropTypes.number,
  OffenderManagerId: PropTypes.number,
  OffenderManagerName: PropTypes.string,
  OffenderManagerCollarNumber: PropTypes.number,
  OffenderManagerPhoneNumber: PropTypes.string,
  Active: PropTypes.bool,
};

NominalBasicInfo.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {}, 
  edit: () => {},
  loading: false,
  DateOfBirth: "",
  Address1: "",
  Address2: null,
  City: "",
  PostalCode: "",
  FirstName: "",
  LastName: "",
  Id: 0,
  OffenderManagerId: null,
  OffenderManagerName: "",
  OffenderManagerCollarNumber: null,
  OffenderManagerPhoneNumber: "",
  Active: true,
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;

  return { loading };
}

const connectedNominalBasicInfo = connect(mapStateToProps)(NominalBasicInfo);

export { connectedNominalBasicInfo as default };

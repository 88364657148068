import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import actions from "../../account/actions";
import { history } from "../../../store";
import "./LoginPage.css";
import MFAForm from "../form/MFAForm";

class MFAPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.username = window.location.pathname.substring(
      window.location.pathname.lastIndexOf("/") + 1
    );
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loggedIn !== this.props.loggedIn)
      history.push(this.getPath());
  }

  getPath() {
    return this.props.location.state
      ? this.props.location.state.from.pathname
      : "/";
  }

  handleLogin = (data) => {
    const { dispatch } = this.props;
    dispatch(actions.VerifyMFA(this.username, data.VerificationCode));
  };

  render() {
    return (
      <div className="login-layout-container">
        <Row justify="center">
          <Col span={24}>
            <MFAForm
              name="Login"
              onSubmit={this.handleLogin}
              hasError={this.props.hasError}
              error={this.props.error}
              username={this.username}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { hasError, error, loggedIn } = state.Account;
  return {
    hasError,
    error,
    loggedIn,
  };
}

const connectedMFAPage = connect(mapStateToProps)(MFAPage);

MFAPage.propTypes = {
  dispatch: () => {},
  hasError: PropTypes.bool,
  loggedIn: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }),
  }),
  error: PropTypes.string,
};

MFAPage.defaultProps = {
  dispatch: () => {},
  hasError: false,
  loggedIn: false,
  location: {},
  error: null,
};

export { connectedMFAPage as default };

/* eslint-disable no-console */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { Table, Row, Col, DatePicker, Tooltip, Divider, Button } from "antd";
import moment from "moment";
import {
  faMap,
  faCheckCircle,
  faLocationArrow,
  faSyncAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateFilterDropdown } from "../../../common/components/date-filter-dropdown";
import actions from "../actions";
import { NominalService } from "../../../services";
import {
  getColumnSearchProps,
  dateSorter,
  stringSorter,
  timeSorter,
  INPUT,
  DATE,
  TIME,
} from "../../../common/utils/grid-filter-helper";

const { RangePicker } = DatePicker;
class LocationHistoryGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      locationHistory: props.locationHistory,
      IsLocationRequestSuccess: false,
      pendingLocationHistory: [],
      numUpdates: 0,
      dateFilterVal: null,
      // locationAddresses: [],
      // isLocationAddressesUpdate: false,
    };
  }

  componentDidMount() {
    if(this.props.Nominal.Id) {
      this.props.onLocationSearch();
      setTimeout(() => this.getNominalLocationsAsync(this.props.Nominal.Id), 60000);
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.Nominal ? prevProps.Nominal.Id !== this.props.Nominal.Id : false) {
      this.props.onLocationSearch();
      setTimeout(() => this.getNominalLocationsAsync(this.props.Nominal.Id), 60000);
    }
    if (prevProps.locationHistory !== this.props.locationHistory || prevProps.locationHistory.length !== this.props.locationHistory.length) {
      this.setLocationData(this.props.locationHistory);
    }
  }

  getNominalLocationsAsync(id) {
    const searchQuery = `$filter=NominalId eq ${id}`;
    NominalService.getNominalLocationOdata(searchQuery).then(
      (response) => {
        this.setPendingLocationData(response.data.value);
        setTimeout(() => this.getNominalLocationsAsync(id), 60000);
      });
  }

  // getLocationData = () => {
  //   const searchQuery = `$filter=NominalId eq ${this.props.Nominal.Id}`;

  //   const { dispatch } = this.props;
  //   dispatch(
  //     actions.getMyNominalLocationData(searchQuery, this.setLocationData)
  //   );
  // };

  setLocationData = (data) => {
    this.setState(
      {
        locationHistory: data,
      },
      this.forceUpdate()
    );
  };

  setPendingLocationData = (data) => {
    let updates = 0;
    if (data && data.length > this.state.locationHistory.length) {
      updates = data.length - this.state.locationHistory.length;
    }
    this.setState((prevState) => ({
      ...prevState,
      pendingLocationHistory: data,
      numUpdates: updates
    }));
  }

  onRefreshClick = () => {
    const pending = this.state.pendingLocationHistory;
    if (this.state.numUpdates) {
      this.setState({
        locationHistory: pending,
        pendingLocationHistory: [],
        numUpdates: 0
      });
    } else {
      this.props.onLocationSearch();
    }
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  // getAddresses = () => {
  //   if (this.props.locationHistory.length > 0) {
  //     for (let i = 0; i < this.props.locationHistory.length; i += 1) {
  //       const location = this.props.locationHistory[i];
  //       // const key = `${this.props.locationHistory[i].Latitude},${this.props.locationHistory[i].Longitude}`
  //       if (!this.state.locationAddresses[i]) {
  //         AddressService.getAddressFromPosition(
  //           location.Latitude,
  //           location.Longitude
  //         )
  //           .then((response) => this.setAddress(response, i))
  //           .catch((error) => console.log(error));
  //       }
  //     }
  //     this.setState((prevState) => ({
  //       ...prevState,
  //       isLocationAddressesUpdate: true,
  //     }));
  //   }
  // };

  getAddressFromPosition = (record) => {
    // AddressService.getAddressFromPosition(record.Latitude, record.Longitude, result => {
    //   const address = result && result.length > 0 ? result[0].name : "";
    //   this.setAddress(address, record.Id);
    // });
    this.props.dispatch(
      actions.getLocationAddress(
        { Latitude: record.Latitude, Longitude: record.Longitude },
        this.props.onLocationSearch
      )
    );
  };

  setAddress = (newAddress, recordId) => {
    const addresses = [...this.state.locationHistory];
    const address = addresses.find((a) => a.Id === recordId);
    address.Address = newAddress;
    this.setState(
      (prevState) => ({
        ...prevState,
        locationHistory: addresses,
      }),
      this.forceUpdate()
    );
  };

  handleLocationRequestSuccessCallback = () => {
    this.setState((prevState) => ({
      ...prevState,
      IsLocationRequestSuccess: true,
    }),
      () => setTimeout(() => this.setState({
        IsLocationRequestSuccess: false,
      }), 5000)
    )
  };

  onSearchDate = (value) => {
    if (value && value.length === 2) {
      const startDate = value[0].utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ").replace('+', '%2B');
      const endDate = value[1].utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ").replace('+', '%2B');
      this.props.onLocationSearch({startDate, endDate, dateField: 'CreatedDate', isSearch: true});
    } else {
      // This is needed as the filters persist incase one is cleared. This just makes the date filter range large. 
      const startDate = moment('1/1/1900').format("YYYY-MM-DD");
      const endDate = moment('1/1/3000').format("YYYY-MM-DD");
      this.props.onLocationSearch({startDate, endDate, dateField: 'CreatedDate', isSearch: false});
    }
  }

  onDateFilterChange = (value) => {
    if (!value) {
      this.props.onLocationSearch();
    } else if (value === 'Most Recent') {
      this.props.onLocationSearch(null, true);
    } else {
      const startDate = moment(value).format().replace('+', '%2B');
      const endDate = moment().local().format().replace('+', '%2B');
      this.props.onLocationSearch({startDate, endDate, dateField: 'CreatedDate', value});
    }
  }

  disabledDate = (current) => {
    const now = moment();
    const timeDiff = current.diff(now, 'months');
    const tooLate = timeDiff > 6 || timeDiff <= -6;
    const tooEarly = now.isBefore(current);
    return tooEarly || tooLate;
  }

  render() {
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const locationRequestSuccess = (
      <>
        <span style={{ color: "#1C5906" }}>
          <FontAwesomeIcon
            icon={faCheckCircle}
            className=""
            alt="locationRequestSuccess"
          />
        </span>
        <span style={{ color: "#1C5906" }}>Request sent.</span>
      </>
    );

    const gridColumns = [
      {
        title: "Date",
        dataIndex: "CreatedDate",
        key: "Date",
        width: "10%",
        sorter: (a, b) => {return dateSorter(a, b, "CreatedDate");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "CreatedDate", title: "Date", componentType: DATE}),
        render: (text, record) =>
          this.state.locationHistory.length >= 1 &&
          record.CreatedDate !== null ? (
            <>
              <span
                style={{
                  color: this.props.Nominal.Active
                    ? "rgba(0, 0, 0, 0.85)"
                    : "#909090",
                  fontStyle: this.props.Nominal.Active ? "normal" : "italic",
                }}
              >
                {moment(record.CreatedDate).local().format("DD-MM-YYYY")}
              </span>
            </>
          ) : null,
      },
      {
        title: "Time",
        dataIndex: "CreatedDate",
        key: "Time",
        width: "10%",
        sorter: (a, b) => {return timeSorter(moment(a.CreatedDate), moment(b.CreatedDate));},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "CreatedDate", title: "Time", componentType: TIME}),
        render: (text, record) =>
          this.state.locationHistory.length >= 1 &&
          record.CreatedDate !== null ? (
            <>
              <span
                style={{
                  color: this.props.Nominal.Active
                    ? "rgba(0, 0, 0, 0.85)"
                    : "#909090",
                  fontStyle: this.props.Nominal.Active ? "normal" : "italic",
                }}
              >
                {moment(record.CreatedDate).local().format("h:mm:ss A")}
              </span>
            </>
          ) : null,
      },
      {
        title: "Location",
        dataIndex: "Location",
        key: "Location",
        width: "80%",
        sorter: (a, b) => {return stringSorter(a, b, "Address");},
        ...getColumnSearchProps({ dataIndex: "Address", title: "Location", componentType: INPUT}),
        render: (text, record) =>
          this.state.locationHistory.length >= 1 ? (
            <>
              {record.Address && (
                <span
                  style={{
                    color: this.props.Nominal.Active
                      ? "rgba(0, 0, 0, 0.85)"
                      : "#909090",
                    fontStyle: this.props.Nominal.Active ? "normal" : "italic",
                  }}
                >
                  {record.Address}
                </span>
              )}
              {!record.Address && (
                <Button
                  type="link"
                  onClick={() => this.getAddressFromPosition(record)}
                >
                  {`${record.Latitude}, ${record.Longitude}`}
                </Button>
              )}
            </>
          ) : (
            <>
              <span
                style={{
                  color: this.props.Nominal.Active
                    ? "rgba(0, 0, 0, 0.85)"
                    : "#909090",
                  fontStyle: this.props.Nominal.Active ? "normal" : "italic",
                }}
              >
                {record.Address
                  ? record.Address
                  : `${record.Latitude}, ${record.Longitude}`}
              </span>
            </>
          ),
      },
      // {
      //   dataIndex: "LocationButton",
      //   key: "LocationButton",
      //   width: "10%",
      //   render: (text, record) =>
      //     this.state.locationHistory.length >= 1 &&
      //     record.Latitude !== null &&
      //     record.Longitude !== null ? (
      //       <>
      //         <LocationArrowIconButton
      //           onClick={this.getAddressFromPosition(record)}
      //           tooltip="Get the nearest address to this location"
      //           title="Get Address"
      //         />
      //       </>
      //     ) : null,
      // },
    ];

    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
          <Col flex="auto">
            <span className="mosovo-table-header">Location History</span>
          </Col>
          <Col>
            <Tooltip placement="topRight" title={this.state.numUpdates > 0 ? `Refresh (${this.state.numUpdates}) new locations` : "Refresh location detail"}>
              <button
                type="button"
                onClick={this.onRefreshClick}
                className="addExclusionZoneButton"
              >
                <FontAwesomeIcon
                  icon={faSyncAlt}
                  color={this.state.numUpdates > 0 ? "#EF4E4E" : "#243B53"}
                  style={{ display: "inline" }}
                />
              </button>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip placement="topRight" title="Request Location">
              <button
                type="button"
                onClick={() =>
                  this.props.dispatch(
                    actions.contactNominal(
                      { Id: this.props.Nominal.Id, IsLocationRequest: true },
                      this.handleLocationRequestSuccessCallback
                    )
                  )}
                className="addExclusionZoneButton"
              >
                <FontAwesomeIcon
                  icon={faLocationArrow}
                  style={{ display: "inline" }}
                />
              </button>
            </Tooltip>
          </Col>
          <Col>
            <Tooltip placement="topRight" title="View in map">
              <button
                type="button"
                onClick={() =>
                  this.props.dispatch(push({pathname: `/map/${this.props.Nominal.Id}`, state: { from: 'Nominal Details'}}))}
                className="addExclusionZoneButton"
              >
                <FontAwesomeIcon
                  icon={faMap}
                  style={{ display: "inline" }}
                />
              </button>
            </Tooltip>
          </Col>
        </Row>
        {this.state.IsLocationRequestSuccess && (
          <Row>
            <Col sm={24} md={24}>
              {locationRequestSuccess}
            </Col>
          </Row>
        )}
        <Divider />
        <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
          <Col xs={24} sm={24} md={6} lg={6} />
          <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
            <DateFilterDropdown onChange={this.onDateFilterChange} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} className="gutter-row">
            <RangePicker
              className="p5-form-datepicker-input"
              format="DD/MM/YYYY hh:mm:ss A"
              separator=" to "
              disabledDate={this.disabledDate}
              onChange={(val) => {
                this.onSearchDate(val);
              }}
              allowClear
              showTime={{ format: 'hh:mm:ss A', use12Hours:true }}
            />
          </Col>
        </Row>
        <br />
        <Row>
          <Col>
            <span>{`Locations Selected: ${selectedRowKeys.length}`}</span>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              loading={this.props.loading}
              dataSource={this.state.locationHistory}
              columns={gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

LocationHistoryGrid.propTypes = {
  dispatch: PropTypes.func,
  onLocationSearch: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  locationHistory: PropTypes.array,
  loading: PropTypes.bool,
  Nominal: PropTypes.shape(),
};

LocationHistoryGrid.defaultProps = {
  dispatch: () => {},
  onLocationSearch: () => {},
  locationHistory: [],
  loading: false,
  Nominal: {},
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;
  return {
    loading,
  };
}

const connectedLocationHistoryGrid = connect(mapStateToProps)(
  LocationHistoryGrid
);

export { connectedLocationHistoryGrid as default };

import Constants from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  showMessageModal: false,
  showBulkAlertModal: false,
  showExportModal: false,
  showSelfieReviewModal: false,
  fileId: null,
};

export default function ModalReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.OPEN_BULK_MESSAGE_MODAL:
      return {
        ...state,
        showMessageModal: true,
      };
    case Constants.CLOSE_BULK_MESSAGE_MODAL:
      return {
        ...state,
        showMessageModal: false,
      };
    case Constants.MESSAGE_BULK_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case Constants.MESSAGE_BULK_SUCCESS:
      return {
        ...state,
        hasError: false,
        loading: false,
      };
    case Constants.MESSAGE_BULK_FAILURE:
      return {
        ...state,
        hasError: true,
        loading: false,
      };
    case Constants.OPEN_BULK_ALERT_MODAL:
      return {
        ...state,
        showBulkAlertModal: true,
      };
    case Constants.CLOSE_BULK_ALERT_MODAL:
      return {
        ...state,
        showBulkAlertModal: false,
      };
    case Constants.ALERT_BULK_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case Constants.ALERT_BULK_SUCCESS:
      return {
        ...state,
        hasError: false,
        loading: false,
      };
    case Constants.ALERT_BULK_FAILURE:
      return {
        ...state,
        hasError: true,
        loading: false,
      };
    case Constants.OPEN_EXPORT_MODAL:
      return {
        ...state,
        showExportModal: true,
      };
    case Constants.CLOSE_EXPORT_MODAL:
      return {
        ...state,
        showExportModal: false,
      };
    case Constants.EXPORT_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case Constants.EXPORT_SUCCESS:
      return {
        ...state,
        hasError: false,
        loading: false,
      };
    case Constants.EXPORT_FAILURE:
      return {
        ...state,
        hasError: true,
        loading: false,
      };
    case Constants.OPEN_SELFIE_REVIEW_MODAL:
      return {
        ...state,
        showSelfieReviewModal: true,
        fileId: action.fileId
      };
    case Constants.CLOSE_SELFIE_REVIEW_MODAL:
      return {
        ...state,
        showSelfieReviewModal: false,
      };
    default:
      return state;
  }
}

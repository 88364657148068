import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout, PageHeader } from '../../common/components';
import { UserIndexPage } from './pages';

const UsersHeader = (
  <PageHeader title="Users" />
)

const Users = ({ match }) => (
  <Layout PageHeader={UsersHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={UserIndexPage} />
    </Switch>
  </Layout>
);

Users.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Users.defaultProps = {
  match: {
    url: '',
  },
};

export default withRouter(Users);
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";
import moment from "moment";
import { Tooltip, DatePicker, Row, Col } from "antd";
import { faSyncAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import actions from "../../Nominals/actions";
import "./MapViewPage.css";
import { MapViewTabs, MyNominalList } from "../components";
import { IconButtons } from "../../../common/components";
import navigationActions from "../../../common/components/navigation/action";
import { NominalService } from "../../../services";

const { SearchBox } = IconButtons;
const { RangePicker } = DatePicker;

class MapViewPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myNominalList: [],
      myNominalSearch: {},
      showAll: false,
      totalNominals: 0,
      pendingData: [],
      nominalsListRef: null,
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("MapView"));
    this.getNominalData();
    setTimeout(
      () => this.getNominalDataAsync(this.props.currentUser.Id),
      60000
    );
  }

  getNominalDataAsync(userId) {
    const searchQuery = `$filter=OffenderManagerId eq ${userId}`;
    NominalService.getNominalOdata(searchQuery).then((response) => {
      this.setPendingData(response.data.value);
    });
    setTimeout(() => this.getNominalDataAsync(userId), 60000);
  }

  setPendingData = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      pendingData: data,
    }));
  };

  onRefreshClick = () => {
    const pending = this.state.pendingData;
    for (let i = 0; i < pending.length; i += 1) {
      // eslint-disable-next-line no-param-reassign
      pending[i].key = i;
    }

    if (this.state.numUpdates) {
      this.setState({
        myNominalList: pending,
        pendingData: [],
      });
    } else {
      this.getNominalData();
    }
  };

  disabledDate = (current) => {
    const now = moment();
    const timeDiff = current.diff(now, "months");
    const tooLate = timeDiff > 6 || timeDiff <= -6;
    const tooEarly = now.isBefore(current);
    return tooEarly || tooLate;
  };

  buildSearchQuery = (query) => {
    // let searchQuery = query.baseSearchQuery ? query.baseSearchQuery : `$filter=OffenderManagerId eq ${this.props.currentUser.Id}`;
    let searchQuery = query.baseSearchQuery
      ? query.baseSearchQuery
      : `$filter=OffenderManagerId eq ${this.props.currentUser.Id}`;
    let queryTerms = {};
    if (this.state.myNominalSearch) {
      queryTerms = this.state.myNominalSearch;
    }
    if (query.name) queryTerms.Name = query.name;
    if (query.startDate && query.endDate && query.dateField) {
      queryTerms.StartDate = query.startDate;
      queryTerms.EndDate = query.endDate;
      queryTerms.DateField = query.dateField;
    }
    if (queryTerms.Name) {
      searchQuery = `${searchQuery} and contains(FullName,'${queryTerms.Name}')`;
    }
    if (queryTerms.StartDate && queryTerms.EndDate && queryTerms.DateField) {
      searchQuery = `${searchQuery} and ${queryTerms.DateField} ge ${queryTerms.StartDate} and ${queryTerms.DateField} le ${queryTerms.EndDate}`;
    }
    if (!query.baseSearchQuery) {
      this.setState({ myNominalSearch: queryTerms });
    }
    return searchQuery;
  };

  getNominalData = (value = "") => {
    const searchQuery = this.buildSearchQuery({ name: value });
    this.props.dispatch(
      actions.getMyNominalData(searchQuery, this.setNominalData)
    );
  };

  onSearchDate = (value) => {
    if (value && value.length === 2) {
      let searchQuery = "";
      if (this.isDisplayingLocationHistory()) {
        const startDate = value[0]
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
          .replace("+", "%2B");
        const endDate = value[1]
          .utc()
          .format("YYYY-MM-DDTHH:mm:ss.SSSZ")
          .replace("+", "%2B");
        const nominalId = window.location.pathname.substring(
          window.location.pathname.lastIndexOf("/") + 1
        );
        searchQuery = this.buildSearchQuery({
          startDate,
          endDate,
          dateField: "CreatedDate",
          baseSearchQuery: `$filter=NominalId eq ${nominalId}`,
        });
        this.props.dispatch(
          actions.getMyNominalLocationData(
            searchQuery,
            this.state.nominalsListRef
          )
        );
      } else {
        const startDate = value[0]
          .utc()
          .format("YYYY-MM-DD")
          .replace("+", "%2B");
        const endDate = value[1].utc().format("YYYY-MM-DD").replace("+", "%2B");
        searchQuery = this.buildSearchQuery({
          startDate,
          endDate,
          dateField: "DateOfBirth",
        });
        this.props.dispatch(
          actions.getMyNominalData(searchQuery, this.setNominalData)
        );
      }
    } else {
      const startDate = moment("1/1/1900")
        .format("YYYY-MM-DD")
        .replace("+", "%2B");
      const endDate = moment("1/1/3000")
        .format("YYYY-MM-DD")
        .replace("+", "%2B");
      let searchQuery = "";
      if (this.isDisplayingLocationHistory()) {
        const nominalId = window.location.pathname.substring(
          window.location.pathname.lastIndexOf("/") + 1
        );
        searchQuery = this.buildSearchQuery({
          startDate,
          endDate,
          dateField: "CreatedDate",
          baseSearchQuery: `$filter=NominalId eq ${nominalId}`,
        });
        this.props.dispatch(
          actions.getMyNominalLocationData(
            searchQuery,
            this.state.nominalsListRef
          )
        );
      } else {
        searchQuery = this.buildSearchQuery({
          startDate,
          endDate,
          dateField: "DateOfBirth",
        });
        this.props.dispatch(
          actions.getMyNominalData(searchQuery, this.setNominalData)
        );
      }
    }
  };

  setNominalData = (data) => {
    const nominals = data.filter((n) => !n.Suspended);
    // eslint-disable-next-line react/no-access-state-in-setstate
    const totalNominals = Math.max(this.state.totalNominals, nominals.length);

    // set item keys
    for (let i = 0; i < nominals.length; i += 1) {
      // eslint-disable-next-line no-param-reassign
      nominals[i].key = i;
    }

    this.setState({
      myNominalList: nominals,
      totalNominals,
    });
  };

  setListRef = (nominalsListRef) => {
    if (!this.state.nominalsListRef) {
      this.setState((prevState) => ({
        ...prevState,
        nominalsListRef,
      }));
    }
  };

  RefreshGrid = () => {
    this.getNominalData(this.state.myNominalSearch);
  };

  changeTabs = (tab) => {
    if (tab === "mynominals") {
      this.getNominalData(this.state.myNominalSearch);
    }
  };

  updateShowAll = () => {
    this.setState({
      showAll: document.getElementById("viewAllNominals").checked,
    });
  };

  isDisplayingLocationHistory = () => {
    return window.location.toString().match(/\/\d+/g) !== null;
  };

  GoBack = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(goBack());
  };

  render() {
    let backText = "Back to Map View";
    if (
      this.props.location &&
      this.props.location.state &&
      this.props.location.state.from
    ) {
      backText = `Back to ${this.props.location.state.from}`;
    }
    console.log(this.props);
    return (
      <div>
        <MapViewTabs />

        {this.isDisplayingLocationHistory() && (
          <div className="backButton">
            <a href="true" onClick={this.GoBack}>
              {backText}
            </a>
          </div>
        )}

        <div className="mapViewContainer">
          {/* <div className="header"> */}
          <Row gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}>
            <Col xs={2} sm={2} md={2} lg={2}>
              <span className="mosovo-table-header">My Nominals</span>
            </Col>
            <Col xs={0} sm={0} md={1} lg={1} />
            <Col xs={0} md={1} lg={1}>
              <Tooltip placement="topRight" title="Refresh map data">
                <button
                  type="button"
                  onClick={this.onRefreshClick}
                  className="addExclusionZoneButton"
                >
                  <FontAwesomeIcon
                    icon={faSyncAlt}
                    color="#EF4E4E"
                    style={{ display: "inline" }}
                  />
                </button>
              </Tooltip>
            </Col>
            <Col xs={16} sm={16} md={16} lg={16}>
              {this.state.totalNominals >= 1 &&
                !this.isDisplayingLocationHistory() && (
                  <>
                    <Row xs={24} sm={24} md={24} lg={24}>
                      <Col xs={8} sm={8} md={7} lg={7}>
                        <SearchBox
                          placeholder="Search Nominal"
                          allowClear
                          onSearch={this.getNominalData}
                          minText={3}
                          loading={false}
                        />
                      </Col>
                      <Col md={1} lg={1} />
                      <Col xs={10} sm={10} md={16} lg={16}>
                        <RangePicker
                          className="p5-form-datepicker-input"
                          format="DD/MM/YYYY"
                          separator=" to "
                          onChange={(val) => {
                            this.onSearchDate(val);
                          }}
                          placeholder={[
                            "Date of Birth Start",
                            "Date of Birth End",
                          ]}
                          allowClear
                        />
                      </Col>
                    </Row>
                  </>
                )}
              {(this.state.totalNominals === 0 ||
                this.isDisplayingLocationHistory()) && (
                <>
                  <Row xs={24} sm={24} md={24} lg={24}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                      <RangePicker
                        className="p5-form-datepicker-input"
                        format="DD/MM/YYYY hh:mm:ss A"
                        separator=" to "
                        disabledDate={this.disabledDate}
                        onChange={(val) => {
                          this.onSearchDate(val);
                        }}
                        allowClear
                        placeholder={[
                          "Location Date Start",
                          "Location Date End",
                        ]}
                        showTime={{ format: "hh:mm:ss A", use12Hours: true }}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Col>
            <Col xs={4} sm={4} md={4} lg={4}>
              <span className="viewAllNominals">
                <input
                  id="viewAllNominals"
                  type="checkbox"
                  onInput={this.updateShowAll}
                  name="viewAllNominals"
                />
                <span htmlFor="viewAllNominals">
                  {this.isDisplayingLocationHistory()
                    ? "View all locations"
                    : "View all my nominals"}
                </span>
              </span>
            </Col>
          </Row>
          <Row xs={24} sm={24} md={24} lg={24}>
            <Col xs={24} sm={24} md={24} lg={24}>
              <MyNominalList
                setListRef={this.setListRef}
                myNominalList={this.state.myNominalList}
                data={this.state.myNominalList}
                showAll={this.state.showAll}
                updateShowAll={this.updateShowAll}
                dispatch={this.props.dispatch}
              />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

MapViewPage.propTypes = {
  dispatch: PropTypes.func,
  currentUser: PropTypes.shape({
    Id: PropTypes.number,
    ForceId: PropTypes.number,
  }),
  Id: PropTypes.number,
  location: PropTypes.shape(),
};

MapViewPage.defaultProps = {
  dispatch: () => {},
  currentUser: {
    Id: null,
    ForceId: null,
  },
  Id: 0,
  location: {},
};

function mapStateToProps(state) {
  const { currentUser } = state.Account;
  const { loading } = state.Nominal;

  return {
    currentUser,
    loading,
  };
}

const connectedMapViewPage = connect(mapStateToProps)(MapViewPage);

export { connectedMapViewPage as default };

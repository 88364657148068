import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Row, Col, Tooltip, Input, Space, Button,  } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from 'react-highlight-words';
import { IconButtons } from "../../../common/components";
import { hasRights, rights } from "../../../common/utils/role-right-helper";
import { BulkTransferOMModal } from "../modals";

import actions from "../actions";

const { SearchBox, EditUserButton, AddButton, EditIcon, Icons } = IconButtons;

const { UserIcon, AdminIcon } = Icons;

class UserGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      searchedColumn: '',
      selectedRowKeys: [],
    };
  }

  getForceFilters = () => {
    const forceFilter = []; 
    this.props.userList.map((user) => {
      const force = {text: user.ForceName, value: user.ForceName || " "};
      
      if(!forceFilter.filter(e => e.value === (user.ForceName || " ")).length > 0)
        forceFilter.push(force);

        return forceFilter;
    });
    return forceFilter.sort();
  }

  getAreaFilters = () => {
    const areaFilter = []; 
    this.props.userList.map((user) => {
      const area = {text: user.AreaName, value: user.AreaName || " "};
      
      if(!areaFilter.filter(e => e.value === (user.AreaName || " ")).length > 0)
      areaFilter.push(area);

        return areaFilter.sort();
    });
    return areaFilter;
  }

  getTeamFilters = () => {
    const teamFilter = []; 
    this.props.userList.map((user) => {
      const team = {text: user.TeamName, value: user.TeamName || " "};
      
      if(!teamFilter.filter(e => e.value === (user.TeamName || " ")).length > 0)
      teamFilter.push(team);

        return teamFilter.sort();
    });
    return teamFilter;
  }

  getRoleFilters = () => {
    const roleFilter = []; 
    this.props.userList.map((user) => {
      const role = {text: user.RoleType, value: user.RoleType || " "};
      
      if(!roleFilter.filter(e => e.value === (user.RoleType || " ")).length > 0)
      roleFilter.push(role);

        return roleFilter;
    });
    return roleFilter;
  }

  editUser = (Id) => {
    const { dispatch } = this.props;
    dispatch(actions.getUserById(Id, this.openUserModal));
    // console.log("Edit", Id);
  };

  openUserModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openEditUserModal());
  }

  OpenAddUserModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openAddUserModal());
  };

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: "block" }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              this.setState({
                searchText: selectedKeys[0],
                searchedColumn: dataIndex,
              });
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select(), 100);
      }
    },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: "#ffc069", padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: "" });
  };

  openTransferOMModal = () => {
    this.props.dispatch(actions.openTransferOMModal());
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const gridColumns = [
      {
        title: "",
        dataIndex: "Id",
        key: "Id",
        width: "3%",
        render: (text, record) =>
          record.RoleId === 1 ||
          record.RoleId === 2 ||
          record.RoleId === 3 ||
          record.RoleId === 4 ? (
            <>
              <span>
                <AdminIcon />
              </span>
            </>
          ) : (
            <>
              <span>
                <UserIcon />
              </span>
            </>
          ),
      },
      {
        title: "Name",
        dataIndex: "FullName",
        key: "FullName",
        sorter: (a, b) => {          
          if(a.FullName.toUpperCase() > b.FullName.toUpperCase())
            return -1;

          if(a.FullName.toUpperCase() < b.FullName.toUpperCase())
            return 1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
        ...this.getColumnSearchProps('FullName'),
      },
      {
        title: "Force",
        dataIndex: "ForceName",
        key: "ForceName",
        sorter: (a, b) => {    
          const aForceName =  a.ForceName || " "; 
          const bForceName =  b.ForceName || " ";

          if((aForceName.toUpperCase() || "") > (bForceName.toUpperCase() || ""))
            return -1;

          if((aForceName.toUpperCase() || "") < (bForceName.toUpperCase() || ""))
            return 1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
        filters: this.getForceFilters(),
        onFilter: (value, record) => (record.ForceName || " ") === value,
      },
      {
        title: "Area",
        dataIndex: "AreaName",
        key: "AreaName",
        sorter: (a, b) => {    
          const aAreaName =  a.AreaName || " "; 
          const bAreaName =  b.AreaName || " ";

          if((aAreaName.toUpperCase() || "") > (bAreaName.toUpperCase() || ""))
            return -1;

          if((aAreaName.toUpperCase() || "") < (bAreaName.toUpperCase() || ""))
            return 1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
        filters: this.getAreaFilters(),
        onFilter: (value, record) => (record.AreaName || " ") === value,
      },
      {
        title: "Team",
        dataIndex: "TeamName",
        key: "TeamName",
        sorter: (a, b) => {    
          const aTeamName =  a.TeamName || " "; 
          const bTeamName =  b.TeamName || " ";

          if((aTeamName.toUpperCase() || "") > (bTeamName.toUpperCase() || ""))
            return -1;

          if((aTeamName.toUpperCase() || "") < (bTeamName.toUpperCase() || ""))
            return 1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
        filters: this.getTeamFilters(),
        onFilter: (value, record) => (record.TeamName || " ") === value,
      },
      {
        title: "User Role",
        dataIndex: "RoleType",
        key: "RoleType",
        sorter: (a, b) => {    
          const aRoleType =  a.RoleType || " "; 
          const bRoleType =  b.RoleType || " ";

          if((aRoleType.toUpperCase() || "") > (bRoleType.toUpperCase() || ""))
            return -1;

          if((aRoleType.toUpperCase() || "") < (bRoleType.toUpperCase() || ""))
            return 1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
        filters: this.getRoleFilters(),
        onFilter: (value, record) => (record.RoleType || " ") === value,
      },
      {
        title: "",
        dataIndex: "Id",
        key: "Id",
        render: (text, record) =>
          this.props.userList.length >= 1 ? (
            <>
              <span>
                <EditIcon
                  tooltip={`Edit ${record.FullName}`}
                  onClick={this.editUser}
                  value={record.Id}
                  pull="right"
                />
              </span>
            </>
          ) : null,
      },
    ];

    const { selectedRowKeys } = this.state;
    // eslint-disable-next-line no-unused-vars
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row>
          <Col xs={12} md={10} lg={10}>
            <span className="mosovo-table-header">Users</span>
          </Col>
          <Col xs={24} md={14} lg={14}>
            <Row justify="end" gutter={{ xs: 8, sm: 16, md: 10 }}>
              <Col xs={19} sm={20} md={18} lg={12} className="gutter-row">
                <SearchBox
                  placeholder="user search"
                  allowClear
                  onSearch={this.props.onSearch}
                  minText={3}
                  loading={this.props.loading}
                />
              </Col>
              {(hasRights([rights.CanManageForceAdmins])
                || hasRights([rights.CanManageAreaAdmins])
                || hasRights([rights.CanManageTeamAdmins]))
                && (
                  <>
                    <Col xs={2} sm={2} md={1} lg={1} style={{textAlign: "center"}}>
                      <EditUserButton 
                        onClick={this.openTransferOMModal}
                        isDisabled={this.state.selectedRowKeys.length === 0}
                        tooltipText="Change OM Assignment"
                      />                
                    </Col>
                  </>
                )} 
              <Col className="gutter-row">
                <Tooltip title="Add new user">
                  <span>
                    <AddButton id="AddArea" onClick={this.OpenAddUserModal} />
                  </span>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              loading={this.props.loading}
              dataSource={this.props.userList}
              columns={gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
        <BulkTransferOMModal
          selectedOMs={this.state.selectedRowKeys}
          refreshGrid={this.props.refreshGrid}
        />
      </div>
    );
  }
}

UserGrid.propTypes = {
  dispatch: PropTypes.func,
  onSearch: PropTypes.func,
  userList: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  refreshGrid: PropTypes.func,
};

UserGrid.defaultProps = {
  dispatch: () => {},
  onSearch: () => {},
  userList: [{}],
  loading: false,
  refreshGrid: () => {},
};

function mapStateToProps(state) {
  const { loading } = state.Users;
  return {
    loading,
  };
}

const connectedUserGrid = connect(mapStateToProps)(UserGrid);

export { connectedUserGrid as default };

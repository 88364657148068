import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Spin } from "antd";
import { CommunicationsGrid } from '../grids';

class Communications extends React.Component {
  render() {
    return (
      <>
        <Spin spinning={this.props.loading}>
          <CommunicationsGrid communications={this.props.communications} onSearch={this.props.onSearch} active={this.props.active} />
        </Spin>
      </>
    );
  }
}

Communications.propTypes = {
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
  communications: PropTypes.arrayOf(PropTypes.shape({})),
  active: PropTypes.bool,
};

Communications.defaultProps = {
  onSearch: () => {},
  loading: false,
  communications: [],
  active: true,
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;

  return { loading };
}

const connectedCommunications = connect(mapStateToProps)(Communications);

export { connectedCommunications as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Layout, Row, Col, Button, Dropdown, Menu, Tooltip } from "antd";
import { faMinusCircle, faTrashAlt, faFileExport } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WarningOutlined, DownOutlined } from "@ant-design/icons";
import { push } from "connected-react-router";
import moment from "moment";

import { IconButtons } from "../../../common/components";
import ModalActions from "../../../common/components/mosovo-modals/actions";
import actions from "../actions";
// import { TrashCanIconButton } from "../../../common/components/icon-buttons";
import { hasRights, rights } from "../../../common/utils/role-right-helper";

const { MessageIconButton } = IconButtons;
const ActionsConstants = {
  Export: 1,
  Suspend: 2,
  Remove: 3,
  AddLocation: 4,
};

class NominalDetailsPageHeader extends React.PureComponent {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     IsTestAddLocations: process.env.TEST_ADD_LOCATIONS,
  //   };
  // }

  componentDidMount() {
    this.props.dispatch(actions.getNominalDetail(this.props.Id));
  }

  ExportNominalData = () => {
    this.props.dispatch(
      ModalActions.openExportModal()
    );
  }

  RemoveNominal = () => {
    this.props.dispatch(
      actions.removeNominal(this.props.Id, () =>
        this.props.dispatch(push("/nominals"))
      )
    );
  };

  RemoveNominalModal = () => {
    const { confirm } = Modal;
    const removeNominal = this.RemoveNominal;
    const message = (
      <div>
        <p>
          Removing nominal will <b>permanently</b> delete all records of this
          nominal from the system. You will be <b>unable to undo</b> this
          action. You will be receiving 6 months worth of data in PDF and Excel format via your email for your records.
        </p>
        <p>
          <b>Are you sure you want to remove the following nominal?</b>
        </p>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Name:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {this.props.Nominal.FirstName} {this.props.Nominal.LastName}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Date of Birth:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {this.props.Nominal.DateOfBirth
              ? moment(this.props.Nominal.DateOfBirth).format("DD-MM-YYYY")
              : ""}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">PNC ID:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {this.props.Nominal.PNCId}
          </Col>
        </Row>
      </div>
    );

    confirm({
      title: "Remove Nominal",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      closable: true, 
      onOk() {
        removeNominal();
      },
      okText: "Yes, Remove",
    });
  };

  UnsuspendNominal = () => {
    this.props.dispatch(
      actions.unsuspendNominal(this.props.Id, () =>
        this.props.dispatch(push("/nominals"))
      )
    );
  };

  SuspendNominal = () => {
    this.props.dispatch(
      actions.suspendNominal(this.props.Id, () =>
        this.props.dispatch(push("/nominals"))
      )
    );
  };

  SuspendNominalModal = () => {
    const { confirm } = Modal;
    const suspendNominal = this.SuspendNominal;
    const message = (
      <div>
        <p>
          Suspending device will pause all alerts and will no longer track location or other nominal settings on this device.
        </p>
        <p>
          <b>Are you sure you want to suspend the following nominal?</b>
        </p>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Name:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {this.props.Nominal.FirstName} {this.props.Nominal.LastName}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Date of Birth:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {this.props.Nominal.DateOfBirth
              ? moment(this.props.Nominal.DateOfBirth).format("DD-MM-YYYY")
              : ""}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">PNC ID:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {this.props.Nominal.PNCId}
          </Col>
        </Row>
      </div>
    );

    confirm({
      title: "Suspend Nominal",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      closable: true,
      onOk() {
        suspendNominal();
      },
      okText: "Yes, Suspend",
    });
  };

  contactNominal = () => {
    this.props.dispatch(
      actions.getNominalBasicDetail(
        this.props.Nominal.Id,
        this.openContactNominal
      )
    );
  };

  openContactNominal = () => {
    this.props.dispatch(actions.openContactNominalModal());
  };

  addLocationModal = () => {
    this.props.dispatch(actions.openAddNominalLocationModal());
  };

  isRemoveDisabled = () => {
    if (this.props.Nominal.OffenderManagerId === this.props.currentUser.Id) return false;
    if (hasRights([rights.CanManageTeamUsers])) return false;
    return true;
  }

  handleActionsMenuClick = (e) => {
    switch (e.key) {
      case `${ActionsConstants.Export}`:
        this.ExportNominalData();
        break;
      case `${ActionsConstants.Suspend}`:
        this.props.Nominal.Active ? this.SuspendNominalModal() : this.UnsuspendNominal();
        break;
      case `${ActionsConstants.Remove}`:
        this.RemoveNominalModal();
        break;
      case `${ActionsConstants.AddLocation}`:
        this.addLocationModal();
        break;
      default:
        break;
    }
  };

  render() {
    const { Content } = Layout;
    const actionsMenu = (
      <Menu onClick={this.handleActionsMenuClick}>
        <Menu.Item key={ActionsConstants.Export}>
          <Tooltip placement="topRight" title="Export Nominal Data">
            <FontAwesomeIcon
              icon={faFileExport}
              className=""
              alt="export"
            />
            {" Export Nominal Data"}
          </Tooltip>
        </Menu.Item>
        <Menu.Item key={ActionsConstants.Suspend} disabled={this.isRemoveDisabled()}>
          <Tooltip placement="topRight" title={this.props.Nominal.Active ? "Temporarily suspend nominal" : "Unsuspend nominal"}>
            <FontAwesomeIcon
              icon={faMinusCircle}
              className=""
              alt="suspend"
            />
            {this.props.Nominal.Active ? " Suspend Nominal" : " Unsuspend Nominal"}
          </Tooltip>
        </Menu.Item>
        <Menu.Item key={ActionsConstants.Remove} disabled={this.isRemoveDisabled()}>
          <Tooltip placement="topRight" title="Permanently delete nominal">
            <FontAwesomeIcon
              icon={faTrashAlt}
              className=""
              alt="delete"
            />
            {" Remove Nominal"}
          </Tooltip>
        </Menu.Item>
        {/* {this.state.IsTestAddLocations && (
          <Menu.Item key={ActionsConstants.AddLocation}>
            <Tooltip placement="topRight" title="Add location for nominal">
              <FontAwesomeIcon
                icon={faLocationArrow}
                className=""
                alt="add-location"
              />
              {" Add Location"}
            </Tooltip>
          </Menu.Item>
        )} */}
      </Menu>
    );

    return (
      <div className="PageHeader">
        <Content>
          <Row>
            <Col xs={20} sm={12} md={19} lg={20}>
              <div className="pg-header-title">
                <span>
                  {this.props.Nominal.FirstName} {this.props.Nominal.LastName}
                </span>
              </div>
            </Col>
            <Col xs={0} sm={6} md={3} lg={2}>
              <div style={{ height: "100%", position: "relative" }}>
                <div
                  style={{
                    margin: "auto 15px",
                    position: "absolute",
                    top: "30%",
                    right: "15px",
                  }}
                >
                  <Dropdown overlay={actionsMenu}>
                    <Button
                      style={{
                        backgroundColor: "white",
                        border: "0px",
                        color: "#243B53",
                        float: "right",
                      }}
                      className="dropdown-btn"
                    >
                      <span className="hoverMenu">Nominal Actions</span> <DownOutlined />
                    </Button>
                  </Dropdown>
                </div>
              </div>
            </Col>
            <Col xs={0} sm={6} md={3} lg={2}>
              <div style={{ height: "100%", position: "relative" }}>
                <div
                  style={{
                    margin: "auto 15px",
                    position: "absolute",
                    top: "30%",
                    right: "15px",
                  }}
                >
                  <MessageIconButton
                    onClick={this.contactNominal}
                    tooltip="Contact nominal"
                    title="Contact"
                  />
                </div>
              </div>
            </Col>
            {/* <Col xs={4} sm={0} md={0} lg={0}>
              <div style={{ height: "100%", position: "relative" }}>
                <div
                  style={{
                    margin: "auto 15px",
                    position: "absolute",
                    top: "30%",
                  }}
                >
                  <TrashCanIconButton
                    isDisabled={
                      this.props.Nominal.OffenderManagerId !==
                        this.props.currentUser.Id ||
                      !hasRights([rights.CanManageTeamUsers]) ||
                      this.props.currentUser.TeamId !==
                        this.props.Nominal.TeamId
                    }
                    onClick={this.RemoveNominalModal}
                    tooltip="Permanently delete nominal"
                  />
                </div>
              </div>
            </Col> */}
          </Row>
        </Content>
      </div>
    );
  }
}

NominalDetailsPageHeader.propTypes = {
  dispatch: PropTypes.func,
  Nominal: PropTypes.shape(),
  Id: PropTypes.string,
  currentUser: PropTypes.shape({
    Id: PropTypes.number,
    TeamId: PropTypes.number,
  }),
};

NominalDetailsPageHeader.defaultProps = {
  dispatch: () => {},
  Nominal: {},
  Id: 0,
  currentUser: {
    Id: null,
    TeamId: null,
  },
};

const mapStateToProps = (state) => {
  const { Nominal } = state.Nominal;
  const { currentUser } = state.Account;
  return { Nominal, currentUser };
};

const connectedNominalDetailsPageHeader = connect(mapStateToProps)(
  NominalDetailsPageHeader
);

export { connectedNominalDetailsPageHeader as default };

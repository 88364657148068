import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import { Row, Col, Spin, Divider, Button } from "antd";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckinModal } from "../modals";
import { CheckinHistoryGrid } from "../grids";

class Checkin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modalVisible: false,
    }
  }

  onEditClick = () => {
    this.setState({
      modalVisible: true,
    });
  }

  closeModal = () => {
    this.setState({
      modalVisible: false,
    });
  }

  editSettingsButton = () => (
    <Button
      type="link"
      className="SettingsEditButton"
      onClick={this.onEditClick}
    >
      <FontAwesomeIcon
        icon={faEdit}
        id="EditSettings"
        className=""
        alt="basic"
      />
    </Button>
  );

  render() {
    return (
      <>
        <Spin spinning={this.props.loading}>
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col sm={24} md={24} lg={18} className="gutter-row">
              <CheckinHistoryGrid />
            </Col>
            <Col sm={24} md={24} lg={6} className="gutter-row">
              <div className="nominal-detail-container">
                <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
                  <Col span={20} className="gutter-row">
                    <span className="mosovo-table-header">Check-in Settings</span>
                  </Col>
                  <Col span={4}>
                    <Row justify="end">
                      <Col>
                        <span>{this.editSettingsButton()}</span>
                      </Col>
                    </Row>
                  </Col>
                  <Divider />
                </Row>
                <Row className="SettingsInfoBody">
                  { !this.props.Settings && (
                    <span className="FullWidth centeredText">This nominal does not have any check in settings at this time.</span>
                  )}
                  { this.props.Settings && (
                    <Col>
                      <Row>
                        <span className="SettingsInfoTitle">Check-in Period: </span>
                      </Row>
                      <Row>
                        <span className="SettingsInfoText">
                          {`${moment(`${moment(Date()).format('MM/DD/yyyy')} ${this.props.Settings.StartTime}Z`).local().format('HH:mm')}`}
                          {` - ${moment(`${moment(Date()).format('MM/DD/yyyy')} ${this.props.Settings.EndTime}Z`).local().format('HH:mm')}`}
                        </span>
                      </Row>
                      <br />
                      <Row>
                        <span className="SettingsInfoTitle">Frequency: </span>
                      </Row>
                      <Row>
                        <span className="SettingsInfoText">
                          {this.props.Settings.Frequency}
                        </span>
                      </Row>
                      <br />
                      <Row>
                        <span className="SettingsInfoTitle">Type of check-ins: </span>
                      </Row>
                      { this.props.Settings.CheckinTypeIds.indexOf(1) > -1 && (
                        <Row>
                          <span className="SettingsInfoText">
                            Biometric
                          </span>
                        </Row>
                      )}
                      { this.props.Settings.CheckinTypeIds.indexOf(2) > -1 && (
                        <Row>
                          <span className="SettingsInfoText">
                            Selfie
                          </span>
                        </Row>
                      )}
                      { this.props.Settings.CheckinTypeIds.indexOf(3) > -1 && (
                        <Row>
                          <span className="SettingsInfoText">
                            Question
                          </span>
                        </Row>
                      )}
                      <br />
                      { this.props.Settings.CheckinTypeIds.indexOf(3) > -1 && (
                        <>
                          <Row>
                            <span className="SettingsInfoTitle">Check-in Questions: </span>
                          </Row>
                          { this.props.Settings.Questions.map((question, index) => (
                            <Row key={`question-${question.Id}`}>
                              <span className="SettingsInfoText">
                                {`${index + 1}. ${question.QuestionText}`}
                              </span>
                            </Row>
                          ))}
                        </>
                      )}
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Spin>
        <CheckinModal 
          showModal={this.state.modalVisible}
          CloseModal={this.closeModal}
          Settings={this.props.Settings}
        />
      </>
    );
  }
}

Checkin.propTypes = {
  loading: PropTypes.bool,
  Settings: PropTypes.shape(),
  // NominalId: PropTypes.number,
};

Checkin.defaultProps = {
  loading: false,
  Settings: null,
  // NominalId: 0,
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;
  return { loading };
}

const connectedCheckin = connect(mapStateToProps)(Checkin);

export { connectedCheckin as default };

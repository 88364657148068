import constants from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  BulkCreateModal: {
    show: false,
  },
  ForceModal: {
    show: false,
  },
  forceBulk: {},
  force: {},
  AreaModal: {
    showAddModal: false,
    defaultForce: null,
    showEditModal: false,
  },
  area: {},
  TeamModal: {
    showAddModal: false,
    defaultForce: null,
    showEditModal: false,
  },
  team: {},
  organisation: {},
};

export default function OrganisationReducer(state = initialState, action) {
  switch (action.type) {
    case constants.FORCE_GRID_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.FORCE_GRID_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.FORCE_GRID_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.AREA_GRID_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.AREA_GRID_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.AREA_GRID_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.TEAM_GRID_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.TEAM_GRID_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.TEAM_GRID_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.OPEN_BULK_CREATE_MODAL:
      return {
        ...state,
        forceBulk: {},
        BulkCreateModal: {
          show: true,
        },
      };
    case constants.CLOSE_BULK_CREATE_MODAL:
      return {
        ...state,
        forceBulk: {},
        BulkCreateModal: {
          show: false,
        },
      };
    case constants.BULK_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.BULK_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.BULK_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.OPEN_FORCE_MODAL:
      return {
        ...state,
        ForceModal: {
          show: true,
        },
      };
    case constants.CLOSE_FORCE_MODAL:
      return {
        ...state,
        ForceModal: {
          show: false,
        },
        force: {},
      };
    case constants.GET_FORCE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        force: {},
      };
    case constants.GET_FORCE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        force: action.force,
      };
    case constants.GET_FORCE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.UPDATE_FORCE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.UPDATE_FORCE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        force: action.force,
      };
    case constants.UPDATE_FORCE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.OPEN_AREA_MODAL:
      return {
        ...state,
        AreaModal: {
          showAddModal: false,
          showEditModal: true,
          defaultForce: null,
        },
      };
    case constants.CLOSE_AREA_MODAL:
      return {
        ...state,
        AreaModal: {
          showAddModal: false,
          showEditModal: false,
          defaultForce: null,
        },
        area: {},
      };
    case constants.GET_AREA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        area: {},
      };
    case constants.GET_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        area: action.area,
      };
    case constants.GET_AREA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.UPDATE_AREA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.UPDATE_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        area: action.area,
      };
    case constants.UPDATE_AREA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.CREATE_AREA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.CREATE_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.CREATE_AREA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.OPEN_ADD_AREA_MODAL:
      return {
        ...state,
        AreaModal: {
          showAddModal: true,
          showEditModal: false,
          defaultForce: action.forceId,
        },
        area: {},
      };
    case constants.OPEN_TEAM_MODAL:
      return {
        ...state,
        TeamModal: {
          showAddModal: false,
          showEditModal: true,
          defaultForce: null,
        },
      };
    case constants.CLOSE_TEAM_MODAL:
      return {
        ...state,
        TeamModal: {
          showAddModal: false,
          showEditModal: false,
          defaultForce: null,
        },
      };
    case constants.GET_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        team: {},
      };
    case constants.GET_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        team: action.team,
      };
    case constants.GET_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.UPDATE_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.UPDATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        team: action.team,
      };
    case constants.UPDATE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.CREATE_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.CREATE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.CREATE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.OPEN_ADD_TEAM_MODAL:
      return {
        ...state,
        TeamModal: {
          showAddModal: true,
          showEditModal: false,
          defaultForce: action.forceId,
        },
        team: {},
      };
    case constants.GET_ORGANISATION_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        organisation: {},
      };
    case constants.GET_ORGANISATION_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        organisation: action.organisation,
      };
    case constants.GET_ORGANISATION_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.DELETE_FORCE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.DELETE_FORCE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.DELETE_FORCE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.DELETE_AREA_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.DELETE_AREA_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.DELETE_AREA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.DELETE_TEAM_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.DELETE_TEAM_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.DELETE_TEAM_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}

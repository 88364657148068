import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import ResetPage from './reset-password';
import ForgotPage from './forgot-password';
import ConfirmEmailPage from './pages/ConfirmEmailPage';

const Account = ({ match }) => (
  <Switch>
    <Route exact path={`${match.url}/reset-password`} component={ResetPage} />
    <Route exact path={`${match.url}/forgot-password`} component={ForgotPage} />
    <Route exact path={`${match.url}/confirmemail`} component={ConfirmEmailPage} />
  </Switch>
);

Account.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Account.defaultProps = {
  match: {
    url: '',
  },
};

export default withRouter(Account);

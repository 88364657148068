/* global google */
// eslint-disable-next-line max-classes-per-file
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { renderToStaticMarkup } from 'react-dom/server';
import { Marker, InfoWindow } from "@react-google-maps/api";

class SVGMarkerIcon extends React.Component {
	render() {
		return (
  <svg width="70" height="150" viewBox="0 0 70 150" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="31" y="70" width="8" height="70" fill="black" />
    {/* eslint-disable-next-line react/prop-types */}
    <circle cx="35" cy="35" r="34.5" fill={this.props.color} stroke="black" />
  </svg>
		)
	}
}

class MarkerWithLabel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

	getMarkerIcon = () => {
		return encodeURIComponent(renderToStaticMarkup(<SVGMarkerIcon color={this.props.color} />));
	}

  toggleOpen = () => {
    this.setState(prevState => ({isOpen: !prevState.isOpen}));
  }

  render() {
    return (
      <div>
        <Marker
          position={this.props.position}
          icon={this.props.icon ?? {
            url: `data:image/svg+xml,${this.getMarkerIcon()}`,
            anchor: new google.maps.Point(20, 40),
            scaledSize: new google.maps.Size(this.props.size, this.props.size),
            labelOrigin: new google.maps.Point(20, 11)
          }}
          label={{
						text: this.props.label,
						color: "white",
					}}
          onClick={() => this.toggleOpen()}
        >
          {this.props.showPinHover && this.state.isOpen && (
            <InfoWindow onCloseClick={() => this.toggleOpen()}>
              <>
                {this.props.infoWindowJSX}
              </>
            </InfoWindow>
          )}
        </Marker>
      </div>
    );
  }
}

MarkerWithLabel.propTypes = {
  position: PropTypes.shape({}),
	color: PropTypes.string,
	label: PropTypes.string,
  // onClick: PropTypes.func,
  infoWindowJSX: PropTypes.element,
  icon: PropTypes.shape({}),
  showPinHover: PropTypes.bool,
  size: PropTypes.number,
};

MarkerWithLabel.defaultProps = {
  position: {
    lat: 0,
    lng: 0
  },
	color: "red",
	label: " ",
  // onClick: () => {},
  infoWindowJSX: React.createElement('div'),
  icon: null,
  showPinHover: false,
  size: 40,
};

function mapStateToProps(state) {
  return { state };
}

const connectedMarkerWithLabel = connect(mapStateToProps)(MarkerWithLabel);

export { connectedMarkerWithLabel as default };

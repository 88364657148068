export default ({
    HUB_REQUEST: 'HUB_REQUEST',
    HUB_SUCCESS: 'HUB_SUCCESS',
    HUB_FAILURE: 'HUB_FAILURE',
    HUB_CREATE_REQUEST: 'HUB_CREATE_REQUEST',
    HUB_CREATE_SUCCESS: 'HUB_CREATE_SUCCESS',
    HUB_CREATE_FAILURE: 'HUB_CREATE_FAILURE',
    HUB_GET_REQUEST: 'HUB_GET_REQUEST',
    HUB_GET_SUCCESS: 'HUB_GET_SUCCESS',
    HUB_GET_FAILURE: 'HUB_GET_FAILURE',
    HUB_UPDATE_REQUEST: 'HUB_UPDATE_REQUEST',
    HUB_UPDATE_SUCCESS: 'HUB_UPDATE_SUCCESS',
    HUB_UPDATE_FAILURE: 'HUB_UPDATE_FAILURE',
    OPEN_ADD_ALLOCATION_MODAL: 'OPEN_ADD_ALLOCATION_MODAL',
    OPEN_EDIT_ALLOCATION_MODAL: 'OPEN_EDIT_ALLOCATION_MODAL',
    CLOSE_ALLOCATION_MODAL: 'CLOSE_ALLOCATION_MODAL',
})  
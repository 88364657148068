import React from "react";
import {
  faUsersCog,
  faShieldAlt,
  faTachometer,
  faUserFriends,
  faMapMarked,
  faUsers,
  faUserShield,
  faSearch,
} from "@fortawesome/pro-regular-svg-icons";
import { faHubspot } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const DashboardIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faTachometer}
      id="Dashboard"
      className="side-nav-icon"
      alt="DashboardIcon"
    />
  </span>
);

const OrganisationIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faShieldAlt}
      id="Organisation"
      alt="Organisation"
      className="side-nav-icon"
    />
  </span>
);

const UserIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faUsersCog}
      id="Users"
      alt="Users"
      className="side-nav-icon"
    />
  </span>
);

const MapViewIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faMapMarked}
      id="MapView"
      alt="MapView"
      className="side-nav-icon"
    />
  </span>
);

const MyNominalsIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faUserFriends}
      id="MyNominals"
      alt="MyNominals"
      className="side-nav-icon"
    />
  </span>
);

const TeamNominalsIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faUsers}
      id="TeamNominals"
      className="side-nav-icon"
      alt="TeamNominals"
    />
  </span>
);

const AdminNominalsIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faUserShield}
      id="AdminNominals"
      className="side-nav-icon"
      alt="AdminNominals"
    />
  </span>
);

const HubIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faHubspot}
      id="Users"
      alt="Users"
      className="side-nav-icon"
    />
  </span>
);

const InvestigateIcon = (
  <span className="anticon" role="img">
    <FontAwesomeIcon
      icon={faSearch}
      id="Investigate"
      className="side-nav-icon"
      alt="Investigate"
    />
  </span>
);

export default {
  DashboardIcon,
  OrganisationIcon,
  UserIcon,
  HubIcon,
  MapViewIcon,
  MyNominalsIcon,
  TeamNominalsIcon,
  AdminNominalsIcon,
  InvestigateIcon
};

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const CloseBtn = (props) => {
  return(
    <Button
      block
      type="reset"
      id="closeBtn"
      className="closeBtn"
      onClick={() => props.onClick()}
    >
      Close
    </Button>
  );
}

CloseBtn.propTypes = {
    onClick: PropTypes.func,
}

CloseBtn.defaultProps = {
    onClick: () => {},
}

export default CloseBtn;

import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";

import { Col, Row, Input, Form, Spin } from "antd";
import { CancelBtn, SaveBtn } from "../../../common/components/form";

import accountActions from "../../account/actions";

class ProfileChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      newPassword: "",
      confirmPassword: "",
    };
  }

  handleChangePassword = (data) => {
    this.props.dispatch(
      accountActions.ChangePassword(data, this.handleSuccessCallback)
    );
  };

  handleSuccessCallback = () => {
    this.props.setChangePassword(false);
  };

  pivotData = (data) => ({
    UserName: this.props.UserName,
    OldPassword: data.oldPassword,
    NewPassword: data.newPassword,
  });

  render() {
    // eslint-disable-next-line
    const passwordRegExp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{6,})/;
    const ValidationSchema = Yup.object().shape({
      oldPassword: Yup.string()
        .typeError("Old password is required.")
        .required("Old password is required."),
      newPassword: Yup.string()
        .matches(passwordRegExp, "Password is weak.")
        .typeError("New password is required")
        .required("New password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
        .typeError("Confirm password is required")
        .required("Confirm password is required"),
    });

    return (
      <>
        <div>
          <Spin spinning={this.props.loading}>
            <Formik
              enableReinitialize
              initialValues={{
                oldPassword: "",
                newPassword: this.state.newPassword,
                confirmPassword: this.state.confirmPassword,
              }}
              validationSchema={ValidationSchema}
              onSubmit={(values) => this.handleChangePassword(this.pivotData(values))}
            >
              {({ values, errors, touched, setFieldValue, handleSubmit }) => (
                <Form
                  autoComplete={0}
                  name="ConfirmEmailForm"
                  id="ConfirmEmailForm"
                  onChange={this.handleChange}
                  onSubmit={handleSubmit}
                >
                  <div>
                    <Row justify="center">
                      <h5 style={{ textAlign: "center" }}>Change password</h5>
                    </Row>
                    <br />
                    <Row justify="center">
                      <Col span={24}>
                        <Form.Item
                          className="email-password"
                          validateStatus={
                            errors && errors.oldPassword && touched.oldPassword
                              ? "error"
                              : null
                          }
                          help={errors && touched.oldPassword && errors.oldPassword}
                        >
                          <div className="p5-form-label">
                            <span>Old Password</span>
                          </div>
                          <Input.Password
                            placeholder="Password"
                            id="oldPassword"
                            className="p5-form-input"
                            value={values.oldPassword}
                            onChange={(val) => {
                              setFieldValue("oldPassword", val.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={24}>
                        <Form.Item
                          className="email-password"
                          validateStatus={
                            errors && errors.newPassword && touched.newPassword
                              ? "error"
                              : null
                          }
                          help={errors && touched.newPassword && errors.newPassword}
                        >
                          <div className="p5-form-label">
                            <span>New Password</span>
                          </div>
                          <Input.Password
                            placeholder="Password"
                            id="newPassword"
                            className="p5-form-input"
                            value={values.newPassword}
                            onChange={(val) => {
                              setFieldValue("newPassword", val.target.value);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify="center">
                      <Col span={24}>
                        <Form.Item
                          className="email-password"
                          validateStatus={
                            errors &&
                            errors.confirmPassword &&
                            touched.confirmPassword
                              ? "error"
                              : null
                          }
                          help={
                            errors &&
                            touched.confirmPassword &&
                            errors.confirmPassword
                          }
                        >
                          <div className="p5-form-label">
                            <span>Confirm Password</span>
                          </div>
                          <Input.Password
                            placeholder="Confirm Password"
                            id="confirm-password"
                            className="p5-form-input"
                            value={values.confirmPassword}
                            onChange={(val) => {
                              setFieldValue(
                                "confirmPassword",
                                val.target.value
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        paddingTop: 0,
                        paddingBottom: 20,
                        marginTop: 10,
                        marginBottom: -5,
                      }}
                    >
                      <div className="form-label" style={{ fontSize: 14 }}>
                        Passwords must have at least one lowercase letter, one
                        uppercase letter and one non-alphanumeric character.
                      </div>
                    </Row>
                    <Row justify="center">
                      <Col xs={24} sm={8} md={6} className="gutter-row">
                        <CancelBtn onClick={this.props.onCancel} />
                      </Col>
                      <Col xs={24} sm={8} md={6} className="gutter-row">
                        <SaveBtn
                          onClick={handleSubmit}
                        />
                      </Col>
                    </Row>
                  </div>
                </Form>
              )}
            </Formik>
          </Spin>
        </div>
      </>
    );
  }
}

ProfileChangePasswordForm.propTypes = {
  UserName: PropTypes.string,
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  setChangePassword: PropTypes.func,
  onCancel: PropTypes.func,
};

ProfileChangePasswordForm.defaultProps = {
  UserName: "",
  dispatch: () => {},
  loading: false,
  setChangePassword: () => {},
  onCancel: () => {},
};

function mapStateToProps(state) {
  const { loading } = state.Account;
  return {
    loading,
  };
}

// eslint-disable-next-line max-len
const connectedProfileChangePasswordForm = connect(mapStateToProps)(ProfileChangePasswordForm);

export { connectedProfileChangePasswordForm as default };

import React from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { Row, Col, Form, Input, Divider, DatePicker } from "antd";
import moment from "moment";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelBtn, SaveBtn } from "../../../common/components/form";
import { AddressService } from "../../../services";

class NominalBasicInfoForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidAddress: true,
    };
    this.formIK = React.createRef();
  }

  componentDidUpdate() {
    this.formIK.current.handleReset();
  }

  pivotData = (data) => ({
    Id: data.Id,
    FirstName: data.FirstName,
    LastName: data.LastName,
    // PhoneNumber: data.PhoneNumber,
    DateOfBirth: data.DOB.format("l"),
    Address1: data.Address1,
    Address2: data.Address2,
    City: data.City,
    PostalCode: data.PostalCode,
  });

  onSaveEdit = async (data) => {
    const coords = await AddressService.validateAddress(data);
    if (coords && coords.lat && coords.lng) {
      this.setState(
        (prevState) => ({
          ...prevState,
          isValidAddress: true,
        }),
        this.props.onSubmit(data)
      );
      this.formIK.current.setFieldValue("isValidAddress", true);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        isValidAddress: false,
      }));
      this.formIK.current.setFieldValue("isValidAddress", false);
    }
  };

  render() {
    const dateFormat = ["DD/MM/YYYY", "DD/MM/YY"];
    const invalidAddressMsg = (
      <>
        <span style={{color: "red"}}>
          <FontAwesomeIcon
            icon={faTimesCircle}
            className=""
            alt="invalidAddress"
          />
        </span>
        <span style={{color: "red"}}>{` Address is invalid, please try again.`}</span>
      </>
    );

    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.props.Id || 0,
          FirstName: this.props.FirstName || "",
          LastName: this.props.LastName || "",
          // PhoneNumber: this.props.PhoneNumber || "",
          DOB: moment(this.props.DateOfBirth) || moment(),
          Address1: this.props.Address1 || "",
          Address2: this.props.Address2 || "",
          City: this.props.City || "",
          PostalCode: this.props.PostalCode || "",
          isValidAddress: true,
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          FirstName: Yup.string()
            .typeError("First Name is required")
            .required("First Name is required"),
          LastName: Yup.string()
            .typeError("Last Name is required")
            .required("Last Name is required"),
          Address1: Yup.string()
            .required("Address 1 is required")
            .typeError("Address 1 is required"),
          Address2: Yup.string(),
          City: Yup.string()
            .required("City is required")
            .typeError("City is required"),
          PostalCode: Yup.string()
            .required("Postal Code is required")
            .typeError("Postal Code is required"),
          DOB: Yup.string()
            .typeError("Date of Birth is required")
            .required("Date of Birth is required"),
        })}
        // onChange={(values) => this.handleChange(values)}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form
            id="NominalBasicInfoForm"
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <div>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col sm={24} md={6}>
                      <span className="required-field">First Name</span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.FirstName && touched.FirstName
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.FirstName && touched.FirstName
                            ? errors.FirstName
                            : null
                        }
                      >
                        <Input
                          id="FirstName"
                          onBlur={handleBlur}
                          value={values.FirstName}
                          onChange={(val) => {
                            setFieldValue("First Name", val.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} md={6}>
                      <span className="required-field">Last Name</span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.LastName && touched.LastName
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.LastName && touched.LastName
                            ? errors.LastName
                            : null
                        }
                      >
                        <Input
                          id="LastName"
                          onBlur={handleBlur}
                          value={values.LastName}
                          onChange={(val) => {
                            setFieldValue("Last Name", val.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} md={6}>
                      <span className="required-field">Date of Birth: </span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.DOB && touched.DOB
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.DOB && touched.DOB
                            ? errors.DOB
                            : null
                        }
                      >
                        <DatePicker
                          className="p5-form-datepicker-input"
                          value={moment(values.DOB, dateFormat[0])}
                          format={dateFormat}
                          onChange={(val) => {
                            setFieldValue("DOB", val);
                          }}
                          onBlur={handleBlur}
                          allowClear={false}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} md={6}>
                      <span className="required-field">Address Line 1</span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.Address1 && touched.Address1
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.Address1 && touched.Address1
                            ? errors.Address1
                            : null
                        }
                      >
                        <Input
                          id="Address1"
                          onBlur={handleBlur}
                          value={values.Address1}
                          onChange={(val) => {
                            setFieldValue("Address Line 1", val.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} md={6}>
                      <span>Address Line 2</span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.Address2 && touched.Address2
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.Address2 && touched.Address2
                            ? errors.Address2
                            : null
                        }
                      >
                        <Input
                          id="Address2"
                          onBlur={handleBlur}
                          value={values.Address2}
                          onChange={(val) => {
                            setFieldValue("Address Line 2", val.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} md={6}>
                      <span className="required-field">City</span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.City && touched.City
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.City && touched.City
                            ? errors.City
                            : null
                        }
                      >
                        <Input
                          id="City"
                          onBlur={handleBlur}
                          value={values.City}
                          onChange={(val) => {
                            setFieldValue("City", val.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} md={6}>
                      <span className="required-field">Postal Code</span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item
                        validateStatus={
                          errors && errors.PostalCode && touched.PostalCode
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.PostalCode && touched.PostalCode
                            ? errors.PostalCode
                            : null
                        }
                      >
                        <Input
                          id="PostalCode"
                          onBlur={handleBlur}
                          value={values.PostalCode}
                          onChange={(val) => {
                            setFieldValue("Postal Code", val.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Divider plain />
              <Row>
                <Col xs={24} sm={24} md={8} />
                {!this.state.isValidAddress && (
                  <Col xs={24} sm={24} md={16}>
                    {invalidAddressMsg}
                  </Col>
                )}
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <SaveBtn
                    onClick={() => this.onSaveEdit(this.pivotData(values))}
                    loading={this.props.isLoading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

NominalBasicInfoForm.propTypes = {
  onSubmit: PropTypes.func, // .isRequired,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  Id: PropTypes.number,
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  // PhoneNumber: PropTypes.string,
  Address1: PropTypes.string,
  Address2: PropTypes.string,
  City: PropTypes.string,
  PostalCode: PropTypes.string,
  DateOfBirth: PropTypes.string,
};

NominalBasicInfoForm.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  isLoading: false,
  Id: 0,
  FirstName: null,
  LastName: null,
  // PhoneNumber: null,
  Address1: null,
  Address2: null,
  City: null,
  PostalCode: null,
  DateOfBirth: moment().toString(),
};

// function mapStateToProps() {
//   return {};
// }

// const connectedNominalBasicInfoForm = connect(mapStateToProps)(NominalBasicInfoForm);

// export { connectedNominalBasicInfoForm as default };
export default NominalBasicInfoForm;

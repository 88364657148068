import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Row, Col } from "antd";

// import actions from "../actions";

class KnowAssociateGrid extends React.Component {
  render() {
    const gridColumns = [
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name - b.Name,
        sortDirections: ["descend", "ascend"], 
      },
      {
        title: "RiskLevel",
        dataIndex: "RiskLevel",
        key: "RiskLevel",
        // sorter: (a, b) => a.RiskLevel - b.RiskLevel,
        // sortDirections: ["descend", "ascend"], 
        render: (text, record) =>
          record.RiskLevel !== null ? (
            <>
              <span>{record.RiskLevel.Name}</span>
            </>
          ) : null,
      },
      {
        title: "Location",
        dataIndex: "LastLocation",
        key: "LastLocation",     
      },
    ];

    return (
      <div>
        <Row justify="center">
          <Col span={24}>
            <Table
              loading={this.props.loading}
              dataSource={this.props.KnownAssociates}
              columns={gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

KnowAssociateGrid.propTypes = {
  KnownAssociates: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
};

KnowAssociateGrid.defaultProps = {
  KnownAssociates: [],
  loading: false,
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;
  return {
    loading,
  };
}

const connectedKnowAssociateGrid = connect(mapStateToProps)(KnowAssociateGrid);

export { connectedKnowAssociateGrid as default };

import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "antd";
import { ExportOutlined } from "@ant-design/icons";

class ExportIconButton extends React.Component {
  render() {
    return (
      <>
        <Tooltip placement="topRight" title={this.props.tooltip}>
          <Button
            id={this.props.id}
            icon={<ExportOutlined />}
            style={{
                backgroundColor: "white",
                border: "1px solid #243B53",
                color: "#243B53",
                float: "right",
              }}
            onClick={this.props.onClick}
            pull={this.props.pull}
          >
            {this.props.title}
          </Button>
        </Tooltip>
      </>
    );
  }
}

ExportIconButton.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  pull: PropTypes.string,
};

ExportIconButton.defaultProps = {
  onClick: () => {},
  id: "",
  title: "Export",
  pull: "",
  tooltip: "Export to pdf"
};

export default ExportIconButton;

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout, PageHeader } from '../../common/components';
import { NominalPageHeader, NominalDetailsPageHeader } from './components';
import { MyNominalsPage, NominalDetailPage } from './pages';

const NominalHeader = (
  <PageHeader customHeader={(<NominalPageHeader />)} />
)

const NominalDetailsHeader = (id) => (
  <PageHeader customHeader={(<NominalDetailsPageHeader Id={id} />)} />
)

const NominalsPageWithHeader = () => (
  <Route render={() => (
    <Layout PageHeader={NominalHeader}>
      <MyNominalsPage />
    </Layout>
  )} 
  />
)

const NominalDetailsPageWithHeader = (ref) => ( 
  <Layout PageHeader={NominalDetailsHeader(ref.match.params.Id)}>
    <NominalDetailPage Id={ref.match.params.Id} />
  </Layout>
)

const MyNominals = ({ match }) => (
  <Switch>
    <NominalsPageWithHeader exact path={`${match.url}`} />
    <Route path={`${match.url}/:Id/:Tab?`} component={NominalDetailsPageWithHeader} />
  </Switch>
);


MyNominals.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

MyNominals.defaultProps = {
  match: {
    url: '',
  },
};

export default withRouter(MyNominals);

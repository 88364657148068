import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout, PageHeader } from '../../common/components';
import InvestigatePage from './pages';

const InvestigateHeader = (
  <PageHeader title="Investigation Tool" />
)

const Investigate = ({ match }) => (
  <Layout PageHeader={InvestigateHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={InvestigatePage} />
    </Switch>
  </Layout>
);

Investigate.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Investigate.defaultProps = {
  match: {
    url: '',
  },
};

export default withRouter(Investigate);
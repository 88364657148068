/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import { Formik } from "formik";
import { Form, Input, Checkbox, Row, Col, Button, Select, Divider } from "antd";
import * as Yup from "yup";
// import actions from "../actions";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { DynamicSelect } from "../../../common/components/dynamic-select";
import Map from "../../MapView/components/Map";
import { NominalService } from "../../../services";
import SearchLocationInput from "../../../common/components/search-location-input";
// import { CancelBtn, SaveBtn } from "../../../common/components/form";
// import { propTypes } from "react-bootstrap/esm/Image";

const { Option } = Select;

class ExclusionZoneForm extends React.Component {
  constructor() {
    super();

    this.state = {
      exclusionZone: [
        {
          Latitude: null,
          Longitude: null,
          Radius: 100,
        },
      ],
      ExclusionZoneTypes: [],
      mapRef: null,
      centerPosition: {
        lat: null,
        lng: null
      }
    };
  }

  componentDidMount() {
    this.mapComponentRef=React.createRef();
    this.autocompleteInputRef = React.createRef();
    this.formIK = React.createRef();
  }

  componentDidUpdate(previousProps) {
    if (
      previousProps.ExclusionZone !== this.props.ExclusionZone &&
      (this.props.ExclusionZone.Latitude !==
        previousProps.ExclusionZone.Latitude ||
        this.props.ExclusionZone.Longitude !==
          previousProps.ExclusionZone.Longitude)
    ) {
      const position = {
        lat: this.props.ExclusionZone.Latitude,
        lng: this.props.ExclusionZone.Longitude,
        radius: this.props.ExclusionZone.Radius,
      };
      this.updateLocation(position);
    }
  }

  autocompleteAddressSelection = (result, status, callback) => {
    if (result) {

      this.formIK.current.setFieldValue("Location", result.formatted_address);

      const position = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
        radius: this.formIK.current.values.Radius,
      };
      this.updateLocation(position);
    }
  };

  updateLocation = (position) => {
    this.setState({
      exclusionZone: [
        {
          Latitude: position.lat,
          Longitude: position.lng,
          Radius: position.radius,
        },
      ],
      centerPosition: { lat: position.lat, lng: position.lng }
    });
    if (this.state.mapRef && this.state.mapRef.state && this.state.mapRef.state.map) {
      this.state.mapRef.state.map.panTo({ lat: position.lat, lng: position.lng });
      // this.state.mapRef.state.map.setZoom(15);
    }
  };

  updateRadius = (radius, unit) => {
    if (this.state.exclusionZone && this.state.exclusionZone.length > 0) {
      const exclusionZone = this.state.exclusionZone[0];
      if (exclusionZone) {
        const convertedRadius = radius * this.getUnitToMetersConversion(unit);
        exclusionZone.Radius = convertedRadius;
        this.setState({
          exclusionZone: [exclusionZone],
        });
      }
    }
  }

  getType = (type) => {
    const exclusionZoneTypes = [...this.state.ExclusionZoneTypes];
    const exclusionZoneType = exclusionZoneTypes.find((x) => x.Name === type);
    return exclusionZoneType.Id;
  };

  pivotData = (data) => ({
    Name: data.Name,
    ExclusionZoneTypeId: this.getType(data.Type),
    Latitude: this.state.exclusionZone[0].Latitude,
    Longitude: this.state.exclusionZone[0].Longitude,
    Address: data.Location,
    Radius: this.state.exclusionZone[0].Radius,
    CreateNominalAlertSettings: [
      {
        IsNotifyEmail: data.NotifyByEmail,
        IsNotifyText: data.NotifyByTextMessage,
        IsNotifyTeamEmail: data.NotifyByTeamEmail,
        IsNotifyNominal: data.NotifyNominal,
        IsUrgent: data.IsUrgent,
        IsAutomaticPushNotification: true,
        IsActive: true,
        NominalId: this.props.Nominal.Id,
      },
    ],
  });

  getUnitToMetersConversion = (unit) => {
    let meters;

    switch (unit) {
      case "Meters":
        meters = 1;
        break;
      case "Feet":
        meters = 0.3048;
        break;
      default:
    }

    return meters;
  };

  getPositionFromAddress = async (location, radius, unit) => {
    let locationAddress = location;
    if (location.display_name) {
      locationAddress = location.display_name;
    }
    let position = {
      lat: null,
      lng: null,
      radius: 0,
    };

    if (locationAddress !== "") {
      await Geocode.fromAddress(locationAddress)
        .then((response) => {
          position = response.results[0].geometry.location;
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error));
    }
    
    position.radius = radius * this.getUnitToMetersConversion(unit);
    this.updateLocation(position);
  };

  getExclusionZoneTypes = async () => {
    const response = await NominalService.getNominalExclusionZoneTypes();

    this.setState((prevState) => ({
      ...prevState,
      ExclusionZoneTypes: response.data,
    }));

    return response.data.map((x) => x.Name);
  };

  mapRefSetter = (gmap) => {
    if (!this.state.mapRef) {
      this.setState((prevState) => ({
        ...prevState,
        mapRef: gmap,
      }));
    }
  };

  checkFormErrors = (errors) => {
    return Object.keys(errors).length > 0;
  }

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Location: this.props.ExclusionZone.Address || "",
          Name: this.props.ExclusionZone.Name || "",
          Type: this.props.ExclusionZone.ExclusionZoneType ? this.props.ExclusionZone.ExclusionZoneType.Name : null,
          Radius: this.props.ExclusionZone.Radius || 100,
          Unit: "Meters",
          NotifyByEmail: this.props.IsNotifyEmail || false,
          NotifyByTextMessage: this.props.IsNotifyText || false,
          NotifyNominal: this.props.IsNotifyNominal || false,
          NotifyByTeamEmail: this.props.IsNotifyTeamEmail || false,
          IsUrgent: this.props.IsUrgent || false,
        }}
        validationSchema={Yup.object().shape({
          Location: Yup.string()
            .test(
              "locationRequired",
              "Location is required",
              (val) => val || (this.state.exclusionZone[0].Latitude !== null && this.state.exclusionZone[0].Latitude !== undefined 
                && this.state.exclusionZone[0].Longitude !== null && this.state.exclusionZone[0].Longitude !== undefined && this.state.exclusionZone[0].Radius)),
          Name: Yup.string()
            .required("Name is required")
            .typeError("Name is required"),
          Radius: Yup.number()
            .required("Radius is required")
            .typeError("Radius is required"),
          Unit: Yup.string(),
        })}
        onSubmit={(values) => {
          if (
            this.state.exclusionZone[0].Latitude !== null &&
            this.state.exclusionZone[0].Longitude !== null
          ) {
            this.props.onSubmit(this.pivotData(values));
            // this.createNominalExclusionZone(this.pivotData(values));
            // this.props.setShowAddExclusionZone(false);
          } else {
            document.getElementById("validAddressMessage").style.display =
              "block";
          }
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <Row gutter={[16,16]} style={{marginLeft: 70, marginBottom: 50}}>
              <Col span={12}>
                <Row>
                  <p
                    style={{
                      fontSize: 15,
                      marginRight: 70,
                      marginTop: "5%",
                      marginBottom: 30,
                      color: "rgba(0, 0, 0, 0.8)",
                    }}
                  >
                    Nominal is not permitted within a certain radius of these
                    specified location(s).
                  </p>
                  <Form.Item
                    style={{ width: "100%" }}
                    validateStatus={
                      errors && errors.Location && touched.Location
                        ? "error"
                        : "success"
                    }
                    help={
                      errors && errors.Location && touched.Location
                        ? errors.Location
                        : null
                    }
                  >
                    <Row>
                      <Col span={6}>
                        <label>Find Location: </label>
                      </Col>
                      <Col span={16}>
                        <SearchLocationInput
                          ref={this.autocompleteInputRef}
                          id="Location"
                          placeholder="Location"
                          onBlur={handleBlur}
                          value={values.Location}
                          onSelect={this.autocompleteAddressSelection}
                          onChange={(val) => {
                            setFieldValue("Location", val);
                            this.getPositionFromAddress(
                              val,
                              values.Radius,
                              values.Unit
                            );
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                    style={{ width: "100%" }}
                    validateStatus={
                      errors && errors.Name && touched.Name
                        ? "error"
                        : "success"
                    }
                    help={
                      errors && errors.Name && touched.Name
                        ? errors.Location
                        : null
                    }
                  >
                    <Row>
                      <Col span={6}>
                        <label>Name: </label>
                      </Col>
                      <Col span={16}>
                        <Input
                          id="Name"
                          placeholder="Name"
                          onBlur={handleBlur}
                          value={values.Name}
                          onChange={(val) => {
                            setFieldValue("Name", val.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                    style={{ width: "100%" }}
                    validateStatus={
                      errors && errors.Type && touched.Type
                        ? "error"
                        : "success"
                    }
                    help={
                      errors && errors.Type && touched.Type
                        ? errors.Location
                        : null
                    }
                  >
                    <Row>
                      <Col span={6}>
                        <label>Type: </label>
                      </Col>
                      <Col span={16}>
                        <DynamicSelect
                          hasNone={false}
                          field="RolePicker"
                          getData={this.getExclusionZoneTypes}
                          displayFieldName="Name"
                          valueFieldName="Id"
                          value={values.Type}
                          onBlur={handleBlur}
                          onChange={(val) => {
                            setFieldValue("Type", val);
                          }}
                        />
                      </Col>
                    </Row>
                  </Form.Item>
                  <Form.Item
                    style={{ width: "100%" }}
                    validateStatus={
                      errors && errors.Type && touched.Type
                        ? "error"
                        : "success"
                    }
                    help={
                      errors && errors.Type && touched.Type
                        ? errors.Location
                        : null
                    }
                  >
                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <label>Radius: </label>
                      </Col>
                      <Col span={8}>
                        <Input
                          id="Radius"
                          placeholder="Radius"
                          onBlur={handleBlur}
                          value={values.Radius}
                          onChange={(val) => {
                            setFieldValue("Radius", val.target.value);
                            this.updateRadius(val.target.value, values.Unit);
                          }}
                        />
                      </Col>
                      <Col span={8}>
                        <Select
                          onChange={() => {
                            const unit = document.getElementById("radiusUnit")
                              .value;
                            setFieldValue("Unit", unit);
                            this.updateRadius(values.Radius, unit);
                          }}
                          id="radiusUnit"
                          value={values.Unit}
                        >
                          {" "}
                          <Option value="Meters">Meters</Option>
                          <Option value="Feet">Feet</Option>
                        </Select>
                      </Col>
                    </Row>
                  </Form.Item>
                  <Row style={{width: '100%'}}>
                    <Col span={22}>
                      <Divider />
                    </Col>
                    <Col span={2} />
                  </Row>
                  <Row>
                    <Col>
                      <Checkbox
                        style={{
                          marginRight: 5,
                        }}
                        id="NotifyNominal"
                        name="NotifyNominal"
                        onBlur={handleBlur}
                        checked={values.NotifyNominal}
                        onChange={(val) => {
                          setFieldValue("NotifyNominal", val.target.checked);
                        }}
                      />
                      <label htmlFor="textNominal">Notify nominal when zone breach detected.</label>
                    </Col>
                  </Row>
                  <Form.Item style={{ width: "100%" }}>
                    <Row gutter={[16, 16]}>
                      <Col>
                        <label>Notify me by: </label>
                      </Col>
                      <Col>
                        <Checkbox
                          style={{
                            display: "inline-block",
                            marginRight: 5,
                          }}
                          id="NotifyByEmail"
                          onBlur={handleBlur}
                          checked={values.NotifyByEmail}
                          onChange={(val) => {
                            setFieldValue(
                              "NotifyByEmail",
                              val.target.value
                            );
                          }}
                        />
                        <label htmlFor="email">Email</label>
                      </Col>
                      <Col>
                        <Checkbox
                          style={{
                            display: "inline-block",
                            marginRight: 5,
                          }}
                          id="NotifyByTextMessage"
                          onBlur={handleBlur}
                          checked={values.NotifyByTextMessage}
                          onChange={(val) => {
                            setFieldValue(
                              "NotifyByTextMessage",
                              val.target.value
                            );
                          }}
                        />
                        <label htmlFor="textMessage">Text Message</label>
                      </Col>
                      <Col flex="auto">
                        <Checkbox
                          style={{
                            display: "inline-block",
                            marginRight: 5,
                          }}
                          id="NotifyByTeamEmail"
                          onBlur={handleBlur}
                          checked={values.NotifyByTeamEmail}
                          onChange={(val) => {
                            setFieldValue(
                              "NotifyByTeamEmail",
                              val.target.value
                            );
                          }}
                        />
                        <label htmlFor="teamEmail">Team Email</label>
                      </Col>
                    </Row>
                  </Form.Item>
                  <span className="exclusion-zone-urgent">
                    <Checkbox
                      style={{ display: "inline-block", marginRight: 5 }}
                      id="IsUrgent"
                      onBlur={handleBlur}
                      checked={values.IsUrgent}
                      onChange={(val) => {
                        setFieldValue("IsUrgent", val.target.value);
                      }}
                    />
                    <label htmlFor="flagAsUrgent"> Flag as Urgent</label>
                  </span>
                </Row>
              </Col>
              <Col span={12}>
                <Row gutter={[16, 16]}>
                  <Col span={24} style={{marginLeft: -25, height: '60vh', minHeight: 500, marginTop: 20}}>
                    <Map
                      centerPosition={this.state.centerPosition}
                      zoomLevel={15}
                      mapRefSetter={this.mapRefSetter}
                      exclusionZones={this.state.exclusionZone}
                      setExclusionZoneLocation={(location) => {
                        const position = {
                          lat: location.lat,
                          lng: location.lon,
                          radius: this.state.exclusionZone[0].Radius * this.getUnitToMetersConversion("Meters")
                        }
                        this.updateLocation(position);
                        setFieldValue("Location", location.display_name ? location.display_name : location);
                      }}
                    />
                  </Col>
                  <Col span={24}>
                    <Row gutter={[16, 16]} style={{marginRight: 20}}>
                      <Col flex="auto">
                        <label
                          id="validAddressMessage"
                          style={{ display: "none", color: "red" }}
                        >
                          Please enter a valid address.
                        </label>
                      </Col>
                      <Col>
                        <Button
                          onClick={this.props.onCancel}
                        >
                          Cancel
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          onClick={handleSubmit}
                          disabled={this.checkFormErrors(errors)}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

ExclusionZoneForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  // setShowAddExclusionZone: PropTypes.func,
  Nominal: PropTypes.shape(),
  ExclusionZone: PropTypes.shape({
    Latitude: PropTypes.number,
    Longitude: PropTypes.number,
    Address: PropTypes.string,
    Name: PropTypes.string,
    ExclusionZoneType: PropTypes.shape({
      Id: PropTypes.number,
      Name: PropTypes.string,
    }),
    Radius: PropTypes.number,
  }),
  IsNotifyEmail: PropTypes.bool,
  IsNotifyText: PropTypes.bool,
  IsNotifyTeamEmail: PropTypes.bool,
  IsNotifyNominal: PropTypes.bool,
  IsUrgent: PropTypes.bool,
};

ExclusionZoneForm.defaultProps = {
  onCancel: () => {},
  // setShowAddExclusionZone: () => {},
  Nominal: {},
  ExclusionZone: {
    Latitude: 0,
    Longitude: 0,
    Address: null,
    Name: null,
    ExclusionZoneType: {
      Id: -1,
      Name: null,
    },
    Radius: null,
  },
  IsNotifyEmail: false,
  IsNotifyText: false,
  IsNotifyTeamEmail: false,
  IsNotifyNominal: false,
  IsUrgent: false,
};

function mapStateToProps(state) {
  const { Nominal } = state.Nominal;
  return { Nominal };
}

const connectExclusionZoneForm = connect(mapStateToProps)(ExclusionZoneForm);

export { connectExclusionZoneForm as default };

import React from "react";
import PropTypes from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { connect } from "react-redux";
import Moment from 'moment';

import {
  Row,
  Col,
  Button,
  Input,
} from "antd";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import SearchLocationInput from '../../../common/components/search-location-input';
import { AlertService, AddressService, NominalService } from '../../../services';
import { Map } from '../../../features/MapView/components';
// import { DynamicSelect } from "../../../common/components/dynamic-select";
// import { HelperService, OrganisationService } from "../../../services";
import "./Alert.scss";

const { TextArea } = Input;

class AlertDetail extends React.Component {
  constructor(props) {
		super(props);
    this.state = {
      mapRef: null,
      currentComment: '',
      commentList: [],
    };
}

  componentDidUpdate(prevProps) {
    if((prevProps && prevProps.Alert !== this.props.Alert) || (prevProps === null && this.props.Alert !== null))
    {
      AlertService.getComments(this.props.Alert.Id).then((newCommentList) => {
        this.setState({
          currentComment: '',
          commentList: newCommentList,
        });
      });
    }
  }

  submitComment = () => {
    const alert = {
      NominalAlertId: this.props.Alert.Id,
      Comment: this.state.currentComment,
    }

    AlertService.submitComment(alert).then((newCommentList) => {
      this.setState({
        currentComment: '',
        commentList: newCommentList,
      });
    });
  }

  onSave = async (data) => {
    const coords = await AddressService.validateAddress(data);
    if (coords && coords.lat && coords.lng) {
      this.setState((prevState) => ({
        ...prevState,
        isValidAddress: true
      }), this.props.onSubmit(data));
      this.formIK.current.setFieldValue("isValidAddress", true);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        isValidAddress: false
      }));
      this.formIK.current.setFieldValue("isValidAddress", false);
    }
  };

  pivotData = (data) => ({
    Id: data.Id,
    FirstName: data.FirstName,
    LastName: data.LastName,
    DateOfBirth: data.DOB.format("l"),
    Address1: data.Address1,
    Address2: data.Address2,
    City: data.City,
    PostalCode: data.PostalCode,
    PhoneNumber: data.PhoneNumber.replace(/[^\d]/g, ""),
    ConsumerId: data.ConsumerId,   
    NominalTypeId: data.NominalTypeId,
  });

  setConsumerId = (nominal) => {
      this.setState((prevState) => ({
        ...prevState,
        ConsumerId: nominal.ConsumerId,
        ErrorText: nominal.Error,
      }));

      this.formIK.current.setFieldValue("ConsumerId", nominal.ConsumerId);
  }


  setDOBValue = (dob) => {
    const isValid = Moment(dob).isValid();
    if (isValid) {
      return Moment(dob);
    }
    return null;
  }

  handleChange = (e) => {
    const { value, id } = e.target;  
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  validatePhoneNumber = (phoneNumber) => {
    return NominalService.getPhoneNumber(phoneNumber).then(
      (response) => {
        return response.data.ConsumerId ?? 0;
      },
      () => {
        return 0;
      }
    );
  }

  isValidDOB = (d) => {
    return Moment(d).isValid();
  }

  deviceLinkedError = () => (
    <>
      <span style={{color: "red"}}>
        <FontAwesomeIcon
          icon={faTimesCircle}
          className=""
          alt="deviceLinkedError"
        />
      </span>
      <span style={{color: "red"}}> {this.state.ErrorText}</span>
    </>
  );

  mapRefSetter = (gmap) => {
    if (!this.state.mapRef) {
      this.setState((prevState) => ({
        ...prevState,
        mapRef: gmap,
      }));
    }
  };

  replaceValues = (value) => {
    let newString = value;
    if (this.props.Alert.AlertDetail.TimespanReplacements) {
      Object.keys(this.props.Alert.AlertDetail.TimespanReplacements).map((key) => {
        newString = newString.replace(key, Moment(`${Moment(Date()).format('MM/DD/yyyy')} ${this.props.Alert.AlertDetail.TimespanReplacements[key]} Z`).format('h:mm a'));
        return '';
      });
    }
    return newString;
  }
    
  render() {
    if (!this.props.Alert)
    {
      return (<div />);
    }
    return (
      <>
        <Row>
          <Col span={12}>
            <Row gutter={[16, 48]}>
              <Col span={24}>
                <span className="AlertFieldTitle">Alert Type: </span>
                <span className="AlertFieldDescription">{this.props.TypeIcon}</span>
              </Col>
            </Row>
            <Row id="AlertModalMiddleRow">
              <Col span={12}>
                <span className="AlertFieldTitle">Date: </span>
                <span className="AlertFieldDescription">{Moment(this.props.Alert.CreatedDate).format('DD-MM-yyyy')}</span>
              </Col>
              <Col span={12}>
                <span className="AlertFieldTitle">Time: </span>
                <span className="AlertFieldDescription">{Moment(this.props.Alert.CreatedDate).format('LTS')}</span>
              </Col>
            </Row>
            {this.props.Alert.AlertDetail && (
            <Row>
              <Col span={24}>
                <Row>
                  <Col span={4}>
                    <span className="AlertFieldTitle">Details: </span>
                  </Col>
                  <Col span={20}>
                    <div className="AlertDetailText">{this.props.Alert.AlertDetail.Message}</div>
                    {Object.keys(this.props.Alert.AlertDetail.SpecificDetails).map((key) => (
                      <Row>
                        <span className="AlertFieldTitle">{key}</span>
                        <span className="AlertFieldDescription">{this.replaceValues(this.props.Alert.AlertDetail.SpecificDetails[key])}</span>
                      </Row>
                    ))}
                  </Col>
                </Row>
              </Col>
            </Row>
            )}
          </Col>
          <Col span={12}>
            <Map
              key={uuidv4()}
              centerPosition={this.props.Alert.Location}
              mapRefSetter={this.mapRefSetter}
              dispatch={this.props.dispatch}
              details={this.props.Alert.Location}
              data={[this.props.Alert.Location]}
              selectedIDs={[this.props.Alert.Nominal.Id]}
              exclusionZones={this.props.Alert.AlertType.Id === 3 ? [this.props.Alert.CurfewSettings] : [this.props.Alert.ExclusionZone]}
              cssClass="ModalMap"
              zoomLevel={15}
              // isShowExclusionZones={this.props.isShowExclusionZones}
            />
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Row>
              <span className="AlertFieldTitle">Actions: </span>
            </Row>
            <Row>
              <Button 
                onClick={() => {
                  this.props.OnMessageClick();
                }}
              >
                Message Nominal
              </Button>
            </Row>
          </Col>
          <Col span={18}>
            <Row>
              <span className="AlertFieldTitle">Comments: </span>
            </Row>
            <Row>
              <TextArea
                value={this.state.currentComment}
                placeholder="Type your message"
                rows={4}
                onChange={(e) => this.setState({
                  currentComment: e.target.value
                })}
              />
            </Row>
            <Row className="AlertComments SubmitComment">
              <Button
                onClick={this.submitComment}
              >
                Add Comment
              </Button>
            </Row>
          </Col>
        </Row>
        <Row className="AlertComments">
          <Col span={12} />
          <Col span={12}>
            <span className="AlertFieldTitle FullWidth">{`${this.state.commentList.length} Comments`}</span>
            <Row>
              {
                this.state.commentList.length > 0 && this.state.commentList.map(comment => (
                  <Row className="FullWidth">
                    <Row className="CommentName">{comment.CreatedUser}</Row>
                    <Row className="CommentContainer"><span className="Comment">{comment.Comment}</span></Row>
                    <Row className="CommentDate">{Moment(comment.CreatedDate).format('MMMM Do YYYY, h:mm:ss a')}</Row>
                  </Row>
                ))
              }
            </Row>
          </Col>
        </Row>
      </>
    );
  }
}

AlertDetail.propTypes = {
  dispatch: PropTypes.func,
  // alertType: PropTypes.number,
  onSubmit: PropTypes.func,
  TypeIcon: PropTypes.element,
  Alert: PropTypes.shape().isRequired,
  OnMessageClick: PropTypes.func,
  // ExclusionZones: PropTypes.arrayOf(PropTypes.shape({})),
  // isShowExclusionZones: PropTypes.bool,
};

AlertDetail.defaultProps = {
  dispatch: () => {},
  onSubmit: () => {},
  TypeIcon: null,
  OnMessageClick: () => {},
  // ExclusionZones: [],
  // isShowExclusionZones: false,
};

function mapStateToProps() {
  return {}
}

  const connectedAlertDetail = connect(mapStateToProps)(AlertDetail);
  
  export { connectedAlertDetail as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Spin } from "antd";
import SearchForm from "../form"
import ResultsCard from "../components/ResultsCard";
import navigationActions from "../../../common/components/navigation/action";
import { NotFoundModal } from '../modals';

class InvestigatePage extends React.Component {
  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("Investigate"));
  }

  fakeFunc = () => {
    this.props.dispatch();
  }
  
  render() {
    return (
      <div className="mosovo-content-container">
        <Spin spinning={this.props.loading}>
          {!(this.props.foundLocations.length > 0) && (
            <SearchForm />
          )}
          {this.props.foundLocations.length > 0 && (
            <ResultsCard
              locations={this.props.foundLocations}
              searchParams={this.props.searchParams}
            />
          )}
          <NotFoundModal />
        </Spin>
      </div>
    );
  }
}

InvestigatePage.propTypes = {
    dispatch: PropTypes.func,
    loading: PropTypes.bool,
    foundLocations: PropTypes.arrayOf(PropTypes.shape()),
    searchParams: PropTypes.shape(),
};

InvestigatePage.defaultProps = {
    dispatch: () => {},
    loading: false,
    foundLocations: [],
    searchParams: {},
};

function mapStateToProps(state) {
  return {
    loading: state.Investigate.loading,
    foundLocations: state.Investigate.locations,
    searchParams: state.Investigate.searchParams,
  };
}

const connectInvestigationPage = connect(mapStateToProps)(InvestigatePage);

export { connectInvestigationPage as default };

import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";
import { HelperService } from "../../../services";

const { Option } = Select;

class RiskLevelSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getRiskLevels();
  }

  getRiskLevels() {
    HelperService.getRiskLevelList().then((result) => {
      this.setState({
        data: result.data,
      });
    });
  }

  render() {
    return (
      <Select
        placeholder="Risk Level"
        className={this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}
        style={{ width: "100%" }}
        allowClear={this.props.isAllowClear}
      >
        {this.state.data.map((opt) => (
          <Option key={opt.Id} value={opt.Id}>
            {opt.Name}
          </Option>
        ))}
      </Select>
    );
  }
}

RiskLevelSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  className: PropTypes.string,
  isAllowClear: PropTypes.bool,
};

RiskLevelSelect.defaultProps = {
  value: 0,
  className: "",
  isAllowClear: false,
};

export { RiskLevelSelect as default };

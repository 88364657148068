import c from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  error: null,
  loggedIn: false,
  currentUser: {},
};

export default function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case c.GET_CURRENT_USER_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        currentUser: {},
      };
    case c.GET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        loggedIn: true,
        currentUser: action?.profile?.data,
      };
    case c.GET_CURRENT_USER_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: false,
        loggedIn: false,
        currentUser: {},
      };
    case c.LOGIN_REQUEST:
      return {
        ...state,
        loggedIn: false,
        loading: true,
        hasError: false,
      };
    case c.LOGIN_SUCCESS:
      return {
        ...state,
        loggedIn: true,
        loading: false,
        hasError: false,
      };
    case c.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.MFA_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case c.LOGOUT_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case c.LOGOUT_SUCCESS:
      return {
        ...state,
        loggedIn: false,
        loading: false,
        hasError: false,
        isLoggedIn: false,
      };
    case c.LOGOUT_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}

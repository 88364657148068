import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "antd";
import { faLocationArrow } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class LocationArrowIconButton extends React.Component {
  render() {
    const locationArrowIcon = (
      <span>
        <FontAwesomeIcon
          icon={faLocationArrow}
          id={this.props.id}
          className=""
          alt="location-arrow"
        />
      </span>
    );

    return (
      <>
        <Tooltip placement="topRight" title={this.props.tooltip}>
          <Button
            id={this.props.id}
            icon={locationArrowIcon}
            style={{
              backgroundColor: "white",
              border: "1px solid #243B53",
              color: "#243B53",
              float: "right",
            }}
            onClick={this.props.onClick}
          >
            <span style={{marginLeft: '8px'}}>{` ${this.props.title}`}</span>
          </Button>
        </Tooltip>
      </>
    );
  }
}

LocationArrowIconButton.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
};

LocationArrowIconButton.defaultProps = {
  onClick: () => {},
  id: "",
  title: null,
  tooltip: null
};

export default LocationArrowIconButton;

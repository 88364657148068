import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, notification, Row } from "antd";

import actions from "../actions";
import AlertService from "../../../services/Alert";
import CheckinAlertForm from "../forms/CheckinAlertForm";

class CheckinAlertModal extends React.Component {
  SaveAlert = (data) => {
    AlertService.postCheckinAlerts(data).then(() => {
      notification.success({
        message: 'Alert Saved',
        description:
          'The alert has been saved.',
      });
      this.CloseModal();
    });
  }

  CloseModal = () => {
    this.props.dispatch(actions.closeNominalModal());
    this.props.refreshGrid();
  };

  render() {
    return (
      <>
        <Modal
          title="Check-In Alert"
          visible={this.props.showCheckinAlertModal}
          onCancel={this.CloseModal}
          footer={null}
          width={600}
          destroyOnClose
        >
          <Row>
            <CheckinAlertForm
              changeTabs={this.props.changeTabs}
              onCancel={this.CloseModal}
              onSubmit={this.SaveAlert}
              NominalId={this.props.Nominal.Id}
              {...this.props.AlertSettings}
              hasSetting={this.props.Nominal.CheckinSetting !== null}
            />
          </Row>
        </Modal>
      </>
    );
  }
}

CheckinAlertModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  showCheckinAlertModal: PropTypes.bool,
  changeTabs: PropTypes.func,
  Nominal: PropTypes.shape(),
  AlertSettings: PropTypes.shape(),
};

CheckinAlertModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  showCheckinAlertModal: true,
  changeTabs: () => {},
  Nominal: {},
  AlertSettings: {},
};

function mapStateToProps(state) {
  const {
    showCheckinAlertModal,
    Nominal,
    AlertSettings,
  } = state.Nominal;

  return { showCheckinAlertModal, Nominal, AlertSettings };
}

const connectedCheckinAlertModal = connect(mapStateToProps)(
  CheckinAlertModal
);

export { connectedCheckinAlertModal as default };

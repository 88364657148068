import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout, PageHeader } from '../../common/components';
import { OrganisationIndexPage, OrganisationDetailPage } from './pages';

const OrganizationHeader = (
  <PageHeader title="Organisations" />
)

const Organisation = ({ match }) => (
  <Layout PageHeader={OrganizationHeader}>
    <Switch>
      <Route exact path={`${match.url}`} component={OrganisationIndexPage} />
      <Route exact path={`${match.url}/:Id`} component={OrganisationDetailPage} />
    </Switch>
  </Layout>
);

Organisation.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

Organisation.defaultProps = {
  match: {
    url: '',
  },
};

export default withRouter(Organisation);
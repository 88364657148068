import { toast } from "react-toastify";
// import { push } from 'connected-react-router';
import constants from "./constants";
import { UserService } from "../../services";

function getUserList(queryString, callback) {
  function request() {
    return { type: constants.USERS_GRID_REQUEST };
  }
  function success() {
    return { type: constants.USERS_GRID_SUCCESS };
  }
  function failure(error) {
    return { type: constants.USERS_GRID_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.getUserList(queryString).then(
      (response) => {
        dispatch(success());
        callback(response.data.value);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function openTransferOMModal() {
  function open() {
    return { type: constants.OPEN_TRANSFER_OM_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function closeTransferOMModal() {
  function close() {
    return { type: constants.CLOSE_USERS_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function openAddUserModal() {
  function open() {
    return { type: constants.OPEN_ADD_USERS_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function openEditUserModal() {
  function open() {
    return { type: constants.OPEN_EDIT_USERS_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function closeUserModal() {
  function close() {
    return { type: constants.CLOSE_USERS_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function openProfileModal() {
  function open() {
    return { type: constants.OPEN_PROFILE_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function createUser(data, callback) {
  function request() {
    return { type: constants.USERS_CREATE_REQUEST };
  }
  function success() {
    return { type: constants.USERS_CREATE_SUCCESS };
  }
  function failure(error) {
    return { type: constants.USERS_CREATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.createUser(data).then(
      () => {
        dispatch(success());
        toast("Successfully create user");
        callback();
      },
      (error) => {
        let errorMessage = error.toString();
        if (error.response.data.Error) errorMessage = error.response.data.Error;
        dispatch(failure(errorMessage));
        toast.error(errorMessage);
      }
    );
  };
}

function getUserById(Id, callback) {
  function request() {
    return { type: constants.GET_USER_REQUEST };
  }
  function success(user) {
    return { type: constants.GET_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: constants.GET_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.getUserById(Id).then(
      (response) => {
        dispatch(success(response.data));
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function updateUser(data, callback) {
  function request(user) {
    return { type: constants.UPDATE_USER_REQUEST, user };
  }
  function success(user) {
    return { type: constants.UPDATE_USER_SUCCESS, user };
  }
  function failure(error) {
    return { type: constants.UPDATE_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request(data));
    UserService.updateUser(data.Id, data).then(
      () => {
        dispatch(success(data));
        toast("Successfully updated user");
        callback();
      },
      (error) => {
        dispatch(failure(error.response.data?.Error ?? error.toString()));
        toast.error(error.response.data?.Error ?? "An error occurred, please try again");
      }
    );
  };
}

function deleteUser(id, callback) {
  function request() {
    return { type: constants.DELETE_USER_REQUEST };
  }
  function success() {
    return { type: constants.DELETE_USER_SUCCESS };
  }
  function failure(error) {
    return { type: constants.DELETE_USER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.deleteUser(id).then(
      () => {
        dispatch(success());
        toast("Successfully deleted user");
        callback();
        // dispatch(push(`/users`));
      },
      (error) => {
        dispatch(failure(error.response.data?.Error ?? error.toString()));
        toast.error(error.response.data?.Error ?? "An error occurred, please try again");
      }
    );
  };
}

function getUserDetails(id, callback) {
  function request() {
    return { type: constants.USER_DETAILS_REQUEST };
  }
  function success() {
    return { type: constants.USER_DETAILS_SUCCESS };
  }
  function failure(error) {
    return { type: constants.USER_DETAILS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.getUserDetails(id).then(
      (response) => {
        dispatch(success());
        callback(response.data);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function transferOMBulk(data, callback) {
  function request() {
    return { type: constants.TRANSFER_OM_REQUEST };
  }
  function success() {
    return { type: constants.TRANSFER_OM_SUCCESS };
  }
  function failure(error) {
    return { type: constants.TRANSFER_OM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    UserService.transferOMBulk(data).then(
      (response) => {
        dispatch(success());
        if (response.data.HasError === true) {
          toast.error(response.data.Error);
        } else {
          toast("Successfully changed Offender Manager's assignments");
          callback();
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Unable to change Offender Manager, please try again");
      }
    );
  };
}

export default {
  getUserList,
  openTransferOMModal,
  closeTransferOMModal,
  openAddUserModal,
  openEditUserModal,
  closeUserModal,
  openProfileModal,
  createUser,
  getUserById,
  updateUser,
  deleteUser,
  getUserDetails,
  transferOMBulk,
};

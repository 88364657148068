import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "antd";
import { faMinusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MinusCircleIconButton = (props) => {
  const minusCircleIcon = (
    <span>
      <FontAwesomeIcon
        icon={faMinusCircle}
        id={props.id}
        className=""
        alt="suspend"
      />
    </span>
  );

  return (
    <>
      <Tooltip placement="topRight" title={props.tooltip}>
        <Button
          id={props.id}
          disabled={props.isDisabled}
          icon={minusCircleIcon}
          style={{
            backgroundColor: props.isDisabled ? "#F5F5F5" : "white",
            border: props.isDisabled ? "none" : "1px solid #243B53",
            color: props.isDisabled ? "#868E96" : "#243B53",
            float: "right",
          }}
          onClick={props.onClick}
        >
          <span style={{marginLeft: '8px'}}>{` ${props.title}`}</span>
        </Button>
      </Tooltip>
    </>
  );
};

MinusCircleIconButton.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  isDisabled: PropTypes.bool,
};

MinusCircleIconButton.defaultProps = {
  onClick: () => {},
  id: "",
  title: null,
  tooltip: null,
  isDisabled: false,
};

export default MinusCircleIconButton;

import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "antd";
import { faCommentDots } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./IconButtons.scss";

class CommentCircleIconButton extends React.Component {
  render() {
    return (
      <>
        <Tooltip placement="topLeft" title={this.props.tooltipText}>  
          <Button
            type="text"
            shape="circle"
            size="middle"
            style={{ color: "#243B53" }}
            onClick={this.props.onClick}
            disabled={this.props.isDisabled}
            className={this.props.isDisabled ? "mosovo-btn-disabled" : "mosovo-btn-effect"}
          >
            <FontAwesomeIcon
              icon={faCommentDots}
              id={this.props.id}
              className=""
              alt="EditUser"
            />
          </Button>      
        </Tooltip>        
      </>
    );
  }
}

CommentCircleIconButton.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  isDisabled: PropTypes.bool,
  tooltipText: PropTypes.string,
};

CommentCircleIconButton.defaultProps = {
  onClick: () => {},
  id: "",
  isDisabled: false,
  tooltipText: 'Comment',
};

export default CommentCircleIconButton;

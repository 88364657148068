export default {
    FORCE_GRID_REQUEST: 'FORCE_GRID_REQUEST',
    FORCE_GRID_SUCCESS: 'FORCE_GRID_SUCCESS',
    FORCE_GRID_FAILURE: 'FORCE_GRID_FAILURE',    
    AREA_GRID_REQUEST: 'AREA_GRID_REQUEST',
    AREA_GRID_SUCCESS: 'AREA_GRID_SUCCESS',
    AREA_GRID_FAILURE: 'AREA_GRID_FAILURE', 
    TEAM_GRID_REQUEST: 'TEAM_GRID_REQUEST',
    TEAM_GRID_SUCCESS: 'TEAM_GRID_SUCCESS',
    TEAM_GRID_FAILURE: 'TEAM_GRID_FAILURE',  
    OPEN_BULK_CREATE_MODAL: 'OPEN_BULK_CREATE_MODAL',
    CLOSE_BULK_CREATE_MODAL: 'CLOSE_BULK_CREATE_MODAL',
    BULK_REQUEST: 'BULK_REQUEST',
    BULK_SUCCESS: 'BULK_SUCCESS',
    BULK_FAILURE: 'BULK_FAILURE', 
    OPEN_FORCE_MODAL: 'OPEN_FORCE_MODAL',
    CLOSE_FORCE_MODAL: 'CLOSE_FORCE_MODAL',
    GET_FORCE_REQUEST: 'GET_FORCE_REQUEST',
    GET_FORCE_SUCCESS: 'GET_FORCE_SUCCESS',
    GET_FORCE_FAILURE: 'GET_FORCE_FAILURE',
    UPDATE_FORCE_REQUEST: 'UPDATE_FORCE_REQUEST',
    UPDATE_FORCE_SUCCESS: 'UPDATE_FORCE_SUCCESS',
    UPDATE_FORCE_FAILURE: 'UPDATE_FORCE_FAILURE',
    OPEN_AREA_MODAL: 'OPEN_AREA_MODAL',
    CLOSE_AREA_MODAL: 'CLOSE_AREA_MODAL',
    GET_AREA_REQUEST: 'GET_AREA_REQUEST',
    GET_AREA_SUCCESS: 'GET_AREA_SUCCESS',
    GET_AREA_FAILURE: 'GET_AREA_FAILURE',
    CREATE_AREA_REQUEST: 'CREATE_AREA_REQUEST',
    CREATE_AREA_SUCCESS: 'CREATE_AREA_SUCCESS',
    CREATE_AREA_FAILURE: 'CREATE_AREA_FAILURE',
    UPDATE_AREA_REQUEST: 'UPDATE_AREA_REQUEST',
    UPDATE_AREA_SUCCESS: 'UPDATE_AREA_SUCCESS',
    UPDATE_AREA_FAILURE: 'UPDATE_AREA_FAILURE',
    OPEN_ADD_AREA_MODAL: 'OPEN_ADD_AREA_MODAL',
    OPEN_TEAM_MODAL: 'OPEN_TEAM_MODAL',
    CLOSE_TEAM_MODAL: 'CLOSE_TEAM_MODAL',
    GET_TEAM_REQUEST: 'GET_TEAM_REQUEST',
    GET_TEAM_SUCCESS: 'GET_TEAM_SUCCESS',
    GET_TEAM_FAILURE: 'GET_TEAM_FAILURE',
    UPDATE_TEAM_REQUEST: 'UPDATE_TEAM_REQUEST',
    UPDATE_TEAM_SUCCESS: 'UPDATE_TEAM_SUCCESS',
    UPDATE_TEAM_FAILURE: 'UPDATE_TEAM_FAILURE',
    OPEN_ADD_TEAM_MODAL: 'OPEN_ADD_TEAM_MODAL',
    CREATE_TEAM_REQUEST: 'CREATE_TEAM_REQUEST',
    CREATE_TEAM_SUCCESS: 'CREATE_TEAM_SUCCESS',
    CREATE_TEAM_FAILURE: 'CREATE_TEAM_FAILURE',  
    GET_ORGANISATION_REQUEST: 'GET_ORGANISATION_REQUEST',
    GET_ORGANISATION_SUCCESS: 'GET_ORGANISATION_SUCCESS',
    GET_ORGANISATION_FAILURE: 'GET_ORGANISATION_FAILURE',
    DELETE_FORCE_REQUEST: 'DELETE_FORCE_REQUEST',
    DELETE_FORCE_SUCCESS: 'DELETE_FORCE_SUCCESS',
    DELETE_FORCE_FAILURE: 'DELETE_FORCE_FAILURE',
    DELETE_AREA_REQUEST: 'DELETE_AREA_REQUEST',
    DELETE_AREA_SUCCESS: 'DELETE_AREA_SUCCESS',
    DELETE_AREA_FAILURE: 'DELETE_AREA_FAILURE',
    DELETE_TEAM_REQUEST: 'DELETE_TEAM_REQUEST',
    DELETE_TEAM_SUCCESS: 'DELETE_TEAM_SUCCESS',
    DELETE_TEAM_FAILURE: 'DELETE_TEAM_FAILURE',
  };
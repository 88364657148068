import { toast } from "react-toastify";
import constants from "./constants";
import { AddressService, NominalService, CheckinService } from "../../services";

function getMyNominalData(querystring, callback) {
  function request() {
    return { type: constants.MY_NOMINAL_REQUEST };
  }
  function success() {
    return { type: constants.MY_NOMINAL_SUCCESS };
  }
  function failure(error) {
    return { type: constants.MY_NOMINAL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.getNominalOdata(querystring).then(
      (response) => {
        dispatch(success());
        callback(response.data.value);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function getMyNominalLocationData(querystring, callback) {
  function request() {
    return { type: constants.MY_NOMINAL_REQUEST };
  }
  function success() {
    return { type: constants.MY_NOMINAL_SUCCESS };
  }
  function failure(error) {
    return { type: constants.MY_NOMINAL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.getNominalLocationOdata(querystring).then(
      (response) => {
        dispatch(success());
        callback(response.data.value);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function getMyNominalCommunicationsData(id, skip, take, callback, nextCallback) {
  function request() {
    return { type: constants.MY_NOMINAL_REQUEST };
  }
  function success() {
    return { type: constants.MY_NOMINAL_SUCCESS };
  }
  function failure(error) {
    return { type: constants.MY_NOMINAL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.getNominalCommunications(id, skip, take).then(
      (response) => {
        dispatch(success());
        callback(response.data, nextCallback);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function getNominalAlerts(id, callback) {
  function request() {
    return { type: constants.NOMINAL_ALERTS_REQUEST };
  }
  function success() {
    return { type: constants.NOMINAL_ALERTS_SUCCESS };
  }
  function failure(error) {
    return { type: constants.NOMINAL_ALERTS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.getNominalAlerts(id).then(
      (response) => {
        dispatch(success());
        if(callback) {
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function getAlertSettings(nominalId, settingsId, callback) {
  const queryString=`NominalId=${nominalId}&SettingsId=${settingsId}`;
  function request() {
    return { type: constants.ALERT_SETTINGS_REQUEST };
  }
  function success(alertSettings) {
    return { type: constants.ALERT_SETTINGS_SUCCESS, alertSettings };
  }
  function failure(error) {
    return { type: constants.ALERT_SETTINGS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.getAlertSettings(queryString).then(
      (response) => {
        dispatch(success(response.data));
        if(callback) {
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function resetAlertSettings(callback) {
  function success(alertSettings) {
    return { type: constants.ALERT_SETTINGS_SUCCESS, alertSettings };
  }

  return (dispatch) => {
    dispatch(success({}));
    if (callback) {
      callback();
    }
  }
}

function getNominalAlertSettings(id, callback) {
  function request() {
    return { type: constants.NOMINAL_ALERT_SETTINGS_REQUEST };
  }
  function success(alertSettings) {
    return { type: constants.NOMINAL_ALERT_SETTINGS_SUCCESS, alertSettings };
  }
  function failure(error) {
    return { type: constants.NOMINAL_ALERT_SETTINGS_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.getNominalAlertSettings(id).then(
      (response) => {
        dispatch(success(response.data));
        callback(response.data);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function closeNominalModal() {
  function close() {
    return { type: constants.CLOSE_NOMINAL_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function closeNominalBasicInfoModal() {
  function close() {
    return { type: constants.CLOSE_NOMINAL_BASIC_INFO_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function getLocationAddress(latlng, callback) {
  function request() {
    return { type: constants.GET_LOCATION_ADDRESS_REQUEST };
  }
  function success(address) {
    return { type: constants.GET_LOCATION_ADDRESS_SUCCESS, address };
  }
  function failure(error) {
    return { type: constants.GET_LOCATION_ADDRESS_FAILURE, error };
  }

  return (dispatch) => {
      dispatch(request());
      AddressService.getLocationAddress(latlng).then(
        (response) => {
          dispatch(success(response.data));

          if(callback){
            callback(response.data);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
  };
}

function getNominalDetail(id, callback = null) {
  function request() {
    return { type: constants.GET_NOMINAL_REQUEST };
  }
  function success(nominal) {
    return { type: constants.GET_NOMINAL_SUCCESS, nominal };
  }
  function failure(error) {
    return { type: constants.GET_NOMINAL_FAILURE, error };
  }

  return (dispatch) => {
      dispatch(request());
      NominalService.getNominalById(id).then(
        (response) => {
          dispatch(success(response.data));

          if(callback){
            callback(response.data);
          }
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
  };
}

function removeNominal(id, callback) {
  function request() {
    return { type: constants.GET_NOMINAL_REQUEST };
  }
  function success(nominal) {
    return { type: constants.GET_NOMINAL_SUCCESS, nominal };
  }
  function failure(error) {
    return { type: constants.GET_NOMINAL_FAILURE, error };
  }

  return (dispatch) => {
      dispatch(request());
      NominalService.removeNominal(id).then(
        (response) => {
          dispatch(success(response.data));
          callback();
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
  };
}

function suspendNominal(id, callback) {
  function request() {
    return { type: constants.GET_NOMINAL_REQUEST };
  }
  function success(nominal) {
    return { type: constants.GET_NOMINAL_SUCCESS, nominal };
  }
  function failure(error) {
    return { type: constants.GET_NOMINAL_FAILURE, error };
  }

  return (dispatch) => {
      dispatch(request());
      NominalService.suspendNominal(id).then(
        (response) => {
          dispatch(success(response.data));
          callback();
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
  };
}

function unsuspendNominal(id, callback) {
  function request() {
    return { type: constants.GET_NOMINAL_REQUEST };
  }
  function success(nominal) {
    return { type: constants.GET_NOMINAL_SUCCESS, nominal };
  }
  function failure(error) {
    return { type: constants.GET_NOMINAL_FAILURE, error };
  }

  return (dispatch) => {
      dispatch(request());
      NominalService.unsuspendNominal(id).then(
        (response) => {
          dispatch(success(response.data));
          callback();
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
  };
}

function getNominalBasicDetail(id, callback) {
  function request() {
    return { type: constants.GET_NOMINAL_BASICINFO_REQUEST };
  }
  function success(nominal) {
    return { type: constants.GET_NOMINAL_BASICINFO_SUCCESS, nominal };
  }
  function failure(error) {
    return { type: constants.GET_NOMINAL_BASICINFO_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.getNominalById(id).then(
      (response) => {
        dispatch(success(response.data));
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function openAddNominalModal() {
  function open() {
    return { type: constants.OPEN_ADD_NOMINAL_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function openNominalBasicInfoModal() {
  function open() {
    return { type: constants.OPEN_NOMINAL_BASIC_INFO_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function openNominalDeviceInfoModal() {
  function open() {
    return { type: constants.OPEN_NOMINAL_DEVICE_INFO_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function openNominalAdditionalInfoModal() {
  function open() {
    return { type: constants.OPEN_NOMINAL_ADDITIONAL_INFO_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function openContactNominalModal() {
  function open() {
    return { type: constants.OPEN_CONTACT_NOMINAL_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function openExclusionZoneModal() {
  function open() {
    return { type: constants.OPEN_EXCLUSION_ZONE_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}


function openCheckinAlertModal() {
  function open() {
    return { type: constants.OPEN_CHECKIN_ALERT_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function openCurfewAlertModal() {
  function open() {
    return { type: constants.OPEN_CURFEW_ALERT_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function openLocationFailModal() {
  function open() {
    return { type: constants.OPEN_LOCATION_FAIL_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function openIdleSettingsModal() {
  function open() {
    return { type: constants.OPEN_IDLE_SETTINGS_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function openAddNominalLocationModal() {
  function open() {
    return { type: constants.OPEN_ADD_NOMINAL_LOCATION_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function linkDevice(code, callback) {
  function request() {
    return { type: constants.LINK_DEVICE_REQUEST };
  }
  function success() {
    return { type: constants.LINK_DEVICE_SUCCESS };
  }
  function failure(error) {
    return { type: constants.LINK_DEVICE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.validateConsumerId(code).then(
      (response) => {
        dispatch(success());
        callback(response.data);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function contactNominal(data, callback) {
  function request() {
    return { type: constants.CONTACT_NOMINAL_REQUEST };
  }
  function success() {
    return { type: constants.CONTACT_NOMINAL_SUCCESS };
  }
  function failure(error) {
    return { type: constants.CONTACT_NOMINAL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.contactNominal(data).then(
      (response) => {
        if(response.data.HasError)
        {
          dispatch(failure(response.data.Error));
          toast.error(response.data.Error);
        }
        else{
          dispatch(success());
          toast("Successfully sent nominal contact request");
        }        
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
        callback();
      }
    );
  };

}

function addNominalLocation(data) {
  function request() {
    return { type: constants.ADD_NOMINAL_LOCATION_REQUEST };
  }
  function success() {
    return { type: constants.ADD_NOMINAL_LOCATION_SUCCESS };
  }
  function failure(error) {
    return { type: constants.ADD_NOMINAL_LOCATION_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.addNominalLocation(data).then(
      (response) => {
        if(response.data.HasError)
        {
          dispatch(failure(response.data.Error));
          toast.error(response.data.Error);
        }
        else{
          dispatch(success());
          toast("Successfully sent nominal contact request");
        }        
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
      }
    );
  };

}

function createNominal(data, callback) {
  function request() {
    return { type: constants.MY_NOMINAL_CREATE_REQUEST };
  }
  function success() {
    return { type: constants.MY_NOMINAL_CREATE_SUCCESS };
  }
  function failure(error) {
    return { type: constants.MY_NOMINAL_CREATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.createNominal(data).then(
      (response) => {
        if(response.data.HasError)
        {
          dispatch(failure(response.data.Error));
          toast.error(response.data.Error);
        }
        else{
          dispatch(success());
          toast("Successfully create nominal");
        }        
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error(error.toString());
        callback();
      }
    );
  };
}

function updateNominalBasicInfo(data, callback) {
  function request() {
    return { type: constants.NOMINAL_BASICINFO_UPDATE_REQUEST };
  }
  function success(nominal) {
    return { type: constants.NOMINAL_BASICINFO_UPDATE_SUCCESS, nominal };
  }
  function failure(error) {
    return { type: constants.NOMINAL_BASICINFO_UPDATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.updateNominalBasicInfo(data).then(
      (response) => {
        dispatch(success(response.data));
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function updateNominalDeviceInfo(data, callback) {
  function request() {
    return { type: constants.NOMINAL_DEVICEINFO_UPDATE_REQUEST };
  }
  function success(nominal) {
    return { type: constants.NOMINAL_DEVICEINFO_UPDATE_SUCCESS, nominal };
  }
  function failure(error) {
    return { type: constants.NOMINAL_DEVICEINFO_UPDATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.updateNominalDeviceInfo(data).then(
      (response) => {
        dispatch(success(response.data));
        callback(response.data);
      },
      (error) => {
        dispatch(failure(error.toString()));
        callback(error);
      }
    );
  };
}

function updateNominalAdditionalInfo(data, callback) {
  function request() {
    return { type: constants.NOMINAL_ADDITIONALINFO_UPDATE_REQUEST };
  }
  function success(nominal) {
    return { type: constants.NOMINAL_ADDITIONALINFO_UPDATE_SUCCESS, nominal };
  }
  function failure(error) {
    return { type: constants.NOMINAL_ADDITIONALINFO_UPDATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.updateNominalAdditionalInfo(data).then(
      (response) => {
        dispatch(success(response.data));
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function getNominalExclusionZoneTypes(data, callback) {
  function request() {
    return { type: constants.GET_EXCLUSION_ZONE_REQUEST };
  }
  function success(nominal) {
    return { type: constants.GET_EXCLUSION_ZONE_SUCCESS, nominal };
  }
  function failure(error) {
    return { type: constants.GET_EXCLUSION_ZONE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.getNominalExclusionZoneTypes().then(
      (response) => {
        dispatch(success(response.data));
                
        if(callback)
        {
          callback(data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function createNominalExclusionZone(data, callback) {
  function request() {
    return { type: constants.CREATE_EXCLUSION_ZONE_REQUEST };
  }
  function success(exclusionZone) {
    return { type: constants.CREATE_EXCLUSION_ZONE_SUCCESS, exclusionZone };
  }
  function failure(error) {
    return { type: constants.CREATE_EXCLUSION_ZONE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.createNominalExclusionZone(data).then(
      (response) => {
        dispatch(success(response.data));
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        if(error.response.data?.Error) {
          toast.error(error.response.data.Error);
        }
        dispatch(failure(error.toString()));
      }
    );
  };
}

function updateNominalExclusionZone(data, callback) {
  function request() {
    return { type: constants.CREATE_EXCLUSION_ZONE_REQUEST };
  }
  function success(exclusionZone) {
    return { type: constants.CREATE_EXCLUSION_ZONE_SUCCESS, exclusionZone };
  }
  function failure(error) {
    return { type: constants.CREATE_EXCLUSION_ZONE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.updateNominalExclusionZone(data).then(
      (response) => {
        dispatch(success(response.data));
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        if(error.response.data?.Error) {
          toast.error(error.response.data.Error);
        }
        dispatch(failure(error.toString()));
      }
    );
  };
}

function deleteExclusionZoneFromNominal(id, callback) {
  function request() {
    return { type: constants.DELETE_NOMINAL_EXCLUSION_ZONE_REQUEST };
  }
  function success(exclusionZone) {
    return { type: constants.DELETE_NOMINAL_EXCLUSION_ZONE_SUCCESS, exclusionZone };
  }
  function failure(error) {
    return { type: constants.DELETE_NOMINAL_EXCLUSION_ZONE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.deleteExclusionZoneFromNominal(id).then(
      (response) => {
        dispatch(success(response.data));
        console.log(response);
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };

}

function deleteCurfewFromNominal(data, callback) {
  function request() {
    return { type: constants.DELETE_NOMINAL_CURFEW_REQUEST };
  }
  function success(curfew) {
    return { type: constants.DELETE_NOMINAL_CURFEW_SUCCESS, curfew };
  }
  function failure(error) {
    return { type: constants.DELETE_NOMINAL_CURFEW_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.deleteCurfewFromNominal(data).then(
      (response) => {
        dispatch(success(response.data));
        console.log(response);
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function createNominalCurfew(data, callback) {
  function request() {
    return { type: constants.CREATE_CURFEW_REQUEST };
  }
  function success(exclusionZone) {
    return { type: constants.CREATE_CURFEW_SUCCESS, exclusionZone };
  }
  function failure(error) {
    return { type: constants.CREATE_CURFEW_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.createNominalCurfew(data).then(
      (response) => {
        dispatch(success(response.data));
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function updateNominalCurfew(data, callback) {
  function request() {
    return { type: constants.UPDATE_CURFEW_REQUEST };
  }
  function success(exclusionZone) {
    return { type: constants.UPDATE_CURFEW_SUCCESS, exclusionZone };
  }
  function failure(error) {
    return { type: constants.UPDATE_CURFEW_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.updateNominalCurfew(data).then(
      (response) => {
        dispatch(success(response.data));
        console.log(response);
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function createLocationFailSettings(data, callback) {
  function request() {
    return { type: constants.CREATE_LOCATION_FAIL_REQUEST };
  }
  function success(exclusionZone) {
    return { type: constants.CREATE_LOCATION_FAIL_SUCCESS, exclusionZone };
  }
  function failure(error) {
    return { type: constants.CREATE_LOCATION_FAIL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.createLocationFailSettings(data).then(
      (response) => {
        dispatch(success(response.data));
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function updateLocationFailSettings(data, callback) {
  function request() {
    return { type: constants.UPDATE_LOCATION_FAIL_REQUEST };
  }
  function success(exclusionZone) {
    return { type: constants.UPDATE_LOCATION_FAIL_SUCCESS, exclusionZone };
  }
  function failure(error) {
    return { type: constants.UPDATE_LOCATION_FAIL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.updateLocationFailSettings(data).then(
      (response) => {
        dispatch(success(response.data));
        console.log(response);
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function deleteLocationFailSettings(data, callback) {
  function request() {
    return { type: constants.DELETE_LOCATION_FAIL_REQUEST };
  }
  function success(curfew) {
    return { type: constants.DELETE_LOCATION_FAIL_SUCCESS, curfew };
  }
  function failure(error) {
    return { type: constants.DELETE_LOCATION_FAIL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.deleteLocationFailSettings(data).then(
      (response) => {
        dispatch(success(response.data));
        console.log(response);
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function createIdleSettings(data, callback) {
  function request() {
    return { type: constants.CREATE_LOCATION_FAIL_REQUEST };
  }
  function success(exclusionZone) {
    return { type: constants.CREATE_LOCATION_FAIL_SUCCESS, exclusionZone };
  }
  function failure(error) {
    return { type: constants.CREATE_LOCATION_FAIL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.createIdleSettings(data).then(
      (response) => {
        dispatch(success(response.data));
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function updateIdleSettings(data, callback) {
  function request() {
    return { type: constants.UPDATE_LOCATION_FAIL_REQUEST };
  }
  function success(exclusionZone) {
    return { type: constants.UPDATE_LOCATION_FAIL_SUCCESS, exclusionZone };
  }
  function failure(error) {
    return { type: constants.UPDATE_LOCATION_FAIL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.updateIdleSettings(data).then(
      (response) => {
        dispatch(success(response.data));
        console.log(response);
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function deleteIdleSettings(data, callback) {
  function request() {
    return { type: constants.DELETE_LOCATION_FAIL_REQUEST };
  }
  function success(curfew) {
    return { type: constants.DELETE_LOCATION_FAIL_SUCCESS, curfew };
  }
  function failure(error) {
    return { type: constants.DELETE_LOCATION_FAIL_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.deleteIdleSettings(data).then(
      (response) => {
        dispatch(success(response.data));
        console.log(response);
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function createCheckInSettings(data, callback) {
  function request() {
    return { type: constants.CREATE_CHECKIN_REQUEST };
  }
  function success(checkinSettings) {
    return { type: constants.CREATE_CHECKIN_SUCCESS, checkinSettings };
  }
  function failure(error) {
    return { type: constants.CREATE_CHECKIN_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    CheckinService.postSettings(data).then(
      (response) => {
        dispatch(success(response.data));
        // Reload nominal data
        dispatch(getNominalDetail(data.NominalId));
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function getCheckinHistory(data, callback) {
  function request() {
    return { type: constants.GET_CHECKIN_REQUEST };
  }
  function success(checkin) {
    return { type: constants.GET_CHECKIN_SUCCESS, checkin };
  }
  function failure(error) {
    return { type: constants.GET_CHECKIN_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    CheckinService.getCheckinHistory(data).then(
      (response) => {
        dispatch(success(response.data.value));
        if(callback){
          callback(response.data);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function transferNominal(data,callback)
{
  function request() {
    return { type: constants.TRANSFER_REQUEST };
  }
  function success() {
    return { type: constants.TRANSFER_SUCCESS };
  }
  function failure(error) {
    return { type: constants.TRANSFER_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.transferNominal(data).then(
      (response) => {
        dispatch(success());
        if(response.data.HasError === true){
          toast.error(response.data.Error);         
        }
        else{
          toast("Successfully transferred nominal");
          callback();          
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Unable to transfer nominal, please try again");
      }
    );
  };
}

function suspendDevice(id,callback)
{
  function request() {
    return { type: constants.SUSPEND_REQUEST };
  }
  function success() {
    return { type: constants.SUSPEND_SUCCESS };
  }
  function failure(error) {
    return { type: constants.SUSPEND_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.suspendDevice(id).then(
      (response) => {
        dispatch(success());
        if(response.data.HasError === true){
          toast.error(response.data.Error);         
        }
        else{
          toast("Successfully suspended device");
          callback();          
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Unable to suspend device, please try again");
      }
    );
  };
}

function unsuspendDevice(id,callback)
{
  function request() {
    return { type: constants.SUSPEND_REQUEST };
  }
  function success() {
    return { type: constants.SUSPEND_SUCCESS };
  }
  function failure(error) {
    return { type: constants.SUSPEND_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.unsuspendDevice(id).then(
      (response) => {
        dispatch(success());
        if(response.data.HasError === true){
          toast.error(response.data.Error);         
        }
        else{
          toast("Successfully activated device");
          callback();          
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("Unable to activate device, please try again");
      }
    );
  };
}

export default {
  getMyNominalData,
  getMyNominalLocationData,
  getNominalDetail,
  getNominalBasicDetail,
  closeNominalModal,
  closeNominalBasicInfoModal,
  openAddNominalModal,
  openContactNominalModal,
  openExclusionZoneModal,
  openCheckinAlertModal,
  openCurfewAlertModal,
  openLocationFailModal,
  openIdleSettingsModal,
  openAddNominalLocationModal,
  openNominalBasicInfoModal,
  linkDevice,
  createNominal,
  removeNominal,
  contactNominal,
  addNominalLocation,
  updateNominalBasicInfo,
  openNominalDeviceInfoModal,
  openNominalAdditionalInfoModal,
  updateNominalDeviceInfo,
  updateNominalAdditionalInfo,
  getMyNominalCommunicationsData,
  createNominalExclusionZone,
  updateNominalExclusionZone,
  getNominalExclusionZoneTypes,
  createNominalCurfew,
  updateNominalCurfew,
  getNominalAlerts,
  getNominalAlertSettings,
  getAlertSettings,
  resetAlertSettings,
  getLocationAddress,
  deleteExclusionZoneFromNominal,
  deleteCurfewFromNominal,
  suspendNominal,
  unsuspendNominal,
  createLocationFailSettings,
  updateLocationFailSettings,
  deleteLocationFailSettings,
  createIdleSettings,
  updateIdleSettings,
  deleteIdleSettings,
  createCheckInSettings,
  getCheckinHistory,
  transferNominal,
  suspendDevice,
  unsuspendDevice,
}
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { push } from "connected-react-router";
import { withAuth0 } from "@auth0/auth0-react";
import { Layout, Menu, Row, Col, Badge } from "antd";
import {
  faUserCircle,
  faChevronDown,
  faUser,
  faBell,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import SOSLogo from "../../assets/img/tecsos_logo.png";
import SOSLogo from "../../assets/img/TAMS_Inverted_Logo_from_tecSOS_resized.png";
// import Actions from "../../../features/account/actions";
import userActions from "../../../features/Users/actions";
import SideNav from "../side-nav";
import { ProfileModal } from "../../../features/profile/modals";
import alertService from "../../../services/Alert";
import { hasRights, rights } from "../../utils/role-right-helper";
import LogoutMenuItem from "./LogoutMenuItem";
// import "./MainLayout.scss";

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class MainLayout extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      alertCount: 0,
      user: {},
    };
  }

  componentDidMount() {
    const CHECK_INTERVAL = 10000; // in ms
    setTimeout(() => this.checkSession(),CHECK_INTERVAL);

    this.getAlertCount();
    this.getUserData();
  }

  getUserData = () => {
    /* eslint-disable-next-line react/prop-types */
    this.props.dispatch(
      userActions.getUserDetails(this.props.currentUser.Id, this.setUserData)
    );
  };

  setUserData = (data) => {
    this.setState({
      user: data,
    });
  };

  handleProfileClick = (eventKey) => {
    const { dispatch } = this.props;
    switch (eventKey.key) {
      case "profile":
        dispatch(userActions.openProfileModal());
        break;
      case "logout":
        console.log("logout?");
        this.props.auth0.logout();
        // dispatch(Actions.Logout());
        // dispatch(push("/login"));
        break;
      default:
        console.log("default...");
        this.props.auth0.logout();
        // dispatch(push("/login"));
    }
  };

  getAlertCount = () => {
    alertService.getMyAlertCount().then((response) => {
      this.setState({
        alertCount: response,
      });

      setTimeout(this.getAlertCount, 60000);
    });
  };

  checkSession = ()  => {
    const date = new Date();
    if (window.lastApiAction >= new Date(date.getTime() - 10*60000)) {
      this.props.auth0.getAccessTokenSilently().then(() => {
        console.log("checking token...");
        const CHECK_INTERVAL = 10000; // in ms
        setTimeout(() => this.checkSession(),CHECK_INTERVAL);
      }).catch((e) => {
        console.log("in the catch: ", e)
        if (e.error === 'login_required') {
          this.props.auth0.logout();
        }
      });
    } else {
      this.props.auth0.logout();
    }
  }

  render() {
    const { Header, Content } = Layout;
    const { SubMenu } = Menu;

    const ProfileIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faUserCircle}
          id="ProfileInfo"
          className="top-nav-icon"
          alt="ProfileIcon"
        />
      </span>
    );

    const ChevronDownIcon = (
      <span>
        <span
          style={{ marginRight: "10px", fontSize: "18px", coloe: "#F0F4F8" }}
        >
          {this.props.currentUser.UserName}
        </span>
        <span className="anticon" role="img">
          <FontAwesomeIcon
            icon={faChevronDown}
            id="ChevronDown"
            className="top-nav-secondary-icon"
            alt="ChevronDownIcon"
          />
        </span>
      </span>
    );

    const userIcon = (
      <span className="anticon" role="img">
        <FontAwesomeIcon
          icon={faUser}
          id="Users"
          alt="UserIcon"
          className="side-nav-icon"
        />
      </span>
    );

    return (
      <div className="MainContainer">
        <Layout>
          <Header>
            <div>
              <div className="logo-container">
                <Link to="/">
                  <img
                    src={SOSLogo}
                    alt="TechSOSLogo"
                    className="logoimg"
                    style={{ maxHeight: "100%", maxWidth: "100%" }}
                  />
                </Link>
              </div>

              <Row className="top-nav-items">
                {hasRights([rights.CanViewAsOM]) && (
                  <Col>
                    <span className="anticon notificationIcon" role="img">
                      <Link to="/mynominals">
                        <Badge count={this.state.alertCount}>
                          <FontAwesomeIcon
                            icon={faBell}
                            id="Bell"
                            className="top-nav-icon"
                          />
                        </Badge>
                      </Link>
                    </span>
                  </Col>
                )}
                <Col>
                  <Menu
                    selectedKeys={[this.props.currentPage]}
                    mode="horizontal"
                  >
                    <SubMenu
                      key="Profile"
                      icon={ProfileIcon}
                      title={ChevronDownIcon}
                    >
                      <Menu.ItemGroup>
                        <Menu.Item
                          key="profile"
                          icon={userIcon}
                          onClick={(e) => this.handleProfileClick(e)}
                        >
                          <span>Profile</span>
                        </Menu.Item>
                        <LogoutMenuItem key="logout" />
                      </Menu.ItemGroup>
                    </SubMenu>
                  </Menu>
                </Col>
              </Row>
            </div>
          </Header>
          {this.props.PageHeader}
          <Layout>
            <SideNav />
            <Content>
              {this.props.children}
              <ProfileModal
                dispatch={this.props.dispatch}
                userData={this.state.user}
                userUpdateCallback={this.getUserData}
              />
            </Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  dispatch: () => {},
  currentUser: PropTypes.shape({
    UserName: PropTypes.string,
  }),
  auth0: PropTypes.shape(),
};

MainLayout.defaultProps = {
  dispatch: () => {},
  currentUser: {
    UserName: "",
  },
  auth0: {},
};

function mapStateToProps(state) {
  const { currentUser } = state.Auth;
  const { currentPage } = state.Navigation;
  return {
    currentUser,
    currentPage,
  };
}

const connectedMainLayout = connect(mapStateToProps)(MainLayout);

export default withAuth0(connectedMainLayout);

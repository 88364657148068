import React from "react";
import { connect } from "react-redux";
import "antd/dist/antd.css";
import { Layout, Row, Col } from "antd";
import "./PageHeader.scss";

// eslint-disable require-default-props
/* eslint react/prop-types: 0 */
class PageHeader extends React.PureComponent {
  render() {
    const { Content } = Layout;

    return (
      <div className="PageHeader">
        {this.props.title && (
          <Content>
            <Row>
              <Col lg={6} xs={24}>
                <div className="pg-header-title">
                  <span>{this.props.title}</span>
                </div>
              </Col>
            </Row>
          </Content>
        )}
        {this.props.customHeader}
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
}

const connectedPageHeader = connect(mapStateToProps)(PageHeader);

export { connectedPageHeader as default };

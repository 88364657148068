import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col } from "antd";

import { TeamForm } from "../forms";
import actions from "../actions";

class TeamModal extends React.Component {
  CloseTeamModal = () => {
    this.props.dispatch(actions.closeTeamModal());
    this.props.refreshGrid();
  }

  TeamUpdate = (data) => {
    this.props.dispatch(actions.updateTeam(data, this.CloseTeamModal));
  }

  TeamCreate = (data) => {
    this.props.dispatch(actions.createTeam(data, this.CloseTeamModal));
  }
  
  render() {
    return (
      <>
        <Modal title="Edit Team" visible={this.props.showEditModal} onCancel={this.CloseTeamModal} footer={null} width={500} destroyOnClose>
          <div>
            <Row>
              <Col span={24}>  
                <TeamForm onCancel={this.CloseTeamModal} onSubmit={this.TeamUpdate} isLoading={this.props.loading} isEdit {...this.props.team} />
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal title="Add Team" visible={this.props.showAddModal} onCancel={this.CloseTeamModal} footer={null} width={500} destroyOnClose>
          <div>
            <Row>
              <Col span={24}>  
                <TeamForm defaultForce={this.props.defaultForce} onCancel={this.CloseTeamModal} onSubmit={this.TeamCreate} isLoading={this.props.loading} {...this.props.team} />
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

TeamModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  showEditModal: PropTypes.bool,
  showAddModal: PropTypes.bool,
  loading: PropTypes.bool,
  team: PropTypes.shape({}),
  defaultForce: PropTypes.number,
};

TeamModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  showEditModal: false,
  showAddModal: false,
  loading: false,
  team: {},
  defaultForce: null,
};

function mapStateToProps(state) {
  const { loading, team } = state.Organisation;
  const { showEditModal, showAddModal, defaultForce } = state.Organisation.TeamModal;

  return { showEditModal, loading, team, showAddModal, defaultForce};
}

const connectedTeamModal = connect(mapStateToProps)(TeamModal);

export { connectedTeamModal as default };

import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Table, Row, Col, Tooltip, Tag, Button, DatePicker } from "antd";
import moment from "moment";
import { faBell, faBan } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BulkTransferNominalModal } from '../modals';
import actions from "../actions";
import { IconButtons, MosovoModals } from "../../../common/components";
import { BatteryIcons } from "../../../common/components/icon-buttons";
import AlertModal from "../../../common/components/alert-modal";
import modalactions from "../../../common/components/mosovo-modals/actions";
import {
  getColumnSearchProps,
  nominalsGridAlertSorter,
  NotificationTypes,
  getNominalsGridNotificationAlertsFilter,
  RiskTypes,
  getNominalsGridRiskTypeFilter,
  NominalTypes,
  getNominalsGridNominalTypeFilter,
  numericSorter,
  dateSorter,
  stringSorter,
  INPUT,
  DATE
} from "../../../common/utils/grid-filter-helper";
import { hasRights, rights } from "../../../common/utils/role-right-helper";

// import actions from "../actions";
const { RangePicker } = DatePicker;
const { SearchBox, EditUserButton, CommentCircleIconButton, AlertCircleIconButton } = IconButtons;
const { BulkMessageNotificationModal, BulkAlertResolveModal } = MosovoModals;
const {
  BatteryFull,
  BatteryThreeQuarter,
  BatteryHalf,
  BatteryQuarter,
  BatteryEmtpy,
} = BatteryIcons;

class AreaNominalGrid extends React.Component {
  constructor() {
    super();

    this.state = {
      selectedRowKeys: [],
      AlertId: 0,
      AlertIdList: [],
      AlertModalVisible: false,
      SelectedNominal: null,
    };
  }

  openAlerts = (alertIds, record) => {
    this.setState({
      AlertModalVisible: true,
      AlertId: alertIds[0],
      AlertIdList: alertIds,
      SelectedNominal: record,
    });
  };

  resolveAlert = () => {
    this.setState((prevState) => ({
      AlertId: prevState.AlertIdList.filter((x) => x !== prevState.AlertId)[0],
      AlertIdList: prevState.AlertIdList.filter((x) => x !== prevState.AlertId),
      AlertModalVisible:
        prevState.AlertIdList.filter((x) => x !== prevState.AlertId).length > 0,
      SelectedNominal:
        prevState.AlertIdList.filter((x) => x !== prevState.AlertId).length > 0
          ? prevState.SelectedNominal
          : null,
    }));
    this.props.refreshGrid();
  };

  closeModal = () => {
    this.setState({
      AlertId: 0,
      AlertIdList: [],
      AlertModalVisible: false,
      SelectedNominal: null,
    });
  };

  openTransferNominalModal = () => {
    this.props.dispatch(actions.openTransferNominalModal());
  }

  openMessageBulkModal = () => {
    this.props.dispatch(modalactions.openBulkMessageModal());
  }

  openBulkAlertResolveModal = () => {
    this.props.dispatch(modalactions.openBulkAlertModal());
  }

  getRiskType = (record) => {
    const riskId = record.RiskLevelId;
    if (riskId === RiskTypes.VH) {
      return (
        <span>
          <Tooltip title="Very High">
            <Tag className={record.Active ? "tag-vh" : "tag-nt"}>VH</Tag>
          </Tooltip>
        </span>
      );
    }

    if (riskId === RiskTypes.H) {
      return (
        <span>
          <Tooltip title="High">
            <Tag className={record.Active ? "tag-vh" : "tag-nt"}>H</Tag>
          </Tooltip>
        </span>
      );
    }

    if (riskId === RiskTypes.M) {
      return (
        <span>
          <Tooltip title="Medium">
            <Tag className={record.Active ? "tag-m" : "tag-nt"}>M</Tag>
          </Tooltip>
        </span>
      );
    }

    return (
      <span>
        <Tooltip title="Low">
          <Tag className={record.Active ? "tag-l" : "tag-nt"}>L</Tag>
        </Tooltip>
      </span>
    );
  };

  onSearchDate = (value) => {
    if (value && value.length === 2) {
      const startDate = value[0].format("YYYY-MM-DD");
      const endDate = value[1].format("YYYY-MM-DD");
      this.props.onSearch({startDate, endDate, dateField: 'RegisteredDate'});
    } else {
      // This is needed as the filters persist incase one is cleared. This just makes the date filter range large. 
      const startDate = moment('1/1/1900').format("YYYY-MM-DD");
      const endDate = moment('1/1/3000').format("YYYY-MM-DD");
      this.props.onSearch({startDate, endDate, dateField: 'RegisteredDate'});
    }
  }

  ChangeIndex = (currentIndex, changeAmount) => {
    const newIndex = currentIndex + changeAmount;
    this.setState((prevState) => ({
      AlertId: prevState.AlertIdList[newIndex],
    }));
  };

  getNotificationType = (record) => {
    const alertIds = record.AlertIds
      ? record.AlertIds.split(", ").map((x) => parseInt(x, 10))
      : null;
    const alertCount = alertIds ? alertIds.length : null;

    const alertIcon = (
      <Tooltip
        title={`${alertCount} alert${alertCount > 1 ? "s" : ""}, Click to view`}
      >
        <Button
          type="text"
          onClick={() => this.openAlerts(alertIds, record)}
          className="alertIconButton"
        >
          <FontAwesomeIcon
            icon={faBell}
            id="alertBell"
            className="redAlertIcon"
          />
        </Button>
      </Tooltip>
    );
    const suspendedIcon = (
      <Tooltip title="Nominal is suspended">
        <FontAwesomeIcon icon={faBan} id="banIcon" className="banIcon" />
      </Tooltip>
    );

    switch (record.NominalTypeId) {
      case NotificationTypes.E:
        return (
          <span>
            <Tooltip title="Email">
              <Tag className="tag-nt">E</Tag>
            </Tooltip>
            {record.Active ? alertCount > 0 && alertIcon : suspendedIcon}
          </span>
        );
      case NotificationTypes.T:
        return (
          <span>
            <Tooltip title="Text Message">
              <Tag className="tag-nt">T</Tag>
            </Tooltip>
            {record.Active ? alertCount > 0 && alertIcon : suspendedIcon}
          </span>
        );
      default:
        return (
          <span>
            <Tooltip title="Other">
              <Tag className="tag-nt">O</Tag>
            </Tooltip>
            {record.Active ? alertCount > 0 && alertIcon : suspendedIcon}
          </span>
        );
    }
  };

  getNominalTypeString = (typeId) => {
    if (typeId === NominalTypes.S) {
      return 'S';
    }

    if (typeId === NominalTypes.V) {
      return 'V';
    }

    return 'O';
  };

  getNominalType = (typeId) => {
    if (typeId === NominalTypes.S) {
      return (
        <span>
          <Tooltip title="Sex Offender">
            <Tag className="tag-nt">S</Tag>
          </Tooltip>
        </span>
      );
    }

    if (typeId === NominalTypes.V) {
      return (
        <span>
          <Tooltip title="Violent Offender">
            <Tag className="tag-nt">V</Tag>
          </Tooltip>
        </span>
      );
    }

    return (
      <span>
        <Tooltip title="Other">
          <Tag className="tag-nt">O</Tag>
        </Tooltip>
      </span>
    );
  };

  getBatteryIcon = (level) => {
    if (level < 5) return BatteryEmtpy;
    if (level <= 25) return BatteryQuarter;
    if (level <= 50) return BatteryHalf;
    if (level <= 75) return BatteryThreeQuarter;

    return BatteryFull;
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const gridColumns = [
      {
        title: "Notification",
        dataIndex: "Notification",
        key: "Notification",
        sorter: (a, b) => {return nominalsGridAlertSorter(a, b);},
        sortDirections: ["descend", "ascend"],
        ...getNominalsGridNotificationAlertsFilter(true),
        render: (text, record) =>
          record.NominalTypeId !== null ? (
            <>{this.getNotificationType(record)}</>
          ) : null,
      },
      {
        title: "Full Name",
        dataIndex: "FullName",
        key: "FullName",
        sorter: (a, b) => {return stringSorter(a, b, "FullName");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "FullName", title: "Full Name", componentType: INPUT}),
        render: (text, record) =>
          record.FullName !== null ? (
            <>
              <span>
                <Link
                  to={{
                    pathname: `Nominals/${record.Id}`,
                    state: { from: "Area Nominals" },
                  }}
                >
                  {record.FullName}
                </Link>
              </span>
            </>
          ) : null,
      },
      {
        title: "DateOfBirth",
        dataIndex: "DateOfBirth",
        key: "DateOfBirth",
        sorter: (a, b) => {return dateSorter(a, b, "DateOfBirth");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "DateOfBirth", title: "DateOfBirth", componentType: DATE}),
        render: (text, record) =>
          this.props.myNominalList.length >= 1 ? (
            <>
              <span
                style={{
                  color: record.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                  fontStyle: record.Active ? "normal" : "italic",
                }}
              >
                {moment(record.DateOfBirth).format("DD-MM-YYYY")}
              </span>
            </>
          ) : null,
      },
      {
        title: "Risk",
        dataIndex: "RiskLevel",
        key: "RiskLevel",
        sorter: (a, b) => {return numericSorter(a, b, "RiskLevelId");},
        sortDirections: ["descend", "ascend"],
        ...getNominalsGridRiskTypeFilter(),
        render: (text, record) =>
          record.RiskLevelId !== null ? <>{this.getRiskType(record)}</> : null,
      },
      {
        title: "Type",
        dataIndex: "NominalTypeId",
        key: "NominalTypeId",
        sorter: (a, b) => this.getNominalTypeString(a.NominalTypeId).localeCompare(this.getNominalTypeString(b.NominalTypeId)),
        sortDirections: ["descend", "ascend"],
        ...getNominalsGridNominalTypeFilter(),
        render: (text, record) =>
          record.NominalTypeId !== null ? (
            <>{this.getNominalType(record.NominalTypeId)}</>
          ) : null,
      },
      {
        title: "Offender Manager",
        dataIndex: "OffenderManager",
        key: "OffenderManager",
        sorter: (a, b) => {return stringSorter(a, b, "OffenderManager");},
        ...getColumnSearchProps({ dataIndex: "OffenderManager", title: "OM", componentType: INPUT}),
        render: (text,record) => (
          <>
            <span
              style={{
                color: record.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                fontStyle: record.Active ? "normal" : "italic",
              }}
            >
              {record.OffenderManager}
            </span>
          </>
        ),
      },
      {
        title: "Team",
        dataIndex: "TeamName",
        key: "TeamName",
        sorter: (a, b) => {return stringSorter(a, b, "TeamName");},
        ...getColumnSearchProps({ dataIndex: "TeamName", title: "Team", componentType: INPUT}),
        render: (text,record) => (
          <>
            <span
              style={{
                color: record.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                fontStyle: record.Active ? "normal" : "italic",
              }}
            >
              {record.TeamName}
            </span>
          </>
        ),
      },
    ];

    const { selectedRowKeys } = this.state;
    // eslint-disable-next-line no-unused-vars
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row>
          <Col xs={12} md={6} lg={6}>
            <span className="mosovo-table-header">Area Nominals</span>
          </Col>
          <Col xs={12} md={6} lg={6} justify="end">
            <SearchBox
              placeholder="Search by name"
              allowClear
              onSearch={this.props.onSearch}
              minText={3}
              loading={this.props.loading}
            />
          </Col>
          <Col xs={2} md={1} lg={1} />
          <Col xs={12} md={6} lg={6}>
            <RangePicker
              className="p5-form-datepicker-input"
              format="DD/MM/YYYY"
              separator=" to "
              onChange={(val) => {
                this.onSearchDate(val);
              }}
              allowClear
            />
          </Col>
          {(hasRights([rights.CanManageForceAdmins])
            || hasRights([rights.CanManageAreaAdmins])
            || hasRights([rights.CanManageTeamAdmins]))
            && (
              <>
                <Col xs={2} sm={2} md={1} lg={1} style={{textAlign: "center"}}>
                  <EditUserButton 
                    onClick={this.openTransferNominalModal}
                    isDisabled={this.state.selectedRowKeys.length === 0}
                    tooltipText="Assign OM"
                  />                
                </Col>
                <Col xs={2} sm={2} md={1} lg={1} style={{textAlign: "center"}}>
                  <AlertCircleIconButton 
                    onClick={this.openBulkAlertResolveModal}
                    isDisabled={this.state.selectedRowKeys.length === 0}
                    tooltipText="Resolve all nominal alerts"
                  />                
                </Col>
                <Col xs={2} sm={2} md={1} lg={1} style={{textAlign: "center"}}>
                  <CommentCircleIconButton 
                    onClick={this.openMessageBulkModal}
                    isDisabled={this.state.selectedRowKeys.length === 0}
                    tooltipText="Send Message"
                  />                
                </Col>
              </>
           )}
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              loading={this.props.loading}
              dataSource={this.props.myNominalList}
              columns={gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
        <AlertModal
          showAlertModal={this.state.AlertModalVisible}
          AlertId={this.state.AlertId}
          AlertIdList={this.state.AlertIdList}
          closeModal={this.closeModal}
          resolveAlert={this.resolveAlert}
          ChangeIndex={this.ChangeIndex}
          Nominal={this.state.SelectedNominal}
        />
        <BulkTransferNominalModal
          selectedNominals={this.state.selectedRowKeys}
          refreshGrid={this.props.refreshGrid}
        />
        <BulkMessageNotificationModal
          selectedNominals={this.state.selectedRowKeys}
          refreshGrid={this.props.refreshGrid}
        />
        <BulkAlertResolveModal 
          selectedNominals={this.state.selectedRowKeys}
          refreshGrid={this.props.refreshGrid}
        />
      </div>
    );
  }
}

AreaNominalGrid.propTypes = {
  dispatch: PropTypes.func,
  onSearch: PropTypes.func,
  myNominalList: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  refreshGrid: PropTypes.func,
};

AreaNominalGrid.defaultProps = {
  dispatch: () => {},
  onSearch: () => {},
  myNominalList: [{}],
  loading: false,
  refreshGrid: () => {},
};

function mapStateToProps(state) {
  const { loading } = state.TeamNominal;
  return {
    loading,
  };
}

const connectedAreaNominalGrid = connect(mapStateToProps)(AreaNominalGrid);

export { connectedAreaNominalGrid as default };

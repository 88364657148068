import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Input, Divider, Spin, Button } from "antd";
import { faEdit } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelBtn, SaveBtn } from "../../../common/components/form";

class ProfileTeamInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isEditable: false,
    };
    this.formIK = React.createRef();
  }

  componentDidUpdate() {
    this.formIK.current.handleReset();
  }

  pivotData = (data) => ({
    Id: this.props.LawEnforcementTeamId,
    Name: this.props.TeamName,
    TeamEmail: data.TeamEmail,
    LawEnforcementAgencyId: this.props.LawEnforcementAgencyId,
    LawEnforcementAreaId: this.props.LawEnforcementAreaId,
    AreaName: this.props.AreaName,
  });

  edit = () => {
    this.setState((prevState) => ({ isEditable: !prevState.isEditable }));
  };

  checkFormErrors = (errors) => {
    return Object.keys(errors).length > 0;
  };

  render() {
    const editTeamInfoButton = () => (
      <Button
        style={{ backgroundColor: "white", color: "#243b53", margin: "5px" }}
        onClick={this.edit}
      >
        <FontAwesomeIcon
          icon={faEdit}
          id="EditTeamDetails"
          className=""
          alt="basic"
        />
      </Button>
    );

    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          TeamEmail: this.props.TeamEmail || "",
        }}
        validationSchema={Yup.object().shape({
          TeamEmail: Yup.string(),
        })}
        // onChange={(values) => this.handleChange(values)}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form
            id="ProfileTeamInfoForm"
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <div>
              <Spin spinning={this.props.loading}>
                <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
                  <Col span={12} className="gutter-row">
                    <h6>Team Information</h6>
                  </Col>
                  {this.props.LawEnforcementTeamId >= 0 && (
                    <Col span={12}>
                      <Row justify="end">
                        <Col>
                          <span>{editTeamInfoButton(this.props.LawEnforcementTeamId)}</span>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
                <Divider />
                <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
                  <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
                    <span>Force:</span>
                  </Col>
                  <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
                    <span
                      style={{
                        color: "rgba(0, 0, 0, 0.85)",
                        fontStyle: "normal",
                      }}
                    >
                      {`${this.props.ForceName || ""}`}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
                    <span>Area:</span>
                  </Col>
                  <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
                    <span
                      style={{
                        color: "rgba(0, 0, 0, 0.85)",
                        fontStyle: "normal",
                      }}
                    >
                      {`${this.props.AreaName || ""}`}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
                    <span>Team:</span>
                  </Col>
                  <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
                    <span
                      style={{
                        color: "rgba(0, 0, 0, 0.85)",
                        fontStyle: "normal",
                      }}
                    >
                      {`${this.props.TeamName || ""}`}
                    </span>
                  </Col>
                </Row>
                <Row>
                  <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
                    <span>Team Email:</span>
                  </Col>
                  <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
                    <Form.Item
                      validateStatus={
                        errors && errors.TeamEmail && touched.TeamEmail
                          ? "error"
                          : "success"
                      }
                      help={
                        errors && errors.TeamEmail && touched.TeamEmail
                          ? errors.TeamEmail
                          : null
                      }
                    >
                      {this.state.isEditable ? (
                        <Input
                          id="TeamEmail"
                          onBlur={handleBlur}
                          value={values.TeamEmail}
                          onChange={(val) => {
                            setFieldValue("Team Email", val.target.value);
                          }}
                        />
                      ) : (
                        <span
                          style={{
                            color: "rgba(0, 0, 0, 0.85)",
                            fontStyle: "normal",
                          }}
                        >
                          {`${this.props.TeamEmail || ""}`}
                        </span>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Divider plain />
                <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                  <Col xs={24} sm={8} md={6} className="gutter-row">
                    <CancelBtn onClick={this.props.onCancel} />
                  </Col>
                  {this.state.isEditable && (
                    <Col xs={24} sm={8} md={6} className="gutter-row">
                      <SaveBtn
                        onClick={() => this.props.onSubmit(this.pivotData(values))}
                        loading={this.props.loading}
                        isDisabled={this.checkFormErrors(errors)}
                      />
                    </Col>
                  )}
                </Row>
              </Spin>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

ProfileTeamInfo.propTypes = {
  onSubmit: PropTypes.func, // .isRequired,
  onCancel: PropTypes.func,
  loading: PropTypes.bool,
  ForceName: PropTypes.string,
  AreaName: PropTypes.string,
  TeamName: PropTypes.string,
  TeamEmail: PropTypes.string,
  LawEnforcementAgencyId: PropTypes.number,
  LawEnforcementAreaId: PropTypes.number,
  LawEnforcementTeamId: PropTypes.number,
};

ProfileTeamInfo.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
 loading: false,
  ForceName: "",
  AreaName: "",
  TeamName: "",
  TeamEmail: "",
  LawEnforcementAgencyId: null,
  LawEnforcementAreaId: null,
  LawEnforcementTeamId: null,
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;

  return { loading };
}

const connectedProfileTeamInfo = connect(mapStateToProps)(ProfileTeamInfo);

export { connectedProfileTeamInfo as default };

import React, { useState } from "react";
import { Row, Col, Divider, Spin, Button } from "antd";
import useUserLogsContext from "../hooks/useProfileManagementContext";
import ProfileActivityLogGrid from "./ProfileActivityLogGrid";

const ProfileActivityLogs = () => {
  const { loading, resetPassword } = useUserLogsContext();
  const [hasResetPassword, setHasResetPassword] = useState(false);

  const handleResetPasswordClick = () => {
    setHasResetPassword(true);
    resetPassword();
  };

  return (
    <>
      <Spin spinning={loading}>
        <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
          <Col span={24} className="gutter-row">
            <h6>Account Activity</h6>
            <p>
              If you notice any suspicious activity on your account, please
              reset your password immediately to end all suspicious sessions.
            </p>
            {!hasResetPassword && (
              <Button onClick={handleResetPasswordClick}>Reset Password</Button>
            )}
            {hasResetPassword && (
              <p>
                <i>
                  You should recieve an email to reset your password
                  momentarily.
                </i>
              </p>
            )}
          </Col>
        </Row>
        <Divider />
        <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
          <Col xs={24} sm={24} md={24} lg={24} className="gutter-row">
            <ProfileActivityLogGrid />
          </Col>
        </Row>
      </Spin>
    </>
  );
};

export default ProfileActivityLogs;

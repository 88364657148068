import React from "react";
import PropTypes from "prop-types";
import { withAuth0 } from "@auth0/auth0-react";
import "./LoginPage.css";

class LoginPage extends React.PureComponent {
  componentDidMount() {
    this.props.auth0.loginWithRedirect();
  }

  render() {
    return <div>login</div>;
  }
}

LoginPage.propTypes = {
  auth0: PropTypes.shape(),
};

LoginPage.defaultProps = {
  auth0: {},
};

export default withAuth0(LoginPage);

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Row, Col, DatePicker } from "antd";
import moment from "moment";
// import actions from "../actions";
import NominalTypesSelect from '../../../common/components/nominal-type-select';
import RiskLevelSelect from '../../../common/components/risk-level-select';

const { RangePicker } = DatePicker;

class AllocationReportGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: null,
      endDate: null,
      // searchQuery: "$filter=",
      nominalTypeId: null,
      riskLevelId: null,
      forceUsed: this.getUsedList(),
    };
    this.getUsedList();
  }

  getForceFilters = () => {
    const forceFilter = []; 
    this.state.forceUsed.map((user) => {
      const force = {text: user.ForceName, value: user.ForceName || " "};
      
      if(!forceFilter.filter(e => e.value === (user.ForceName || " ")).length > 0)
        forceFilter.push(force);

        return forceFilter;
    });
    return forceFilter.sort();
  }

  getUsedList = () => {
    const used = {};
    for (let i = 0; i < this.props.usedList.length; i += 1) {
      const entry = this.props.usedList[i];
    /* eslint-disable-next-line react/prop-types */
    used[entry.ForceName] = used[entry.ForceName] ? used[entry.ForceName] + entry.Used : entry.Used
    }
    const ul = [];
    Object.keys(used).map((key) => ul.push({ForceName: key, Used: used[key]}));
    return ul;
  }

  onSearchDate = (vals) => {
    if (vals && vals.length === 2) {
      const start = vals[0].utc().format("YYYY-MM-DD").replace('+', '%2B');
      const end = vals[1].utc().format("YYYY-MM-DD").replace('+', '%2B');
      this.props.onSearchLicensesIssued(`$filter=StartDate ge ${start} and StartDate le ${end}`);
      this.props.onSearchLicensesUsed({StartDate: start, EndDate: end, NominalTypeId: this.state.nominalTypeId, RiskLevelId: this.state.riskLevelId});
      this.setState({
        startDate: start,
        endDate: end
      });
    } else {
      this.props.onSearchLicensesIssued();
      this.props.onSearchLicensesUsed({StartDate: null, EndDate: null, NominalTypeId: this.state.nominalTypeId, RiskLevelId: this.state.riskLevelId});
      this.setState({
        startDate: null,
        endDate: null
      });
    }
  }

  onNominalTypeChange = (value) => {
    this.setState({
      nominalTypeId: value
    });
    this.props.onSearchLicensesUsed({StartDate: this.state.startDate, EndDate: this.state.endDate, NominalTypeId: value, RiskLevelId: this.state.riskLevelId})
  }

  onRiskLevelChange = (value) => {
    this.setState({
      riskLevelId: value
    });
    this.props.onSearchLicensesUsed({StartDate: this.state.startDate, EndDate: this.state.endDate, NominalTypeId: this.state.nominalTypeId, RiskLevelId: value})
  }

  // onExportClick = () => {
  //   this.props.dispatch(actions.exportLicenseReport({StartDate: this.state.startDate, EndDate: this.state.endDate, NominalTypeId: this.state.nominalTypeId, RiskLevelId: this.state.riskLevelId}));
  // }

getTotalIssued = () => {
  let total = 0;
  for (let i = 0; i < this.props.issuedList.length; i += 1) {
    // eslint-disable-next-line react/prop-types
    total += this.props.issuedList[i].Total;
  }
  return total;
}


  render() {
    const gridColumns = [
      {
        title: "Force",
        dataIndex: "ForceName",
        key: "ForceName",
        sorter: (a, b) => 
        {          
          if(a.ForceName.toUpperCase() > b.ForceName.toUpperCase())
            return 1;

          if(a.ForceName.toUpperCase() < b.ForceName.toUpperCase())
            return -1;

          return 0;
        },
        sortDirections: ["descend", "ascend"],
        filters: this.getForceFilters(),
        onFilter: (value, record) => (record.ForceName || " ") === value,
      },
      {
        title: "Used",
        dataIndex: "Used",
        sorter: (a, b) => {
          if (a.Used > b.Used) return 1;
          if (a.Used < b.Used) return -1;
          return 0;
        },
        sortDirections: ["descend", "ascend"],
      },  
    ];

    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row>
          <Col xs={24} md={11}>
            <span className="mosovo-table-header">Allocations</span>
          </Col>
          <Col sm={24} md={2}>
            <span style={{ textAlign: "right", paddingRight: "5px", flex:1 }}>Nominal Type: </span>
          </Col>
          <Col xs={24} md={4}>
            <NominalTypesSelect
              id="NominalTypeId"
              name="NominalTypeId"
              value={this.state.nominalTypeId}
              onChange={this.onNominalTypeChange}
              isAllowClear="true"
            />
          </Col>
          <Col sm={0} md={1} />
          <Col sm={24} md={2}>
            <span style={{ textAlign: "right", paddingRight: "5px", width: "100%"}}>Risk Level: </span>
          </Col>
          <Col xs={24} md={4}>
            <RiskLevelSelect
              id="RiskLevelId"
              name="RiskLevelId"
              value={this.state.riskLevelId}
              onChange={this.onRiskLevelChange}
              isAllowClear="true"
            />
          </Col>
        </Row>
        <br />
        <Row justify="end" gutter={{ xs: 8, sm: 16, md: 12 }}>
          <Col xs={19} sm={20} md={18} lg={12} className="gutter-row">
            <RangePicker
              className="p5-form-datepicker-input"
              format="DD/MM/YYYY"
              separator=" to "
              onChange={this.onSearchDate}
              placeholder={['License Start Date', 'License End Date']}
            />
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <p>Total Licenses Issued{this.state.startDate && this.state.endDate ? ` ${moment(this.state.startDate).format("DD/MM/YYYY")} - ${moment(this.state.endDate).format("DD/MM/YYYY")}`: ""}: {this.getTotalIssued()}</p>
          </Col>
        </Row>
        <Row justify="center">
          <Col span={24}>
            <Table
              loading={this.props.loading}
              dataSource={this.state.forceUsed}
              columns={gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

AllocationReportGrid.propTypes = {
  // dispatch: PropTypes.func,
  onSearchLicensesUsed: PropTypes.func,
  onSearchLicensesIssued: PropTypes.func,
  usedList: PropTypes.arrayOf(PropTypes.shape({})),
  issuedList: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
};

AllocationReportGrid.defaultProps = {
  // dispatch: () => {},
  onSearchLicensesUsed: () => {},
  onSearchLicensesIssued: () => {},
  usedList: [{}],
  issuedList: [{}],
  loading: false,
};

function mapStateToProps(state) {
  const { loading } = state.Allocation;
  return {
    loading,
  };
}

const connectedAllocationReportGrid = connect(mapStateToProps)(AllocationReportGrid);

export { connectedAllocationReportGrid as default };

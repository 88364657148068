import React, { Component } from "react";
import PropTypes from "prop-types";
import { Input } from "antd";

let autocompleteService;
// let autoCompleteRef;

class SearchLocationInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: props.value,
    };

    // autoCompleteRef = React.createRef(props.refProp);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      this.setQuery(this.props.value);
    }
  }

  componentWillUnmount() {
      document.removeEventListener('mousedown', this.handleClickOutside);
  }
  
  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside = (event) => {
    if (!(event.target.className && event.target.className.includes('autocomplete'))) {
      const autocompleteResults = document.querySelector(
        ".autocomplete-results"
      );
      autocompleteResults.style.display = "none";
    }
  }

  autocompleteItemClick = (callback, htmlElement) => {
    return () => {
      const map = new window.google.maps.Map(document.createElement("div"));
      const detailsToken = new window.google.maps.places.AutocompleteSessionToken();
      const detailsService = new window.google.maps.places.PlacesService(map);
      const placeId = htmlElement.getAttribute("data-place-id");
      detailsService.getDetails(
        { placeId, sessionToken: detailsToken },
        (result, status) => {
          callback(result, status, (value) =>
            this.setState({
              query: value,
            })
          );
        }
      );
      const autocompleteResults = document.querySelector(
        ".autocomplete-results"
      );
      autocompleteResults.style.display = "none";
    };
  };

  updateAutocompleteResults = (response, callback) => {
    const autocompleteResults = document.querySelector(".autocomplete-results");
    const resultsHtml = [];

    if (response) {
      for (let i = 0; i < response.length; i += 1) {
        const item = response[i];
        resultsHtml.push(`<li class="autocomplete-item" data-type="store" data-place-id=${item.place_id}>
      <span class="autocomplete-icon icon-store"></span>
      			    <span class="autocomplete-text">${item.description}</span>
		    			</li>`);
      }
    }
    autocompleteResults.innerHTML = resultsHtml.join("");
    autocompleteResults.style.display = "block";
    const autocompleteItems = autocompleteResults.querySelectorAll(
      ".autocomplete-item"
    );
    for (let j = 0; j < autocompleteItems.length; j += 1) {
      const autocompleteItem = autocompleteItems[j];
      autocompleteItem.addEventListener(
        "click",
        this.autocompleteItemClick(callback, autocompleteItem)
      );
    }
  };

  debounce = (val, sessionToken, callback) => {
    let timeout;
    const later = function (func) {
      timeout = null;
      autocompleteService.getPlacePredictions(
        {
          input: val,
          sessionToken,
          types: ["address"],
        },
        (response) => func(response, callback)
      );
    };
    clearTimeout(timeout);
    timeout = setTimeout(later(this.updateAutocompleteResults), 150);
  };

  minChars = (val, sessionToken, callback) => {
    if (val && val.length > 3) {
      this.debounce(val, sessionToken, callback);
    }
  };

  setQuery = (value) => {
    this.setState(() => ({
      query: value,
    }));
  };

  render() {
    // Create a new session token.
    const sessionToken = new window.google.maps.places.AutocompleteSessionToken();
    autocompleteService = new window.google.maps.places.AutocompleteService();

    return (
      <>
        <Input
          id="location"
          ref={this.props.refProp}
          name={this.props.name}
          onChange={(event) => {
            this.setQuery(event.target.value);
            this.minChars(
              event.target.value,
              sessionToken,
              this.props.onSelect
            );
            this.props.onChange(event.target.value);
          }}
          placeholder={this.props.placeholder}
          value={this.state.query}
          disabled={this.props.isDisabled}
          onBlur={this.props.onBlur}
        />
        <ul
          className="autocomplete-results"
          style={{
            listStyleType: "none",
            border: "1px solid #d2d2d2",
            borderTop: 0,
            boxSizing: "border-box",
            backgroundColor: "white",
            zIndex: 1000,
            position: "relative",
          }}
        />
      </>
    );
  }
}

SearchLocationInput.propTypes = {
  onSelect: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  refProp: PropTypes.shape({ current: PropTypes.instanceOf(Component) }),
  name: PropTypes.string,
  value: PropTypes.string,
  onBlur: PropTypes.func,
};

SearchLocationInput.defaultProps = {
  isDisabled: false,
  placeholder: "",
  onChange: () => {},
  refProp: null,
  name: "",
  value: "",
  onBlur: () => {},
};

export { SearchLocationInput as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import moment from "moment";
import Geocode from "react-geocode";
import { Formik } from "formik";
import {
  Row,
  Card,
  Col,
  Form,
  Button,
  Input,
  Select,
  DatePicker,
  TimePicker
} from "antd";
import * as Yup from "yup";
import SearchLocationInput from "../../../common/components/search-location-input";
import { Map } from "../../MapView/components";
import "../Investigate.scss";
import actions from "../actions";

const { Option } = Select;

class SearchForm extends React.Component {
  constructor() {
    super();
   
    this.state = {
      searchZone: [
        {
          Latitude: null,
          Longitude: null,
          Radius: 100,
        },
      ],
      mapRef: null,
      centerPosition: {
        lat: null,
        lng: null
      }
    };
  }

  componentDidMount() {
    this.mapComponentRef=React.createRef();
    this.autocompleteInputRef = React.createRef();
    this.formIK = React.createRef();
  }

  getPositionFromAddress = async (location, radius, unit) => {
    let locationAddress = location;
    if (location.display_name) {
      locationAddress = location.display_name;
    }
    let position = {
      lat: null,
      lng: null,
      radius: 0,
    };

    if (locationAddress !== "") {
      await Geocode.fromAddress(locationAddress)
        .then((response) => {
          position = response.results[0].geometry.location;
        })
        // eslint-disable-next-line no-console
        .catch((error) => console.log(error));
    }
    
    position.radius = radius * this.getUnitToMetersConversion(unit);
    this.updateLocation(position);
  };

  mapRefSetter = (gmap) => {
    if (!this.state.mapRef) {
      this.setState((prevState) => ({
        ...prevState,
        mapRef: gmap,
      }));
    }
  };

  onInvestigateClick = (StartDate, EndDate) => {
    const zone = this.state.searchZone[0];

    // Location QS
    const coef = zone.Radius * 0.0000089;
    const newLat = zone.Latitude + coef;
    const newLong = zone.Longitude + coef / Math.cos(zone.Latitude * 0.018);

    const coef2 = -zone.Radius * 0.0000089;
    const newLat2 = zone.Latitude + coef2;
    const newLong2 = zone.Longitude + coef2 / Math.cos(zone.Latitude * 0.018);

    const locationQS = `Latitude gt ${newLat2} and Latitude lt ${newLat} and Longitude gt ${newLong2} and Longitude lt ${newLong}`

    const params = {
      Zone: zone,
      StartDate,
      EndDate
    };
    // DateQS
    const dateQS = `CreatedDate gt ${encodeURIComponent(moment(StartDate, 'DD/MM/yyyy h:mm A').utc().toISOString())} and CreatedDate lt ${encodeURIComponent(moment(EndDate, 'DD/MM/yyyy h:mm A').utc().toISOString())}`;

    this.props.dispatch(actions.getNominalLocations(params, `$filter=${locationQS} and ${dateQS}`));
  }

  updateRadius = (radius, unit) => {
    if (this.state.searchZone && this.state.searchZone.length > 0) {
      const searchZone = this.state.searchZone[0];
      if (searchZone) {
        const convertedRadius = radius * this.getUnitToMetersConversion(unit);
        searchZone.Radius = convertedRadius;
        this.setState({
            searchZone: [searchZone],
        });
      }
    }
  }

  getUnitToMetersConversion = (unit) => {
    let meters;

    switch (unit) {
      case "Meters":
        meters = 1;
        break;
      case "Feet":
        meters = 0.3048;
        break;
      default:
    }

    return meters;
  };

  updateLocation = (position) => {
    this.setState({
      searchZone: [
        {
          Latitude: position.lat,
          Longitude: position.lng,
          Radius: position.radius,
        },
      ],
      centerPosition: { lat: position.lat, lng: position.lng }
    });
    if (this.state.mapRef && this.state.mapRef.state && this.state.mapRef.state.map) {
      this.state.mapRef.state.map.panTo({ lat: position.lat, lng: position.lng });
      // this.state.mapRef.state.map.setZoom(15);
    }
  };

  validateDates = (StartDate, EndDate) => {
    if(moment(EndDate, 'DD/MM/yyyy h:mm A').isBefore(moment(StartDate, 'DD/MM/yyyy h:mm A'))
      || moment(StartDate, 'DD/MM/yyyy h:mm A').add(36, 'hours').isBefore(moment(EndDate, 'DD/MM/yyyy h:mm A')))
        return false;
    return true;
  }

  autocompleteAddressSelection = (result) => {
    if (result) {

      this.formIK.current.setFieldValue("Location", result.formatted_address);

      const position = {
        lat: result.geometry.location.lat(),
        lng: result.geometry.location.lng(),
        radius: this.formIK.current.values.Radius,
      };
      this.updateLocation(position);
    }
  };
  
  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Location: "",
          Radius: 100,
          Unit: "Meters",
          StartDate: null,
          StartTime: null,
          EndDate: null,
          EndTime: null,
          DateRange: [],
        }}
        validationSchema={Yup.object().shape({
          Location: Yup.string()
            .test(
              "locationRequired",
              "Location is required",
              (val) => val || (this.state.searchZone[0].Latitude !== null && this.state.searchZone[0].Latitude !== undefined 
                && this.state.searchZone[0].Longitude !== null && this.state.searchZone[0].Longitude !== undefined && this.state.searchZone[0].Radius)),
          Radius: Yup.number().required('Please enter a number').typeError('Please enter a number'),
          Unit: Yup.string(),
          StartDate: Yup.string(),
          StartTime: Yup.string(),
          EndDate: Yup.string(),
          EndTime: Yup.string(),
        })}
        onSubmit={() => {
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => {
          const startString = `${moment(values.StartDate).format('DD/MM/yyyy')} ${moment(values.StartTime).format('h:mm A')}`;
          const endString = `${moment(values.EndDate).format('DD/MM/yyyy')} ${moment(values.EndTime).format('h:mm A')}`;
          return(
            <Card style={{ width: '100%' }}>
              <Form
                onSubmit={handleSubmit}
                onChange={handleChange}
              >
                <Row>
                  <Col span={10}>
                    <Row gutter={[16, 32]}>
                      <span>
                        Use this tool to investigate nominals that meet the indicated criteria below
                      </span>
                    </Row>
                    <br />
                    <Row>
                      <Col span={24}>
                        <span>Search by address or locate on map:</span>
                      </Col>
                      <Col span={24}>
                        <Form.Item
                          validateStatus={
                            errors && errors.Location && touched.Location
                              ? "error"
                              : "success"
                          }
                          help={
                            errors && errors.Location && touched.Location
                              ? errors.Location
                              : null
                          }
                        >
                          <SearchLocationInput
                            ref={this.autocompleteInputRef}
                            id="Location"
                            name="Location"
                            placeholder="Address"
                            onBlur={handleBlur}
                            value={values.Location}
                            onSelect={this.autocompleteAddressSelection}
                            onChange={(val) => {
                              setFieldValue("Location", val);
                              this.getPositionFromAddress(
                                val,
                                values.Radius,
                                values.Unit
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[16, 32]}>
                      <Col span={4} className="InvestigateLabel">
                        <span>Radius:</span>
                      </Col>
                      <Col span={4}>
                        <Form.Item
                          validateStatus={
                            errors && errors.Radius && touched.Radius
                              ? "error"
                              : "success"
                          }
                          help={
                            errors && errors.Radius && touched.Radius
                              ? errors.Radius
                              : null
                          }
                        >
                          <Input
                            id="Radius"
                            placeholder="Radius"
                            onBlur={handleBlur}
                            value={values.Radius}
                            onChange={(val) => {
                              setFieldValue("Radius", val.target.value);
                              this.updateRadius(val.target.value, values.Unit);
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Select
                          onChange={(val) => {
                            setFieldValue("Unit", val);
                            this.updateRadius(values.Radius, val);
                          }}
                          id="radiusUnit"
                          style={{ minWidth: 100, width: "30%", height: "32px", marginLeft: "10%" }}
                          value={values.Unit}
                        >
                          <Option value="Meters">Meters</Option>
                          <Option value="Feet">Feet</Option>
                        </Select>
                      </Col>
                    </Row>
                    <br />
                    <Form.Item
                      validateStatus={
                          !this.validateDates(startString, endString)
                          ? "error"
                          : "success"
                      }
                      help={
                          !this.validateDates(startString, endString)
                          ? 'Date range cannot exceed 36 hours, enter a valid date range.'
                          : null
                      }
                    >
                      <Row gutter={[16, 16]}>
                        <Col span={24}>
                          Date and time range:
                        </Col>
                        <Col span={4} className="InvestigateLabel">
                          <span>From:</span> 
                        </Col>
                        <Col span={18}>
                          <DatePicker
                            name="StartDate"
                            value={values.StartDate ? moment(values.StartDate) : null}
                            className='InvestigateDate'
                            format="DD/MM/yyyy"
                            onChange={(val) => {
                              setFieldValue('StartDate', val);
                            }}
                          />
                          <TimePicker
                            format='h:mm A'
                            value={values.StartTime ? moment(values.StartTime) : null}
                            onChange={(val) => {
                              setFieldValue('StartTime', val);
                            }}
                          />
                        </Col>
                        <br />
                        <Col span={4} className="InvestigateLabel">
                          <span>To:</span>
                        </Col>
                        <Col span={18}>
                          <DatePicker
                            name="EndDate"
                            value={values.EndDate ? moment(values.EndDate) : null}
                            className='InvestigateDate'
                            format="DD/MM/yyyy"
                            onChange={(val) => {
                              setFieldValue('EndDate', val);
                            }}
                          />
                          <TimePicker
                            format='h:mm A'
                            value={values.EndTime ? moment(values.EndTime) : null}
                            onChange={(val) => {
                              setFieldValue('EndTime', val);
                            }}
                          />
                        </Col>
                      </Row>
                    </Form.Item>
                    <Col span={24}>
                      <Button
                        className="BlueButton"
                        disabled={Object.keys(errors).length !== 0 || !this.validateDates(startString, endString)}
                        onClick={() => this.onInvestigateClick(startString, endString)}
                      >
                        Investigate
                      </Button>
                    </Col>
                  </Col>
                  <Col span={2} />
                  <Col span={10}>
                    <Map
                      centerPosition={this.state.centerPosition}
                      mapRefSetter={this.mapRefSetter}
                      dispatch={this.props.dispatch}
                      exclusionZones={this.state.searchZone}
                      setExclusionZoneLocation={(location) => {
                        const position = {
                          lat: location.lat,
                          lng: location.lon,
                          radius: this.state.searchZone[0].Radius * this.getUnitToMetersConversion("Meters")
                        }
                        this.updateLocation(position);
                        setFieldValue("Location", location.display_name ? location.display_name : location);
                      }}
                      cssClass="InvestigateMap"
                    />
                  </Col>
                </Row>
              </Form>
            </Card>
        )}}
      </Formik>
    );
  }
}

SearchForm.propTypes = {
  dispatch: PropTypes.func,
  // loading: PropTypes.bool,
};

SearchForm.defaultProps = {
  dispatch: () => {},
  // loading: false,
};

function mapStateToProps() {
  return {};
}

const connectSearchForm = connect(mapStateToProps)(SearchForm);

export { connectSearchForm as default };

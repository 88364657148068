import React from "react";
import { Menu } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { faSignOutAlt } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LogoutMenuItem = () => {
  const { logout } = useAuth0();

  const logOffIcon = (
    <span className="anticon" role="img">
      <FontAwesomeIcon
        icon={faSignOutAlt}
        id="Logoff"
        alt="Logout Icon"
        className=""
      />
    </span>
  );

  return (
    <Menu.Item
      className="ant-menu-item"
      key="logout"
      icon={logOffIcon}
      onClick={logout}
    >
      <span>Logout</span>
    </Menu.Item>
  );
};

export default LogoutMenuItem;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
// import { push } from "connected-react-router";
import { Tabs, Row, Col } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import actions from '../actions';
import { ForceGrid, AreaGrid, TeamGrid } from "../grids";
import { ForceBulkModal } from '../force-bulk-modal'
import { ForceEditModal, AreaModal, TeamModal } from '../modals';

import "./Organisation.scss";

const { TabPane } = Tabs;

class OrganisationIndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      forceList: [],
      forceSearch: "",
      areaList: [],
      areaSearch: "",
      teamList: [],
      teamSearch: "",
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("Organisations"));
    this.getForceData();
    this.getAreaData();
    this.getTeamData();
  }

  getForceData = (value = "") => {
    this.setState({ forceSearch: value });
    const searchQuery =
    value !== ""
        ? `$filter=contains(Name,'${value}')`
        : "";
    this.props.dispatch(actions.getForceODataList(searchQuery, this.setForceData));
  };

  setForceData = (data) => {
    this.setState({         
        forceList: data 
    });   
  }

  getAreaData = (value = "") => {   
    this.setState({ areaSearch: value }); 
    const searchQuery =
    value !== ""
        ? `$filter=contains(Name,'${value}')`
        : "";
    this.props.dispatch(actions.getAreaODataList(searchQuery, this.setAreaData));
  };

  setAreaData = (data) => {
    this.setState({         
        areaList: data 
    });   
  }

  getTeamData = (value = "") => {
    this.setState({ teamSearch: value });
    const searchQuery =
    value !== ""
        ? `$filter=contains(Name,'${value}')`
        : "";
    this.props.dispatch(actions.getTeamODataList(searchQuery, this.setTeamData));
  };

  setTeamData = (data) => {
    this.setState({         
        teamList: data 
    });   
  }

  changeTabs = (tab) =>{
    if (tab === "Force") { 
        this.getForceData(this.state.forceSearch);
    }
    if (tab === "Area") { 
        this.getAreaData(this.state.areaSearch);
    }
    if (tab === "Team") { 
        this.getTeamData(this.state.teamSearch);
    }

  }

  RefreshForceGrid = () => {
    this.getForceData(this.state.forceSearch);
  }

  RefreshAreaGrid = () => {
    this.getAreaData(this.state.areaSearch);
  }

  RefreshTeamGrid = () => {
    this.getTeamData(this.state.teamSearch);
  }

  render() {
    return (
      <div className="mosovo-content-container">
        <Row justify="center">
          <Col span={24}>
            <Tabs size="large" onTabClick={(e) => this.changeTabs(e)}>
              <TabPane tab="Force" key="Force">
                <ForceGrid forceList={this.state.forceList} onSearch={this.getForceData} />
              </TabPane>
              <TabPane tab="Area" key="Area">
                <AreaGrid areaList={this.state.areaList} onSearch={this.getAreaData} />
              </TabPane>
              <TabPane tab="Team" key="Team">
                <TeamGrid teamList={this.state.teamList} onSearch={this.getTeamData} />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        <ForceBulkModal refreshGrid={this.RefreshForceGrid} />
        <ForceEditModal refreshGrid={this.RefreshForceGrid} />
        <AreaModal refreshGrid={this.RefreshAreaGrid} />
        <TeamModal refreshGrid={this.RefreshTeamGrid} />
      </div>
    );
  }
}

OrganisationIndexPage.propTypes = {
  dispatch: PropTypes.func,
};

OrganisationIndexPage.defaultProps = {
  dispatch: () => {},
};

function mapStateToProps() {
  return {};
}

const connectOrganisationIndexPage = connect(mapStateToProps)(
  OrganisationIndexPage
);

export { connectOrganisationIndexPage as default };

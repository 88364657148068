import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "antd";
import { MessageOutlined } from "@ant-design/icons";

class MessageIconButton extends React.Component {
  render() {
    return (
      <>
        <Tooltip placement="topRight" title={this.props.tooltip}>
          <Button
            id={this.props.id}
            icon={<MessageOutlined />}
            style={{
              backgroundColor: "#243B53",
              color: "white",
              float: "right",
            }}
            onClick={this.props.onClick}
          >
            {this.props.title}
          </Button>
        </Tooltip>
      </>
    );
  }
}

MessageIconButton.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
};

MessageIconButton.defaultProps = {
  onClick: () => {},
  id: "",
  title: null,
  tooltip: null
};

export default MessageIconButton;

import React from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { HelperService } from '../../../services';

const { Option } = Select;

class QuestionSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.getRiskLevels();
  }

  getRiskLevels() {
    HelperService.getCheckinQuestions().then((result) => {
      this.setState({
        data: result.data,
      });
    });
  }

  render() {
    return (
      <Select
        placeholder="Questions"
        className={this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}
      >
        {
          this.state.data.map((opt) => {
            if(this.props.questionsToShow.length < 1 && this.props.currentQuestionList.indexOf(opt.Id) === -1) {
              return (
                <Option
                  key={opt.Id}
                  value={opt.Id}
                >
                  {opt.Name}
                </Option>
              )}
            if(this.props.questionsToShow.indexOf(opt.Id) > -1) {
              return (
                <Option
                  key={opt.Id}
                  value={opt.Id}
                >
                  {opt.Name}
                </Option>
              )}
            return null;
          })
        }
      </Select>
    );
  }
}

QuestionSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.number,
  className: PropTypes.string,
  currentQuestionList: PropTypes.arrayOf(PropTypes.number),
  questionsToShow: PropTypes.arrayOf(PropTypes.number),
};

QuestionSelect.defaultProps = {
  value: null,
  className: "",
  currentQuestionList: [],
  questionsToShow: [],
};

export { QuestionSelect as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col } from "antd";

import { AreaForm } from "../forms";
import actions from "../actions";

class AreaModal extends React.Component {
  CloseAreaModal = () => {
    this.props.dispatch(actions.closeAreaModal());
    this.props.refreshGrid();
  }

  AreaUpdate = (data) => {
    this.props.dispatch(actions.updateArea(data, this.CloseAreaModal));
  }

  AreaCreate = (data) => {
    this.props.dispatch(actions.createArea(data, this.CloseAreaModal));
  }
  
  render() {
    return (
      <>
        <Modal title="Edit Area" visible={this.props.showEditModal} onCancel={this.CloseAreaModal} footer={null} width={500} destroyOnClose>
          <div>
            <Row>
              <Col span={24}>  
                <AreaForm onCancel={this.CloseAreaModal} onSubmit={this.AreaUpdate} isLoading={this.props.loading} isEdit {...this.props.area} />
              </Col>
            </Row>
          </div>
        </Modal>
        <Modal title="Add Area" visible={this.props.showAddModal} onCancel={this.CloseAreaModal} footer={null} width={500} destroyOnClose>
          <div>
            <Row>
              <Col span={24}>  
                <AreaForm defaultForce={this.props.defaultForce} onCancel={this.CloseAreaModal} onSubmit={this.AreaCreate} isLoading={this.props.loading} {...this.props.area} />
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

AreaModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  showEditModal: PropTypes.bool,
  showAddModal: PropTypes.bool,
  loading: PropTypes.bool,
  area: PropTypes.shape({}),
  defaultForce: PropTypes.number,
};

AreaModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  showEditModal: false,
  showAddModal: false,
  loading: false,
  area: {},
  defaultForce: null,
};

function mapStateToProps(state) {
  const { loading, area } = state.Organisation;
  const { showEditModal, showAddModal, defaultForce } = state.Organisation.AreaModal;

  return { showEditModal, showAddModal, loading, area, defaultForce };
}

const connectedAreaModal = connect(mapStateToProps)(AreaModal);

export { connectedAreaModal as default };

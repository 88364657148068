import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { Form, Row, Col, Input, Button, Spin } from "antd";
import * as Yup from "yup";
import Actions from "../actions";
// import AccountLayout from "../layout";
import { history } from "../../../store";
import "./ForgotForm.css";

class ForgotForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      UserName: "",
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.loggedIn !== this.props.loggedIn)
      history.push(this.getPath());
  }

  getPath() {
    return this.props.location.state
      ? this.props.location.state.from.pathname
      : "/";
  }

  handleChange = (data) => {
    const { value, id } = data.target;
    this.setState((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  handleSubmit = () => {
    const { dispatch } = this.props;
    if (!this.props.hasError) {
      dispatch(Actions.ForgotPassword(this.state));
    }
  };

  render() {
    return (
      <div className="login-layout-container">
        <Row justify="center">
          <Col>
            <div className="login-layout-form-container">
              <Spin spinning={this.props.loading}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    UserName: this.state.UserName,
                  }}
                  validationSchema={Yup.object().shape({
                    UserName: Yup.string()
                      .typeError("Username is required")
                      .required("Username is required"),
                  })}
                  onSubmit={(values) => this.handleSubmit(values)}
                  validateOnChange
                  render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                  }) => (
                    <div className="account-layout-form-container">
                      <Form
                        name="Forgot"
                        className="forgot-form"
                        id="Forgot"
                        onSubmit={handleSubmit}
                        onChange={handleChange}
                        autoComplete="off"
                      >
                        <Row justify="Center">
                          <Col span={20}>
                            <Row>
                              <h1 className="p5-form-h1-header">
                                {" "}
                                Forgot Password{" "}
                              </h1>
                            </Row>
                          </Col>
                        </Row>
                        <Row justify="Center">
                          <Col span={24}>
                            <Form.Item
                              validateStatus={
                                errors && errors.UserName && touched.UserName
                                  ? "error"
                                  : null
                              }
                              help={
                                errors && touched.UserName && errors.UserName
                              }
                            >
                              <div className="p5-form-label"> Username </div>
                              <Input
                                type="text"
                                id="UserName"
                                className="p5-form-input"
                                name="UserName"
                                placeholder="Username"
                                value={values.UserName}
                                onChange={this.handleChange}
                                autoComplete="off"
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row className="btn-row">
                          <Button
                            onClick={handleSubmit}
                            type="submit"
                            className="forgotBtn"
                          >
                            Send Email
                          </Button>
                        </Row>
                      </Form>
                    </div>
                  )}
                />
              </Spin>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { loggedIn } = state.Auth;
  const { loading, hasError } = state.Account;
  return {
    loading,
    hasError,
    loggedIn,
  };
}

const connectedForgotForm = connect(mapStateToProps)(ForgotForm);

ForgotForm.propTypes = {
  dispatch: () => {},
  hasError: PropTypes.bool,
  loading: PropTypes.bool,
  loggedIn: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      from: PropTypes.shape({
        pathname: PropTypes.string,
      }),
    }),
  }),
};

ForgotForm.defaultProps = {
  dispatch: () => {},
  hasError: false,
  loading: false,
  loggedIn: false,
  location: {},
};

export default connectedForgotForm;

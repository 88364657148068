import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import { Layout, PageHeader } from '../../common/components';
import { NominalPageHeader } from './components';
import { MyNominalsPage, ForceNominalsPage, AreaNominalsPage } from './pages';

const NominalHeader = (title) => (
  <PageHeader customHeader={(<NominalPageHeader title={title} />)} />
)

const ForceNominalsPageWithHeader = () => (
  <Route render={() => (
    <Layout PageHeader={NominalHeader('Force Nominals')}>
      <ForceNominalsPage />
    </Layout>
  )} 
  />
)

const AreaNominalsPageWithHeader = () => (
  <Route render={() => (
    <Layout PageHeader={NominalHeader('Area Nominals')}>
      <AreaNominalsPage />
    </Layout>
  )} 
  />
)

const TeamNominalsPageWithHeader = () => (
  <Route render={() => (
    <Layout PageHeader={NominalHeader('Team Nominals')}>
      <MyNominalsPage />
    </Layout>
  )} 
  />
)

const TeamNominals = ({ match }) => (
  <Switch>
    <ForceNominalsPageWithHeader exact path="/forcenominals" />
    <AreaNominalsPageWithHeader exact path="/areanominals" />
    <TeamNominalsPageWithHeader exact path={`${match.url}`} />
  </Switch>
);

TeamNominals.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

TeamNominals.defaultProps = {
  match: {
    url: '',
  },
};

export default withRouter(TeamNominals);
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button, Divider, Spin, Modal, Tooltip } from "antd";
import { WarningOutlined } from "@ant-design/icons";
// import { push } from "connected-react-router";
// import moment from "moment";
import { faEdit, faMinusCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { phoneCodeAndNumberFormatter } from "../../../common/utils/phone-number-helper";
import actions from "../actions";

class DeviceInfo extends React.Component {
  UnsuspendDevice = () => {
    this.props.dispatch(
      actions.unsuspendDevice(this.props.DeviceInfo.Id, () =>
        this.props.dispatch(actions.getNominalDetail(this.props.Id))
      )
    );
  };

  SuspendDevice = () => {
    this.props.dispatch(
      actions.suspendDevice(this.props.DeviceInfo.Id, () =>
        this.props.dispatch(actions.getNominalDetail(this.props.Id))
      )
    );
  };

  SuspendDeviceModal = () => {
    const { confirm } = Modal;
    const suspendDevice = this.SuspendDevice;
    const message = (
      <div>
        <p>
          Suspending device will pause all alerts and will no longer track
          location or other nominal settings on this device.
        </p>
        <p>
          <b>Are you sure you want to suspend the following device?</b>
        </p>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Phone Number:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {this.props.DeviceInfo.PhoneNumber}
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Device Code:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {this.props.DeviceInfo.DeviceCode}
          </Col>
        </Row>
      </div>
    );

    confirm({
      title: "Suspend Device",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      closable: true,
      onOk() {
        suspendDevice();
      },
      okText: "Yes, Suspend",
    });
  };

  render() {
    const editBasicInfoButton = (id) => (
      <Tooltip
        placement="topRight"
        title="Edit Device Information"
      >
        <Button
          shape="circle"
          style={{ backgroundColor: "white", color: "#243b53", margin: "5px" }}
          class="mosovo-btn-effect"
          onClick={() => this.props.edit(id)}
        >
          <FontAwesomeIcon
            icon={faEdit}
            id="EditNominalBasicInfo"
            className=""
            alt="basic"
          />
        </Button>
      </Tooltip>
    );
    const suspendButton = () => (
      <Tooltip
        placement="topRight"
        title={this.props.DeviceInfo.Active ? "Suspend Nominal" : "Unsuspend Nominal"}
      >
        <Button
          shape="circle"
          style={{ backgroundColor: "white", color: "#243b53", margin: "5px" }}
          onClick={
            this.props.DeviceInfo.Active ? this.SuspendDeviceModal : this.UnsuspendDevice
          }
        >
          <FontAwesomeIcon
            icon={faMinusCircle}
            id={this.props.DeviceInfo.Active ? "SuspendNominal" : "UnsuspendNominal"}
            className=""
            alt={this.props.DeviceInfo.Active ? "suspend" : "unsuspend"}
            style={{}}
          />
        </Button>
      </Tooltip>
    );

    return (
      <>
        <Spin spinning={this.props.loading}>
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col span={12} className="gutter-row">
              <h6>Device Information</h6>
            </Col>
            <Col span={8} />
            <Col span={2}>
              <Row justify="end">
                { this.props.DeviceInfo && (
                  <Col>
                    <span>{suspendButton()}</span>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={2}>
              <Row justify="end">
                <Col>
                  <span>{editBasicInfoButton(this.props.Id)}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col xs={24} sm={24} md={24} lg={24} className="gutter-row">
              <span>Current Device</span>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>Phone Number</span>
            </Col>
            {this.props.DeviceInfo && (
              <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
                <span
                  style={{
                    color: this.props.DeviceInfo.Active && this.props.Active
                      ? "rgba(0, 0, 0, 0.85)"
                      : "#909090",
                    fontStyle: this.props.DeviceInfo.Active && this.props.Active ? "normal" : "italic",
                  }}
                >
                  {`${
                    phoneCodeAndNumberFormatter(
                      this.props.DeviceInfo.PhoneNumber
                    ) || ""
                  }`}
                </span>
              </Col>
            )}
          </Row>
          <Row>
            <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
              <span>Device Code</span>
            </Col>
            {this.props.DeviceInfo && (
              <Col xs={24} sm={24} md={18} lg={18} className="gutter-row">
                <span
                  style={{
                    color: this.props.DeviceInfo.Active && this.props.Active
                      ? "rgba(0, 0, 0, 0.85)"
                      : "#909090",
                    fontStyle: this.props.DeviceInfo.Active && this.props.Active ? "normal" : "italic",
                  }}
                >
                  {`${this.props.DeviceInfo.DeviceCode || ""}`}
                </span>
              </Col>
            )}
          </Row>
          <br />
        </Spin>
      </>
    );
  }
}

DeviceInfo.propTypes = {
  dispatch: PropTypes.func,
  edit: PropTypes.func,
  loading: PropTypes.bool,
  Id: PropTypes.number,
  DeviceInfo: PropTypes.shape({
    Id: PropTypes.number,
    PhoneNumber: PropTypes.string,
    DeviceCode: PropTypes.string,
    Active: PropTypes.bool,
  }),
  Active: PropTypes.bool,
};

DeviceInfo.defaultProps = {
  dispatch: () => {},
  edit: () => {},
  loading: false,
  Id: 0,
  DeviceInfo: {
    Id: 0,
    PhoneNumber: "",
    DeviceCode: "",
    Active: false,
  },
  Active: true,
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;

  return { loading };
}

const connectedNominalDeviceInfo = connect(mapStateToProps)(DeviceInfo);

export { connectedNominalDeviceInfo as default };

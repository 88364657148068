import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";
import { Row, Col, Form, Divider, Input, Button, Checkbox, Radio } from "antd";
import { CancelBtn, SaveBtn } from "../../../common/components/form";
import { DynamicSelect } from "../../../common/components/dynamic-select";
import { OrganisationService, UserService } from "../../../services";

class AreaForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
  }

  // componentDidMount() {
  //   if (!this.props.LawEnforcementAreaId) this.onRadioChange(false);
  // }
  
  componentDidUpdate() {
    this.formIK.current.handleReset();
  }

  // onRadioChange(e) {
  //   this.formIK.current.setFieldValue('AreaId', 0);
  //   this.formIK.current.setFieldValue('HaveAreas', e);
  // }

  pivotData = (data) => ({
    Id: data.Id,
    UserName: data.UserName,
    EmailAddress: data.EmailAddress,
    EmailConfirmed: false,
    FirstName: data.FirstName,
    LastName: data.LastName,
    Role: { Id: data.RoleId },
    LawEnforcementAgencyId: data.ForceId,
    LawEnforcementAreaId: data.AreaId === 0 ? null : data.AreaId,
    LawEnforcementTeamId: data.TeamId,
    ReceivedTraining: data.ReceivedTraining,
  });

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.props.Id,
          UserName: this.props.UserName,
          EmailAddress: this.props.EmailAddress,
          FirstName: this.props.FirstName,
          LastName: this.props.LastName,
          ForceId: this.props.LawEnforcementAgencyId,
          AreaId: this.props.LawEnforcementAreaId,
          TeamId: this.props.LawEnforcementTeamId,
          RoleId: this.props.Role.Id,
          ReceivedTraining: this.props.ReceivedTraining,
          // eslint-disable-next-line no-unneeded-ternary
          HaveAreas: this.props.LawEnforcementAreaId || !this.props.EmailAddress ? true : false,
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          FirstName: Yup.string()
            .typeError("First Name is required")
            .required("First Name is required"),
          UserName: Yup.string()
            .typeError("Username is required")
            .required("Username is required")
            .test('UsernameTest', 'Username is already in use. Please enter another.',
            (value) => {
              if (value && !this.props.isEdit) {
                return new Promise((resolve) => {
                  UserService.validateUser(value).then(
                    (response) => {
                      resolve(response.data)
                    }).catch(() => {
                      resolve(true)
                    });
                })
              }
              return true;
            }),
          LastName: Yup.string()
            .typeError("Last Name is required")
            .required("Last Name is required"),
          EmailAddress: Yup.string()
            .typeError("Email Address is required")
            .required("Email Address is required"),
          RoleId: Yup.number()
            .typeError("User Role is required")
            .required("User Role is required"),
          ForceId: Yup.number().when("RoleId", {
            is: 1,
            then: Yup.number(),
            otherwise: Yup.number()
              .typeError("Force is required")
              .required("Force is required"),
          }),
          AreaId: Yup.number().when("HaveAreas", {
            is: true,
            then: Yup.number()
              .typeError("invalid area id type")
              .required("Area is required"),
            otherwise: Yup.number().notRequired()
          }),
          TeamId: Yup.number().when("RoleId", {
            is: 1,
            then: Yup.number(),
            otherwise: Yup.number()
              .typeError("Team is required")
              .required("Team is required"),
          }),
          ReceivedTraining: Yup.bool().test("TrainingTest", "\"User has been trained\" checkbox must be selected to save changes.",
          (value) => {
            return value;
          }),
        })}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => {
          const onRadioChange = (e) => {
            setFieldValue('AreaId', 0);
            setFieldValue('HaveAreas', e.target.value);
          }

        return (
          <Form id="UserForm" onSubmit={handleSubmit} onChange={handleChange}>
            <div>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col xs={24} sm={4} md={6}>
                      <span className="required-field">First Name: </span>
                    </Col>
                    <Col xs={24} sm={20} md={16}>
                      <Form.Item
                        validateStatus={
                          errors && errors.FirstName && touched.FirstName
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.FirstName && touched.FirstName
                            ? errors.FirstName
                            : null
                        }
                      >
                        <span className="sameLine">
                          <Input
                            id="FirstName"
                            onBlur={handleBlur}
                            value={values.FirstName}
                            onChange={(val) => {
                              setFieldValue("FirstName", val.target.value);
                            }}
                          />
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={4} md={6}>
                      <span className="required-field">Last Name: </span>
                    </Col>
                    <Col xs={24} sm={20} md={16}>
                      <Form.Item
                        validateStatus={
                          errors && errors.LastName && touched.LastName
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.LastName && touched.LastName
                            ? errors.LastName
                            : null
                        }
                      >
                        <span className="sameLine">
                          <Input
                            id="LastName"
                            onBlur={handleBlur}
                            value={values.LastName}
                            onChange={(val) => {
                              setFieldValue("LastName", val.target.value);
                            }}
                          />
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={4} md={6}>
                      <span className="required-field">Email Address:</span>
                    </Col>
                    <Col xs={24} sm={20} md={16}>
                      <Form.Item
                        validateStatus={
                          errors && errors.EmailAddress && touched.EmailAddress
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.EmailAddress && touched.EmailAddress
                            ? errors.EmailAddress
                            : null
                        }
                      >
                        <span className="sameLine">
                          <Input
                            id="EmailAddress"
                            onBlur={handleBlur}
                            value={values.EmailAddress}
                            onChange={(val) => {
                              setFieldValue("EmailAddress", val.target.value);
                            }}
                          />
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col xs={24} sm={4} md={6}>
                      <span className="required-field">Username:</span>
                    </Col>
                    <Col xs={24} sm={20} md={16}>
                      <Form.Item
                        validateStatus={
                          errors && errors.UserName && touched.UserName
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.UserName && touched.UserName
                            ? errors.UserName
                            : null
                        }
                      >
                        <span className="sameLine">
                          <Input
                            disabled={this.props.isEdit}
                            id="UserName"
                            onBlur={handleBlur}
                            value={values.UserName}
                            onChange={(val) => {
                              setFieldValue("UserName", val.target.value);
                            }}
                          />
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={24} sm={4} md={6}>
                      <span className="required-field">User Role:</span>
                    </Col>
                    <Col xs={24} sm={20} md={16}>
                      <Form.Item
                        validateStatus={
                          errors && errors.RoleId && touched.RoleId
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.RoleId && touched.RoleId
                            ? errors.RoleId
                            : null
                        }
                      >
                        <span className="sameLine">
                          <DynamicSelect
                            hasNone={false}
                            className=""
                            field="RolePicker"
                            getData={UserService.getRoleList}
                            displayFieldName="Name"
                            valueFieldName="Id"
                            value={values.RoleId}
                            onBlur={handleBlur}
                            onChange={(val) => {
                              setFieldValue("RoleId", val);
                              if(val === 1)
                              {
                                setFieldValue("ForceId", 0);
                                setFieldValue("AreaId", 0);
                                setFieldValue("TeamId", 0);
                              }
                              else {
                                  setFieldValue("ForceId", null);
                                  setFieldValue("AreaId", null);
                                  setFieldValue("TeamId", null);
                              }
                            }}
                          />
                        </span>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Form.Item
                      validateStatus={
                        errors &&
                        errors.ReceivedTraining &&
                        touched.ReceivedTraining
                          ? "error"
                          : "success"
                      }
                      help={
                        errors &&
                        errors.ReceivedTraining &&
                        touched.ReceivedTraining
                          ? errors.ReceivedTraining
                          : null
                      }
                    >
                      <span>
                        <Checkbox
                          className="smallMarginRight"
                          checked={values.ReceivedTraining}
                          onChange={() => setFieldValue('ReceivedTraining', !values.ReceivedTraining)}
                        />
                        <span className="required-field">User has been trained</span>
                      </span>
                    </Form.Item>
                  </Row>
                  <Divider />
                  {values.RoleId !== 1 &&
                    (values.RoleId !== null && (
                      <div>
                        <Row>
                          <Col xs={24} sm={4} md={6}>
                            <span
                              className={
                                values.RoleId !== 1 ? "required-field" : ""
                              }
                            >
                              Force:
                            </span>
                          </Col>
                          <Col xs={24} sm={20} md={16}>
                            <Form.Item
                              validateStatus={
                                errors &&
                                errors.ForceId &&
                                touched.ForceId 
                                  ? "error"
                                  : "success"
                              }
                              help={
                                errors &&
                                errors.ForceId &&
                                touched.ForceId 
                                  ? errors.ForceId
                                  : null
                              }
                            >
                              <DynamicSelect
                                hasNone={false}
                                className=""
                                field="ForcePicker"
                                getData={OrganisationService.getForceGridOdata}
                                displayFieldName="Name"
                                valueFieldName="Id"
                                value={values.ForceId}
                                onBlur={handleBlur}
                                onChange={(val) => {
                                  setFieldValue("ForceId", val);
                                  setFieldValue("AreaId", null);
                                  setFieldValue("TeamId", null);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Row>
                          <Col span={24}>
                            <span>Is this User assigned to an Area?</span>
                          </Col>
                          <Col span={24}>
                            <Radio.Group onChange={onRadioChange} value={values.HaveAreas}>
                              {/* eslint-disable-next-line */}
                              <Radio value={true}>Yes</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          </Col>
                        </Row>
                        {values.HaveAreas && (
                          <Row>
                            <Col xs={24} sm={4} md={6}>
                              <span>
                                Area:
                              </span>
                            </Col>
                            <Col xs={24} sm={20} md={16}>
                              <Form.Item
                                validateStatus={
                                  errors &&
                                  errors.AreaId &&
                                  touched.AreaId
                                    ? "error"
                                    : "success"
                                }
                                help={
                                  errors &&
                                  errors.AreaId &&
                                  touched.AreaId
                                    ? errors.AreaId
                                    : null
                                }
                              >
                                <DynamicSelect
                                  hasNone
                                  className=""
                                  field="AreaPicker"
                                  getData={OrganisationService.getAreaGridOdata}
                                  filters={`$filter=ForceId eq ${values.ForceId}`}
                                  displayFieldName="Name"
                                  valueFieldName="Id"
                                  value={values.AreaId}
                                  onBlur={handleBlur}
                                  onChange={(val) => {
                                    setFieldValue("AreaId", val);
                                    setFieldValue("TeamId", null);
                                  }}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        )}
                        <Row>
                          <Col xs={24} sm={4} md={6}>
                            <span
                              className={
                                values.RoleId !== 1 ? "required-field" : ""
                              }
                            >
                              Team:
                            </span>
                          </Col>
                          <Col xs={24} sm={20} md={16}>
                            <Form.Item
                              validateStatus={
                                errors &&
                                errors.TeamId &&
                                touched.TeamId
                                  ? "error"
                                  : "success"
                              }
                              help={
                                errors &&
                                errors.ForceId &&
                                touched.TeamId
                                  ? errors.TeamId
                                  : null
                              }
                            >
                              <DynamicSelect
                                hasNone
                                className=""
                                field="TeamPicker"
                                getData={OrganisationService.getTeamGridOdata}
                                filters={`$filter=ForceId eq ${
                                  values.ForceId
                                } and AreaId eq ${
                                  values.AreaId === 0 ? null : values.AreaId
                                }&`}
                                displayFieldName="Name"
                                valueFieldName="Id"
                                value={values.TeamId}
                                onBlur={handleBlur}
                                onChange={(val) => {
                                  setFieldValue("TeamId", val);
                                }}
                              />
                            </Form.Item>
                          </Col>
                        </Row>
                      </div>
                    ))}
                </Col>
              </Row>
              <Divider plain />
              <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                {this.props.isEdit && (
                  <>
                    <Col xs={24} sm={8} md={6} className="gutter-row">
                      <Button type="primary" style={{width: "100%"}} danger onClick={this.props.onDelete}>Delete</Button>
                    </Col>                
                    <Col xs={0} sm={0} md={6} />
                  </>
                )}                
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <SaveBtn
                    onClick={handleSubmit}
                    loading={this.props.isLoading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}}
      </Formik>
    );
  }
}

AreaForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  isEdit: PropTypes.bool,
  Id: PropTypes.number,
  UserName: PropTypes.string,
  EmailAddress: PropTypes.string,
  LawEnforcementAgencyId: PropTypes.number,
  LawEnforcementAreaId: PropTypes.number,
  LawEnforcementTeamId: PropTypes.number,
  Role: PropTypes.shape({
    Id: PropTypes.number,
  }), 
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  ReceivedTraining: PropTypes.bool,
};

AreaForm.defaultProps = {
  onCancel: () => {},
  onDelete: () => {},
  isLoading: false,
  isEdit: false,
  Id: 0,
  UserName: "",
  EmailAddress: "",
  LawEnforcementAgencyId: null,
  LawEnforcementAreaId: null,
  LawEnforcementTeamId: null,
  Role: {
    Id: null
  },
  FirstName: "",
  LastName: "",
  ReceivedTraining: false,
};

export default AreaForm;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin } from "antd";

import { AddNominalLocationForm } from "../forms";
import actions from "../actions";

class AddNominalLocationModal extends React.Component {
  CloseModal = () => {
    this.props.dispatch(actions.closeNominalModal());
    this.props.refreshGrid();
  };

  AddLocation = (data) => {
    this.props.dispatch(actions.addNominalLocation(data));
    this.CloseModal();
  };

  render() {
    return (
      <>
        <Modal
          title={`Add Location For: ${this.props.FirstName} ${this.props.LastName}`}
          visible={this.props.showAddNominalLocationModal}
          // onCancel={this.CloseModal}
          footer={null}
          width={600}
          onCancel={this.CloseModal}
        >
          <div>
            <Row>
              <Col span={24}>
                <Spin spinning={this.props.loading}>
                  <AddNominalLocationForm
                    onCancel={this.CloseModal}
                    onSubmit={this.AddLocation}
                    isLoading={this.props.loading}
                    ConsumerId={this.props.ConsumerId}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

AddNominalLocationModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  //   showEditModal: PropTypes.bool,
  showAddNominalLocationModal: PropTypes.bool,
  loading: PropTypes.bool,
	FirstName: PropTypes.string,
	LastName: PropTypes.string,
  ConsumerId: PropTypes.number,
  Nominal: PropTypes.shape({}),
};

AddNominalLocationModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  //   showEditModal: false,
  showAddNominalLocationModal: true,
  loading: false,
	FirstName: null,
	LastName: null,
  ConsumerId: 0,
  Nominal: {},
};

function mapStateToProps(state) {
  const { loading, showAddNominalLocationModal, Nominal } = state.Nominal;

  return { loading, showAddNominalLocationModal, Nominal };
}

const connectedAddNominalLocationModal = connect(mapStateToProps)(AddNominalLocationModal);

export { connectedAddNominalLocationModal as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Row, Col, Tooltip, DatePicker } from "antd";
import moment from "moment";
import {
  faEye,
  faCheck,
  faSyncAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { faWrench, faExclamation } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AlertModal from "../alert-modal";
import AlertService from "../../../services/Alert";
import { IconButtons, MosovoModals } from "..";
import { getAlertTypeElement } from "../../utils/alert-settings-helper";
import modalactions from "../mosovo-modals/actions";
import {
  getColumnSearchProps,
  // getAlertGridAlertStateFilter,
  numericSorter,
  dateSorter,
  timeSorter,
  stringSorter,
  booleanSorter,
  // getAlertGridUrgencyFilter,
  // getAlertGridAlertTypeFilter,
  INPUT,
  DATE,
  TIME,
  AlertStates,
  AlertTypes,
} from "../../utils/grid-filter-helper";
import { hasRights, rights } from "../../utils/role-right-helper";

const { SearchBox, AlertCircleIconButton } = IconButtons;
const { BulkAlertResolveModal } = MosovoModals;
const { RangePicker } = DatePicker;

const KeyToODataField = {
  Urgency: "IsUrgent",
  AlertType: "AlertType/Id",
  Nominal: "Nominal/FullName",
  OffenderManager: "Nominal/OffenderManager",
  Date: "CreatedDate",
  Time: "CreatedDate",
  Reviewer: "Reviewer/FullName",
  AlertState: "AlertState/Id",
};

class AlertGrid extends React.Component {
  constructor(props) {
    super(props);

    const paginationObj = {
      position: 'bottom',
      showSizeChanger: true,
      current: 1,
      pageSizeOptions: [10, 20, 50, 100],
      pageSize: 10,
      total: 1,
      hasError: false,
    };

    this.state = {
      selectedRowKeys: [],
      AlertId: 0,
      AlertModalVisible: false,
      TypeIcon: null,
      Alerts: [],
      Nominal: null,
      searchQuery: props.searchQuery,
      sortString: '',
      pagination: paginationObj,
      loading: props.loading,
      pendingAlerts: [],
      numUpdates: 0,
    };

    this.filterData = this.filterData.bind(this);
  }

  componentDidMount() {
    const searchQuery =
      `&$count=true&$top=${this.state.pagination.pageSize}&$skip=${this.state.pagination.pageSize * (this.state.pagination.current - 1)}${this.props.searchQuery}&$orderby=AlertState/Id,CreatedDate%20desc`;
    this.setState({
      loading: true,
    });
    AlertService.getAlertsOData(searchQuery).then(
      (response) => {
        const total = response['@odata.count'];
        this.setState(prevState => ({
          Alerts: response.value,
          loading: false,
          pagination: {
            ...prevState.pagination,
            total,
          },
        }));
      },
    );
    setTimeout(() => this.getAlertsAsync(searchQuery), 60000);
  }

  getAlertsAsync(searchQuery) {
    const regex = new RegExp(`&\\$orderby=.*`);
    AlertService.getAlertsOData(`${searchQuery.replace(regex, '')}&$orderby=AlertState/Id,CreatedDate%20desc`).then(
      (response) => {
        this.setPendingAlertsData(response.value);
      });
    setTimeout(() => this.getAlertsAsync(searchQuery), 60000);
  }

  openBulkAlertResolveModal = () => {
    this.props.dispatch(modalactions.openBulkAlertModal());
  }

  setPendingAlertsData = (data) => {
    let updates = 0;
    if (data && data.length > this.state.Alerts.length) {
      updates = data.length - this.state.Alerts.length;
    }
    this.setState((prevState) => ({
      ...prevState,
      pendingAlerts: data,
      numUpdates: updates
    }));
  }

  onRefreshClick = () => {
    const pending = this.state.pendingAlerts;
    const total = this.state.numUpdates;
    if (total) {
      this.setState((prevState) => ({
        Alerts: pending,
        pendingAlerts: [],
        numUpdates: 0,
        pagination: {
          ...prevState.pagination,
          total,
        },
      }));
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(prevState => ({
      ...prevState,
      loading: true,
      sortString: sorter,
      pagination: prevState.pagination !== false
        ? {
          ...prevState.pagination,
          current: prevState.pagination
            && prevState.pagination.pageSize !== null
            && prevState.pagination.pageSize !== pagination.pageSize
            ? 1 : pagination.current,
          pageSize: pagination.pageSize,
          filters,
        } : false,
      }), () => { 
        if (filters) {
          const params = [{dataIndex: "Id", objectName: "AlertType", values: filters.AlertType},
            {dataIndex: "IsUrgent", values: filters.Urgency},
            {dataIndex: "Id", objectName: "AlertState", values: filters.AlertState},
          ];
          this.updateFilters(params, sorter);
        } else {
          this.refreshGrid(null, sorter);
        }
      }
    );
  }

  refreshGrid = (sq, sorter) => {
    let searchQuery = 
      `&$count=true&$top=${this.state.pagination.pageSize}&$skip=${this.state.pagination.pageSize * (this.state.pagination.current - 1)}${sq ?? this.state.searchQuery}`;
    let orderByClause = '&$orderby=AlertState/Id,CreatedDate%20desc';
    if (sorter && sorter.order) {
      let obc = '&$orderby=';
      const sortOrder = sorter.order;
      const keyToOData = KeyToODataField[sorter.columnKey];
      if (keyToOData) {
        if (sorter.columnKey === 'Time') {
          obc = `${obc}TimeHours`;
          if (sortOrder === 'descend')
            obc = `${obc} desc`;
          obc = `${obc},TimeMinutes`;
        } else {
          obc = `${obc}${keyToOData}`
        }
        if (sortOrder === 'descend')
          obc = `${obc} desc`;
        orderByClause = obc;
      }
    }
    const regex = new RegExp(`&\\$orderby=.*`);
    searchQuery = searchQuery.replace(regex, '');
    searchQuery = `${searchQuery}${orderByClause}`;
    console.info(sq, searchQuery);
    AlertService.getAlertsOData(searchQuery).then(
      (response) => {
        const total = response['@odata.count'];
        this.setState(prevState => ({
          Alerts: response.value,
          loading: false,
          pagination: prevState.pagination !== false ? {
            ...prevState.pagination,
            total,
          } : false,
        }), () => {
        })
      },
    );
  }

  onSearch = (value) => {
    if (value) {
      const regex = /(?<=FullName,').+(?=')/
      this.setState(prevState => ({
        loading: true,
        searchQuery: prevState.searchQuery.includes('FullName') ? prevState.searchQuery.replace(regex, value) : `${prevState.searchQuery} and contains(Nominal/FullName,'${value}')`,
        pagination: prevState.pagination !== false
        ? {
          ...prevState.pagination,
          current: 1,
        } : false, 
      }), () => {
        this.refreshGrid(this.state.searchQuery);
      });
    } else {
      const regex = /(?= and contains\(Nominal\/FullName,').+'\)/
      this.setState(prevState => ({
        loading: true,
        searchQuery: prevState.searchQuery.replace(regex, ''),
        pagination: prevState.pagination !== false
        ? {
          ...prevState.pagination,
          current: 1,
        } : false, 
      }), () => {
        this.refreshGrid(this.state.searchQuery);
      });
    }
  }

  onSearchDate = (value) => {
    if (value && value.length === 2) {
      const startDate = value[0].utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ").replace('+', '%2B');
      const endDate = value[1].utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ").replace('+', '%2B');
      const startRegex = /(?<=CreatedDate ge ).+(?= and)/
      const endRegex = /(?<=CreatedDate le ).+(?= |$)/
      const searchQuery = this.state.searchQuery.includes('CreatedDate')
        ? this.state.searchQuery.replace(startRegex, startDate).replace(endRegex, endDate)
        : `${this.state.searchQuery} and CreatedDate ge ${startDate} and CreatedDate le ${endDate}`;
      this.setState(prevState => ({
        loading: true,
        searchQuery,
        pagination: prevState.pagination !== false
        ? {
          ...prevState.pagination,
          current: 1,
        } : false, 
      }), () => {
        this.refreshGrid(searchQuery);
      });
    } else {
      const regex = /(?= and CreatedDate ge ).+(?<= and CreatedDate le ).+?(?= |$)/
      this.setState(prevState => ({
        loading: true,
        searchQuery: prevState.searchQuery.replace(regex, ''),
        pagination: prevState.pagination !== false
        ? {
          ...prevState.pagination,
          current: 1,
        } : false, 
      }), () => {
        this.refreshGrid(this.state.searchQuery);
      });
    }
  }

  onClickRow = (record) => {
    AlertService.getAlertDetail(record.Id).then((result) => {
      const alert = result.data;
      let ezs = [];
      if (alert && alert.AlertType.Id === 1) ezs = [alert.ExclusionZone];
      else if (alert && alert.AlertType.Id === 3) ezs = [alert.CurfewSettings];
      let isShow = false;
      if (ezs.length > 0) isShow = true;
        this.setState({
        Alert: result.data,
        ExclusionZones: ezs,
        isShowExclusionZones: isShow,
        AlertId: record.Id,
        AlertModalVisible: true,
        TypeIcon: getAlertTypeElement(record, this.props.active),
        Nominal: record.Nominal,
      })
    });
  };

  resolveAlert = () => {
    this.refreshGrid();
    this.closeModal();
  };

  closeModal = () => {
    this.setState({
      AlertId: 0,
      AlertModalVisible: false,
    });
  };

  onRowBind = (record) => {
    return {
      onClick: () => this.onClickRow(record),
    };
  };

  ChangeIndex = (currentIndex, changeAmount) => {
    const newIndex = currentIndex + changeAmount;
    const alert = this.state.Alerts[newIndex];
    this.onClickRow(alert);
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  updateFilters(params, sorter) {
    if (!params) return;
    // eslint-disable-next-line react/no-access-state-in-setstate, prefer-destructuring
    let searchQuery = this.state.searchQuery;
    for (let j = 0; j < params.length; j += 1) {
      const { dataIndex, objectName, values } = params[j];
      let field = dataIndex;
      if (objectName) {
        field = `${objectName}/${dataIndex}`;
      }
  
      if (values && values.length > 0) {
        const regex = new RegExp(`(?<=${field} in \\().+(?=\\))`);
        let inClause=`${values[0]}`;
        for (let i = 1; i < values.length; i += 1) {
          inClause = `${inClause},${values[i]}`
        }
        if (searchQuery.includes(field)) {
          searchQuery = searchQuery.replace(regex, inClause);
        } else {
          searchQuery = `${searchQuery} and ${field} in (${inClause})`;
        }
      } else {
        const regex = new RegExp(` and ${field} in \\(.*\\)`);
        searchQuery = searchQuery.replace(regex, '');
      }
    }

    this.setState({
      loading: true,
      searchQuery,
    }, () => {
      this.refreshGrid(this.state.searchQuery, sorter);
    });
  }
  
  filterData(values, paramObj) {
    const { dataIndex, dataIndex2, componentType, objectName } = paramObj;
    let field = dataIndex;
    if (objectName) {
      field = `${objectName}/${dataIndex}`;
    }
    if (values && values[0]) {
      if (componentType === INPUT) {
        const regex1 = new RegExp(`(?<=${dataIndex},').+(?=')`);
        const regex2 = new RegExp(`(?<=${dataIndex2},').+(?=')`);
        let field2 = dataIndex2;
        if (objectName) {
          field2 = `${objectName}/${dataIndex2}`;
        }
        let replacement=values[0];
        if (values[0].toLowerCase() === 'none') {
          replacement = `${this.state.searchQuery} and ${field} eq null`;
          if (dataIndex2) {
            replacement = `${replacement} and ${field2} eq null`;
          }
        } else if (this.state.searchQuery.includes(dataIndex)) {
          replacement = this.state.searchQuery.replace(regex1, values[0]).replace(regex2, values[0]);
        } else if (dataIndex2) {
          replacement = `${this.state.searchQuery} and (contains(${field},'${values[0]}') or contains(${field2},'${values[0]}'))`;
        } else {
          replacement = `${this.state.searchQuery} and contains(${field},'${values[0]}')`;
        }
        this.setState(prevState => ({
          loading: true,
          searchQuery: replacement,
          pagination: prevState.pagination !== false
          ? {
            ...prevState.pagination,
            current: 1,
          } : false, 
        }), () => {
          this.refreshGrid(this.state.searchQuery);
        });
      } else {
        let startDate;
        let searchQuery = "";
        if (componentType === DATE) {
          const startRegex = new RegExp(`(?<=${dataIndex} eq ).+(?=)`);
          startDate = moment(values[0], "DD-MM-YYYY").format("YYYY-MM-DD")
          searchQuery = this.state.searchQuery.includes(dataIndex)
            ? this.state.searchQuery.replace(startRegex, startDate)
            : `${this.state.searchQuery} and ${field} eq ${startDate}`;
          }
        if (componentType === TIME) {
          const hourRegex = new RegExp(`(?<=hour(${dataIndex}) eq ).+(?=)`);
          const minuteRegex = new RegExp(`(?<=minute(${dataIndex}) eq ).+(?=)`)
          startDate = moment(values[0], "h:mm:ss a").utc();
          const minutes = startDate.minute()<10 ? `0${startDate.minute()}` : startDate.minute();
          searchQuery = this.state.searchQuery.includes(dataIndex)
            ? this.state.searchQuery.replace(hourRegex, startDate.hours()).replace(minuteRegex, minutes)
            : `${this.state.searchQuery} and hour(${field}) eq ${startDate.hours()} and minute(${field}) eq ${minutes}`;
          }
        this.setState(prevState => ({
          loading: true,
          searchQuery,
          pagination: prevState.pagination !== false
          ? {
            ...prevState.pagination,
            current: 1,
          } : false, 
        }), () => {
          this.refreshGrid(searchQuery);
        });
      }
    } else if (componentType === INPUT) {
      let regexString = `(?= and contains\\(${field},').+'\\)`;
      let field2 = dataIndex2;
      if (objectName) {
        field2 = `${objectName}/${dataIndex2}`;
        regexString = `(?= and \\(contains\\(${field},').+('\\) or contains\\(${field2},').+('\\)\\))`;
      }
      const regex = new RegExp(regexString);
      // none case
      let regexStringForNone = ` and ${field} eq null`;
      if (dataIndex2) {
        regexStringForNone = `${regexStringForNone} and ${field2} eq null`;
      }
      const regex2 = new RegExp(regexStringForNone);
      this.setState(prevState => ({
        loading: true,
        searchQuery: prevState.searchQuery.replace(regex, '').replace(regex2, ''),
        pagination: prevState.pagination !== false
        ? {
          ...prevState.pagination,
          current: 1,
        } : false, 
      }), () => {
        this.refreshGrid(this.state.searchQuery);
      });
    } else {
      let regex = "";
      if (componentType === DATE) {
        regex = new RegExp(` and ${field} eq \\d{4}-\\d{2}-\\d{2}`);
      }
      if (componentType === TIME) {
        regex = new RegExp(` and hour\\(${field}\\) eq \\d{1,2} and minute\\(${field}\\) eq \\d{1,2}`);
      }
      this.setState(prevState => ({
        loading: true,
        searchQuery: prevState.searchQuery.replace(regex, ''),
        pagination: prevState.pagination !== false
        ? {
          ...prevState.pagination,
          current: 1,
        } : false, 
      }), () => {
        this.refreshGrid(this.state.searchQuery);
      });
    }
  }

  render() {
    const gridColumns = [
      {
        dataIndex: "IsUrgent",
        key: "Urgency",
        width: "2%",
        align: 'center',
        sorter: (a, b) => {return booleanSorter(a, b, "IsUrgent");},
        // filters: [
        //   {text: "Urgent", value: true},
        //   {text: "Not Urgent", value: false}
        // ],
        render: (text, record) =>
          record.IsUrgent ? (
            <>
              <Tooltip placement="topRight" title="Urgent!">
                <FontAwesomeIcon
                  icon={faExclamation}
                  style={{
                    color: this.props.active ? "#960000" : "#909090",
                  }}
                  alt="IsUrgent"
                />
              </Tooltip>
            </>
          ) : null,
      },
      {
        dataIndex: "AlertType",
        key: "AlertType",
        width: "5%",
        align: 'center',
        sorter: (a, b) => {return numericSorter(a.AlertType, b.AlertType, "Id");},
        filters: [
          {text: "Exclusion Zone Breach", value: AlertTypes.ExclusionZone},
          {text: "Location Fail", value: AlertTypes.LocationFail},
          {text: "Missed Curfew", value: AlertTypes.Curfew},
          {text: "Idle Device", value: AlertTypes.Idle},
          {text: "Missed/Incorrect Check-In", value: AlertTypes.CheckIn}
      ],
        render: (text, record) => <>{getAlertTypeElement(record, this.props.active)}</>,
      },
      {
        title: "Nominal",
        dataIndex: "Nominal",
        key: "Nominal",
        width: "15%",
        sorter: (a, b) => {return stringSorter(a.Nominal, b.Nominal, "FullName");},
        ...getColumnSearchProps({ dataIndex: "FullName", title: "Nominal", componentType: INPUT, objectName: "Nominal", callback: this.filterData}),
        render: (text, record) =>
          record.Nominal !== null && record.Nominal.FullName !== null ? (
            <>
              <span>
                {record.Nominal.FullName}
              </span>
            </>
          ) : (
            <>
              <span
                className={
                  this.props.active
                    ? "alert-reviewer-none"
                    : "alert-reviewer-none-suspended"
                }
              >
                None
              </span>
            </>
          ),
      },
      {
        title: "Offender Manager",
        dataIndex: "Nominal.OffenderManager",
        key: "OffenderManager",
        width: "15%",
        sorter: (a, b) => {return stringSorter(a.Nominal, b.Nominal, "OffenderManager");},
        ...getColumnSearchProps({ dataIndex: "OffenderManager", title: "Offender Manager", componentType: INPUT, objectName: "Nominal", callback: this.filterData}),
        render: (text, record) =>
          record.Nominal !== null && record.Nominal.OffenderManager !== null ? (
            <>
              <span>
                {record.Nominal.OffenderManager}
              </span>
            </>
          ) : (
            <>
              <span
                className={
                  this.props.active
                    ? "alert-reviewer-none"
                    : "alert-reviewer-none-suspended"
                }
              >
                None
              </span>
            </>
          ),
      },
      {
        title: "Date",
        dataIndex: "CreatedDate",
        key: "Date",
        width: "10%",
        sorter: (a, b) => {return dateSorter(a, b, "CreatedDate");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "CreatedDate", title: "Date", componentType: DATE, callback: this.filterData}),
        render: (text, record) =>
          record.CreatedDate !== null ? (
            <>
              <span
                style={{
                  color: this.props.active
                    ? "rgba(0, 0, 0, 0.85)"
                    : "#909090",
                  fontStyle: this.props.active ? "normal" : "italic",
                }}
              >
                {moment(record.CreatedDate).local().format("DD-MM-YYYY")}
              </span>
            </>
          ) : null,
      },
      {
        title: "Time",
        dataIndex: "CreatedDate",
        key: "Time",
        width: "10%",
        sorter: (a, b) => {return timeSorter(moment(a.CreatedDate), moment(b.CreatedDate));},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "CreatedDate", title: "Time", componentType: TIME, callback: this.filterData}),
        render: (text, record) =>
          record.CreatedDate !== null ? (
            <>
              <span
                style={{
                  color: this.props.active
                    ? "rgba(0, 0, 0, 0.85)"
                    : "#909090",
                  fontStyle: this.props.active ? "normal" : "italic",
                }}
              >
                {moment(record.CreatedDate).local().format("h:mm:ss A")}
              </span>
            </>
          ) : null,
      },
      {
        title: "Reviewer",
        dataIndex: "Reviewer",
        key: "Reviewer",
        width: "10%",
        sorter: (a, b) => {return stringSorter(a.Reviewer, b.Reviewer, "FirstName");},
        ...getColumnSearchProps({ dataIndex: "FirstName", dataIndex2: "LastName", title: "Reviewer", componentType: INPUT, objectName: "Reviewer", callback: this.filterData}),
        render: (text, record) =>
          record.Reviewer !== null && record.Reviewer.Name !== null ? (
            <>
              <span
                className={
                  this.props.active ? "alert-reviewer" : "alert-reviewer-suspended"
                }
              >
                {record.Reviewer.FirstName} {record.Reviewer.LastName}
              </span>
            </>
          ) : (
            <>
              <span
                className={
                  this.props.active
                    ? "alert-reviewer-none"
                    : "alert-reviewer-none-suspended"
                }
              >
                None
              </span>
            </>
          ),
      },
      {
        title: "Status",
        dataIndex: "AlertState",
        key: "AlertState",
        width: "40%",
        sorter: (a, b) => {return numericSorter(a.AlertState, b.AlertState, "Id")},
        filters: [
          { text: AlertStates.ActionRequired.Name, value: AlertStates.ActionRequired.Id},
          { text: AlertStates.InReview.Name, value: AlertStates.InReview.Id},
          { text: AlertStates.Resolved.Name, value: AlertStates.Resolved.Id}
        ],
        // onFilter: (value) => { this.filterData([value], {dataIndex: "Id", componentType: "BaseFilter", objectName: "AlertState"}); },
            // ...getAlertGridAlertStateFilter(),
        render: (text, record) =>
          record.AlertState.Name === AlertStates.Resolved.Name ? (
            <>
              <FontAwesomeIcon
                icon={faCheck}
                style={{
                  color: this.props.active ? "#636363" : "#909090",
                }}
                alt="resolved"
              />
              <span
                style={{
                  fontWeight: 300,
                  color: this.props.active ? "#636363" : "#909090",
                  fontStyle: this.props.active ? "normal" : "italic",
                }}
              >
                {record.AlertState.Name}
              </span>
            </>
          ) : (
            [
              record.AlertState.Name === AlertStates.InReview.Name ? (
                <>
                  <FontAwesomeIcon
                    icon={faEye}
                    style={{
                      color: this.props.active ? "#000000" : "#909090",
                    }}
                    alt="in review"
                  />
                  <span
                    style={{
                      fontWeight: 300,
                      color: this.props.active ? "#000000" : "#909090",
                      fontStyle: this.props.active ? "normal" : "italic",
                    }}
                  >
                    {record.AlertState.Name}
                  </span>
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={faWrench}
                    style={{
                      color: this.props.active ? "#960000" : "#909090",
                    }}
                    alt="action required"
                  />
                  <span
                    style={{
                      fontWeight: 300,
                      color: this.props.active ? "#960000" : "#909090",
                      fontStyle: this.props.active ? "normal" : "italic",
                    }}
                  >
                    {record.AlertState.Name}
                  </span>
                </>
              ),
            ]
          ),
      },
    ];

    const alertState = this.state.Alerts.length > 0
      ? this.state.Alerts.find((a) => a.Id === this.state.AlertId)?.AlertState
          .Id
      : 0;

    const { selectedRowKeys } = this.state;
    // eslint-disable-next-line no-unused-vars
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };
  
    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
          <Col xs={8} sm={8} md={2} lg={2}>
            <span className="mosovo-table-header">Alerts</span>
          </Col>
          <Col xs={2} md={2} lg={2}>
            <Tooltip placement="topRight" title={this.state.numUpdates > 0 ? `Refresh (${this.state.numUpdates}) new alets` : "Refresh alert details"}>
              <button
                type="button"
                onClick={this.onRefreshClick}
                className="addExclusionZoneButton"
              >
                <FontAwesomeIcon
                  icon={faSyncAlt}
                  color={this.state.numUpdates > 0 ? "#EF4E4E" : "#243B53"}
                  style={{ display: "inline" }}
                />
              </button>
            </Tooltip>
          </Col>
          <Col xs={6} sm={6} md={3} lg={3} />
          <Col xs={12} md={8} lg={8} justify="end">
            <SearchBox
              placeholder="Search by Nominal name"
              allowClear
              onSearch={this.onSearch}
              minText={3}
              loading={this.props.loading}
            />
          </Col>
          <Col xs={2} md={1} lg={1} />
          <Col xs={10} md={7} lg={7}>
            <RangePicker
              className="p5-form-datepicker-input"
              format="DD/MM/YYYY hh:mm:ss A"
              separator=" to "
              onChange={(val) => {
                this.onSearchDate(val);
              }}
              allowClear
              showTime={{ format: 'hh:mm:ss A', use12Hours:true }}
            />
          </Col>
          {(hasRights([rights.CanManageForceAdmins])
            || hasRights([rights.CanManageAreaAdmins])
            || hasRights([rights.CanManageTeamAdmins])
            || hasRights([rights.CanManageTeamUsers]))
            && (
              <>
                <Col xs={2} sm={2} md={1} lg={1} style={{textAlign: "center"}}>
                  <AlertCircleIconButton 
                    onClick={this.openBulkAlertResolveModal}
                    isDisabled={this.state.selectedRowKeys.length === 0}
                    tooltipText="Resolve selected alerts"
                  />                
                </Col>
              </>
           )}         
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <Table
              rowSelection={rowSelection}
              loading={this.state.loading}
              dataSource={this.state.Alerts}
              columns={gridColumns}
              onChange={this.handleTableChange}
              pagination={this.state.pagination}
              rowKey="Id"
              scroll={{ x: true }}
              onRow={(record) => this.onRowBind(record)}
            />
          </Col>
        </Row>
        <AlertModal
          showAlertModal={this.state.AlertModalVisible}
          Alert={this.state.Alert}
          AlertId={this.state.AlertId}
          TypeIcon={this.state.TypeIcon}
          closeModal={this.closeModal}
          resolveAlert={this.resolveAlert}
          ChangeIndex={this.ChangeIndex}
          AlertStateId={alertState}
          Nominal={this.state.Nominal}
          ExclusionZones={this.state.ExclusionZones}
          isShowExclusionZones={this.state.isShowExclusionZones}
        />
        <BulkAlertResolveModal 
          selectedAlerts={this.state.selectedRowKeys}
          refreshGrid={() => this.refreshGrid(this.state.searchQuery, this.state.sortString)}
        />
      </div>
    );
  }
}

AlertGrid.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  Nominal: PropTypes.shape({}),
  active: PropTypes.bool,
  searchQuery: PropTypes.string,
};

AlertGrid.defaultProps = {
  dispatch: () => {},
  loading: false,
  Nominal: null,
  active: true,
  searchQuery: '',
};

function mapStateToProps(state) {
  const { loading, Nominal } = state.Nominal;
  return {
    loading,
    Nominal,
  };
}

const connectedAlertGrid = connect(mapStateToProps)(AlertGrid);

export { connectedAlertGrid as default };

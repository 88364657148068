import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal } from "antd";
import action from "../actions";

class NotFoundModal extends React.Component {

  render() {
    return (
      <>
        <Modal
          title="Not Found"
          visible={this.props.showModal}
          // onCancel={this.CloseModal}
          footer={null}
          width={600}
          onCancel={() => this.props.dispatch(action.closeModal())}
          destroyOnClose
        >
          <div>
            No Results found, please expand your search
          </div>
        </Modal>
      </>
    );
  }
}

NotFoundModal.propTypes = {
  dispatch: PropTypes.func,
  //   showEditModal: PropTypes.bool,
  showModal: PropTypes.bool,
};

NotFoundModal.defaultProps = {
  dispatch: () => {},
  //   showEditModal: false,
  showModal: false,
};

function mapStateToProps(state) {
  const { Investigate } = state;

  return { showModal: Investigate.showNotFoundModal };
}

const connectedNotFoundModal = connect(mapStateToProps)(NotFoundModal);

export { connectedNotFoundModal as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin, Button, notification } from "antd";
import { faBell } from "@fortawesome/pro-solid-svg-icons";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAlertTypeElement } from "../../utils/alert-settings-helper";
import AlertDetail from "./AlertDetail";
import AlertService from "../../../services/Alert";
import MessageModal from "../message-nominal-modal";
import "./Alert.scss";

class AlertModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      Alert: this.props.Alert,
      ShowMessageModal: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.AlertId !== this.props.AlertId && this.props.AlertId > 0) {
      AlertService.getAlertDetail(this.props.AlertId).then((result) => {
        this.setState({
          Alert: result.data,
        })
      });
    }
  }

  closeMessageModal = () => {
    this.setState({
      ShowMessageModal: false,
    })
  }

  onMessageClick = () => {
    this.setState({
      ShowMessageModal: true,
    })
  }

  //   AllocationUpdate = (data) => {
  //     this.props.dispatch(actions.UpdateAllocation(data, this.CloseUserModal));
  //     // console.log("updating", data);
  //   };

  modalTitle = () => {
    if(!this.props.Nominal)
    {
      return null;
    }
    return (
      <span>
        <FontAwesomeIcon
          icon={faBell}
          className="AlertModalTitle"
        />
        <span className="AlertModalTitle">{`  Alerts: ${this.props.Nominal.FirstName} ${this.props.Nominal.LastName}`}</span>
        {this.props.AlertIdList.length > 0 && (
          <span className="AlertIndex">{`${this.props.AlertIdList.indexOf(this.props.AlertId) + 1} of ${this.props.AlertIdList.length} alerts`}</span>
        )}
      </span>
    )
  }

  resolveAlert = () => {
    AlertService.resolveAlert(this.props.AlertId).then(() => {
      notification.success({
        message: 'Alert Resolved',
        description:
          'The alert has been resolved.',
      });
      this.props.resolveAlert();
    });
  }

  createFooter = () => {
    let footer = null;
    if (this.props.AlertIdList.length > 0) {
      const index = this.props.AlertIdList.indexOf(this.props.AlertId);
      footer = (
        <>
          <Button
            type="text"
            onClick={() => this.props.ChangeIndex(index, -1)}
            disabled={index===0}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              style={{marginRight: "10px"}}
              id="nominaleBack"
            />
          </Button>
          <Button
            type="text"
            onClick={() => this.props.ChangeIndex(index, 1)}
            disabled={index===this.props.AlertIdList.length - 1}
          >
            <FontAwesomeIcon
              icon={faChevronRight}
              style={{marginRight: "10px"}}
              id="nominaleBack"
            />
          </Button>
        </>
      );
    }
    footer = (
      <Row>
        <Col span={12} className="DisplayLeft">
          {footer}
        </Col>
        <Col span={12}>
          <Button
            onClick={this.props.closeModal}
          >
            Close
          </Button>
          <Button
            className="blueButton"
            onClick={this.resolveAlert}
            disabled={this.props.AlertStateId === 3 || !(this.props.Nominal && this.props.Nominal.OffenderManagerId === this.props.AccountId)}
          >
            Resolve
          </Button>
        </Col>
      </Row>
    )
    return footer;
  }

  render() {
    if(!this.props.AlertId) {
      return null;
    }
    return (
      <>
        <Modal
          title={this.modalTitle()}
          className="AlertModal"
          visible={this.props.showAlertModal}
          onCancel={this.props.closeModal}
          footer={this.createFooter()}
          width={1000}
          height={1500}
          destroyOnClose
        >
          <div>
            <Row>
              <Col span={24}>
                <Spin spinning={this.props.loading} />
                <AlertDetail 
                  TypeIcon={this.props.TypeIcon ?? getAlertTypeElement(this.state.Alert, true)}
                  Alert={this.state.Alert}
                  OnMessageClick={this.onMessageClick}
                  // isShowExclusionZones={this.props.isShowExclusionZones}
                />
              </Col>
            </Row>
          </div>
        </Modal>
        <MessageModal
          showMessageModal={this.state.ShowMessageModal}
          Nominal={this.props.Nominal}
          closeModal={this.closeMessageModal}
        />
      </>
    );
  }
}

AlertModal.propTypes = {
  // dispatch: PropTypes.func,
  resolveAlert: PropTypes.func,
  ChangeIndex: PropTypes.func,
  closeModal: PropTypes.func.isRequired,
  //   showEditModal: PropTypes.bool,
  showAlertModal: PropTypes.bool,
  loading: PropTypes.bool,
  // Name: PropTypes.string,
  // ExclusionZones: PropTypes.arrayOf(PropTypes.shape({})),
  Alert: PropTypes.shape(),
  AlertId: PropTypes.number.isRequired,
  TypeIcon: PropTypes.element,
  AlertIdList: PropTypes.arrayOf(PropTypes.number),
  AlertStateId: PropTypes.number,
  Nominal: PropTypes.shape(),
  AccountId: PropTypes.number,
  // isShowExclusionZones: PropTypes.bool,
};

AlertModal.defaultProps = {
  // dispatch: () => {},
  resolveAlert: () => {},
  ChangeIndex: () => {},
  //   showEditModal: false,
  TypeIcon: null,
  // Name: '',
  showAlertModal: false,
  loading: false,
  // ExclusionZones: [],
  Alert: null,
  AlertIdList: [],
  AlertStateId: 0,
  Nominal: null,
  AccountId: 0,
  // isShowExclusionZones: false,
};

function mapStateToProps(state) {
  const { loading } = state.Nominal;
  const AccountId = state.Account.currentUser.Id
  return { loading, AccountId };
}

const connectedAlertModal = connect(mapStateToProps)(AlertModal);

export { connectedAlertModal as default };

import apiRequest from "../common/utils/apiRequest";

function postSettings(data) {
  return apiRequest()
    .post(`api/CheckinRequest/Settings`, data)
    .then((result) => Promise.resolve(result))
    .catch((error) => Promise.reject(error));
}

function getCheckinHistory(querystring = "") {
  return apiRequest()
    .get(`/odata/CheckinOdata?${querystring}`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

export default {
  postSettings,
  getCheckinHistory,
};

import { toast } from "react-toastify";
import { NominalService, HelperService } from "../../services";
import constants from "./constants";

function getNominalLocations(params, querystring, callback) {
  function request() {
    return { type: constants.GET_NOMINAL_LOCATIONS_REQUEST, searchParams: params };
  }
  function success(data) {
    return { type: constants.GET_NOMINAL_LOCATIONS_SUCCESS, locations: data };
  }
  function failure(error) {
    return { type: constants.GET_NOMINAL_LOCATIONS_FAILURE, error };
  }
  function notFound() {
    return { type: constants.OPEN_NOT_FOUND };
  }

  return (dispatch) => {
    dispatch(request());
    NominalService.getNominalLocationOdata(querystring).then(
      (response) => {
        dispatch(success(response.data.value));

        if(!(response.data.value.length > 0)) {
          dispatch(notFound());
        }

        if(callback) {
          callback(response.data.value);
        }
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function exportLocations(params) {
  function request() {
    return { type: constants.HUB_CREATE_REQUEST };
  }
  function success(hub) {
    return { type: constants.HUB_CREATE_SUCCESS, hub };
  }
  function failure(error) {
    return { type: constants.HUB_CREATE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    HelperService.exportInvestigation(params).then(
      (hub) => {
        dispatch(success(hub));
        toast("Successfully exported report to Excel");
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An Error occurred, please try again");
      }
    );
  };
}

function resetNominalLocations() {
  return { type: constants.RESET_NOMINAL_LOCATIONS };
}

function closeModal() {
  function close() {
    return { type: constants.CLOSE_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

export default {
  getNominalLocations,
  resetNominalLocations,
  closeModal,
  exportLocations,
}
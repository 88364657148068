import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col } from "antd";

import { BulkCreateForm } from '../forms';
import actions from "../actions";


class ForceBulkModal extends React.Component {
  CloseBulkModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.closeBulkCreateModal());
    this.props.refreshGrid();
  };

  BulkSubmit = (data) => {
    const { dispatch } = this.props;
    dispatch(actions.createForceBulk(data, this.CloseBulkModal));
  }

  render() {
    return (
      <Modal title="New Force" visible={this.props.show} onCancel={this.CloseBulkModal} footer={null} width={700} destroyOnClose>
        <div>            
          <Row>
            <Col span={24}>
              <BulkCreateForm onCancel={this.CloseBulkModal} onSubmit={this.BulkSubmit} isLoading={this.props.loading} {...this.props.forceBulk} />
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

ForceBulkModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  show: PropTypes.bool,
  loading: PropTypes.bool,
  forceBulk: PropTypes.shape({}),
};

ForceBulkModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  show: false,
  loading: false,
  forceBulk: {},
};

function mapStateToProps(state) {
  const { loading, forceBulk } = state.Organisation;
  const { show } = state.Organisation.BulkCreateModal;

  return { show, loading, forceBulk };
}

const connectedForceBulkModal = connect(mapStateToProps)(ForceBulkModal);

export { connectedForceBulkModal as default };

import Constants from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  allocation: {},
  showAddModal: false,
  showEditModal: false,
};

export default function allocationReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.HUB_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case Constants.HUB_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        hubGrid: action.hub,
      };
    case Constants.HUB_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    case Constants.HUB_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case Constants.HUB_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case Constants.HUB_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case Constants.HUB_GET_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case Constants.HUB_GET_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        allocation: action.hub,
      };
    case Constants.HUB_GET_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case Constants.HUB_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case Constants.HUB_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case Constants.HUB_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case Constants.OPEN_ADD_ALLOCATION_MODAL:
      return {
        ...state,
        showAddModal: true,
        showEditModal: false,
      };
    case Constants.OPEN_EDIT_ALLOCATION_MODAL:
      return {
        ...state,
        showAddModal: false,
        showEditModal: true,
      };
    case Constants.CLOSE_ALLOCATION_MODAL:
      return {
        ...state,
        showAddModal: false,
        showEditModal: false,
        allocation: {},
      };
    default:
      return state;
  }
}

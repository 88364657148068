import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin } from "antd";

import { ContactNominalForm } from "../forms";
import actions from "../actions";

class ContactNominalModal extends React.Component {
  CloseModal = () => {
    this.props.dispatch(actions.closeNominalModal());
    this.props.refreshGrid();
  };

  ContactNominal = (data) => {
    this.props.dispatch(actions.contactNominal(data, this.CloseModal));
  };

  render() {
    return (
      <>
        <Modal
          title={`Contact: ${this.props.Nominal.FirstName} ${this.props.Nominal.LastName}`}
          visible={this.props.showContactModal}
          // onCancel={this.CloseModal}
          footer={null}
          width={600}
          onCancel={this.CloseModal}
          destroyOnClose
        >
          <div>
            <Row>
              <Col span={24}>
                <Spin spinning={this.props.loading}>
                  <ContactNominalForm
                    {...this.props.Nominal}
                    onCancel={this.CloseModal}
                    onSubmit={this.ContactNominal}
                    isLoading={this.props.loading}
                    goToTab={this.props.goToTab}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

ContactNominalModal.propTypes = {
  dispatch: PropTypes.func,
  goToTab: PropTypes.func,
  refreshGrid: PropTypes.func,
  //   showEditModal: PropTypes.bool,
  showContactModal: PropTypes.bool,
  loading: PropTypes.bool,
  FirstName: PropTypes.string,
  LastName: PropTypes.string,
  Nominal: PropTypes.shape({
    Id: PropTypes.number,
    FirstName: PropTypes.string,
    LastName: PropTypes.string,
  }),
};

ContactNominalModal.defaultProps = {
  dispatch: () => {},
  goToTab: () => {},
  refreshGrid: () => {},
  //   showEditModal: false,
  showContactModal: true,
  loading: false,
  FirstName: null,
  LastName: null,
  Nominal: {},
};

function mapStateToProps(state) {
  const { loading, showContactModal, Nominal } = state.Nominal;

  return { loading, showContactModal, Nominal };
}

const connectedContactNominalModal = connect(mapStateToProps)(
  ContactNominalModal
);

export { connectedContactNominalModal as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  Row,
  Col,
  Form,
  InputNumber,
} from "antd";
import { CloseBtn, SaveBtn } from '../../../common/components/form/button';
import actions from '../actions';

class AddNominalLocationForm extends React.Component {
    constructor(props) {
        super(props);
        this.formIK = React.createRef();
      }

  pivotData = (data) => ({
    ConsumerId: data.ConsumerId,   
    Latitude: data.Latitude,
    Longitude: data.Longitude,
    Reason: "test",
    BatteryLevel: 10,
    Heading: 10,
    Altitude: 10,
    Speed: 10,
    Accuracy: 10,
    RespondingToActiveNotification: true
  });

  addNominalLocation = (data) => {
    this.props.dispatch(actions.addNominalLocation(data));
  };

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
            ConsumerId: this.props.ConsumerId || 0,
            Latitude: this.props.Latitude || 0,
            Longitude: this.props.Longitude || 0,
        }}
        validationSchema={Yup.object().shape({
            ConsumerId: Yup.number(),
            Latitude: Yup.number(),
            Longitude: Yup.number()
        })}
          // onChange={(values) => this.handleChange(values)}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
            values,
            handleChange,
            handleSubmit,
            handleBlur,
            setFieldValue
        }) => (
          <Form id="AddNominalLocationForm" onSubmit={handleSubmit} onChange={handleChange}>
            <div>
              <Row>
                <Col span={24}>
                  <Row>
                    <Col sm={24} md={6}>
                      <span>Latitude:</span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item>
                        <InputNumber
                          id="Latitude"
                          style={{width:"100%"}}
                          onBlur={handleBlur}
                          value={values.Latitude}
                          onChange={(val) => {
                            setFieldValue("Latitude", val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={24} md={6}>
                      <span>Longitude:</span>
                    </Col>
                    <Col sm={24} md={18}>
                      <Form.Item>
                        <InputNumber
                          id="Longitude"
                          style={{width:"100%"}}
                          onBlur={handleBlur}
                          value={values.Longitude}
                          onChange={(val) => {
                            setFieldValue("Longitude", val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <CloseBtn onClick={this.props.onCancel} />
                </Col>
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <SaveBtn
                    onClick={handleSubmit}
                    loading={this.props.isLoading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
          )}
      </Formik>
      );
    }
}
  
AddNominalLocationForm.propTypes = {
    dispatch: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func,
    isLoading: PropTypes.bool,
	Latitude: PropTypes.number,
    Longitude: PropTypes.number,
    ConsumerId: PropTypes.number,
};
  
  AddNominalLocationForm.defaultProps = {
    dispatch: () => {},
    onCancel: () => {},
    isLoading: false,
	Latitude: 0,
    Longitude: 0,
    ConsumerId: 0,
};
  
  function mapStateToProps() {
      return {};
    }
  
    const connectedAddNominalLocationForm = connect(mapStateToProps)(AddNominalLocationForm);
    
    export { connectedAddNominalLocationForm as default };
  
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin } from "antd";

import { ExclusionZoneForm } from "../forms";
import actions from "../actions";

class ExclusionZoneModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ExclusionZone: {...this.props.AlertSettings.ExclusionZone},
      IsNotifyEmail: this.props.AlertSettings.IsNotifyEmail,
      IsNotifyText: this.props.AlertSettings.IsNotifyText,
      IsNotifyNominal: this.props.AlertSettings.IsNotifyNominal,
      IsNotifyTeamEmail: this.props.AlertSettings.IsNotifyTeamEmail,
      IsUrgent: this.props.AlertSettings.IsUrgent,
    };
  }

  componentDidUpdate(prevProps) {
    if((prevProps && prevProps.AlertSettings !== this.props.AlertSettings) || (prevProps === null && this.props.AlertSettings !== null))
    {
      this.refreshState();
    }
  }

  refreshState = () => {
    this.setState({
      ExclusionZone: {...this.props.AlertSettings.ExclusionZone},
      IsNotifyEmail: this.props.AlertSettings.IsNotifyEmail,
      IsNotifyText: this.props.AlertSettings.IsNotifyText,
      IsNotifyNominal: this.props.AlertSettings.IsNotifyNominal,
      IsNotifyTeamEmail: this.props.AlertSettings.IsNotifyTeamEmail,
      IsUrgent: this.props.AlertSettings.IsUrgent,
    });
}

  CloseModal = () => {
    this.props.dispatch(actions.closeNominalModal());
    this.props.refreshGrid();
    this.setState({
      ExclusionZone: {},
      IsNotifyEmail: false,
      IsNotifyText: false,
      IsNotifyNominal: false,
      IsNotifyTeamEmail: false,
      IsUrgent: false,
    });
  };

  CreateNominalExclusionZone = (data) => {
    this.props.dispatch(
      actions.createNominalExclusionZone(data, this.CloseModal)
    );
    this.props.refreshGrid();
  };

  UpdateNominalExclusionZone = (data) => {
    const updateData = {
      Id: this.props.AlertSettings.ExclusionZone.Id,
      Name: data.Name,
      ExclusionZoneTypeId: data.ExclusionZoneTypeId,
      Latitude: data.Latitude,
      Longitude: data.Longitude,
      Address: data.Address,
      Radius: data.Radius,
      UpdateNominalAlertSettings: [
        {
          Id: this.props.AlertSettings.Id,
          IsNotifyEmail: data.CreateNominalAlertSettings[0].IsNotifyEmail,
          IsNotifyText: data.CreateNominalAlertSettings[0].IsNotifyText,
          IsNotifyNominal: data.CreateNominalAlertSettings[0].IsNotifyNominal,
          IsNotifyTeamEmail: data.CreateNominalAlertSettings[0].IsNotifyTeamEmail,
          IsUrgent: data.CreateNominalAlertSettings[0].IsUrgent,
          IsAutomaticPushNotification: true,
          IsActive: true,
          NominalId: this.props.Nominal.Id,
        },
      ],
    };
    this.props.dispatch(
      actions.updateNominalExclusionZone(updateData, this.CloseModal)
    );
  };

  render() {
    return (
      <>
        <Modal
          title="Exclusion Zone"
          visible={this.props.showExclusionZoneModal}
          // onCancel={this.CloseModal}
          footer={null}
          width="90%"
          closable={false}
          destroyOnClose
        >
          <Row>
            <Col span={24}>
              <Spin spinning={this.props.loading}>
                <ExclusionZoneForm
                  refreshGrid={this.props.refreshGrid}
                  onCancel={this.CloseModal}
                  onSubmit={this.props.AlertSettings && this.props.AlertSettings.ExclusionZone ? this.UpdateNominalExclusionZone : this.CreateNominalExclusionZone}
                  Nominal={this.props.Nominal}
                  ExclusionZone={this.state.ExclusionZone}
                  IsNotifyEmail={this.state.IsNotifyEmail}
                  IsNotifyText={this.state.IsNotifyText}
                  IsNotifyNominal={this.state.IsNotifyNominal}
                  IsNotifyTeamEmail={this.state.IsNotifyTeamEmail}
                  IsUrgent={this.state.IsUrgent}
                />
              </Spin>
            </Col>
          </Row>
        </Modal>
      </>
    );
  }
}

ExclusionZoneModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  showExclusionZoneModal: PropTypes.bool,
  loading: PropTypes.bool,
  Nominal: PropTypes.shape(),
  AlertSettings: PropTypes.shape(),
};

ExclusionZoneModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  showExclusionZoneModal: true,
  loading: false,
  Nominal: {},
  AlertSettings: {},
};

function mapStateToProps(state) {
  const {
    loading,
    showExclusionZoneModal,
    Nominal,
    AlertSettings,
  } = state.Nominal;

  return { loading, showExclusionZoneModal, Nominal, AlertSettings };
}

const connectedExclusionZoneModal = connect(mapStateToProps)(
  ExclusionZoneModal
);

export { connectedExclusionZoneModal as default };

import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

const CancelBtn = (props) => {
  return(
    <Button
      block
      type="reset"
      id="cancelBtn"
      className="cancelBtn"
      onClick={() => props.onClick()}
    >
      Cancel
    </Button>
  );
}

CancelBtn.propTypes = {
    onClick: PropTypes.func,
}

CancelBtn.defaultProps = {
    onClick: () => {},
}

export default CancelBtn;

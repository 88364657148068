import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Layout, Row, Col } from "antd";

import { IconButtons } from '../../../common/components';
import actions from "../actions";

const { PlusIconButton } = IconButtons;

class NominalPageHeader extends React.PureComponent {
  AddNewNominal = () => {
    this.props.dispatch(actions.openAddNominalModal());
  }

  render() {
    const { Content } = Layout;

    return (
      <div className="PageHeader">
        <Content>
          <Row>
            <Col xs={20} sm={12} md={10} lg={6}>
              <div className="pg-header-title">
                <span>{this.props.title}</span>
              </div>
            </Col>
            <Col xs={0} sm={6} md={10} lg={15} />
            <Col xs={0} sm={6} md={4} lg={3}>
              <div style={{ height: "100%", position: "relative" }}>
                <div style={{ margin: "auto 15px", position: "absolute", top: "30%" }}>
                  <PlusIconButton onClick={this.AddNewNominal} tooltip="Add new nominal" title="Add Nominal" />
                </div>
              </div>
            </Col>
            <Col xs={4} sm={0} md={0} lg={0}>
              <div style={{ height: "100%", position: "relative" }}>
                <div style={{ margin: "auto 15px", position: "absolute", top: "30%" }}>
                  <PlusIconButton onClick={this.AddNewNominal} tooltip="Add new nominal" />
                </div>
              </div>
            </Col>
          </Row>
        </Content>
      </div>
    );
  }
}

NominalPageHeader.propTypes = {
  dispatch: PropTypes.func,
  title: PropTypes.string,
};

NominalPageHeader.defaultProps = {
  dispatch: () => {},
  title: 'Team Nominals'
};

const mapStateToProps = () => {
  return {};
};

const connectedNominalPageHeader = connect(mapStateToProps)(NominalPageHeader);

export { connectedNominalPageHeader as default };

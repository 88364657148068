import apiRequest from "../common/utils/apiRequest";

function create(file) {
  const form = new FormData();
  form.set("File", file, file.name);
  return apiRequest()
    .post("api/File", form, {
      headers: { "content-type": "multipart/form-data" },
    })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
}

function get(id) {
  return apiRequest()
    .get(`api/File/${id}`, {
      responseType: "arraybuffer",
    })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

function remove(id) {
  return apiRequest()
    .delete(`/api/File/${id}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getImageResize(id) {
  return apiRequest()
    .get(`api/File/Image/${id}?width=250&height=0`, {
      responseType: "arraybuffer",
    })
    .then((response) => Promise.resolve(response))
    .catch((error) => Promise.reject(error));
}

export default {
  create,
  get,
  getImageResize,
  remove,
};

import Constants from "./constants";
import { HelperService } from "../../../services";

const NominalTypeSelectActions = () => {
  const getList = () => {
    function request() {
      return { type: Constants.LIST_REQUEST };
    }
    function success(nominalTypes) {
      return { type: Constants.LIST_SUCCESS, nominalTypes };
    }
    function failure(error) {
      return { type: Constants.LIST_FAILURE, error };
    }

    return (dispatch) => {
      dispatch(request());
      HelperService.getNominalTypesList().then(
        (response) => {
          dispatch(success(response.data));
        },
        (error) => {
          dispatch(failure(error.toString()));
        }
      );
    };
  };

  return {
    getList,
  };
};

export default NominalTypeSelectActions;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import actions from "../actions";
import { UserGrid } from '../grids';
import { UserModal } from '../modals';

class UserIndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userList: [],
      userSearch: "",
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("Users"));
    this.getUserData();
  }

  getUserData = (value = "") => {
    this.setState({ userSearch: value });
    const searchQuery =
    value !== ""
        ? `$filter=contains(FullName,'${value}')`
        : "";
    this.props.dispatch(actions.getUserList(searchQuery, this.setUserData));
  };

  setUserData = (data) => {
    this.setState({         
      userList: data 
    });   
  }

  RefreshUserGrid = () => {
    this.getUserData(this.state.userSearch);
  }

  render() {
    return (
      <div className="mosovo-content-container">
        <Row justify="center">
          <Col span={24}>     
            <UserGrid userList={this.state.userList} onSearch={this.getUserData} refreshGrid={this.RefreshUserGrid} />      
          </Col>
        </Row>
        <UserModal refreshGrid={this.RefreshUserGrid} />
      </div>
    );
  }
}

UserIndexPage.propTypes = {
    dispatch: PropTypes.func,
};

UserIndexPage.defaultProps = {
    dispatch: () => {},
};

function mapStateToProps() {
  return {};
}

const connectUserIndexPage = connect(mapStateToProps)(UserIndexPage);

export { connectUserIndexPage as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Table, Row, Col, Divider, DatePicker, Tooltip } from "antd";
import moment from "moment";
import {
  faPhoneSlash,
  faPhone,
  faSyncAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import actions from "../actions";
import { DateFilterDropdown } from "../../../common/components/date-filter-dropdown";
import { MosovoModals, MosovoImages } from "../../../common/components";
import modalactions from "../../../common/components/mosovo-modals/actions";
import {
  getColumnSearchProps,
  dateSorter,
  stringSorter,
  timeSorter,
  NotificationStatuses,
  getCheckinGridMessageTypeFilter,
  DATE,
  TIME,
  numericSorter,
} from "../../../common/utils/grid-filter-helper";

const { RangePicker } = DatePicker;
const KeyToODataField = {
  AlertType: "NotificationStatusId",
  Date: "RunAfterDateTime",
  Time: "RunAfterDateTime",
  MessageType: "MessageType",
};

const { SelfieReviewModal } = MosovoModals;
const { ImageThumbnail } = MosovoImages;

class CheckinHistoryGrid extends React.Component {
  constructor(props) {
    super(props);

    const paginationObj = {
      position: 'bottom',
      showSizeChanger: true,
      current: 1,
      pageSizeOptions: [10, 20, 50, 100],
      pageSize: 10,
      total: 1,
      hasError: false,
    };

    let baseSearchQuery = `nominalId=${props.Nominal.Id}&$count=true&$top=${paginationObj.pageSize}&$skip=${paginationObj.pageSize * (paginationObj.current - 1)}`;
    baseSearchQuery = this.addOrderBy(baseSearchQuery, null);
    this.state = {
      AlertId: 0,
      AlertModalVisible: false,
      TypeIcon: null,
      activeQuery: null,
      queryValues: null,
      CheckinHistory: props.CheckinHistory,
      pagination: paginationObj,
      pendingHistory: [],
      pendingTotal: null,
      numUpdates: 0,
      searchQuery: baseSearchQuery,
    };

    this.filterData = this.filterData.bind(this);
  }

  componentDidMount() {
    if(this.props.Nominal.Id) {
      this.getCheckinHistory();
      setTimeout(() => this.getCheckinHistoryAsync(), 60000);
    }
  }

  componentDidUpdate(prevProps) {
    if(prevProps.Nominal ? prevProps.Nominal.Id !== this.props.Nominal.Id : false) {
      this.getCheckinHistory();
      setTimeout(() => this.getCheckinHistoryAsync(), 60000);
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    this.setState(prevState => ({
      ...prevState,
      loading: true,
      pagination: prevState.pagination !== false
        ? {
          ...prevState.pagination,
          current: prevState.pagination
            && prevState.pagination.pageSize !== null
            && prevState.pagination.pageSize !== pagination.pageSize
            ? 1 : pagination.current,
          pageSize: pagination.pageSize,
        } : false,
      }), () => {
        if (filters) {
          const params = [{dataIndex: "Id", objectName: "AlertState", values: filters.AlertState},
            {dataIndex: "NotificationStatusId", values: filters.AlertType},
          ];
          this.updateFilters(params, sorter);
        } else {
          this.getCheckinHistory(this.buildSearchQuery(this.state.activeQuery, sorter));
        }
      }
    );
  }

  getCheckinHistory = (queryString) => {
    const { dispatch } = this.props;
    dispatch(
      actions.getCheckinHistory(queryString ?? this.state.searchQuery, this.setHistory)
    );
  };

  getCheckinHistoryAsync() {
    actions.getCheckinHistory(this.state.searchQuery, this.setPendingData).then(() => {
      setTimeout(() => this.getCheckinHistoryAsync(), 60000);
    });
  }

  openSelfieReviewModal = (fileId) => {
    this.props.dispatch(modalactions.openSelfieReviewModal(fileId));
  }

  setPendingData = (response) => {
    const data = response.value;
    const total = response['@odata.count'];
    let updates = 0;
    if (data && total > this.state.pagination.total) {
      updates = total - this.state.pagination.total;
    }
    this.setState((prevState) => ({
      ...prevState,
      pendingHistory: data,
      numUpdates: updates,
      pendingTotal: total,
    }));
  }

  buildSearchQuery = (query, sorter) => {
    // eslint-disable-next-line
    let searchQuery = `nominalId=${this.props.Nominal.Id}&$count=true&$top=${this.state.pagination.pageSize}&$skip=${this.state.pagination.pageSize * (this.state.pagination.current - 1)}`;
    let queryTerms = {};
    if (this.state.queryValues) {
      queryTerms = this.state.queryValues;
    }
    if (query) {
      if (query.name) queryTerms.Name = query.name;
      if (query.startDate && query.endDate && query.dateField) {
        queryTerms.StartDate = query.startDate;
        queryTerms.EndDate = query.endDate;
        queryTerms.DateField = query.dateField;
      }
    } else {
      queryTerms.Name = undefined;
      queryTerms.DateField = undefined;
      queryTerms.StartDate = undefined;
      queryTerms.EndDate = undefined;
    }
    if (queryTerms.Name) {
      searchQuery = `${searchQuery}&$filter=contains(FullName,'${queryTerms.Name}')`
    }
    if (query && queryTerms.StartDate && queryTerms.EndDate && queryTerms.DateField) {
      searchQuery = `${searchQuery}&$filter=${queryTerms.DateField} ge ${queryTerms.StartDate} and ${queryTerms.DateField} le ${queryTerms.EndDate}`;
    }

    searchQuery = this.addOrderBy(searchQuery, sorter);
    // let orderByClause = '&$orderby=RunAfterDateTime desc'
    // if (sorter && sorter.order) {
    //   let obc = '&$orderby=';
    //   const sortOrder = sorter.order;
    //   const keyToOData = KeyToODataField[sorter.columnKey];
    //   if (keyToOData) {
    //     if (sorter.columnKey === 'Time') {
    //       obc = `${obc}TimeHours`;
    //       if (sortOrder === 'descend')
    //         obc = `${obc} desc`;
    //       obc = `${obc},TimeMinutes`;
    //     } else {
    //       obc = `${obc}${keyToOData}`
    //     }
    //     if (sortOrder === 'descend')
    //       obc = `${obc} desc`;
    //     orderByClause = obc;
    //   }
    // }
    // searchQuery = `${searchQuery}${orderByClause}`;

    this.setState((prevState) => ({
      searchQuery,
      queryValues: queryTerms,
      activeQuery: query,
      pagination: {
        ...prevState.pagination,
      }
    }), () => {
      this.getCheckinHistory();
    });
    return searchQuery;
  }

  setHistory = (data) => {
    const total = data['@odata.count'];
    this.setState((prevState) => ({
      ...prevState,
      CheckinHistory: data.value,
      pagination: {
        ...prevState.pagination,
        total,
      }
    }));
  };

 getCheckinIcon = (record) => {
    if (record && record.NotificationStatusId) {
      // TODO - setup IDs in constants
      switch (record.NotificationStatusId) {
        case NotificationStatuses.Error:
          return (
            <FontAwesomeIcon
              icon={faPhoneSlash}
              style={{
                color: this.props.active ? "#585757" : "#909090",
              }}
              alt="Idle"
            />
          );
        default:
          return (
            <FontAwesomeIcon
              icon={faPhone}
              style={{
                color: this.props.active ? "#585757" : "#909090",
              }}
              alt="Idle"
            />
          );
      }
    }
    return null;
  };

  onSearchDate = (value) => {
    if (value && value.length === 2) {
      const startDate = value[0].utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ").replace('+', '%2B');
      const endDate = value[1].utc().format("YYYY-MM-DDTHH:mm:ss.SSSZ").replace('+', '%2B');
      this.setState((prevState) => ({
        pagination: {
          ...prevState.pagination,
          current: 1,
        }
      }), () => {
        this.getCheckinHistory(this.buildSearchQuery({startDate, endDate, dateField: 'RunAfterDateTime'}));
      });
    } else {
      // This is needed as the filters persist incase one is cleared. This just makes the date filter range large.
      const startDate = moment('1/1/1900').format("YYYY-MM-DD");
      const endDate = moment('1/1/3000').format("YYYY-MM-DD");
      this.getCheckinHistory(this.buildSearchQuery({startDate, endDate, dateField: 'RunAfterDateTime'}));
    }
  }

  onDateFilterChange = (value) => {
    this.setState((prevState) => ({
      pagination: {
        ...prevState.pagination,
        current: 1,
      }
    }), () => {
      if (!value) {
          this.buildSearchQuery();
      }
      else if (value === 'Most Recent') {
          this.buildSearchQuery(null, true);
      } else {
        const startDate = moment(value).format().replace('+', '%2B');
        const endDate = moment().local().format().replace('+', '%2B');
        this.buildSearchQuery({startDate, endDate, dateField: 'RunAfterDateTime'})
      }
    });
  }

  onRefreshClick = () => {
    const pending = this.state.pendingHistory;
    if (this.state.numUpdates) {
      this.setState((prevState) => ({
        CheckinHistory: pending,
        pendingHistory: [],
        numUpdates: 0,
        pendingTotal: 0,
        pagination: {
          ...prevState.pagination,
          current: 1,
          total: prevState.pendingTotal,
        },
      }));
    } else {
        this.getCheckinHistory();
    }
  }

  addOrderBy = (searchQuery, sorter) => {
    const regex = new RegExp('&\\$orderby=[^&]*');
    let orderByClause = '&$orderby=RunAfterDateTime desc';
    if (sorter && sorter.order) {
      let obc = '&$orderby=';
      const sortOrder = sorter.order;
      const keyToOData = KeyToODataField[sorter.columnKey];
      if (keyToOData) {
        if (sorter.columnKey === 'Time') {
          obc = `${obc}TimeHours`;
          if (sortOrder === 'descend')
            obc = `${obc} desc`;
          obc = `${obc},TimeMinutes`;
        } else {
          obc = `${obc}${keyToOData}`
        }
        if (sortOrder === 'descend')
          obc = `${obc} desc`;
        orderByClause = obc;
      }
    }
    return searchQuery.includes("&$orderby") ? searchQuery.replace(regex, orderByClause) : `${searchQuery}${orderByClause}`;
  }

  updateFilters(params, sorter) {
    if (!params) return;
    // eslint-disable-next-line react/no-access-state-in-setstate, prefer-destructuring
    let searchQuery = this.state.searchQuery;
    const skipRegex = new RegExp('(?<=&\\$skip=)[^&]*(?=)')
    searchQuery = searchQuery.replace(skipRegex, this.state.pagination.pageSize * (this.state.pagination.current - 1));
    const topRegex = new RegExp('(?<=&\\$top=)[^&]*(?=)');
    searchQuery = searchQuery.replace(topRegex, this.state.pagination.pageSize);
    for (let j = 0; j < params.length; j += 1) {
      const { dataIndex, objectName, values } = params[j];
      let field = dataIndex;
      if (objectName) {
        field = `${objectName}/${dataIndex}`;
      }

      if (values && values.length > 0) {
        if (!searchQuery.includes('&$filter=')) {
          searchQuery=`${searchQuery}&$filter=`;
        } else {
          searchQuery=`${searchQuery} and `;
        }
        let inClause=`${values[0]}`;
        if (dataIndex === 'NotificationStatusId') {
          let regex = new RegExp(`\\({1,2}${field}.*\\)`);
          searchQuery = searchQuery.replace(regex, '');
          regex = new RegExp('&\\$orderby=[^&]*');
          searchQuery = searchQuery.replace(regex, '');
          regex = new RegExp('&\\$filter=($|&)');
          if (!regex.test(searchQuery)) {
            searchQuery = `${searchQuery} and `;
          }
          searchQuery = `${searchQuery}(`;
          for (let valIdx = 0; valIdx < values.length; valIdx += 1) {
            let andOr = '';
            if (valIdx > 0) andOr = ' or ';
            if (values[valIdx] === NotificationStatuses.NotInError) {
              inClause = `${NotificationStatuses.Sent},${NotificationStatuses.Pending},${NotificationStatuses.Error},${NotificationStatuses.Sent},${NotificationStatuses.VerifiedQuestion},${NotificationStatuses.VerifiedQuestion},${NotificationStatuses.VerifiedSelfie}`;
              if (!searchQuery.includes(inClause)) {
                searchQuery = `${searchQuery}${andOr}${field} in (${inClause}) eq false`;
              }
            } else if (values[valIdx] === NotificationStatuses.Send) {
              // regex = new RegExp(`${field} eq ${NotificationStatuses.Send} and (?<=RunAfterDateTime lt ).+(?=)`);
              const startDate = moment().format("YYYY-MM-DD")
              if (!searchQuery.includes(field) && searchQuery.includes(' and RunAfterDateTime lt')) {
                searchQuery = `${searchQuery}${andOr}(${field} eq ${NotificationStatuses.Send} and RunAfterDateTime lt ${startDate})`;
              }
            } else if (values[valIdx] === NotificationStatuses.Pending) {
              // regex = new RegExp(`${field} eq ${NotificationStatuses.Send} and (?<=RunAfterDateTime ge ).+(?=)`);
              const startDate = moment().format("YYYY-MM-DD")
              if (!searchQuery.includes(field) && searchQuery.includes(' and RunAfterDateTime ge')) {
                searchQuery = `${searchQuery}${andOr}(${field} eq ${NotificationStatuses.Send} and RunAfterDateTime ge ${startDate})`;
              }
            } else if (values[valIdx] === NotificationStatuses.Error) {
              const errorClause = `${field} eq ${NotificationStatuses.Error}`;
              if (!searchQuery.includes(errorClause)) {
                searchQuery = `${searchQuery}${andOr}${errorClause}`;
              }
            } else if (values[valIdx] === NotificationStatuses.VerifiedBiometrically || values[valIdx] === NotificationStatuses.VerifiedQuestion || values[valIdx] === NotificationStatuses.VerifiedSelfie) {
              let messageType = '';
              switch (values[valIdx]) {
                case NotificationStatuses.VerifiedBiometrically:
                  messageType = 'Fingerprint';
                  break;
                case NotificationStatuses.VerifiedQuestion:
                  messageType = 'Question';
                  break;
                case NotificationStatuses.VerifiedSelfie:
                  messageType = 'Selfie';
                  break;
                default:
                  messageType = '';
              }
              const verifiedClause = `${field} eq ${NotificationStatuses.Send} and MessageType=${messageType}`;
              if (!searchQuery.includes(verifiedClause)) {
                searchQuery = `${searchQuery}${andOr}${verifiedClause}`;
              }
            }
          }
          searchQuery = `${searchQuery})`;
        } else {
          for (let i = 1; i < values.length; i += 1) {
            inClause = `${inClause},${values[i]}`
          }
          if (searchQuery.includes(field)) {
            const regex = new RegExp(`(?<=${field} in \\().+(?=\\))`);
            searchQuery = searchQuery.replace(regex, inClause);
          } else {
            searchQuery = `${searchQuery} and ${field} in (${inClause})`;
          }
        }
      } else if (dataIndex === 'NotificationStatusId') {
        const regex = new RegExp(`and \\({1,2}${field}.*\\)`);
        const regex2 = new RegExp(`&\\$filter=\\({1,2}${field}.*\\)`);
        searchQuery = searchQuery.replace(regex, '').replace(regex2, '');
      } else {
        const regex = new RegExp(` and ${field} in \\(.*\\)`);
        const regex2 = new RegExp(`&\\$filter=${field} in \\(.*\\)`);
        searchQuery = searchQuery.replace(regex, '').replace(regex2, '');
      }
    }

    searchQuery = this.addOrderBy(searchQuery, sorter);

    const regex = new RegExp('&\\$filter=($|&)');
    if (regex.test(searchQuery)) {
      searchQuery = searchQuery.replace(regex, '');
    }
    this.setState({
      loading: true,
      searchQuery,
    }, () => {
      this.getCheckinHistory(this.state.searchQuery);
    });
  }

  filterData(values, paramObj) {
    const { dataIndex, componentType, objectName } = paramObj;
    let field = dataIndex;
    if (objectName) {
      field = `${objectName}/${dataIndex}`;
    }

    let sq = this.state.searchQuery;

    const skipRegex = new RegExp('(?<=&\\$skip=)[^&]*(?=)')
    sq = sq.replace(skipRegex, this.state.pagination.pageSize * (this.state.pagination.current - 1));
    const topRegex = new RegExp('(?<=&\\$top=)[^&]*(?=)');
    sq = sq.replace(topRegex, this.state.pagination.pageSize);

    if (!sq.includes('&$filter=')) {
      sq=`${sq}&$filter=`;
    } else {
      sq=`${sq} and `;
    }

    if (values && values[0] && values[0].toLowerCase() !== 'none') {
      let startDate;
      let searchQuery = "";
      if (componentType === DATE) {
        const startRegex = new RegExp(`(?<=${dataIndex} eq ).+(?=)`);
        startDate = moment(values[0], "DD-MM-YYYY").format("YYYY-MM-DD")
        searchQuery = sq.includes(dataIndex)
          ? sq.replace(startRegex, startDate)
          : `${sq}${field} eq ${startDate}`;
        }
      if (componentType === TIME) {
        const hourRegex = new RegExp(`(?<=hour(${dataIndex}) eq ).+(?=)`);
        const minuteRegex = new RegExp(`(?<=minute(${dataIndex}) eq ).+(?=)`)
        startDate = moment(values[0], "h:mm:ss a").utc();
        const minutes = startDate.minute()<10 ? `0${startDate.minute()}` : startDate.minute();
        searchQuery = sq.includes(dataIndex)
          ? sq.replace(hourRegex, startDate.hours()).replace(minuteRegex, minutes)
          : `${sq}hour(${field}) eq ${startDate.hours()} and minute(${field}) eq ${minutes}`;
      }
      const regex = new RegExp('&\\$filter=($|&)');
      this.setState(prevState => ({
        loading: true,
        searchQuery: regex.test(searchQuery) ? searchQuery.replace(regex, '') : searchQuery,
        pagination: prevState.pagination !== false
        ? {
          ...prevState.pagination,
          // current: 1,
        } : false,
      }), () => {
        this.getCheckinHistory(searchQuery);
      });
    } else {
      let regex = "";
      let regexFilter = "";
      if (componentType === DATE) {
        regex = new RegExp(` and ${field} eq \\d{4}-\\d{2}-\\d{2}`);
        regexFilter = new RegExp(`&\\$filter=${field} eq \\d{4}-\\d{2}-\\d{2}`);
      }
      if (componentType === TIME) {
        regex = new RegExp(` and hour\\(${field}\\) eq \\d{1,2} and minute\\(${field}\\) eq \\d{1,2}`);
        regexFilter = new RegExp(`&\\$filter=hour\\(${field}\\) eq \\d{1,2} and minute\\(${field}\\) eq \\d{1,2}`);
      }
      let removeFromQuery = this.state.searchQuery.replace(regex, '').replace(regexFilter, '');
      regex = new RegExp('&\\$filter=($|&)');
      if (regex.test(removeFromQuery)) {
        removeFromQuery = removeFromQuery.replace(regex, '');
      }
      this.setState(prevState => ({
        loading: true,
        searchQuery: removeFromQuery,
        pagination: prevState.pagination !== false
        ? {
          ...prevState.pagination,
          // current: 1,
        } : false,
      }), () => {
        this.getCheckinHistory(this.state.searchQuery);
      });
    }
  }

  render() {
    const gridColumns = [
      {
        dataIndex: "AlertType",
        key: "AlertType",
        width: "5%",
        sorter: (a, b) => {return numericSorter(a, b, "NotificationStatusId")},
        filters: [
          { text: "In Error", value: NotificationStatuses.Error},
          { text: "Not in Error", value: NotificationStatuses.NotInError}
        ],
        // ...getNotificationStatusFilter(),
        render: (text, record) => <>{this.getCheckinIcon(record)}</>,
      },
      {
        title: "Date",
        dataIndex: "RunAfterDateTime",
        key: "Date",
        width: "10%",
        sorter: (a, b) => {return dateSorter(a, b, "RunAfterDateTime");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "RunAfterDateTime", title: "Date", componentType: DATE, callback: this.filterData}),
        render: (text, record) =>
          record.RunAfterDateTime !== null ? (
            <>
              <span
                style={{
                  color: this.props.active
                    ? "rgba(0, 0, 0, 0.85)"
                    : "#909090",
                  fontStyle: this.props.active ? "normal" : "italic",
                }}
              >
                {moment(record.RunAfterDateTime).local().format("DD-MM-YYYY")}
              </span>
            </>
          ) : null,
      },
      {
        title: "Time",
        dataIndex: "RunAfterDateTime",
        key: "Time",
        width: "10%",
        sorter: (a, b) => {return timeSorter(moment(a.RunAfterDateTime), moment(b.RunAfterDateTime));},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "RunAfterDateTime", title: "Time", componentType: TIME, callback: this.filterData}),
        render: (text, record) =>
          record.RunAfterDateTime !== null ? (
            <>
              <span
                style={{
                  color: this.props.active
                    ? "rgba(0, 0, 0, 0.85)"
                    : "#909090",
                  fontStyle: this.props.active ? "normal" : "italic",
                }}
              >
                {moment(record.RunAfterDateTime).local().format("h:mm:ss A")}
              </span>
            </>
          ) : null,
      },
      {
        title: "Check-in Detail",
        dataIndex: "MessageType",
        key: "MessageType",
        width: "50%",
        sorter: (a, b) => {return stringSorter(a, b, "MessageType");},
        ...getCheckinGridMessageTypeFilter(),
        render: (text, record) =>
          record.MessageType !== null ? (
            <>
              <span>
                {record.CheckInStatus}
              </span>
            </>
          ) : (
            <>
              <span
                className="alert-reviewer-none"
              >
                None
              </span>
            </>
          ),
      },
      {
        title: "",
        dataIndex: "FileId",
        key: "FileId",
        render: (text, record) =>
          record.FileId !== null && record.FileId > 0 ? (
            <>
              <ImageThumbnail Id={record.FileId} CanPreview={false} OnClick={() => this.openSelfieReviewModal(record.FileId)} />
            </>
          ) : null
      },
    ];

    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
          <Col xs={24} sm={24} md={10} lg={10}>
            <span className="mosovo-table-header">Check-in History</span>
          </Col>
          <Col xs={0} sm={0} md={12} lg={12} />
          <Col xs={0} md={2} lg={2}>
            <Tooltip placement="topRight" title={this.state.numUpdates > 0 ? `Refresh (${this.state.numUpdates}) new Check-ins` : "Refresh check-ins"}>
              <FontAwesomeIcon
                icon={faSyncAlt}
                color={this.state.numUpdates > 0 ? "#EF4E4E" : "#243B53"}
                style={{ display: "inline" }}
                onClick={this.onRefreshClick}
                pull="right"
              />
            </Tooltip>
          </Col>
        </Row>
        <Divider />
        <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
          <Col xs={24} sm={24} md={12} lg={12} />
          <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
            <DateFilterDropdown onChange={this.onDateFilterChange} />
          </Col>
          <Col xs={24} sm={24} md={6} lg={6} className="gutter-row">
            <RangePicker
              className="p5-form-datepicker-input"
              format="DD/MM/YYYY"
              separator=" to "
              onChange={(val) => {
                this.onSearchDate(val);
              }}
              allowClear
            />
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <Table
              loading={this.props.loading}
              dataSource={this.state.CheckinHistory}
              columns={gridColumns}
              onChange={this.handleTableChange}
              rowKey="Id"
              scroll={{ x: true }}
              pagination={this.state.pagination}
            />
          </Col>
        </Row>        
        <SelfieReviewModal 
          refreshGrid={this.getCheckinHistory}
        />
      </div>
    );
  }
}

CheckinHistoryGrid.propTypes = {
  dispatch: PropTypes.func,
  loading: PropTypes.bool,
  Nominal: PropTypes.shape(),
  active: PropTypes.bool,
  CheckinHistory: PropTypes.arrayOf(PropTypes.shape()),
};

CheckinHistoryGrid.defaultProps = {
  dispatch: () => {},
  loading: false,
  Nominal: {},
  active: true,
  CheckinHistory: [],
};

function mapStateToProps(state) {
  const { loading, Nominal, CheckinHistory } = state.Nominal;
  return {
    loading,
    Nominal,
    CheckinHistory,
  };
}

const connectedCheckinHistoryGrid = connect(mapStateToProps)(CheckinHistoryGrid);

export { connectedCheckinHistoryGrid as default };

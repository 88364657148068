import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Table, Row, Col, Button, Dropdown, Menu, Tooltip } from "antd";
import moment from "moment";
import {
  // faMapMarkerTimes,
  // faBan,
  // faClock,
  // faPhoneSlash,
  // faMailBulk,
  // faEnvelope,
  // faComments,
  faEdit,
  faPlus,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { WarningOutlined, DownOutlined } from "@ant-design/icons";
import {
  getAlertTypeElement,
  getNotifyByElements,
} from "../../../common/utils/alert-settings-helper";
import { IconButtons } from "../../../common/components";

import actions from "../actions";
import {
  numericSorter,
  booleanGroupSorter,
  getAlertGridAlertTypeFilter,
  getNotifyByFilter,
} from "../../../common/utils/grid-filter-helper";

const { DeleteIcon } = IconButtons;
const AlertSettingsConstants = {
  ExclusionZone: 1,
  LocationFail: 2,
  Curfew: 3,
  IdleDevice: 4,
  CheckinAlert: 5,
};

class NominalAlertSettingsGrid extends React.Component {
  openAlertModal = (record) => {
    // use action to load from api, callback to open modal
    switch (record.AlertType.Id) {
      case AlertSettingsConstants.CheckinAlert:
        this.props.dispatch(
          actions.getAlertSettings(
            this.props.Nominal.Id,
            record.Id,
            this.props.dispatch(actions.openCheckinAlertModal())
          )
        );
        break;
      case AlertSettingsConstants.Curfew:
        this.props.dispatch(
          actions.getAlertSettings(
            this.props.Nominal.Id,
            record.Id,
            this.props.dispatch(
              actions.openCurfewAlertModal(this.props.refreshGrid)
            )
          )
        );
        break;
      case AlertSettingsConstants.LocationFail:
        this.props.dispatch(
          actions.getAlertSettings(
            this.props.Nominal.Id,
            record.Id,
            this.props.dispatch(
              actions.openLocationFailModal(this.props.refreshGrid)
            )
          )
        );
        break;
      case AlertSettingsConstants.IdleDevice:
        this.props.dispatch(
          actions.getAlertSettings(
            this.props.Nominal.Id,
            record.Id,
            this.props.dispatch(
              actions.openIdleSettingsModal(this.props.refreshGrid)
            )
          )
        );
        break;
      default:
        break;
    }
  };

  DeleteExclusionZoneModal = (record) => {
    const deleteExclusionZone = () =>
      this.deleteExclusionZoneFromNominal(record.Id);
    const { confirm } = Modal;
    const message = (
      <div>
        <p>
          Removing an Exclusion Zone from the nominal will{" "}
          <b>remove the alert</b> and will <b>no longer notify you</b> if this
          nominal has entered the exclusion zone area.
        </p>
        <p>
          <b>Are you sure you want to remove the exclusion zone?</b>
        </p>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Name:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {record.Name}
          </Col>
        </Row>
      </div>
    );

    confirm({
      title: "Remove Exclusion Zone",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        deleteExclusionZone(record.Id);
      },
      okText: "Yes, Remove",
    });
  };

  deleteExclusionZoneFromNominal = (Id) => {
    const { dispatch } = this.props;
    dispatch(
      actions.deleteExclusionZoneFromNominal(
        {
          ExclusionZoneId: Id,
          NominalId: this.props.Nominal.Id,
        },
        this.props.refreshGrid
      )
    );
  };

  DeleteLocationFailModal = (record) => {
    const deleteLocationFail = () =>
      this.deleteLocationFailFromNominal(record.NominalLocationFailSettings.Id);
    const { confirm } = Modal;
    const message = (
      <div>
        <p>
          Removing Location Fail settings from the nominal will cause location fails to alert on the default setting.
        </p>
        <p>
          <b>Are you sure you want to remove the location fail setting?</b>
        </p>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Location fail period:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {record.NominalLocationFailSettings.Amount} {record.NominalLocationFailSettings.Units}
          </Col>
        </Row>
      </div>
    );

    confirm({
      title: "Remove Location Fail",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        deleteLocationFail(record.NominalLocationFailSettings.Id);
      },
      okText: "Yes, Remove",
    });
  };

  deleteLocationFailFromNominal = (Id) => {
    const { dispatch } = this.props;
    dispatch(
      actions.deleteLocationFailSettings(Id, this.props.refreshGrid)
    );
  };

  DeleteIdleModal = (record) => {
    const deleteIdle = () =>
      this.deleteIdleFromNominal(record.NominalIdleSettings.Id);
    const { confirm } = Modal;
    const message = (
      <div>
        <p>
          Removing Idle Device settings from the nominal will cause idle devices to alert on the default setting.
        </p>
        <p>
          <b>Are you sure you want to remove the idle device setting?</b>
        </p>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Max Time Limit:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {record.NominalIdleSettings.IdleTimeLimit >= 60 ? `${record.NominalIdleSettings.IdleTimeLimit / 60} Hours` : `${record.NominalIdleSettings.IdleTimeLimit} Minutes`}
          </Col>
        </Row>
      </div>
    );

    confirm({
      title: "Remove Idle Device",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        deleteIdle(record.NominalIdleSettings.Id);
      },
      okText: "Yes, Remove",
    });
  };

  deleteIdleFromNominal = (Id) => {
    const { dispatch } = this.props;
    dispatch(
      actions.deleteIdleSettings(Id, this.props.refreshGrid)
    );
  };

  DeleteCurfewModal = (record) => {
    const deleteCurfew = (id) => this.deleteCurfewFromNominal(id);
    const { confirm } = Modal;
    const message = (
      <div>
        <p>
          Removing a Curfew from the nominal will <b>remove the alert</b> and
          will <b>no longer notify you</b> if this nominal has violated curfew.
        </p>
        <p>
          <b>Are you sure you want to remove the curfew?</b>
        </p>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Location:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {this.getCurfewAddress(record.NominalCurfewSettings)}
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Radius:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {record.NominalCurfewSettings.Radius} Meters
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Period:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {moment(record.NominalCurfewSettings.StartTime)
              .utc()
              .format("h:mm A")}{" "}
            -{" "}
            {moment(record.NominalCurfewSettings.EndTime)
              .utc()
              .format("h:mm A")}
          </Col>
        </Row>
      </div>
    );

    confirm({
      title: "Remove Curfew",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        deleteCurfew(record.NominalCurfewSettings.Id);
      },
      okText: "Yes, Remove",
    });
  };

  deleteCurfewFromNominal = (Id) => {
    const { dispatch } = this.props;
    dispatch(actions.deleteCurfewFromNominal(Id, this.props.refreshGrid));
  };

  DeleteAlertSettings = (record) => {
    switch (record.AlertType.Id) {
      case AlertSettingsConstants.ExclusionZone:
        this.DeleteExclusionZoneModal(record);
        break;
      case AlertSettingsConstants.Curfew:
        this.DeleteCurfewModal(record);
        break;
      case AlertSettingsConstants.LocationFail:
        this.DeleteLocationFailModal(record);
        break;
      case AlertSettingsConstants.IdleDevice:
        this.DeleteIdleModal(record);
        break;
      default:
        break;
    }
  };

  getCurfewAddress = (settings) => {
    let address = "";
    if (settings) {
      if (settings.Address1) address = settings.Address1;
      if (settings.Address2) address = `${address} ${settings.Address2}`;
      if (settings.City) address = `${address}, ${settings.City}`;
      if (settings.PostalCode) address = `${address} ${settings.PostalCode}`;
    }
    return address;
  };

  getConditionText = (record) => {
    if (record && record.AlertType) {
      // TODO - setup IDs in constants
      switch (record.AlertType.Id) {
        case AlertSettingsConstants.ExclusionZone:
          if (record.ExclusionZone) {
            return (
              <>
                <Row>
                  <span style={{ color: "#585757" }}>Name:</span>
                  <span
                    style={{
                      color: this.props.active ? "#585757" : "#909090",
                      fontStyle: this.props.active ? "normal" : "italic",
                    }}
                  >
                    <b>{record.ExclusionZone.Name}</b>
                  </span>
                </Row>
                <Row>
                  <span style={{ color: "#585757" }}>Radius:</span>
                  <span
                    style={{
                      color: this.props.active ? "#585757" : "#909090",
                      fontStyle: this.props.active ? "normal" : "italic",
                    }}
                  >
                    <b>{record.ExclusionZone.Radius} Meters</b>
                  </span>
                </Row>
              </>
            );
          }
          return null;
        case AlertSettingsConstants.Curfew:
          if (record.NominalCurfewSettings) {
            return (
              <>
                <Row>
                  <span style={{ color: "#585757" }}>Location:</span>
                  <span
                    style={{
                      color: this.props.active ? "#585757" : "#909090",
                      fontStyle: this.props.active ? "normal" : "italic",
                    }}
                  >
                    <b>{this.getCurfewAddress(record.NominalCurfewSettings)}</b>
                  </span>
                </Row>
                <Row>
                  <span style={{ color: "#585757" }}>Radius:</span>
                  <span
                    style={{
                      color: this.props.active ? "#585757" : "#909090",
                      fontStyle: this.props.active ? "normal" : "italic",
                    }}
                  >
                    <b>{record.NominalCurfewSettings.Radius} Meters</b>
                  </span>
                </Row>
                <Row>
                  <span style={{ color: "#585757" }}>Period:</span>
                  <span
                    style={{
                      color: this.props.active ? "#585757" : "#909090",
                      fontStyle: this.props.active ? "normal" : "italic",
                    }}
                  >
                    <b>
                      {moment(record.NominalCurfewSettings.StartTime)
                        .local()
                        .format("h:mm A")}{" "}
                      -{" "}
                      {moment(record.NominalCurfewSettings.EndTime)
                        .local()
                        .format("h:mm A")}
                    </b>
                  </span>
                </Row>
              </>
            );
          }
          return null;
        case AlertSettingsConstants.LocationFail:
          if (record.NominalLocationFailSettings) {
            return (
              <>
                <Row>
                  <span style={{ color: "#585757" }}>Location fail period:</span>
                  <span
                    style={{
                      color: this.props.active ? "#585757" : "#909090",
                      fontStyle: this.props.active ? "normal" : "italic",
                    }}
                  >
                    <b>{record.NominalLocationFailSettings.Amount} {record.NominalLocationFailSettings.Units}</b>
                  </span>
                </Row>
              </>
            );
          }
         return null;
        case AlertSettingsConstants.IdleDevice:
          if (record.NominalIdleSettings) {
            return (
              <>
                <Row>
                  <span style={{ color: "#585757" }}>Max idle time:</span>
                  <span
                    style={{
                      color: this.props.active ? "#585757" : "#909090",
                      fontStyle: this.props.active ? "normal" : "italic",
                    }}
                  >
                    <b>{record.NominalIdleSettings.IdleTimeLimit >= 60 ? `${record.NominalIdleSettings.IdleTimeLimit / 60} Hours` : `${record.NominalIdleSettings.IdleTimeLimit} Minutes`}</b>
                  </span>
                </Row>
              </>
            );
          }
          return null;
        case AlertSettingsConstants.CheckinAlert:
          if (record.MaxCheckinAmount) {
            return (
              <>
                <Row>
                  <span style={{ color: "#585757" }}>Max failed check-ins: </span>
                  <span
                    style={{
                      color: this.props.active ? "#585757" : "#909090",
                      fontStyle: this.props.active ? "normal" : "italic",
                      marginLeft: '5px',
                    }}
                  >
                    <b>{`${record.MaxCheckinAmount} times`}</b>
                  </span>
                </Row>
              </>
            );
          }
         return null;
        default:
          return null;
      }
    }
    return null;
  };

  editAlertSettings = (record) => {
    if (record) {
      // TODO - open modal to edit settings
      this.openAlertModal(record);
    }
    return null;
  };

  AddNewAlertSetting = () => {
    return null;
  };

  handleAddMenuClick = (e) => {
    switch (e.key) {
      case `${AlertSettingsConstants.CheckinAlert}`:
        this.props.dispatch(actions.openCheckinAlertModal());
        break;
      case `${AlertSettingsConstants.Curfew}`:
        this.props.dispatch(actions.openCurfewAlertModal());
        break;
      case `${AlertSettingsConstants.LocationFail}`:
        this.props.dispatch(actions.openLocationFailModal());
        break;
      case `${AlertSettingsConstants.IdleDevice}`:
        this.props.dispatch(actions.openIdleSettingsModal());
        break;
      default:
        break;
    }
  };

  hasAlertSettings(alertType) {
    if (!this.props.alertSettings) return false;
    const setting = this.props.alertSettings.find(
      (x) => x.AlertType.Id === alertType
    );
    if (setting) return true;
    return false;
  }

  render() {
    const addMenu = (
      <Menu onClick={this.handleAddMenuClick}>
        {!this.hasAlertSettings(AlertSettingsConstants.CheckinAlert) && (
          <Menu.Item key={AlertSettingsConstants.CheckinAlert}>
            Check-In Alert
          </Menu.Item>
        )}
        {!this.hasAlertSettings(AlertSettingsConstants.LocationFail) && (
          <Menu.Item key={AlertSettingsConstants.LocationFail}>
            Location Fail
          </Menu.Item>
        )}
        {!this.hasAlertSettings(AlertSettingsConstants.Curfew) && (
          <Menu.Item key={AlertSettingsConstants.Curfew}>Curfew</Menu.Item>
        )}
        {!this.hasAlertSettings(AlertSettingsConstants.IdleDevice) && (
          <Menu.Item key={AlertSettingsConstants.IdleDevice}>
            Idle Device
          </Menu.Item>
        )}
      </Menu>
    );

    const that = this;
    const gridColumns = [
      {
        title: "Type",
        dataIndex: "Type",
        key: "Type",
        width: "14%",
        sorter: (a, b) => {return numericSorter(a.AlertType, b.AlertType, "Id");},
        ...getAlertGridAlertTypeFilter(),
        render: (text, record) => (
          <>{getAlertTypeElement(record, this.props.active, true)} </>
        ),
      },
      {
        title: "Condition",
        dataIndex: "Condition",
        key: "Condition",
        width: "81%",
        render: (text, record) => <>{this.getConditionText(record)}</>,
      },
      {
        title: "Notify by",
        dataIndex: "NotifyBy",
        key: "NotifyBy",
        width: "3%",
        sorter: (a, b) => {return booleanGroupSorter(a, b, ["IsNotifyEmail", "IsNotifyTeamEmail", "IsNotifyText", "IsNotifyNominal"])},
        ...getNotifyByFilter(),
        render: (text, record) => (
          <>{getNotifyByElements(record, this.props.active)}</>
        ),
      },
      {
        key: "EditSettings",
        width: "1%",
        render(text, record) { 
          const clickFunc = () => that.editAlertSettings(record);
        return {
          props: {
            style: { paddingLeft: "0px", paddingRight: "0px"}
          },
          children: 
            (record.AlertType.Id !== AlertSettingsConstants.ExclusionZone && (
              <>
                <Tooltip title="Edit Alert Settings">
                  <FontAwesomeIcon
                    icon={faEdit}
                    id="EditNominalBasicInfo"
                    className=""
                    alt="basic"
                    onClick={clickFunc}
                    pull="right"
                  />
                </Tooltip>
              </>
            ))
          }
        }
      },
      {
        key: "Delete",
        width: "1%",
        render(text, record) {
          const colorToShow = that.props.active ? "#243b53" : "#909090";
          const clickFunc = () => that.DeleteAlertSettings(record);
          return {
            props: {
              style: { paddingLeft: "0px", paddingRight: "0px" }
            },
            children:
              ((record.NominalCurfewSettings ||
              record.NominalLocationFailSettings || record.NominalIdleSettings) && (
                <>
                  <span>
                    <DeleteIcon
                      tooltip="Remove Alert Settings"
                      style={{ color: colorToShow }}
                      onClick={clickFunc}
                      value={record}
                      pull="right"
                    />
                  </span>
                </>
              )),
          }
        }
      },
      {
        dataIndex: "IsUrgent",
        key: "IsUrgent",
        className: "hide",
      },
      {
        dataIndex: "MaxCheckinAmount",
        key: "MaxCheckinAmount",
        className: "hide",
      },
    ];

    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row gutter={{ xs: 8, sm: 16, md: 12, lg: 24 }}>
          <Col xs={16} sm={16} md={8} lg={12}>
            <span className="mosovo-table-header">Nominal Alerts</span>
          </Col>
          <Col lg={8} />
          <Col xs={8} sm={8} md={4} lg={4}>
            <Dropdown overlay={addMenu}>
              <Button
                style={{
                  backgroundColor: "#243B53",
                  color: "white",
                  float: "right",
                }}
                className="dropdown-btn"
              >
                <FontAwesomeIcon
                  icon={faPlus}
                  id="PlusIcon"
                  className=""
                  alt="add"
                />
                {" "}
                <DownOutlined />
              </Button>
            </Dropdown>
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <Table
              loading={this.props.loading}
              dataSource={this.props.alertSettings}
              columns={gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

NominalAlertSettingsGrid.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  alertSettings: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  Nominal: PropTypes.shape(),
  active: PropTypes.bool,
};

NominalAlertSettingsGrid.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  alertSettings: [],
  loading: false,
  Nominal: {},
  active: true,
};

function mapStateToProps(state) {
  const { loading, Nominal } = state.Nominal;
  return {
    loading,
    Nominal,
  };
}

const connectedNominalAlertSettingsGrid = connect(mapStateToProps)(
  NominalAlertSettingsGrid
);

export { connectedNominalAlertSettingsGrid as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal, Table, Row, Col, Button } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faEdit } from "@fortawesome/pro-regular-svg-icons";
import { getNotifyByElements } from "../../../common/utils/alert-settings-helper";
import { IconButtons } from "../../../common/components";

import actions from "../actions";
import {
  getColumnSearchProps,
  getNotifyByFilter,
  stringSorter,
  booleanGroupSorter,
  INPUT,
} from "../../../common/utils/grid-filter-helper";

const { DeleteIcon } = IconButtons;

class ExclusionZoneGrid extends React.Component {
  DeleteExclusionZoneModal = (record) => {
    const deleteExclusionZone = () =>
      this.deleteExclusionZoneFromNominal(record.Id);
    const { confirm } = Modal;
    const message = (
      <div>
        <p>
          Removing an Exclusion Zone from the nominal will{" "}
          <b>remove the alert</b> and will <b>no longer notify you</b> if this
          nominal has entered the exclusion zone area.
        </p>
        <p>
          <b>Are you sure you want to remove the exclusion zone?</b>
        </p>
        <Row>
          <Col xs={12} sm={12} md={8} lg={8}>
            <span className="mosovo-table-header">Name:</span>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8}>
            {record.Name}
          </Col>
        </Row>
      </div>
    );

    confirm({
      title: "Remove Exclusion Zone",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        deleteExclusionZone(record.Id);
      },
      okText: "Yes, Remove",
    });
  };

  editAlertSettings = (record) => {
    if (record) {
      this.props.dispatch(
        actions.getAlertSettings(
          this.props.Nominal.Id,
          record.NominalAlertSettings[0].Id,
          this.props.dispatch(actions.openExclusionZoneModal())
        )
      );
    }
    return null;
  };

  createExclusionZone = () => {
    this.props.dispatch(actions.resetAlertSettings(() => { this.props.dispatch(actions.openExclusionZoneModal()) }));
  }

  deleteExclusionZoneFromNominal = (Id) => {
    const { dispatch } = this.props;
    dispatch(
      actions.deleteExclusionZoneFromNominal(
        {
          ExclusionZoneId: Id,
          NominalId: this.props.Nominal.Id,
        },
        this.props.refreshGrid
      )
    );
  };

  openExclusionZoneModal = () => {
    this.props.dispatch(actions.openExclusionZoneModal());
  };

  render() {
    const gridColumns = [
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => {return stringSorter(a, b, "Name");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "Name", title: "Name", componentType: INPUT}),
        render: (record) => (
          <>
            <span
              style={{
                color: this.props.Nominal.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                fontStyle: this.props.Nominal.Active ? "normal" : "italic",
              }}
            >
              {record}
            </span>
          </>
        ),
      },
      {
        title: "Type",
        key: "ExclusionZoneType.Name",
        sorter: (a, b) => {return stringSorter(a.ExclusionZoneType, b.ExclusionZoneType, "Name");},
        sortDirections: ["descend", "ascend"],
        ...getColumnSearchProps({ dataIndex: "Name", title: "Type", componentType: INPUT, objectName: "ExclusionZoneType"}),
        render: (record) => (
          <>
            <span
              style={{
                color: this.props.Nominal.Active ? "rgba(0, 0, 0, 0.85)" : "#909090",
                fontStyle: this.props.Nominal.Active ? "normal" : "italic",
              }}
            >
              {record.ExclusionZoneType.Name}
            </span>
          </>
        ),
      },
      {
        title: "Notify By",
        dataIndex: "NotifyBy",
        key: "NotifyBy",
        sorter: (a, b) => {return booleanGroupSorter(a.NominalAlertSettings[0], b.NominalAlertSettings[0], ["IsNotifyEmail", "IsNotifyTeamEmail", "IsNotifyText"])},
        ...getNotifyByFilter("NominalAlertSettings"),
        render: (text, record) => (
          <>{getNotifyByElements(record.NominalAlertSettings[0], this.props.Nominal.Active)}</>
        ),
      },
      {
        render: (record) => (
          <>
            <span>
              <Button
                style={{
                  color: this.props.Nominal.Active ? "#243b53" : "#909090",
                  border: "none",
                }}
                type="text"
                onClick={() => this.editAlertSettings(record)}
              >
                <FontAwesomeIcon
                  icon={faEdit}
                  id="EditNominalBasicInfo"
                  className=""
                  alt="basic"
                />
              </Button>
              <DeleteIcon
                tooltip={`Remove Exclusion Zone ${record.Name} from nominal`}
                style={{ marginTop: '8px', color: this.props.Nominal.Active ? "#243b53" : "#909090" }}
                onClick={this.DeleteExclusionZoneModal}
                value={record}
                pull="right"
              />
            </span>
          </>
        ),
      },
    ];

    return (
      <div
        style={{ backgroundColor: "white", margin: "10px", padding: "15px" }}
      >
        <Row>
          <Col flex="auto">
            <span className="mosovo-table-header">Exclusion Zones</span>
          </Col>
          <Col>
            <button
              type="button"
              // onClick={() => this.props.setShowAddExclusionZoneForm(true)}
              onClick={this.createExclusionZone}
              className="addExclusionZoneButton"
            >
              <FontAwesomeIcon
                icon={faPlus}
                size="2x"
                style={{ display: "inline" }}
              />
            </button>
          </Col>
        </Row>
        <br />
        <Row justify="center">
          <Col span={24}>
            <Table
              loading={this.props.loading}
              dataSource={this.props.exclusionZones}
              columns={gridColumns}
              rowKey="Id"
              scroll={{ x: true }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

ExclusionZoneGrid.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  exclusionZones: PropTypes.arrayOf(PropTypes.shape({})),
  loading: PropTypes.bool,
  // setShowAddExclusionZoneForm: PropTypes.func,
  Nominal: PropTypes.shape(),
};

ExclusionZoneGrid.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  exclusionZones: [],
  loading: false,
  // setShowAddExclusionZoneForm: () => {},
  Nominal: {},
};

function mapStateToProps(state) {
  const { Nominal, loading } = state.Nominal;
  return {
    loading,
    Nominal,
  };
}

const connectedExclusionZoneGrid = connect(mapStateToProps)(ExclusionZoneGrid);

export { connectedExclusionZoneGrid as default };

export default ({
    MY_NOMINAL_REQUEST: 'MY_NOMINAL_REQUEST',
    MY_NOMINAL_SUCCESS: 'MY_NOMINAL_SUCCESS',
    MY_NOMINAL_FAILURE: 'MY_NOMINAL_FAILURE',
    GET_NOMINAL_REQUEST: 'GET_NOMINAL_REQUEST',
    GET_NOMINAL_SUCCESS: 'GET_NOMINAL_SUCCESS',
    GET_NOMINAL_FAILURE: 'GET_NOMINAL_FAILURE',
    GET_NOMINAL_BASICINFO_REQUEST: 'GET_NOMINAL_BASICINFO_REQUEST',
    GET_NOMINAL_BASICINFO_SUCCESS: 'GET_NOMINAL_BASICINFO_SUCCESS',
    GET_NOMINAL_BASICINFO_FAILURE: 'GET_NOMINAL_BASICINFO_FAILURE',
    MY_NOMINAL_CREATE_REQUEST: 'MY_NOMINAL_CREATE_REQUEST',
    MY_NOMINAL_CREATE_SUCCESS: 'MY_NOMINAL_CREATE_SUCCESS',
    MY_NOMINAL_CREATE_FAILURE: 'MY_NOMINAL_CREATE_FAILURE',
    CONTACT_NOMINAL_REQUEST: 'CONTACT_NOMINAL_REQUEST',
    CONTACT_NOMINAL_SUCCESS: 'CONTACT_NOMINAL_SUCCESS',
    CONTACT_NOMINAL_FAILURE: 'CONTACT_NOMINAL_FAILURE',
    CREATE_EXCLUSION_ZONE_REQUEST: 'CREATE_EXCLUSION_ZONE_REQUEST',
    CREATE_EXCLUSION_ZONE_SUCCESS: 'CREATE_EXCLUSION_ZONE_SUCCESS',
    CREATE_EXCLUSION_ZONE_FAILURE: 'CREATE_EXCLUSION_ZONE_FAILURE',
    GET_EXCLUSION_ZONE_REQUEST: 'GET_EXCLUSION_ZONE_REQUEST',
    GET_EXCLUSION_ZONE_SUCCESS: 'GET_EXCLUSION_ZONE_SUCCESS',
    GET_EXCLUSION_ZONE_FAILURE: 'GET_EXCLUSION_ZONE_FAILURE',
    ADD_NOMINAL_LOCATION_REQUEST: 'ADD_NOMINAL_LOCATION_REQUEST',
    ADD_NOMINAL_LOCATION_SUCCESS: 'ADD_NOMINAL_LOCATION_SUCCESS',
    ADD_NOMINAL_LOCATION_FAILURE: 'ADD_NOMINAL_LOCATION_FAILURE',
    MY_NOMINAL_GET_REQUEST: 'MY_NOMINAL_GET_REQUEST',
    MY_NOMINAL_GET_SUCCESS: 'MY_NOMINAL_GET_SUCCESS',
    MY_NOMINAL_GET_FAILURE: 'MY_NOMINAL_GET_FAILURE',
    MY_NOMINAL_UPDATE_REQUEST: 'MY_NOMINAL_UPDATE_REQUEST',
    MY_NOMINAL_UPDATE_SUCCESS: 'MY_NOMINAL_UPDATE_SUCCESS',
    MY_NOMINAL_UPDATE_FAILURE: 'MY_NOMINAL_UPDATE_FAILURE',
    NOMINAL_BASICINFO_UPDATE_REQUEST: 'NOMINAL_BASICINFO_UPDATE_REQUEST',
    NOMINAL_BASICINFO_UPDATE_SUCCESS: 'NOMINAL_BASICINFO_UPDATE_SUCCESS',
    NOMINAL_BASICINFO_UPDATE_FAILURE: 'NOMINAL_BASICINFO_UPDATE_FAILURE',
    OPEN_ADD_NOMINAL_MODAL: 'OPEN_ADD_NOMINAL_MODAL',
    OPEN_EDIT_NOMINAL_MODAL: 'OPEN_EDIT_NOMINAL_MODAL',
    OPEN_NOMINAL_BASIC_INFO_MODAL: 'OPEN_NOMINAL_BASIC_INFO_MODAL',
    OPEN_NOMINAL_DEVICE_INFO_MODAL: 'OPEN_NOMINAL_DEVICE_INFO_MODAL',
    OPEN_NOMINAL_ADDITIONAL_INFO_MODAL: 'OPEN_NOMINAL_ADDITIONAL_INFO_MODAL',
    OPEN_CONTACT_NOMINAL_MODAL: 'OPEN_CONTACT_NOMINAL_MODAL',
    OPEN_EXCLUSION_ZONE_MODAL: 'OPEN_EXCLUSION_ZONE_MODAL',
    OPEN_CHECKIN_ALERT_MODAL: 'OPEN_CHECKIN_ALERT_MODAL',
    OPEN_CURFEW_ALERT_MODAL: 'OPEN_CURFEW_ALERT_MODAL',
    OPEN_LOCATION_FAIL_MODAL: 'OPEN_LOCATION_FAIL_MODAL',
    OPEN_IDLE_SETTINGS_MODAL: 'OPEN_IDLE_SETTINGS_MODAL',
    OPEN_ADD_NOMINAL_LOCATION_MODAL: 'OPEN_ADD_NOMINAL_LOCATION_MODAL',
    CLOSE_NOMINAL_MODAL: 'CLOSE_NOMINAL_MODAL',
    CLOSE_NOMINAL_BASIC_INFO_MODAL: 'CLOSE_NOMINAL_BASIC_INFO_MODAL',
    LINK_DEVICE_REQUEST: 'LINK_DEVICE_REQUEST',
    LINK_DEVICE_SUCCESS: 'LINK_DEVICE_SUCCESS',
    LINK_DEVICE_FAILURE: 'LINK_DEVICE_FAILURE',
    NOMINAL_DEVICEINFO_UPDATE_REQUEST: 'NOMINAL_DEVICEINFO_UPDATE_REQUEST',
    NOMINAL_DEVICEINFO_UPDATE_SUCCESS: 'NOMINAL_DEVICEINFO_UPDATE_SUCCESS',
    NOMINAL_DEVICEINFO_UPDATE_FAILURE: 'NOMINAL_DEVICEINFO_UPDATE_FAILURE',
    NOMINAL_ADDITIONALINFO_UPDATE_REQUEST: 'NOMINAL_ADDITIONALINFO_UPDATE_REQUEST',
    NOMINAL_ADDITIONALINFO_UPDATE_SUCCESS: 'NOMINAL_ADDITIONALINFO_UPDATE_SUCCESS',
    NOMINAL_ADDITIONALINFO_UPDATE_FAILURE: 'NOMINAL_ADDITIONALINFO_UPDATE_FAILURE',
    NOMINAL_ALERTS_REQUEST: 'NOMINAL_ALERTS_REQUEST',
    NOMINAL_ALERTS_SUCCESS: 'NOMINAL_ALERTS_SUCCESS',
    NOMINAL_ALERTS_FAILURE: 'NOMINAL_ALERTS_FAILURE',
    NOMINAL_ALERT_SETTINGS_REQUEST: 'NOMINAL_ALERT_SETTINGS_REQUEST',
    NOMINAL_ALERT_SETTINGS_SUCCESS: 'NOMINAL_ALERT_SETTINGS_SUCCESS',
    NOMINAL_ALERT_SETTINGS_FAILURE: 'NOMINAL_ALERT_SETTINGS_FAILURE',
    ALERT_SETTINGS_REQUEST: 'ALERT_SETTINGS_REQUEST',
    ALERT_SETTINGS_SUCCESS: 'ALERT_SETTINGS_SUCCESS',
    ALERT_SETTINGS_FAILURE: 'ALERT_SETTINGS_FAILURE',
    GET_LOCATION_ADDRESS_REQUEST: 'GET_LOCATION_ADDRESS_REQUEST',
    GET_LOCATION_ADDRESS_SUCCESS: 'GET_LOCATION_ADDRESS_SUCCESS',
    GET_LOCATION_ADDRESS_FAILURE: 'GET_LOCATION_ADDRESS_FAILURE',
    DELETE_NOMINAL_EXCLUSION_ZONE_REQUEST: 'DELETE_NOMINAL_EXCLUSION_ZONE_REQUEST',
    DELETE_NOMINAL_EXCLUSION_ZONE_SUCCESS: 'DELETE_NOMINAL_EXCLUSION_ZONE_SUCCESS',
    DELETE_NOMINAL_EXCLUSION_ZONE_FAILURE: 'DELETE_NOMINAL_EXCLUSION_ZONE_FAILURE',
    CREATE_CURFEW_REQUEST: 'CREATE_CURFEW_REQUEST',
    CREATE_CURFEW_SUCCESS: 'CREATE_CURFEW_SUCCESS',
    CREATE_CURFEW_FAILURE: 'CREATE_CURFEW_FAILURE',
    UPDATE_CURFEW_REQUEST: 'UPDATE_CURFEW_REQUEST',
    UPDATE_CURFEW_SUCCESS: 'UPDATE_CURFEW_SUCCESS',
    UPDATE_CURFEW_FAILURE: 'UPDATE_CURFEW_FAILURE',
    DELETE_NOMINAL_CURFEW_REQUEST: 'DELETE_NOMINAL_CURFEW_REQUEST',
    DELETE_NOMINAL_CURFEW_SUCCESS: 'DELETE_NOMINAL_CURFEW_SUCCESS',
    DELETE_NOMINAL_CURFEW_FAILURE: 'DELETE_NOMINAL_CURFEW_FAILURE',
    CREATE_LOCATION_FAIL_REQUEST: 'CREATE_LOCATION_FAIL_REQUEST',
    CREATE_LOCATION_FAIL_SUCCESS: 'CREATE_LOCATION_FAIL_SUCCESS',
    CREATE_LOCATION_FAIL_FAILURE: 'CREATE_LOCATION_FAIL_FAILURE',
    UPDATE_LOCATION_FAIL_REQUEST: 'UPDATE_LOCATION_FAIL_REQUEST',
    UPDATE_LOCATION_FAIL_SUCCESS: 'UPDATE_LOCATION_FAIL_SUCCESS',
    UPDATE_LOCATION_FAIL_FAILURE: 'UPDATE_LOCATION_FAIL_FAILURE',
    DELETE_LOCATION_FAIL_REQUEST: 'DELETE_LOCATION_FAIL_REQUEST',
    DELETE_LOCATION_FAIL_SUCCESS: 'DELETE_LOCATION_FAIL_SUCCESS',
    DELETE_LOCATION_FAIL_FAILURE: 'DELETE_LOCATION_FAIL_FAILURE',
    CREATE_IDLE_SETTINGS_REQUEST: 'CREATE_IDLE_SETTINGS_REQUEST',
    CREATE_IDLE_SETTINGS_SUCCESS: 'CREATE_IDLE_SETTINGS_SUCCESS',
    CREATE_IDLE_SETTINGS_FAILURE: 'CREATE_IDLE_SETTINGS_FAILURE',
    UPDATE_IDLE_SETTINGS_REQUEST: 'UPDATE_IDLE_SETTINGS_REQUEST',
    UPDATE_IDLE_SETTINGS_SUCCESS: 'UPDATE_IDLE_SETTINGS_SUCCESS',
    UPDATE_IDLE_SETTINGS_FAILURE: 'UPDATE_IDLE_SETTINGS_FAILURE',
    DELETE_IDLE_SETTINGS_REQUEST: 'DELETE_IDLE_SETTINGS_REQUEST',
    DELETE_IDLE_SETTINGS_SUCCESS: 'DELETE_IDLE_SETTINGS_SUCCESS',
    DELETE_IDLE_SETTINGS_FAILURE: 'DELETE_IDLE_SETTINGS_FAILURE',
    CREATE_CHECKIN_REQUEST: 'CREATE_CHECKIN_REQUEST',
    CREATE_CHECKIN_SUCCESS: 'CREATE_CHECKIN_SUCCESS',
    CREATE_CHECKIN_FAILURE: 'CREATE_CHECKIN_FAILURE',
    GET_CHECKIN_REQUEST: 'GET_CHECKIN_REQUEST',
    GET_CHECKIN_SUCCESS: 'GET_CHECKIN_SUCCESS',
    GET_CHECKIN_FAILURE: 'GET_CHECKIN_FAILURE',
    TRANSFER_REQUEST: 'TRANSFER_REQUEST',
    TRANSFER_SUCCESS: 'TRANSFER_SUCCESS',
    TRANSFER_FAILURE: 'TRANSFER_FAILURE',    
    SUSPEND_REQUEST: 'SUSPEND_REQUEST',
    SUSPEND_SUCCESS: 'SUSPEND_SUCCESS',
    SUSPEND_FAILURE: 'SUSPEND_FAILURE',
})

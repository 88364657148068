import React from "react";
import PropTypes from "prop-types";
import { Button, Tooltip } from "antd";
import { CommentOutlined } from "@ant-design/icons";

import "./IconButtons.scss";

class SendMessageButton extends React.Component {
  render() {
    return (
      <>
        <Tooltip placement="topRight" title={this.props.tooltip}>
          <Button
            id={this.props.id}
            icon={<CommentOutlined />}
            disabled={this.props.isDisabled}
            style={{
              color: this.props.isDisabled ? "#868E96" : "white",
              backgroundColor: this.props.isDisabled ? "#F5F5F5" : "#243B53",
              border: this.props.isDisabled ? "1px solid #868E96" :"1px solid #096dd9",
            }} 
            onClick={this.props.onClick}
          >
            {this.props.title}
          </Button>
        </Tooltip>
      </>
    );
  }
}

SendMessageButton.propTypes = {
  onClick: PropTypes.func,
  id: PropTypes.string,
  title: PropTypes.string,
  tooltip: PropTypes.string,
  isDisabled: PropTypes.bool,
};

SendMessageButton.defaultProps = {
  onClick: () => {},
  id: "",
  title: null,
  tooltip: null,
  isDisabled: false,
};

export default SendMessageButton;

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin } from "antd";
import { CloseBtn } from "../form";

import { ImageThumbnail } from "../mosovo-images";

import actions from "./actions";

class SelfieReviewModal extends React.Component {
  CloseModal = () => {
    this.props.dispatch(actions.closeSelfieReviewModal());
    this.props.refreshGrid();
  };

  ApproveSelfie = () => {
    this.CloseModal();
  };

  createFooter = () => {
    const footer = (
      <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">          
        <Col xs={24} sm={8} md={6} className="gutter-row">
          <CloseBtn onClick={this.CloseModal} />
        </Col>
      </Row>
    )
    return footer;
  }

  render() {
    return (
      <>
        <Modal
          title=""
          visible={this.props.showSelfieReviewModal}
          footer={this.createFooter()}
          width={450}
          onCancel={this.CloseModal}
          destroyOnClose
        >
          <div style={{marginTop: "25px"}}>
            <Row>
              <Col span={24}>
                <Spin spinning={this.props.loading}>
                  <>
                    <Row justify="center">
                      <Col span={24}>
                        <span>
                          <ImageThumbnail Id={this.props.fileId} Width={400} />
                        </span>                        
                      </Col> 
                    </Row>
                  </>
                </Spin>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

SelfieReviewModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  // NominalId: PropTypes.number,
  showSelfieReviewModal: PropTypes.bool,
  loading: PropTypes.bool,
  fileId: PropTypes.number,
};

SelfieReviewModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  // NominalId: null,
  showSelfieReviewModal: false,
  loading: false,
  fileId: null,
};

function mapStateToProps(state) {
  const { loading, showSelfieReviewModal, fileId } = state.MosovoModal;

  return { loading, showSelfieReviewModal, fileId };
}

const connectedSelfieReviewModal = connect(mapStateToProps)(SelfieReviewModal);

export { connectedSelfieReviewModal as default };

export default ({
    GET_NOMINAL_LOCATIONS_REQUEST: 'GET_NOMINAL_LOCATIONS_REQUEST',
    GET_NOMINAL_LOCATIONS_SUCCESS: 'GET_NOMINAL_LOCATIONS_SUCCESS',
    GET_NOMINAL_LOCATIONS_FAILURE: 'GET_NOMINAL_LOCATIONS_FAILURE',
    RESET_NOMINAL_LOCATIONS: 'RESET_NOMINAL_LOCATIONS',
    OPEN_NOT_FOUND: 'OPEN_NOT_FOUND',
    CLOSE_MODAL: 'CLOSE_MODAL',
    HUB_CREATE_REQUEST: 'HUB_CREATE_REQUEST',
    HUB_CREATE_SUCCESS: 'HUB_CREATE_SUCCESS',
    HUB_CREATE_FAILURE: 'HUB_CREATE_FAILURE',
})
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin } from "antd";
import { WarningOutlined } from '@ant-design/icons';

import { BulkNominalTransferForm } from "../forms";
import actions from "../actions";

class BulkTransferNominalModal extends React.Component {
  CloseModal = () => {
    this.props.dispatch(actions.closeTransferNominalModal());
    this.props.refreshGrid();
  };

  CloseNominalTransferModal = () => {    
    const { confirm } = Modal; 
    // const { dispatch } = this.props;
    const closeModal = this.CloseModal;
    const message = (
      <div>
        <p>
          Are you sure you want to cancel?
        </p>
      </div>
      
    );

    confirm({
      title: "Assign Offender Manager",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        closeModal();
      },
      okText: "Yes, Close"
    });
  }

  NominalTransfer = (data) => {
    this.props.dispatch(actions.transferNominalBulk(data, this.CloseModal));
  };

  render() {
    return (
      <>
        <Modal
          title="Assign Offender Manager"
          visible={this.props.showTransferModal}
          // onCancel={this.CloseModal}
          footer={null}
          width={600}
          onCancel={this.CloseNominalTransferModal}
          destroyOnClose
        >
          <div>
            <Row>
              <Col span={24}>
                <Spin spinning={this.props.loading}>
                  <BulkNominalTransferForm
                    onCancel={this.CloseNominalTransferModal}
                    onSubmit={this.NominalTransfer}
                    isLoading={this.props.loading}
                    selectedNominals={this.props.selectedNominals}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

BulkTransferNominalModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  //   showEditModal: PropTypes.bool,
  selectedNominals: PropTypes.arrayOf(PropTypes.number),
  showTransferModal: PropTypes.bool,
  loading: PropTypes.bool,
};

BulkTransferNominalModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  //   showEditModal: false,
  selectedNominals: [],
  showTransferModal: false,
  loading: false,
};

function mapStateToProps(state) {
  const { loading, showTransferModal } = state.TeamNominal;

  return { loading, showTransferModal };
}

const connectedBulkTransferNominalModal = connect(mapStateToProps)(BulkTransferNominalModal);

export { connectedBulkTransferNominalModal as default };

import React from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { Row, Col, Form, Divider, Input } from "antd";
import { IconButtons } from '../..';
import { CancelBtn } from "../../form";

const { TextArea } = Input;
const { SendMessageButton } = IconButtons;

class BulkMessageNotificationForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
  }

  componentDidUpdate() {
    this.formIK.current.handleReset();
  }

  pivotData = (data) => ({
    NominalId: data.NominalId,
    MessageDetails: data.Message,
  });

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          NominalId: this.props.selectedNominals,
          Message: this.props.Message,         
        }}
        validationSchema={Yup.object().shape({
          NominalId:Yup.array().of(Yup.number()),
          Message: Yup.string().required("Message is required").typeError("Message is required"),
        })}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form id="BulkMessageNotificationForm" onSubmit={handleSubmit} onChange={handleChange}>
            <div>
              <Row>
                <Col span={24}>
                  <Row gutter={{ xs: 8, sm: 16, md: 10}}>
                    <Col xs={24} sm={24} md={24} className="gutter-row">
                      Send a message:
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col sm={24} md={24}>
                      <Form.Item
                        validateStatus={
                          errors && errors.Message && touched.Message
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.Message && touched.Message
                            ? errors.Message
                            : null
                        }
                      >
                        <TextArea
                          showCount 
                          maxLength={280} 
                          id="Message"
                          placeholder="Type your message"
                          rows={4}
                          onBlur={handleBlur}
                          value={values.Message}
                          onChange={(val) => {
                            setFieldValue("Message", val.target.value);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>                   
                  <Divider plain />
                  <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                    <Col xs={24} sm={8} md={6} className="gutter-row">
                      <CancelBtn onClick={this.props.onCancel} />
                    </Col>
                    <Col xs={24} sm={8} md={6} className="gutter-row">
                      <SendMessageButton
                        id="CommentCirlceBtn"
                        onClick={handleSubmit}
                        // loading={this.props.isLoading}
                        title="Send Message"
                        isDisabled={values.Message === null || values.Message === "" || values.NominalId.length === 0}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

BulkMessageNotificationForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
//   isLoading: PropTypes.bool,
  selectedNominals: PropTypes.arrayOf(PropTypes.number),
  Message: PropTypes.string,
};

BulkMessageNotificationForm.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
//   isLoading: false,
  selectedNominals: [],
  Message: null,
};

export default BulkMessageNotificationForm;
/* eslint-disable no-use-before-define */
/* global google */

import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Circle, GoogleMap } from "@react-google-maps/api";
import { v4 as uuidv4 } from "uuid";
import Geocode from "react-geocode";
import moment from "moment";
import MapHomeIcon from "../../../common/assets/img/map_home_icon.svg";
import MarkerWithLabel from "./MarkerWithLabel";
import actions from "../../Nominals/actions";

const containerStyle = {
  display: "inline-block",
  border: "1px solid rgba(0, 0, 0, 0.2)",
  width: "100%",
  height: "100%",
  marginLeft: "30px",
};

class MapView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      googleMap: null,
      zoom: this.props.zoomLevel,
      bounds: null,
      exclusionZones: this.props.exclusionZones,
      circles: [],
      isCirclesLoaded: false,
      circleVisibility: new WeakMap(),
      hasCircleBeenAdded: new WeakMap(),
      openInfoWindow: {},
    };

    this.state.homePosition = this.props.homePosition ?? [];

    let cp = {
      lat: parseFloat(process.env.MAP_DEFAULT_CENTER_LAT),
      lng: parseFloat(process.env.MAP_DEFAULT_CENTER_LNG),
    };
    if (this.props.centerPosition && this.props.centerPosition.lat !== null && this.props.centerPosition.lng !== undefined &&
      this.props.centerPosition.lng !== null && this.props.centerPosition.lng !== undefined) {
        cp = {
          lat: this.props.centerPosition.lat,
          lng: this.props.centerPosition.lng
        };
    }
    this.state.centerPosition = cp;
  }

  componentDidUpdate(prevProps) {
    if (this.state.googleMap) {
      const bounds = new window.google.maps.LatLngBounds();
      let mustUpdateBounds = false;
      if (prevProps.data !== this.props.data && this.props.data.length > 0) {
        this.props.data.forEach((x) => {
          if (
            x !== null && 
            x.Latitude !== null &&
            x.Latitude !== undefined &&
            x.Longitude !== null &&
            x.Longitude !== undefined
          ) {
            bounds.extend({
              lat: x.Latitude,
              lng: x.Longitude,
            });
            mustUpdateBounds = true;
          }
        });
        this.props.exclusionZones.forEach((x) => {
          if (
            x !== null &&
            x.Latitude !== null &&
            x.Latitude !== undefined &&
            x.Longitude !== null &&
            x.Longitude !== undefined
          ) {
            // Corner 1
            const coef = x.Radius * 0.0000089;
            const newLat = x.Latitude + coef;
            const newLong = x.Longitude + coef / Math.cos(x.Latitude * 0.018);

            bounds.extend({
              lat: newLat,
              lng: newLong,
            });
            // corner 2
            const coef2 = -x.Radius * 0.0000089;
            const newLat2 = x.Latitude + coef2;
            const newLong2 = x.Longitude + coef2 / Math.cos(x.Latitude * 0.018);

            bounds.extend({
              lat: newLat2,
              lng: newLong2,
            });
            mustUpdateBounds = true;
          }
        });
      } else {
        const cp = this.getDefaultCenter();
        if (!this.state.centerPosition || this.state.centerPosition.lat !== cp.lat || this.state.centerPosition.lng !== cp.lng) {
          // bounds.extend(cp);
          // mustUpdateBounds = true;
        }
      }
      if (this.state.googleMap.state && this.state.googleMap.state.map && mustUpdateBounds) {
        this.state.googleMap.state.map.fitBounds(bounds);
        // this.setCenter();
        // const zoom = Math.min(this.props.zoomLevel, this.state.googleMap.state.map.getZoom());
        this.setZoomLevel(Math.min(this.props.zoomLevel, this.state.googleMap.state.map.getZoom()));
        // this.state.googleMap.state.map.setZoom(
        //   Math.min(this.props.zoomLevel, this.state.googleMap.state.map.getZoom())
        // );
      }
    }

    if (prevProps && prevProps.homePosition !== this.props.homePosition) {
       this.setHomePosition(this.props.homePosition);
    }
    if (prevProps && prevProps.exclusionZones !== this.props.exclusionZones) {
      this.setExclusionZones(this.props.exclusionZones);
    }
  }

  setZoomLevel(zoomLevel) {
    this.setState({zoom: zoomLevel});
  }

  setHomePosition(hps) {
    this.state.homePosition = [];
    for (let i = 0; i < hps.length; i += 1) {
      this.state.homePosition.push({
        Address1: hps[i].Address1,
        City: hps[i].City,
        PostalCode: hps[i].PostalCode,
        lat: hps[i].lat,
        lng: hps[i].lng
      });
    }
  }

  setExclusionZones(zones) {
    const existingExclusionZones = [...this.state.exclusionZones];
      for (let j = 0; j < existingExclusionZones.length; j += 1) {
        this.state.circleVisibility.set(existingExclusionZones[j], false);
      }
      this.state.exclusionZones = [];
    for (let i = 0; i < zones.length; i += 1) {
      this.state.exclusionZones.push({
        Latitude: zones[i].Latitude,
        Longitude: zones[i].Longitude,
        Radius: zones[i].Radius
      });
      this.state.circleVisibility.set(zones[i], true);
    }
  }

  getDefaultCenter() {
    if (this.props.centerPosition && this.props.centerPosition.lat !== null && this.props.centerPosition.lng !== undefined &&
      this.props.centerPosition.lng !== null && this.props.centerPosition.lng !== undefined) {
      return this.props.centerPosition;
    }
    return {
      lat: parseFloat(process.env.MAP_DEFAULT_CENTER_LAT),
      lng: parseFloat(process.env.MAP_DEFAULT_CENTER_LNG),
    };
  }

  getPositionFromAddress = async (hp) => {
    const address = `${hp.Address1}, ${hp.City} ${hp.PostalCode}`;
    let home = {
      lat: null,
      lng: null,
    };

    const hps = [...this.state.homePosition];
    let foundHp = hps.find(h => h.Address1 === hp.Address1 && h.City === hp.City && h.PostalCode === hp.PostalCode);
    if (!foundHp || foundHp.lat === null || foundHp.lng === null || foundHp.lat === undefined || foundHp.lng === undefined) {
      await Geocode.fromAddress(address)
        .then((response) => {
          home = response.results[0].geometry.location;
          foundHp = hps.find(x => x.lat === home.lat && x.lng === home.lng);
          if (!foundHp) {
            const positions = [];
            for (let i = 0; i < hps.length; i += 1) {
              positions.push({ Address1: hps[i].Address1, City: hps[i].City, PostalCode: hps[i].PostalCode, lat: hps[i].lat, lng: hps[i].lng});
            }
            positions.push({ Address1: hp.Address1, City: hp.City, PostalCode: hp.PostalCode, lat: home.lat, lng: home.lng});
            this.setState({
              homePosition: positions
            });
          }
        })
        // eslint-disable-next-line no-console
        .catch((error) => {
          console.log(error);
        });
    }
  };

  onLoad = (gmap) => {
    this.updateBounds(gmap);
    gmap.addListener("click", (mapsMouseEvent) => {
      const position = JSON.parse(
        JSON.stringify(mapsMouseEvent.latLng.toJSON())
      );
      this.props.dispatch(
        actions.getLocationAddress(
          { Latitude: position.lat, Longitude: position.lng },
          // getAddressFromPosition(position).then((result) =>
          (result) => this.props.setExclusionZoneLocation(result)
        )
      );
    });
  };
  // eslint-disable-next-line no-shadow
  // onLoad = React.useCallback(function callback(gmap) {
  //     // Add listener to set exclusion zone through clicking the map
  //     gmap.addListener("click", (mapsMouseEvent) => {
  //       const position = JSON.parse(
  //         JSON.stringify(mapsMouseEvent.latLng.toJSON())
  //       );
  //       this.props.dispatch(
  //         actions.getLocationAddress(
  //           { Latitude: position.lat, Longitude: position.lng },
  //           // getAddressFromPosition(position).then((result) =>
  //           (result) => this.props.setExclusionZoneLocation(result)
  //         )
  //       );
  //     });

  //     // setMap(gmap);
  // }, []);

  // const onUnmount = React.useCallback(function callback() {
  //   setMap(null);
  // }, []);

  getMarkerColor = (typeId, index, key) => {
    switch (typeId) {
      case 1:
        return key && index === 0 ? "#383838" : "#000000";
      case 2:
        return key && index === 0 ? "#f70000" : "#9B0000";
      case 3:
        return key && index === 0 ? "#fab400" : "#B68300";
      default:
        return key && index === 0 ? "#9d9d9d" : "#585757";
    }
  };

  getCenter = () => {
    if (
      this.props.data.length > 0 &&
      this.state.googleMap &&
      this.state.googleMap.state &&
      this.state.googleMap.state.map
    ) {
      return this.state.googleMap.state.map.getCenter().toJSON();
      // centerLat = center.lat;
      // centerLng = center.lng;
    }
    return { lat: null, lng: null }
  };

  setMap = (gmap) => {
    if (!this.state.googleMap) {
      this.setState((prevState) => ({
        ...prevState,
        googleMap: gmap,
      }));
    }
    this.props.mapRefSetter(gmap);
    this.updateBounds(gmap);
  };


  addCircles = (inCircles) => {
    let allCircles = [...this.state.circles];
    for (let i = 0; i < allCircles.length; i += 1) {
      const curCircle = allCircles[i];
      const foundCircle = inCircles.find(c => c.Latitude === curCircle.lat && c.Longitude === curCircle.lng && c.Radius === curCircle.radius);
      if (!foundCircle) {
        this.state.circleVisibility.set(curCircle, false);
      }
    }
    allCircles = [];
    for (let j = 0; j < inCircles.length; j += 1) {
      const circle = {
        lat: inCircles[j].Latitude,
        lng: inCircles[j].Longitude,
        radius: inCircles[j].Radius
      }
      this.state.circleVisibility.set(circle, true);
      // const foundCircle = allCircles.find(c => c.lat === circle.lat && c.lng === circle.lng && c.radius === circle.radius);
      // if (!foundCircle) {
        allCircles.push(circle);
      // }
    }
    this.setState((prevState) => ({
      ...prevState,
      circles: allCircles,
      isCirclesLoaded: true,
    }));
  }

  getCircleVisibility = (circle) => {
    const visibility = this.state.circleVisibility.get(circle);
    return visibility;
  }

  // drawCircle = (circle) => {
  //   this.state.circlesDrawn.set(circle, true);
  // }

  // isCircleDrawn = (circle) => {
  //   const isDrawn = this.state.circlesDrawn.get(circle);
  //   return isDrawn;
  // }

getCircle = (circle) => {
  const circles = [...this.state.circles];
  const foundCircle = circles.find(c => c.lat === circle.lat && c.lng === circle.lng && c.radius === circle.radius);
  if (!foundCircle) {
    const circleComponent=(
      <Circle
        key={uuidv4()}
        center={{
          lat: circle.lat,
          lng: circle.lng,
        }}
        radius={circle.radius}
        options={{
          fillColor: "#FF0000",
          strokeOpacity: 0,
        }}
        // visible={this.state.circleVisibility.get(circle)}
      />
    );
    this.state.circleVisibility.set(circle, true);
    return circleComponent;
  }
  if (!this.state.hasCircleBeenAdded.get(circle)) {
    this.state.hasCircleBeenAdded.set(circle, true);
    return (      
      <Circle
        key={uuidv4()}
        center={{
          lat: circle.lat,
          lng: circle.lng,
        }}
        radius={circle.radius}
        options={{
          fillColor: "#FF0000",
          strokeOpacity: 0,
        }}
      />
    );
  }
  return null;
}

isInfoWindowOpen = (position) => {
  if (this.state.openInfoWindow === position) return true;
  return false;
}

toggleInfoWindowOpen = (marker) => {
  if (this.state.openInfoWindow !== marker) {
    this.setState({
      openInfoWindow: marker
    });
  }
  // const current = this.state.openInfoWindow.get(marker);
  // Object.keys(this.state.openInfoWindow).forEach(oiw => this.state.openInfoWindow.set(oiw, false));
  // this.state.openInfoWindow.set(marker, !current);
}

updateBounds(map) {
  if (map && map.state && map.state.map) {
  const bounds = new window.google.maps.LatLngBounds();
    let mustUpdateBounds = false;
    if (this.props.data.length > 0) {
      this.props.data.forEach((x) => {
        if (
          x !== null &&
          x.Latitude !== null &&
          x.Latitude !== undefined &&
          x.Longitude !== null &&
          x.Longitude !== undefined
        ) {
          bounds.extend({
            lat: x.Latitude,
            lng: x.Longitude,
          });
          mustUpdateBounds = true;
        }
      });
      this.props.exclusionZones.forEach((x) => {
        if (
          x !== null &&
          x.Latitude !== null &&
          x.Latitude !== undefined &&
          x.Longitude !== null &&
          x.Longitude !== undefined
        ) {
          // Corner 1
          const coef = x.Radius * 0.0000089;
          const newLat = x.Latitude + coef;
          const newLong = x.Longitude + coef / Math.cos(x.Latitude * 0.018);

          bounds.extend({
            lat: newLat,
            lng: newLong,
          });
          // corner 2
          const coef2 = -x.Radius * 0.0000089;
          const newLat2 = x.Latitude + coef2;
          const newLong2 = x.Longitude + coef2 / Math.cos(x.Latitude * 0.018);

          bounds.extend({
            lat: newLat2,
            lng: newLong2,
          });
          mustUpdateBounds = true;
        }
      });
    }
    if (mustUpdateBounds) {
      map.state.map.fitBounds(bounds);
    }
  }
}

render() {
    return (
      <>
        {" "}
        <GoogleMap
          /* eslint-disable-next-line no-return-assign */
          ref={(gmap) => this.setMap(gmap)}
          mapContainerClassName={this.props.cssClass}
          mapContainerStyle={containerStyle}
          center={this.state.centerPosition}
          onDragEnd={this.getCenter()}
          onLoad={(gmap) => this.onLoad(gmap)}
          // onUnmount={onUnmount}
          zoom={this.state.zoom}
        >
          {/* eslint-disable-next-line no-restricted-globals */}
          {this.props.homePosition && this.props.homePosition.map((x) => {
            if (x.lat !== null && x.lat !== undefined && x.lng !== null && x.lng !== undefined) {
              return (
                <MarkerWithLabel
                  key={uuidv4()}
                  position={{lat: x.lat, lng: x.lng}}
                  icon={{
                    url: MapHomeIcon,
                    anchor: new google.maps.Point(17.5, 17.5),
                    scaledSize: new google.maps.Size(35, 35),
                  }}
                  showPinHover={this.props.showPinHover}
                  infoWindowJSX={(
                    <>
                      <Link to={`/Nominals/${x.NominalId}`}>{x.FirstName} {x.LastName}</Link>
                      {x.Address1 && x.City && x.PostalCode && (
                        <>
                          <br />
                          <span>
                            {`${x.Address1}${
                              x.Address2 ? ` ${x.Address2}` : ""
                            }, ${x.City}, ${x.PostalCode}`}
                          </span>
                        </>
                      )}
                    </>
                )}
                />
              );
            }
            return (
              <span key={uuidv4()} />
            )
          })}

          {this.props.data.map((x, index) => {
            // eslint-disable-next-line no-restricted-globals
            const label = x == null || isNaN(x.key) ? " " : `${x.key + 1}`;
            let size = 40;
            // eslint-disable-next-line no-restricted-globals
            if ((x == null || isNaN(x.key)) && index !== 0) size = 20;
            return x != null && x.Latitude != null && x.Longitude != null ? (
              <MarkerWithLabel
                key={uuidv4()}
                position={{
                  lat: x.Latitude,
                  lng: x.Longitude,
                }}
                size={size}
                // eslint-disable-next-line no-restricted-globals
                color={this.getMarkerColor(x.NominalTypeId, index, isNaN(x.key))}
                label={label}
                showPinHover={this.props.showPinHover}
                infoWindowJSX={(
                  <>
                    <Link to={`/Nominals/${x.NominalId}`}>{x.FirstName} {x.LastName}</Link>
                    {x.Date && x.Time && (
                      <>
                        <br />
                        <span>
                          {`${moment(x.CreatedDate).local().format("DD-MM-YYYY h:mm a")}`}
                        </span>
                      </>
                    )}
                    {x.Address1 && x.City && x.PostalCode && (
                      <>
                        <br />
                        <span>
                          {`${x.Address1}${
                            x.Address2 ? ` ${x.Address2}` : ""
                          }, ${x.City}, ${x.PostalCode}`}
                        </span>
                      </>
                    )}
                    {x.Address && (
                      <>
                        <br />
                        <span>
                          {`${x.Address}`}
                        </span>
                      </>
                    )}
                  </>
                )}
              />
            ) : (
              <span key={uuidv4()} />
            );
          })}

          {this.props.exclusionZones && this.props.exclusionZones.map((x) => {
            if (x.Latitude !== null && x.Latitude !== undefined &&
              x.Longitude !== null && x.Longitude !== undefined &&
              x.Radius !== null && x.Radius !== undefined) {
              // && !this.isCircleDrawn(x)) {
              //   this.drawCircle(x);
              return (
                <Circle
                  key={uuidv4()}
                  center={{
                    lat: x.Latitude,
                    lng: x.Longitude,
                  }}
                  radius={x.Radius}
                  options={{
                    fillColor: "#FF0000",
                    strokeOpacity: 0,
                  }}
                  visible={this.getCircleVisibility(x)}
                  // visible={this.state.circleVisibility[x.Id]}
                />
              );
            }
            return (
              <span key={uuidv4()} />
            );
          })}
        </GoogleMap>
      </>
    );
  }
  // return <>{isLoaded && googleMap}</>;
}

MapView.propTypes = {
  dispatch: PropTypes.func,
  mapRefSetter: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  exclusionZones: PropTypes.arrayOf(PropTypes.shape({})),
  centerPosition: PropTypes.shape({
    lat: PropTypes.number,
    lng: PropTypes.number,
  }),
  homePosition: PropTypes.arrayOf(PropTypes.shape({
    Address1: PropTypes.string,
    City: PropTypes.string,
    PostalCode: PropTypes.string,
    lat: PropTypes.number,
    lng: PropTypes.number,
  })),
  setExclusionZoneLocation: PropTypes.func,
  cssClass: PropTypes.string,
  zoomLevel: PropTypes.number,
  showPinHover: PropTypes.bool,
  // isShowExclusionZones: PropTypes.bool,
};

MapView.defaultProps = {
  dispatch: () => {},
  mapRefSetter: () => {},
  data: [],
  exclusionZones: [],
  centerPosition: {
    lat: null,
    lng: null,
  },
  homePosition: [],
  setExclusionZoneLocation: () => {},
  cssClass: "googleMap",
  zoomLevel: 10,
  showPinHover: false,
  // isShowExclusionZones: true,
};

function mapStateToProps(state) {
  return { state };
}

const connectedMapView = connect(mapStateToProps)(MapView);

export { connectedMapView as default };

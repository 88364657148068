import apiRequest from "../common/utils/apiRequest";

function getAlertDetail(id) {
  return apiRequest()
    .get(`/api/Alert/GetAlertDetail/${id}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function resolveAlert(id) {
  return apiRequest()
    .put(`/api/Alert/Resolve/${id}`, id)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getMyAlertCount() {
  return apiRequest()
    .get(`/api/Alert/GetAllMyAlerts`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function getMyCheckinAlerts(NominalId) {
  return apiRequest()
    .get(`/api/CheckinAlertSettings/GetAlertSettings/${NominalId}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function postCheckinAlerts(data) {
  return apiRequest()
    .post("/api/CheckinAlertSettings", data)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function getAlertsOData(querystring = "") {
  return apiRequest()
    .get(
      `/odata/AlertOData?&$expand=Nominal,Reviewer,AlertType,AlertState${querystring}`
    )
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function submitComment(comment) {
  return apiRequest()
    .post(`/api/Alert/AddComment`, comment)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function getComments(id) {
  return apiRequest()
    .get(`/api/Alert/GetComments/${id}`)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

function resolveAlertBulk(data) {
  return apiRequest()
    .put("/api/Alert/Resolve/Bulk", data)
    .then((response) => response.data)
    .catch((error) => Promise.reject(error));
}

export default {
  getAlertDetail,
  resolveAlert,
  getMyAlertCount,
  getMyCheckinAlerts,
  postCheckinAlerts,
  getAlertsOData,
  submitComment,
  getComments,
  resolveAlertBulk,
};

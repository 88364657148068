import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Tabs } from "antd";
import navigationActions from "../../../common/components/navigation/action";
import AlertGrid from "../../../common/components/alert-grid";
import actions from "../actions";
import { MyNominalsGrid } from '../grids';
import { NominalModal } from '../../Nominals/modals';

const { TabPane } = Tabs;

class MyNominalsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      myNominalList: [],
      myNominalSearch: "",
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("TeamNominals"));
    this.getNominalData();
  }

  buildSearchQuery = (query) => {
    let searchQuery = `$filter=TeamId eq ${this.props.currentUser.TeamId}`;
    let queryTerms = {};
    if (this.state.myNominalSearch) {
      queryTerms = this.state.myNominalSearch;
    }
    if (typeof query === 'string')
    {
      searchQuery = `${searchQuery} and contains(FullName,'${query}')`
    } else if (query) {
      if (query.name) queryTerms.Name = query.name;
      if (query.startDate && query.endDate && query.dateField) {
        queryTerms.StartDate = query.startDate;
        queryTerms.EndDate = query.endDate;
        queryTerms.DateField = query.dateField;
      }
    }
    if (queryTerms.Name) {
      searchQuery = `${searchQuery} and contains(FullName,'${queryTerms.Name}')`
    }
    if (queryTerms.StartDate && queryTerms.EndDate && queryTerms.DateField) {
      searchQuery = `${searchQuery} and ${queryTerms.DateField} ge ${queryTerms.StartDate} and ${queryTerms.DateField} le ${queryTerms.EndDate}`;
    }
    this.setState({myNominalSearch: queryTerms});
    return searchQuery;
  }

  getNominalData = (query) => {
    const searchQuery = this.buildSearchQuery(query);
    this.props.dispatch(actions.getMyNominalData(searchQuery, this.setNominalData));
  };

  setNominalData = (data) => {
    this.setState({         
        myNominalList: data 
    });   
  }

  RefreshGrid = () => {
    this.getNominalData(this.state.myNominalSearch);
  }

  
  changeTabs = (tab) =>{
    if (tab === "teamnominals") { 
        this.getNominalData(this.state.myNominalSearch);
    }
  }

  render() {
    const searchQuery = `&$filter=Nominal/TeamId eq ${this.props.currentUser.TeamId} and AlertState/Id ne 3`;
    return (
      <div className="mosovo-content-container">
        <Row justify="center">
          <Col span={24}>
            <Tabs size="large" onTabClick={(e) => this.changeTabs(e)}>
              <TabPane tab="Team Nominals" key="teamnominals">
                <MyNominalsGrid
                  myNominalList={this.state.myNominalList}
                  onSearch={this.getNominalData}
                  refreshGrid={this.RefreshGrid}
                />
              </TabPane>
              <TabPane tab="Team Nominal Alerts" key="mynominalalerts">
                <AlertGrid
                  dispatch={this.props.dispatch}
                  searchQuery={searchQuery}
                  refreshGrid={this.RefreshGrid}
                />
              </TabPane>
            </Tabs>
          </Col>
        </Row>
        <NominalModal refreshGrid={this.RefreshGrid} />
      </div>
    );
  }
}

MyNominalsPage.propTypes = {
    dispatch: PropTypes.func,
    currentUser: PropTypes.shape({
      TeamId: PropTypes.number,
    }),
};

MyNominalsPage.defaultProps = {
    dispatch: () => {},
    currentUser: {
      TeamId: null
    },
};

function mapStateToProps(state) {
  const { currentUser } = state.Account;
  return { currentUser };
}

const connectMyNominalsPage = connect(mapStateToProps)(MyNominalsPage);

export { connectMyNominalsPage as default };

/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik } from "formik";
import {
  Row,
  Col,
  Form,
  Checkbox,
  Divider,
  Select,
  InputNumber,
} from "antd";
import * as Yup from "yup";
import { faPhoneSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelBtn, SaveBtn } from "../../../common/components/form";

import "../../../common/components/alert-modal/Alert.scss";

const { Option } = Select;

class IdleSettingsForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
  }

  getTimeLimit(amount) {
    const timeLimit = {
        Amount: amount,
        Units: "MINUTES"
    };
    if (amount && amount >= 60) {
        timeLimit.Amount = amount / 60;
        timeLimit.Units = "HOURS";
    } else if (this.props.NominalIdleSettings && this.props.NominalIdleSettings.IdleTimeLimit) {
      if (this.props.NominalIdleSettings.IdleTimeLimit >= 60) {
          timeLimit.Amount = this.props.NominalIdleSettings.IdleTimeLimit / 60;
          timeLimit.Units = "HOURS";
      } else {
          timeLimit.Amount = this.props.NominalIdleSettings.IdleTimeLimit;
      }
    } else {
        timeLimit.Amount = 12;
        timeLimit.Units = "HOURS";
    }
    return timeLimit;
}

pivotData = (data) => ({
    IdleTimeLimit: data.Units === "HOURS" ? data.IdleTimeLimit * 60 : data.IdleTimeLimit,
    CreateNominalAlertSettings: {
      IsNotifyEmail: data.NotifyByEmail,
      IsNotifyText: data.NotifyByTextMessage,
      IsNotifyTeamEmail: data.NotifyByTeamEmail,
      IsUrgent: data.IsUrgent,
      IsAutomaticPushNotification: true,
      IsActive: true,
      NominalId: this.props.Nominal.Id,
    },
  });

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Units: this.getTimeLimit().Units,
          IdleTimeLimit: this.getTimeLimit().Amount,
          NotifyByEmail: this.props.IsNotifyEmail,
          NotifyByTextMessage: this.props.IsNotifyText,
          NotifyByTeamEmail: this.props.IsNotifyTeamEmail,
          IsUrgent: this.props.IsUrgent,
        }}
        validationSchema={Yup.object().shape({
          Units: Yup.string()
            .typeError("Units is required")
            .required("Units is required"),
          IdleTimeLimit: Yup.number()
            .typeError("Max Idle Time is required")
            .required("Max Idle Time is required"),
        })}
        onSubmit={(values) => {
          this.props.onSubmit(this.pivotData(values));
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form onSubmit={handleSubmit} onChange={handleChange}>
            <Row>
              <Col span={24}>
                <Row gutter={[16, 48]} justify="center">
                  <Col span={4}>
                    <span
                      className="anticon"
                      role="img"
                      style={{ textAlign: "center" }}
                    >
                      <FontAwesomeIcon
                        icon={faPhoneSlash}
                        id="IdleSettingsInfo"
                        style={{
                          color: "#585757",
                          paddingRight: "5px",
                          fontSize: "24px",
                        }}
                        alt="IdleSettings"
                      />
                    </span>
                  </Col>
                  <Col span={20}>
                    <span
                      style={{
                        fontSize: 13,
                        fontWeight: 400,
                        marginTop: "5%",
                        marginBottom: 30,
                        color: "#585757",
                      }}
                    >
                      Alert me when no location changes have been detected on a Nominal’s device over a period of time.
                    </span>
                  </Col>
                </Row>
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                  <Col xs={12} sm={12} md={4} className="gutter-row">
                    <span className="required-field">Max Idle Time: </span>
                  </Col>
                  <Col xs={12} sm={12} md={8}>
                    <Form.Item
                      validateStatus={
                        errors && errors.IdleTimeLimit && touched.IdleTimeLimit
                          ? "error"
                          : "success"
                      }
                      help={
                        errors && errors.IdleTimeLimit && touched.IdleTimeLimit
                          ? errors.Amount
                          : null
                      }
                    >
                      <InputNumber
                        id="IdleTimeLimit"
                        onBlur={handleBlur}
                        value={values.IdleTimeLimit}
                        onChange={(val) => {
                          setFieldValue("IdleTimeLimit", val);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12} sm={12} md={8}>
                    <Form.Item>
                      <Select
                        name="Units"
                        onChange={(val) => {
                          setFieldValue("Units", val);
                        }}
                        id="units"
                        onBlur={handleBlur}
                        value={values.Units}
                      >
                        <Option
                          style={{ borderRadius: 5 }}
                          key="idle-units-hours"
                          value="HOURS"
                        >
                          Hours
                        </Option>
                        <Option
                          style={{ borderRadius: 5 }}
                          key="idle-units-minutes"
                          value="MINUTES"
                        >
                          Minutes
                        </Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider plain />
                <Form.Item style={{ width: "100%" }}>
                  <Row>
                    <Col xs={12} sm={12} md={6} className="gutter-row">
                      <span className="required-field">Notify me by: </span>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Checkbox
                        style={{
                          marginRight: 5,
                        }}
                        id="NotifyByEmail"
                        onBlur={handleBlur}
                        checked={values.NotifyByEmail}
                        onChange={(val) => {
                          setFieldValue("NotifyByEmail", val.target.value);
                        }}
                      />
                      <span>
                        <label htmlFor="email">Email</label>
                      </span>
                    </Col>
                    <Col xs={12} sm={12} md={8}>
                      <Checkbox
                        style={{
                          display: "inline-block",
                          marginRight: 5,
                        }}
                        id="NotifyByTextMessage"
                        onBlur={handleBlur}
                        checked={values.NotifyByTextMessage}
                        onChange={(val) => {
                          setFieldValue(
                            "NotifyByTextMessage",
                            val.target.value
                          );
                        }}
                      />
                      <label htmlFor="textMessage">Text Message</label>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} sm={12} md={6} />
                    <Col xs={12} sm={12} md={8}>
                      <Checkbox
                        style={{
                          display: "inline-block",
                          marginRight: 5,
                        }}
                        id="NotifyByTeamEmail"
                        onBlur={handleBlur}
                        checked={values.NotifyByTeamEmail}
                        onChange={(val) => {
                          setFieldValue("NotifyByTeamEmail", val.target.value);
                        }}
                      />
                      <label htmlFor="teamEmail">Team Email</label>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item>
                  <Row>
                    <Col xs={12} sm={12} md={8}>
                      <Checkbox
                        style={{ display: "inline-block", marginRight: 5 }}
                        id="IsUrgent"
                        onBlur={handleBlur}
                        checked={values.IsUrgent}
                        onChange={(val) => {
                          setFieldValue("IsUrgent", val.target.value);
                        }}
                      />
                      <label htmlFor="flagAsUrgent"> Flag as Urgent</label>
                    </Col>
                  </Row>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
              <Col xs={24} sm={8} md={6} className="gutter-row">
                <CancelBtn onClick={this.props.onCancel} />
              </Col>
              <Col xs={24} sm={8} md={6} className="gutter-row">
                <SaveBtn
                  onClick={handleSubmit}
                  isDisabled={Object.keys(errors).length > 0}
                />
              </Col>
            </Row>
          </Form>
        )}
      </Formik>
    );
  }
}

IdleSettingsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  Nominal: PropTypes.shape(),
  NominalIdleSettings: PropTypes.shape({
    IdleTimeLimit: PropTypes.number,
  }),
  IsNotifyEmail: PropTypes.bool,
  IsNotifyText: PropTypes.bool,
  IsNotifyTeamEmail: PropTypes.bool,
  IsUrgent: PropTypes.bool,
};

IdleSettingsForm.defaultProps = {
  onCancel: () => {},
  Nominal: {},
  NominalIdleSettings: {
    IdleTimeLimit: 0,
  },
  IsNotifyEmail: false,
  IsNotifyText: false,
  IsNotifyTeamEmail: false,
  IsUrgent: false,
};

function mapStateToProps(state) {
  const { Nominal } = state.Nominal;
  return { Nominal };
}

const connectIdleSettingsForm = connect(mapStateToProps)(
    IdleSettingsForm
);

export { connectIdleSettingsForm as default };

/* eslint-disable camelcase */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import apiRequest from "../../../common/utils/apiRequest";
import lookupAuth0Code from "../../../services/Auth0CodeService";

export const ProfileManagementContext = React.createContext({});

const mapLogs = (rawLogs) => {
  return rawLogs.map((l) => {
    return {
      description: lookupAuth0Code(l.type),
      ipAddress: l.ip,
      client: l.user_agent,
      location: `${l?.location_info?.city_name}, ${l?.location_info?.country_name}`,
    };
  });
};

export const ProfileManagementContextProvider = ({ children }) => {
  const [loading, setLoading] = React.useState(true);
  const [userLogs, setUserLogs] = React.useState([]);

  useEffect(() => {
    setLoading(true);
    return apiRequest()
      .get(`api/user/logs`)
      .then((response) => {
        setUserLogs(mapLogs(response.data));
        setLoading(false);
      })
      .catch((error) => Promise.reject(error));
  }, [setLoading, setUserLogs]);

  const resetPassword = () => {
    return apiRequest()
      .post(`api/User/Password/Reset`)
      .then(() => {})
      .catch((error) => Promise.reject(error));
  };

  return (
    <ProfileManagementContext.Provider
      value={{ loading, setLoading, userLogs, setUserLogs, resetPassword }}
    >
      {children}
    </ProfileManagementContext.Provider>
  );
};

ProfileManagementContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Select } from "antd";

import actions from "./actions";

const { Option } = Select;

class NominalTypesSelect extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(actions().getList());
  }

  render() {
    return (
      <Select
        className={this.props.className}
        value={this.props.value}
        onChange={this.props.onChange}
        placeholder="Select"
        onBlur={this.props.onBlur}
        disabled={this.props.disabled}
        style={{ width: "100%"}}
        allowClear={this.props.isAllowClear}
      >
        {this.props.nominalTypes.map((opt) => (
          <Option key={`nominal-type-${opt.Id}`} value={opt.Id}>
            {opt.Name}
          </Option>
        ))}
      </Select>
    );
  }
}

NominalTypesSelect.propTypes = {
  dispatch: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.number,
  nominalTypes: PropTypes.arrayOf(PropTypes.shape({})),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  isAllowClear: PropTypes.bool,
};

NominalTypesSelect.defaultProps = {
  dispatch: () => {},
  onBlur: () => {},
  value: 1,
  className: "",
  nominalTypes: [],
  disabled: false,
  isAllowClear: false,
};

function mapStateToProps(state) {
  const { nominalTypes } = state.NominalTypes;
  return {
    nominalTypes,
  };
}

const connectedNominalTypesSelect = connect(mapStateToProps)(NominalTypesSelect);

export { connectedNominalTypesSelect as default };

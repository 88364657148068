import React from "react";
import { Table, Row, Col } from "antd";
import useUserLogsContext from "../hooks/useProfileManagementContext";

const sortAlphaBy = (a, b, field) => {
  const aVal = a[field] || " ";
  const bVal = b[field] || " ";

  if ((aVal.toUpperCase() || "") > (bVal.toUpperCase() || "")) return -1;

  if ((aVal.toUpperCase() || "") < (bVal.toUpperCase() || "")) return 1;

  return 0;
};

const buildColumn = (displayName, key) => {
  return {
    title: displayName,
    dataIndex: key,
    key,
    sorter: (a, b) => {
      return sortAlphaBy(a, b, key);
    },
    sortDirections: ["descend", "ascend"],
  };
};

const ProfileActivityLogGrid = () => {
  const { loading, userLogs } = useUserLogsContext();

  const gridColumns = [
    buildColumn("Description", "description"),
    buildColumn("Client", "client"),
    buildColumn("IP Address", "ipAddress"),
    buildColumn("Location", "location"),
  ];

  return (
    <Row justify="center">
      <Col span={24}>
        <Table
          loading={loading}
          dataSource={userLogs}
          columns={gridColumns}
          scroll={{ x: true }}
          pagination={{ pageSize: 5 }}
        />
      </Col>
    </Row>
  );
};

export default ProfileActivityLogGrid;

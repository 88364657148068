import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin } from "antd";
import { WarningOutlined } from '@ant-design/icons';

import { NominalForm } from "../forms";
import actions from "../actions";

class NominalModal extends React.Component {
  CloseModal = () => {
    this.props.dispatch(actions.closeNominalModal());
    this.props.refreshGrid();
  };

  CloseNominalModal = () => {    
    const { confirm } = Modal; 
    // const { dispatch } = this.props;
    const closeModal = this.CloseModal;
    const message = (
      <div>
        <p>
          Are you sure you want to cancel?
        </p>
        <p>You will lose all data on this form.</p>
      </div>
      
    );

    confirm({
      title: "Close New Nominal",
      icon: <WarningOutlined />,
      content: message,
      width: 600,
      onOk() {
        closeModal();
      },
      okText: "Yes, Close"
    });
  }

  NominalCreate = (data) => {
    this.props.dispatch(actions.createNominal(data, this.CloseModal));
  };

  render() {
    return (
      <>
        <Modal
          title="New Nominal"
          visible={this.props.showAddModal}
          // onCancel={this.CloseModal}
          footer={null}
          width={600}
          onCancel={this.CloseNominalModal}
          destroyOnClose
        >
          <div>
            <Row>
              <Col span={24}>
                <Spin spinning={this.props.loading}>
                  <NominalForm
                    onCancel={this.CloseNominalModal}
                    onSubmit={this.NominalCreate}
                    isLoading={this.props.loading}
                    {...this.props.Nominal}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

NominalModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  //   showEditModal: PropTypes.bool,
  showAddModal: PropTypes.bool,
  loading: PropTypes.bool,
  Nominal: PropTypes.shape({}),
};

NominalModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  //   showEditModal: false,
  showAddModal: false,
  loading: false,
  Nominal: {},
};

function mapStateToProps(state) {
  const { loading, showAddModal, Nominal } = state.TeamNominal;

  return { loading, showAddModal, Nominal };
}

const connectedNominalModal = connect(mapStateToProps)(NominalModal);

export { connectedNominalModal as default };

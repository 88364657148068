import constants from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  locations: [],
  searchParams: {},
  showNotFoundModal: false,
};

export default function OrganisationReducer(state = initialState, action) {
  switch (action.type) {
    case constants.GET_NOMINAL_LOCATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        searchParams: action.searchParams,
      };
    case constants.GET_NOMINAL_LOCATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        locations: action.locations,
      };
    case constants.GET_NOMINAL_LOCATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case constants.RESET_NOMINAL_LOCATIONS:
      return {
        ...state,
        locations: [],
        searchParams: {},
      }
    case constants.OPEN_NOT_FOUND:
      return {
        ...state,
        showNotFoundModal: true,
      }
    case constants.CLOSE_MODAL:
      return {
        ...state,
        showNotFoundModal: false,
      }
    case constants.HUB_CREATE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case constants.HUB_CREATE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case constants.HUB_CREATE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}

import React from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
import { Formik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";

import { Row, Col, Form, Input, Divider, Checkbox, Button } from "antd";
import { faExclamationTriangle, faPhoneSlash, faExclamationCircle } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CancelBtn, SaveBtn } from "../../../common/components/form";

class CheckinAlertForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();

  }

  pivotData = (data) => ({
    Id: data.Id,
    MaxCheckinAmount: data.MaxCheckinAmount,
    NominalId: this.props.NominalId,
    IsNotifyEmail: data.IsNotifyEmail,
    IsNotifyText: data.IsNotifyText,
    IsNotifyTeamEmail: data.IsNotifyTeamEmail,
    IsUrgent: data.IsUrgent,
  });

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          Id: this.props.Id,
          MaxCheckinAmount: this.props.MaxCheckinAmount,
          IsUrgent: this.props.IsUrgent,
          IsNotifyEmail: this.props.IsNotifyEmail,
          IsNotifyText: this.props.IsNotifyText,
          IsNotifyTeamEmail: this.props.IsNotifyTeamEmail,
        }}
        validationSchema={Yup.object().shape({
          Id: Yup.number(),
          MaxCheckinAmount: Yup.number().min(1, 'Please enter a number greater than 0.')
            .typeError('Please enter a valid number.').required('Please enter a valid number.'),
          IsNotifyEmail: Yup.bool(),
          IsNotifyText: Yup.bool(),
          IsNotifyTeamEmail: Yup.bool(),
          IsUrgent: Yup.bool(),
        })}
        // onChange={(values) => this.handleChange(values)}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) => (
          <Form
            id="NominalDeviceInfoForm"
            onSubmit={handleSubmit}
            onChange={handleChange}
          >
            <div>
              { !this.props.hasSetting && (
                <Row className="CheckInAlertWarning">
                  <Col span={2} className="CheckinAlertIcon">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      style={{marginLeft: '5px'}}
                    />
                  </Col>
                  <Col span={22}>
                    <span>No check-in settings have been added to this nominal. Before receving check-in alerts on this nominal, you must set up the check-in setting on the
                      <Button
                        type="text"
                        style={{color: 'blue', paddingLeft: '5px'}}
                        onClick={(e) => {
                          e.preventDefault();
                          this.props.changeTabs('checkin');
                          this.props.onCancel();
                        }}
                      >
                        Check-In tab.
                      </Button>
                    </span>
                  </Col>
                </Row>
              )}
              <Row className="AlertInfoMiddleRow">
                <Col span={2} className="CheckinAlertIcon">
                  <FontAwesomeIcon
                    icon={faPhoneSlash}
                  />
                </Col>
                <Col span={22}>
                  <span>Alert me when Nominal has failed to respond to check-in requests the maximum number of times.</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <span className="required-field AlertCheckinInfoTitle">Max Failed Check-ins:</span>
                </Col>
                <Col>
                  <Form.Item
                    validateStatus={
                      errors && errors.MaxCheckinAmount && touched.MaxCheckinAmount
                        ? "error"
                        : "success"
                    }
                    help={
                      errors && errors.MaxCheckinAmount && touched.MaxCheckinAmount
                        ? errors.MaxCheckinAmount
                        : null
                    }
                  >
                    <Input
                      value={values.MaxCheckinAmount}
                      onBlur={handleBlur}
                      placeholder="Number"
                      onChange={(e) => setFieldValue('MaxCheckinAmount', e.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Divider plain />
              <Row>
                <Col>
                  <span className="AlertCheckinInfoTitle">Notify me by: </span>
                </Col>
                <Col className="AlertCheckboxList">
                  <Checkbox
                    checked={values.IsNotifyEmail}
                    onClick={() => setFieldValue('IsNotifyEmail', !values.IsNotifyEmail)}
                  >
                    Email
                  </Checkbox>
                  <Checkbox
                    checked={values.IsNotifyText}
                    onClick={() => setFieldValue('IsNotifyText', !values.IsNotifyText)}
                  >
                    Text Message
                  </Checkbox>
                  <Checkbox
                    checked={values.IsNotifyTeamEmail}
                    onClick={() => setFieldValue('IsNotifyTeamEmail', !values.IsNotifyTeamEmail)}
                  >
                    Team email
                  </Checkbox>
                </Col>
              </Row>
              <Row className="UrgentCheckbox">
                <Checkbox
                  checked={values.IsUrgent}
                  onClick={() => setFieldValue('IsUrgent', !values.IsUrgent)}
                >
                  Flag as urgent
                  <FontAwesomeIcon
                    icon={faExclamationCircle}
                    style={{marginLeft: '5px'}}
                  />
                </Checkbox>
              </Row>
              <Divider plain />
              <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <CancelBtn onClick={this.props.onCancel} />
                </Col>
                <Col xs={24} sm={8} md={6} className="gutter-row">
                  <SaveBtn
                    onClick={handleSubmit}
                    loading={this.props.isLoading}
                  />
                </Col>
              </Row>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

CheckinAlertForm.propTypes = {
  // dispatch: PropTypes.func,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  hasSetting: PropTypes.bool,
  NominalId: PropTypes.number,
  Id: PropTypes.number,
  MaxCheckinAmount: PropTypes.number,
  IsNotifyEmail: PropTypes.bool,
  IsNotifyText: PropTypes.bool,
  IsNotifyTeamEmail: PropTypes.bool,
  IsUrgent: PropTypes.bool,
  changeTabs: PropTypes.func,
};

CheckinAlertForm.defaultProps = {
  // dispatch: () => {},
  onCancel: () => {},
  onSubmit: () => {},
  NominalId: 0,
  hasSetting: false,
  isLoading: false,
  Id: 0,
  MaxCheckinAmount: null,
  IsNotifyEmail: false,
  IsNotifyText: false,
  IsNotifyTeamEmail: false,
  IsUrgent: false,
  changeTabs: () => {},
};

function mapStateToProps() {
  return {};
}

const connectedCheckinAlertForm = connect(mapStateToProps)(CheckinAlertForm);

export { connectedCheckinAlertForm as default };

import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin } from "antd";
// import moment from "moment";

import { CurfewSettingsForm } from "../forms";
import actions from "../actions";
// import { SaveBtn } from "src/app/common/components/form";

class CurfewSettingsModal extends React.Component {
  CloseModal = () => {
    this.props.dispatch(actions.closeNominalModal());
    this.props.refreshGrid();
  };

  CreateCurfewAlert = (data) => {
    this.props.dispatch(
      actions.createNominalCurfew(data, this.CloseModal)
    );
  };

  UpdateCurfewAlert = (data) => {
    const updateData = {
      Id: this.props.AlertSettings.NominalCurfewSettings.Id,
      Latitude: data.Latitude,
      Longitude: data.Longitude,
      StartTime: data.StartTime,
      EndTime: data.EndTime,
      // StartTime: moment(data.StartTime).utc().format('HH:mm'),
      // EndTime: moment(data.EndTime).utc().format('HH:mm'),
      Address1: data.Address1,
      Address2: data.Address2,
      City: data.City,
      PostalCode: data.PostalCode,
      Radius: data.Radius,
      UpdateNominalAlertSettings: 
        {
          Id: this.props.AlertSettings.Id,
          IsNotifyEmail: data.CreateNominalAlertSettings.IsNotifyEmail,
          IsNotifyText: data.CreateNominalAlertSettings.IsNotifyText,
          IsNotifyTeamEmail: data.CreateNominalAlertSettings.IsNotifyTeamEmail,
          IsNotifyNominal: data.CreateNominalAlertSettings.IsNotifyNominal,
          IsUrgent: data.CreateNominalAlertSettings.IsUrgent,
          IsAutomaticPushNotification: true,
          IsActive: true,
          NominalId: this.props.Nominal.Id,
        },
    };
    this.props.dispatch(
      actions.updateNominalCurfew(updateData, this.CloseModal)
    );
  };

//   createFooter = () => {
//     return (
//     <Row>
//     <Col span={12}>
//       <Button onClick={this.props.onCancel}>Cancel</Button>
//       <SaveBtn
//         className="blueButton"
//         onClick={handleSubmit}
//         value="Submit"
//         loading={this.props.isLoading}
//         disabled={Object.keys(errors).length > 0}
//       >
//         Save
//       </SaveBtn>
//     </Col>
//   </Row>);
// }

  render() {
    return (
      <>
        <Modal
          title="Curfew Alert"
          visible={this.props.showCurfewAlertModal}
          onCancel={this.CloseModal}
          footer={null}
          width={1000}
          height={1500}
          destroyOnClose
        >
          <div>
            <Row>
              <Col span={24}>
                <Spin spinning={this.props.loading}>
                  <CurfewSettingsForm
                    onCancel={this.CloseModal}
                    onSubmit={
                      this.props.AlertSettings &&
                      this.props.AlertSettings.NominalCurfewSettings
                        ? this.UpdateCurfewAlert
                        : this.CreateCurfewAlert
                    }
                    Nominal={this.props.Nominal}
                    {...this.props.AlertSettings}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

CurfewSettingsModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  showCurfewAlertModal: PropTypes.bool,
  loading: PropTypes.bool,
  Nominal: PropTypes.shape(),
  AlertSettings: PropTypes.shape(),
};

CurfewSettingsModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  showCurfewAlertModal: true,
  loading: false,
  Nominal: {},
  AlertSettings: {},
};

function mapStateToProps(state) {
  const {
    loading,
    showCurfewAlertModal,
    Nominal,
    AlertSettings,
  } = state.Nominal;

  return { loading, showCurfewAlertModal, Nominal, AlertSettings };
}

const connectedCurfewSettingsModal = connect(mapStateToProps)(CurfewSettingsModal);

export { connectedCurfewSettingsModal as default };

import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
// import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { goBack } from "connected-react-router";

import { Row, Col, Tabs } from "antd";
import { faChevronLeft } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import navigationActions from "../../../common/components/navigation/action";
import { MosovoModals } from "../../../common/components";
import {
  NominalBasicInfo,
  LocationHistory,
  NominalAdditionalInfo,
  DeviceInfo,
  // KnownAssociates,
  Communications,
  Checkin,
  NominalAlerts,
} from "../components";
import {
  NominalModal,
  NominalBasicInfoModal,
  ContactNominalModal,
  CheckinAlertModal,
  AddNominalLocationModal,
  ExclusionZoneModal,
  CurfewSettingsModal,
  LocationFailSettingsModal,
  IdleSettingsModal,
} from "../modals";
import actions from "../actions";

import "./Nominals.scss";

const { TabPane } = Tabs;
const { ExportModal } = MosovoModals;
class NominalDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locationHistory: [],
      locationSearch: null,
      searchQuery: `$filter=NominalId eq ${this.props.Id}`,
      alerts: [],
      activeKey: this.props.match.params.Tab ?? 'nomimaldetail',
      isSearch: false,
      isFilter: false,
    };
  }

  componentDidMount() {
    this.props.dispatch(navigationActions.changePage("MyNominals"));
    this.changeTabs(this.props.match.params.Tab ?? 'nomimaldetail');
    this.props.dispatch(actions.getNominalDetail(this.props.Id));
  }

  RefreshGrid = () => {
    this.props.dispatch(actions.getNominalDetail(this.props.Id));
  };

  GoBack = (e) => {
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(goBack());
  };

  editBasicInfo = (Id) => {
    const { dispatch } = this.props;
    dispatch(actions.getNominalBasicDetail(Id, this.openNominalBasicInfoModal));
  };

  editDevicenfo = (Id) => {
    const { dispatch } = this.props;
    dispatch(
      actions.getNominalBasicDetail(Id, this.openNominalDeviceInfoModal)
    );
  };

  editAdditionalInfo = (Id) => {
    const { dispatch } = this.props;
    dispatch(
      actions.getNominalBasicDetail(Id, this.openNominalAdditionalInfoModal)
    );
  };

  openNominalBasicInfoModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openNominalBasicInfoModal());
  };

  openNominalDeviceInfoModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openNominalDeviceInfoModal());
  };

  openNominalAdditionalInfoModal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openNominalAdditionalInfoModal());
  };

  addNominal = () => {
    const { dispatch } = this.props;
    dispatch(actions.openNominalModal());
  };

  buildSearchQuery = (query) => {
    let sq = "";
    if (this.state.searchQuery) {
      sq = this.state.searchQuery;
    }
    const fullNameRegex = new RegExp(`(?<=FullName,').+(?=')`);
    let queryTerms = {};
    if (this.state.locationSearch) {
      queryTerms = this.state.locationSearch;
    }
    if (query) {
      if (query.name) queryTerms.Name = query.name;
      if (query.startDate && query.endDate && query.dateField) {
        queryTerms.StartDate = query.startDate;
        queryTerms.EndDate = query.endDate;
        queryTerms.DateField = query.dateField;
        queryTerms.Reset = query.reset;
      }
    } else {
      queryTerms.Name = undefined;
      queryTerms.DateField = undefined;
      queryTerms.StartDate = undefined;
      queryTerms.EndDate = undefined;
      queryTerms.Reset = undefined;
    }
    if (!this.state.isSearch && !this.state.isFilter) {
      sq = `$filter=NominalId eq ${this.props.Id}`;
    }
    if (queryTerms.Name) {
      sq = sq.includes(queryTerms.Name) 
        ? sq.replace(fullNameRegex, queryTerms.Name) 
        : `${sq} and contains(FullName,'${queryTerms.Name}')`
      // searchQuery = `${searchQuery} and contains(FullName,'${queryTerms.Name}')`
    }
    if (query && queryTerms.StartDate && queryTerms.EndDate && queryTerms.DateField) {
      const equalsRegex = new RegExp(`(?<=${queryTerms.DateField} eq ).+(?=)`);
      const startRegex = new RegExp(`(?<=${queryTerms.DateField} ge ).+(?= and)`);
      const endRegex = new RegExp(`(?<=${queryTerms.DateField} le ).+(?= |$)`);
      sq = sq.includes(queryTerms.DateField)
        ? sq.replace(equalsRegex, queryTerms.StartDate).replace(startRegex, queryTerms.StartDate).replace(endRegex, queryTerms.EndDate)
        : `${sq} and ${queryTerms.DateField} ge ${queryTerms.StartDate} and ${queryTerms.DateField} le ${queryTerms.EndDate}`;
      // searchQuery = `${searchQuery} and ${queryTerms.DateField} ge ${queryTerms.StartDate} and ${queryTerms.DateField} le ${queryTerms.EndDate}`;
    }
    this.setState({
      locationSearch: queryTerms,
      searchQuery: sq,
    });
    return sq;
  }

  getLocationData = (query, isMostRecent) => {
    const searchQuery = this.buildSearchQuery(query);
    const { dispatch } = this.props;
    dispatch(
      actions.getMyNominalLocationData(searchQuery, (data) => this.setLocationData(data, isMostRecent))
    );
  };

  getAlertsAndSettings = () => {
    this.getNominalAlerts();
    this.getNominalAlertSettings();
  };

  getNominalAlerts = () => {
    const { dispatch } = this.props;
    dispatch(
      actions.getNominalAlerts(this.props.Id, this.setNominalAlerts)
    );
  };

  getNominalAlertSettings = () => {
    const { dispatch } = this.props;
    dispatch(
      actions.getNominalAlertSettings(
        this.props.Id,
        this.setNominalAlertSettings
      )
    );
  };

  getLocationByLastSearch = (query) => {
    const searchQuery = this.buildSearchQuery(query);
    const { dispatch } = this.props;
    dispatch(
      actions.getMyNominalLocationData(searchQuery, this.setLocationData)
    );
  };

  setLocationData = (data, isMostRecent) => {
    let lh = data;
    if (isMostRecent) {
      lh = [];
      if (data && data.length > 0) lh.push(data[0]);
    }
    this.setState({
      locationHistory: lh,
    });
  };

  setNominalAlerts = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      alerts: data,
    }));
  };

  setNominalAlertSettings = (data) => {
    this.setState((prevState) => ({
      ...prevState,
      alertSettings: data,
    }));
  };

  changeTabs = (tab) => {
    this.setState({
      activeKey: tab,
    });
    if (tab === "alerts") {
      this.getNominalAlerts();
      this.getNominalAlertSettings();
    }
  };

  render() {
    const chevronLeftIcon = (
      <span>
        <FontAwesomeIcon
          icon={faChevronLeft}
          style={{ marginRight: "10px" }}
          id="nominaleBack"
          alt="Back to Nominals"
        />
      </span>
    );

    return (
      <>
        <div className="mosovo-content-container">
          {this.props.location &&
            this.props.location.state &&
            this.props.location.state.from && (
              <Row>
                <Col span={24}>
                  <div style={{ margin: "15px 15px 0" }}>
                    <a
                      className="go-back-anchor"
                      href="true"
                      onClick={this.GoBack}
                    >
                      {chevronLeftIcon}
                      {`Back to ${this.props.location.state.from}`}
                    </a>
                  </div>
                </Col>
              </Row>
            )}
          <Row>
            <Col span={24}>
              <Tabs
                activeKey={this.state.activeKey}
                size="large"
                onTabClick={(e) => this.changeTabs(e)}
              >
                <TabPane tab="Nominal Detail" key="nomimaldetail">
                  <Row>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          className="gutter-row"
                        >
                          <div className="nominal-detail-container">
                            <NominalBasicInfo
                              edit={this.editBasicInfo}
                              {...this.props.Nominal}
                            />
                          </div>
                        </Col>
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          className="gutter-row"
                        >
                          <div className="nominal-detail-container">
                            <DeviceInfo
                              dispatch={this.props.dispatch}
                              edit={this.editDevicenfo}
                              {...this.props.Nominal}
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                      <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
                        <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          className="gutter-row"
                        >
                          <div className="nominal-detail-container">
                            <NominalAdditionalInfo
                              edit={this.editAdditionalInfo}
                              {...this.props.Nominal}
                            />
                          </div>
                        </Col>
                        {/* <Col
                          xs={24}
                          sm={24}
                          md={24}
                          lg={24}
                          className="gutter-row"
                        >
                          <div className="nominal-detail-container">
                            <KnownAssociates
                              edit={this.editBasicInfo}
                              {...this.props.Nominal}
                            />
                          </div>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tab="Alerts" key="alerts">
                  <NominalAlerts
                    dispatch={this.props.dispatch}
                    alerts={this.state.alerts}
                    alertSettings={this.state.alertSettings}
                    refreshGrid={this.getAlertsAndSettings}
                    active={this.props.Nominal.Active}
                  />
                </TabPane>
                <TabPane tab="Locations" key="location">
                  <LocationHistory
                    refreshGrid={this.RefreshGrid}
                    Nominal={this.props.Nominal}
                    exclusionZones={this.props.Nominal.ExclusionZones}
                    onLocationSearch={this.getLocationData}
                    dispatch={this.dispatch}
                    locationHistory={this.state.locationHistory}
                  />
                </TabPane>
                <TabPane tab="Check-ins" key="checkin">
                  <Checkin 
                    Settings={this.props.Nominal.CheckinSetting}
                  />
                </TabPane>
                <TabPane tab="Communications" key="communications">
                  <Communications
                    dispatch={this.props.dispatch}
                    active={this.props.Nominal.Active}
                  />
                </TabPane>
              </Tabs>
            </Col>
          </Row>
          <NominalModal refreshGrid={this.RefreshGrid} />
          <NominalBasicInfoModal refreshGrid={this.RefreshGrid} />
          <AddNominalLocationModal
            {...this.props.Nominal}
            refreshGrid={this.RefreshGrid}
          />
          <ContactNominalModal
            goToTab={this.changeTabs}
            refreshGrid={this.RefreshGrid}
          />
          <ExclusionZoneModal
            dispatch={this.props.dispatch}
            refreshGrid={this.RefreshGrid}
            {...this.props.Nominal}
            // AlertSettings={{}}
          />
          <CheckinAlertModal
            Nominal={this.props.Nominal}
            dispatch={this.props.dispatch}
            refreshGrid={() => {
              this.getAlertsAndSettings();
              this.RefreshGrid();
            }}
            changeTabs={this.changeTabs}
          />
          <CurfewSettingsModal
            dispatch={this.props.dispatch}
            refreshGrid={this.getAlertsAndSettings}
            {...this.props.Nominal}
          />
          <LocationFailSettingsModal
            dispatch={this.props.dispatch}
            refreshGrid={this.getAlertsAndSettings}
            {...this.props.Nominal}
          />
          <IdleSettingsModal
            dispatch={this.props.dispatch}
            refreshGrid={this.getAlertsAndSettings}
            {...this.props.Nominal}
          />
          <ExportModal 
            NominalId={this.props.Nominal.Id}
            refreshGrid={this.RefreshGrid}
          />
        </div>
      </>
    );
  }
}

NominalDetailPage.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.shape(),
  Nominal: PropTypes.shape(),
  Id: PropTypes.string,
  location: PropTypes.shape(),
};

NominalDetailPage.defaultProps = {
  dispatch: () => {},
  match: {},
  Nominal: {},
  Id: 0,
  location: {},
};

function mapStateToProps(state) {
  const { Nominal } = state.Nominal;
  return { Nominal };
}

const connectNominalDetailPage = connect(mapStateToProps)(NominalDetailPage);

export default withRouter(connectNominalDetailPage);

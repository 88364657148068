import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Row, Col, Button, Divider, Spin } from "antd";

import { faEdit, faTrash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import allocationActions from '../../Allocation/actions';

class ForceHeader extends React.Component {
  editAllocation = (Id) => {
    const { dispatch } = this.props;
    dispatch(allocationActions.GetAllocationById(Id, this.openEditAllocationModal));
  };

  openEditAllocationModal = () => {
    const { dispatch } = this.props;
    dispatch(allocationActions.openEditAllocationModal());
  }

  OpenAddAllocationModal = () => {
    const { dispatch } = this.props;
    dispatch(allocationActions.openAddAllocationModal());
  };

  render() {
    const editButton = (id) => (
      <Button
        style={{ backgroundColor: "white", color: "#243b53", margin: "5px" }}
        onClick={() => this.props.edit(id)}
      >
        <FontAwesomeIcon
          icon={faEdit}
          id="EditForce"
          className=""
          alt="force"
        />
      </Button>
    );

    const trashButton = (record) => (
      <Button
        style={{ backgroundColor: "white", color: "#243b53", margin: "5px" }}
        onClick={() => this.props.onDelete(record.Id, record.Name)}
      >
        <FontAwesomeIcon
          icon={faTrash}
          id="DeleteForce"
          className=""
          alt="delete"
        />
      </Button>
    );

    return (
      <>
        <Spin spinning={this.props.loading}>
          <Row gutter={{ xs: 8, sm: 16, md: 10 }}>
            <Col span={12} className="gutter-row">
              <span>Force</span>
            </Col>
            <Col span={12}>
              <Row justify="end">
                <Col>
                  <span>
                    {editButton(this.props.Force.Id)}
                  </span>
                </Col>
                <Col>
                  <span>{trashButton(this.props.Force)}</span>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col flex="1 1 300px">
              <span>{this.props.Force.Name}</span>
            </Col>
            <Col flex="0 1 350px">
              <span>{`Remaining License Allocaiton: ${this.props.Allocation ? this.props.Allocation.AllocationRemaining : 0}`}</span>
              {this.props.Allocation !== null ? (
                <span style={{margin: "-5px 15px"}}>
                  <Button size="small" type="primary" onClick={() => this.editAllocation(this.props.Allocation.Id)}>manage</Button>
                </span>
              ) : (
                <span style={{margin: "-5px 15px"}}>
                  <Button size="small" type="primary" onClick={() => this.OpenAddAllocationModal()}>manage</Button>
                </span>
              )}
              
            </Col>
          </Row>
        </Spin>
      </>
    );
  }
}

ForceHeader.propTypes = {
  dispatch: PropTypes.func,
  edit: PropTypes.func,
  onDelete: PropTypes.func,
  loading: PropTypes.bool,
  Force: PropTypes.shape({
    Name: PropTypes.string,
    Id: PropTypes.number,
  }),
  Allocation: PropTypes.shape({
    Id: PropTypes.number,
    AllocationRemaining: PropTypes.number,
  }),
};

ForceHeader.defaultProps = {
  dispatch: () => {},
  edit: () => {},
  onDelete: () => {},
  loading: false,
  Force: {},
  Allocation: {
    Id: 0,
    AllocationRemaining: 0,
  },
};

function mapStateToProps(state) {
  const { loading } = state.Organisation;

  return { loading };
}

const connectedForceHeader = connect(mapStateToProps)(ForceHeader);

export { connectedForceHeader as default };

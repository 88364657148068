import React, { useEffect, useState } from "react";
import { Route } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch } from "react-redux";
import { Spin } from "antd";
import PrivateRoute from "../../common/utils/private-route";
import LoginPage from "../login/page/LoginPage";
import {
  Home,
  Organisation,
  Users,
  Account,
  Allocation,
  MyNominals,
  MapView,
  TeamNominals,
  Investigate,
  Reports,
} from "..";

import "react-toastify/dist/ReactToastify.css";
import "antd/dist/antd.css";
import MFAPage from "../login/page/MFAPage";
import loginActions from "../login/actions";
import PublicRoute from "../../common/utils/public-route";
import {
  hasRights,
  hasUserRight,
  rights,
  roles,
} from "../../common/utils/role-right-helper";

const Routes = () => {
  const { isLoading, error, user, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const [hasGottenUser, setHasGottenUser] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(
        loginActions.GetCurrentUser(() => {
          setHasGottenUser(true);
        })
      );
    }
  }, [isAuthenticated, setHasGottenUser]);

  if (isLoading) {
    return <div><Spin spinning="true" /></div>
  }

  // TO DO: ADD UI
  if (error?.message === "Unauthorized") {
    return <div>UNAUTHORIZED</div>;
  }
  if (error) {
    return <div>{error}</div>;
  }

  if (!hasGottenUser && isAuthenticated) {
    return <div><Spin spinning="true" /></div>
  }

  let hasOrganisations = false;
  let hasUsers = false;
  if (isAuthenticated) {
    const userRole = user["https://mosovo-primary//roles"];
    hasOrganisations = hasUserRight(roles.SystemAdmin, userRole);
    hasUsers =
      hasUserRight(roles.SystemAdmin, userRole) ||
      hasUserRight(roles.ForceAdmin, userRole) ||
      hasUserRight(roles.TeamAdmin, userRole) ||
      hasUserRight(roles.AreaAdmin, userRole) ||
      hasUserRight(roles.TeamAdmin, userRole);
  }

  const orgComponent = hasOrganisations ? (
    <PrivateRoute path="/organisations" component={Organisation} />
  ) : (
    <></>
  );
  const usersComponent = hasUsers ? (
    <PrivateRoute authenticated={hasUsers} path="/users" component={Users} />
  ) : (
    <></>
  );

  return (
    <>
      <Route path="/login" component={LoginPage} />
      <Route path="/mfa/:UserName" component={MFAPage} />
      <PublicRoute authenticated="true" path="/account" component={Account} />
      {orgComponent}
      {usersComponent}
      <PrivateRoute
        authenticated={hasRights([rights.CanManageHub], [rights.CanViewHub])}
        path="/allocations"
        component={Allocation}
      />
      <PrivateRoute
        authenticated={hasRights([rights.CanViewAsOM])}
        path="/mynominals"
        component={MyNominals}
      />
      <PrivateRoute
        authenticated={hasRights([rights.CanViewAsOM])}
        path="/map"
        component={MapView}
      />
      <PrivateRoute
        authenticated={hasRights([rights.CanViewAsOM])}
        path="/teamnominals"
        component={TeamNominals}
      />
      <PrivateRoute
        authenticated={hasRights([rights.CanViewAsForceAdmin])}
        path="/forcenominals"
        component={TeamNominals}
      />
      <PrivateRoute
        authenticated={hasRights([rights.CanViewAsAreaAdmin])}
        path="/areanominals"
        component={TeamNominals}
      />
      <PrivateRoute
        authenticated={hasRights([rights.CanViewAsOM])}
        path="/nominals"
        component={MyNominals}
      />
      <PrivateRoute
        authenticated={hasRights([rights.CanViewAsOM])}
        path="/investigate"
        component={Investigate}
      />
      <PrivateRoute
        authenticated="true"
        path="/"
        exact
        component={
          hasRights([rights.CanManageHub], [rights.CanViewHub]) ||
          hasRights([rights.CanViewAsForceAdmin])
            ? Home
            : MyNominals
        }
      />
      <PrivateRoute
        authenticated={hasRights([rights.CanManageHub], [rights.CanViewHub])}
        path="/reports"
        component={Reports}
      />
      <PrivateRoute path="/account/reset-password" component={Account} />
    </>
  );
};

export default Routes;

import apiRequest from "../common/utils/apiRequest";

function getForceGridOdata(querystring = "") {
  return apiRequest()
    .get(`/odata/ForceOData?${querystring}&$orderby=Name`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function getAreaGridOdata(querystring = "") {
  return apiRequest()
    .get(`/odata/AreaOData?${querystring}&$orderby=Name`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function getTeamGridOdata(querystring) {
  return apiRequest()
    .get(`/odata/TeamOData?${querystring}&$orderby=Name`)
    .then((data) => data)
    .catch((error) => Promise.reject(error));
}

function createForceBulk(data) {
  return apiRequest()
    .post("/api/force/Create/Bulk", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getForceById(id) {
  return apiRequest()
    .get(`/api/force/${id}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateForce(data) {
  return apiRequest()
    .put("/api/force/Update", data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
}

function createArea(data) {
  return apiRequest()
    .post("/api/area/Create", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getAreaById(id) {
  return apiRequest()
    .get(`/api/area/${id}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateArea(data) {
  return apiRequest()
    .put("/api/area/Update", data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
}

function createTeam(data) {
  return apiRequest()
    .post("/api/team/Create", data)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function getTeamById(id) {
  return apiRequest()
    .get(`/api/team/${id}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function updateTeam(data) {
  return apiRequest()
    .put("/api/team/Update", data)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(error));
}

function getOrganisationById(id) {
  return apiRequest()
    .get(`/api/force/organisation/${id}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteForce(id) {
  return apiRequest()
    .delete(`/api/force/${id}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteArea(id) {
  return apiRequest()
    .delete(`/api/area/${id}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

function deleteTeam(id) {
  return apiRequest()
    .delete(`/api/team/${id}`)
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export default {
  getForceGridOdata,
  getAreaGridOdata,
  getTeamGridOdata,
  createForceBulk,
  getForceById,
  updateForce,
  createArea,
  getAreaById,
  updateArea,
  createTeam,
  getTeamById,
  updateTeam,
  getOrganisationById,
  deleteForce,
  deleteArea,
  deleteTeam,
};

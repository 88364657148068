import { toast } from "react-toastify";
import { push } from 'connected-react-router';
import constants from "./constants";
import { OrganisationService } from "../../services";

function getForceODataList(queryString, callback) {
  function request() {
    return { type: constants.FORCE_GRID_REQUEST };
  }
  function success() {
    return { type: constants.FORCE_GRID_SUCCESS };
  }
  function failure(error) {
    return { type: constants.FORCE_GRID_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.getForceGridOdata(queryString).then(
      (response) => {
        // dispatch(success(response.data.value));
        dispatch(success());
        callback(response.data.value);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function getAreaODataList(queryString, callback) {
  function request() {
    return { type: constants.AREA_GRID_REQUEST };
  }
  function success() {
    return { type: constants.AREA_GRID_SUCCESS };
  }
  function failure(error) {
    return { type: constants.AREA_GRID_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.getAreaGridOdata(queryString).then(
      (response) => {
        dispatch(success());
        callback(response.data.value);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function getTeamODataList(queryString, callback) {
  function request() {
    return { type: constants.TEAM_GRID_REQUEST };
  }
  function success() {
    return { type: constants.TEAM_GRID_SUCCESS };
  }
  function failure(error) {
    return { type: constants.TEAM_GRID_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.getTeamGridOdata(queryString).then(
      (response) => {
        dispatch(success());
        callback(response.data.value);
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function openBulkCreateModal() {
  function open() {
    return { type: constants.OPEN_BULK_CREATE_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function closeBulkCreateModal() {
  function close() {
    return { type: constants.CLOSE_BULK_CREATE_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function createForceBulk(data, callback) {
  function request() {
    return { type: constants.BULK_REQUEST };
  }
  function success() {
    return { type: constants.BULK_SUCCESS };
  }
  function failure(error) {
    return { type: constants.BULK_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.createForceBulk(data).then(
      () => {
        dispatch(success());
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function openForceModal() {
  function open() {
    return { type: constants.OPEN_FORCE_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function closeForceModal() {
  function close() {
    return { type: constants.CLOSE_FORCE_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function getForceDetail(id, callback) {
  function request() {
    return { type: constants.GET_FORCE_REQUEST };
  }
  function success(force) {
    return { type: constants.GET_FORCE_SUCCESS, force };
  }
  function failure(error) {
    return { type: constants.GET_FORCE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.getForceById(id).then(
      (response) => {
        dispatch(success(response.data));
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function updateForce(data, callback) {
  function request() {
    return { type: constants.UPDATE_FORCE_REQUEST };
  }
  function success(force) {
    return { type: constants.UPDATE_FORCE_SUCCESS, force };
  }
  function failure(error) {
    return { type: constants.UPDATE_FORCE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.updateForce(data).then(
      (response) => {
        dispatch(success(response.data));
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function openAreaModal() {
  function open() {
    return { type: constants.OPEN_AREA_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function closeAreaModal() {
  function close() {
    return { type: constants.CLOSE_AREA_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function getAreaDetail(id, callback) {
  function request() {
    return { type: constants.GET_AREA_REQUEST };
  }
  function success(area) {
    return { type: constants.GET_AREA_SUCCESS, area };
  }
  function failure(error) {
    return { type: constants.GET_AREA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.getAreaById(id).then(
      (response) => {
        dispatch(success(response.data));
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function updateArea(data, callback) {
  function request() {
    return { type: constants.UPDATE_AREA_REQUEST };
  }
  function success(area) {
    return { type: constants.UPDATE_AREA_SUCCESS, area };
  }
  function failure(error) {
    return { type: constants.UPDATE_AREA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.updateArea(data).then(
      (response) => {
        dispatch(success(response.data));
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function createArea(data, callback) {
  function request() {
    return { type: constants.CREATE_AREA_REQUEST };
  }
  function success() {
    return { type: constants.CREATE_AREA_SUCCESS };
  }
  function failure(error) {
    return { type: constants.CREATE_AREA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.createArea(data).then(
      () => {
        dispatch(success());
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function openAddAreaModal(defaultForceId = null) {
  function open(forceId) {
    return { type: constants.OPEN_ADD_AREA_MODAL, forceId };
  }
  return (dispatch) => {
    dispatch(open(defaultForceId));
  };
}

function openTeamModal() {
  function open() {
    return { type: constants.OPEN_TEAM_MODAL };
  }
  return (dispatch) => {
    dispatch(open());
  };
}

function closeTeamModal() {
  function close() {
    return { type: constants.CLOSE_TEAM_MODAL };
  }
  return (dispatch) => {
    dispatch(close());
  };
}

function openAddTeamModal(defaultForceId = null) {
  function open(forceId) {
    return { type: constants.OPEN_ADD_TEAM_MODAL, forceId };
  }
  return (dispatch) => {
    dispatch(open(defaultForceId));
  };
}

function getTeamDetail(id, callback) {
  function request() {
    return { type: constants.GET_TEAM_REQUEST };
  }
  function success(team) {
    return { type: constants.GET_TEAM_SUCCESS, team };
  }
  function failure(error) {
    return { type: constants.GET_TEAM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.getTeamById(id).then(
      (response) => {
        dispatch(success(response.data));
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function updateTeam(data, callback) {
  function request() {
    return { type: constants.UPDATE_TEAM_REQUEST };
  }
  function success(team) {
    return { type: constants.UPDATE_TEAM_SUCCESS, team };
  }
  function failure(error) {
    return { type: constants.UPDATE_TEAM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.updateTeam(data).then(
      (response) => {
        dispatch(success(response.data));
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function createTeam(data, callback) {
  function request() {
    return { type: constants.CREATE_TEAM_REQUEST };
  }
  function success() {
    return { type: constants.CREATE_TEAM_SUCCESS };
  }
  function failure(error) {
    return { type: constants.CREATE_TEAM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.createTeam(data).then(
      () => {
        dispatch(success());
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function getOrganisationDetail(id) {
  function request() {
    return { type: constants.GET_ORGANISATION_REQUEST };
  }
  function success(organisation) {
    return { type: constants.GET_ORGANISATION_SUCCESS, organisation };
  }
  function failure(error) {
    return { type: constants.GET_ORGANISATION_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.getOrganisationById(id).then(
      (response) => {
        dispatch(success(response.data));
      },
      (error) => {
        dispatch(failure(error.toString()));
      }
    );
  };
}

function deleteForce(id) {
  function request() {
    return { type: constants.DELETE_FORCE_REQUEST };
  }
  function success() {
    return { type: constants.DELETE_FORCE_SUCCESS };
  }
  function failure(error) {
    return { type: constants.DELETE_FORCE_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.deleteForce(id).then(
      () => {
        dispatch(success());
        toast("Successfully deleted force");
        dispatch(push(`/organisations`));
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An error occurred, please try again");
      }
    );
  };
}

function deleteArea(id, callback) {
  function request() {
    return { type: constants.DELETE_AREA_REQUEST };
  }
  function success() {
    return { type: constants.DELETE_AREA_SUCCESS };
  }
  function failure(error) {
    return { type: constants.DELETE_AREA_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.deleteArea(id).then(
      () => {
        dispatch(success());
        toast("Successfully deleted area");
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An error occurred, please try again");
      }
    );
  };
}

function deleteTeam(id, callback) {
  function request() {
    return { type: constants.DELETE_TEAM_REQUEST };
  }
  function success() {
    return { type: constants.DELETE_TEAM_SUCCESS };
  }
  function failure(error) {
    return { type: constants.DELETE_TEAM_FAILURE, error };
  }

  return (dispatch) => {
    dispatch(request());
    OrganisationService.deleteTeam(id).then(
      () => {
        dispatch(success());
        toast("Successfully deleted team");
        callback();
      },
      (error) => {
        dispatch(failure(error.toString()));
        toast.error("An error occurred, please try again");
      }
    );
  };
}

export default {
  getForceODataList,
  getAreaODataList,
  getTeamODataList,
  openBulkCreateModal,
  closeBulkCreateModal,
  createForceBulk,
  openForceModal,
  closeForceModal,
  getForceDetail,
  updateForce,
  openAreaModal,
  closeAreaModal,
  getAreaDetail,
  updateArea,
  createArea,
  openAddAreaModal,
  openTeamModal,
  closeTeamModal,
  openAddTeamModal,
  getTeamDetail,
  updateTeam,
  createTeam,
  getOrganisationDetail,
  deleteForce,
  deleteArea,
  deleteTeam,
};

import { useContext } from "react";
import { ProfileManagementContext } from "../contexts/ProfileManagementContext";

const useProfileManagementContext = () => {
  const context = useContext(ProfileManagementContext);
  if (!context) {
    throw new Error(
      "useProfileManagementContext must be used within a ProfileManagementContextProvider"
    );
  }
  return context;
};

export default useProfileManagementContext;

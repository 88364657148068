import Constants from "./constants";

const initialState = {
  loading: false,
  hasError: false,
  Nominal: {},
  AlertSettings: {},
  showAddModal: false,
  showEditModal: false,
  showContactModal: false,
  showDeviceInfoModal: false,
  showAdditionalInfoModal: false,
  showAddNominalLocationModal: false,
  showExclusionZoneModal: false,
  showCurfewAlertModal: false,
  showLocationFailModal: false,
  showIdleSettingsModal: false,
  showCheckinAlertModal: false,
  CheckinHistory: [],
};

export default function NominalReducer(state = initialState, action) {
  switch (action.type) {
    case Constants.MY_NOMINAL_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        selectedIds: [],
      };
    case Constants.MY_NOMINAL_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case Constants.MY_NOMINAL_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    case Constants.GET_NOMINAL_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case Constants.GET_NOMINAL_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        Nominal: action.nominal,
      };
    case Constants.GET_NOMINAL_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    case Constants.ALERT_SETTINGS_REQUEST:
      return {
        ...state,
        loading: false,
        hasError: false,
        AlertSettings: {},
      };
    case Constants.ALERT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        AlertSettings: action.alertSettings,
      };
    case Constants.ALERT_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case Constants.NOMINAL_ALERT_SETTINGS_REQUEST:
      return {
        ...state,
        loading: false,
        hasError: false,
        AlertSettings: {},
      };
    case Constants.NOMINAL_ALERT_SETTINGS_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        nominalAlertSettings: action.alertSettings,
      };
    case Constants.NOMINAL_ALERT_SETTINGS_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case Constants.GET_NOMINAL_BASICINFO_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
        Nominal: {},
      };
    case Constants.GET_NOMINAL_BASICINFO_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        Nominal: action.nominal,
      };
    case Constants.GET_NOMINAL_BASICINFO_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
      };
    case Constants.OPEN_ADD_NOMINAL_MODAL:
      return {
        ...state,
        showAddModal: true,
        showEditModal: false,
        showContactModal: false,
        showExclusionZoneModal: false,
        showCurfewAlertModal: false,
        showLocationFailModal: false,
        showIdleSettingsModal: false,
        showCheckinAlertModal: false,
      };
    case Constants.OPEN_NOMINAL_BASIC_INFO_MODAL:
      return {
        ...state,
        showEditModal: true,
        showDeviceInfoModal: false,
        showAdditionalInfoModal: false,
        showExclusionZoneModal: false,
        showCurfewAlertModal: false,
        showLocationFailModal: false,
        showIdleSettingsModal: false,
        showCheckinAlertModal: false,
      };
    case Constants.OPEN_NOMINAL_DEVICE_INFO_MODAL:
      return {
        ...state,
        showEditModal: false,
        showDeviceInfoModal: true,
        showAdditionalInfoModal: false,
        showExclusionZoneModal: false,
        showCurfewAlertModal: false,
        showLocationFailModal: false,
        showIdleSettingsModal: false,
        showCheckinAlertModal: false,

      };
    case Constants.OPEN_NOMINAL_ADDITIONAL_INFO_MODAL:
      return {
        ...state,
        showEditModal: false,
        showDeviceInfoModal: false,
        showAdditionalInfoModal: true,
        showExclusionZoneModal: false,
        showCurfewAlertModal: false,
        showLocationFailModal: false,
        showIdleSettingsModal: false,
        showCheckinAlertModal: false,
      };
    case Constants.OPEN_CONTACT_NOMINAL_MODAL:
      return {
        ...state,
        showEditModal: false,
        showAddModal: false,
        showContactModal: true,
        showExclusionZoneModal: false,
        showCurfewAlertModal: false,
        showLocationFailModal: false,
        showIdleSettingsModal: false,
        showCheckinAlertModal: false,
      };
    case Constants.OPEN_ADD_NOMINAL_LOCATION_MODAL:
      return {
        ...state,
        showAddNominalLocationModal: true,
      };
    case Constants.OPEN_EXCLUSION_ZONE_MODAL:
      return {
        ...state,
        showEditModal: false,
        showAddModal: false,
        showContactModal: false,
        showExclusionZoneModal: true,
        showCurfewAlertModal: false,
        showLocationFailModal: false,
        showIdleSettingsModal: false,
        showCheckinAlertModal: false,
      };
    case Constants.OPEN_CHECKIN_ALERT_MODAL:
      return {
        ...state,
        showEditModal: false,
        showAddModal: false,
        showContactModal: false,
        showExclusionZoneModal: false,
        showCurfewAlertModal: false,
        showLocationFailModal: false,
        showCheckinAlertModal: true,
      };
    case Constants.OPEN_CURFEW_ALERT_MODAL:
      return {
        ...state,
        showEditModal: false,
        showAddModal: false,
        showContactModal: false,
        showExclusionZoneModal: false,
        showCurfewAlertModal: true,
        showLocationFailModal: false,
        showIdleSettingsModal: false,
      };
    case Constants.OPEN_LOCATION_FAIL_MODAL:
      return {
        ...state,
        showEditModal: false,
        showAddModal: false,
        showContactModal: false,
        showExclusionZoneModal: false,
        showCurfewAlertModal: false,
        showLocationFailModal: true,
        showIdleSettingsModal: false,
        showCheckinAlertModal: false,
      };
    case Constants.OPEN_IDLE_SETTINGS_MODAL:
      return {
        ...state,
        showEditModal: false,
        showAddModal: false,
        showContactModal: false,
        showExclusionZoneModal: false,
        showCurfewAlertModal: false,
        showLocationFailModal: false,
        showIdleSettingsModal: true,
      };
    case Constants.CLOSE_NOMINAL_MODAL:
      return {
        ...state,
        showAddModal: false,
        showEditModal: false,
        showContactModal: false,
        showAddNominalLocationModal: false,
        showExclusionZoneModal: false,
        showCurfewAlertModal: false,
        showLocationFailModal: false,
        showIdleSettingsModal: false,
        showCheckinAlertModal: false,
      };
    case Constants.CLOSE_NOMINAL_BASIC_INFO_MODAL:
      return {
        ...state,
        showEditModal: false,
        showDeviceInfoModal: false,
        showAdditionalInfoModal: false,
        Nominal: {},
      };
    case Constants.LINK_DEVICE_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case Constants.LINK_DEVICE_SUCCESS:
      return {
        ...state,
        hasError: false,
        loading: false,
      };
    case Constants.LINK_DEVICE_FAILURE:
      return {
        ...state,
        hasError: true,
        loading: false,
      };
    case Constants.MY_NOMINAL_CREATE_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case Constants.MY_NOMINAL_CREATE_SUCCESS:
      return {
        ...state,
        hasError: false,
        loading: false,
      };
    case Constants.MY_NOMINAL_CREATE_FAILURE:
      return {
        ...state,
        hasError: true,
        loading: false,
      };
    case Constants.CONTACT_NOMINAL_REQUEST:
      return {
        ...state,
        hasError: false,
        loading: true,
      };
    case Constants.CONTACT_NOMINAL_SUCCESS:
      return {
        ...state,
        hasError: false,
        loading: false,
      };
    case Constants.CONTACT_NOMINAL_FAILURE:
      return {
        ...state,
        hasError: true,
        loading: false,
      };
    case Constants.NOMINAL_BASICINFO_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case Constants.NOMINAL_BASICINFO_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        Nominal: action.nominal,
      };
    case Constants.NOMINAL_BASICINFO_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case Constants.NOMINAL_DEVICEINFO_UPDATE_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case Constants.NOMINAL_DEVICEINFO_UPDATE_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        Nominal: action.nominal,
      };
    case Constants.NOMINAL_DEVICEINFO_UPDATE_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case Constants.CREATE_CHECKIN_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case Constants.CREATE_CHECKIN_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case Constants.CREATE_CHECKIN_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case Constants.GET_CHECKIN_REQUEST:
      return {
        ...state,
        // loading: true,
        hasError: false,
      };
    case Constants.GET_CHECKIN_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
        CheckinHistory: action.checkin.value,
      };
    case Constants.GET_CHECKIN_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case Constants.TRANSFER_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case Constants.TRANSFER_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case Constants.TRANSFER_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    case Constants.SUSPEND_REQUEST:
      return {
        ...state,
        loading: true,
        hasError: false,
      };
    case Constants.SUSPEND_SUCCESS:
      return {
        ...state,
        loading: false,
        hasError: false,
      };
    case Constants.SUSPEND_FAILURE:
      return {
        ...state,
        loading: false,
        hasError: true,
        error: action.error,
      };
    default:
      return state;
  }
}

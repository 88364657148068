import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import Auth from './features/login/reducer';
import Account from './features/account/reducer';
import Navigation from './common/components/navigation/reducer';
import Nominal from './features/Nominals/reducer';
import NominalTypes from './common/components/nominal-type-select/reducer';
import Organisation from './features/Organisation/reducer';
import TeamNominal from './features/TeamNominals/reducer';
import Users from './features/Users/reducer';
import Allocation from './features/Allocation/reducer';
import MosovoModal from './common/components/mosovo-modals/reducer';
import Investigate from './features/Investigate/reducer';

export default (history) => combineReducers({
  router: connectRouter(history),
  Account,
  Allocation,
  Auth,
  MosovoModal,
  Navigation,
  Nominal,
  NominalTypes,
  Organisation,
  TeamNominal,
  Users,
  Investigate,
});

import React from "react";
import PropTypes from "prop-types";
import { Route, Switch, withRouter } from "react-router-dom";
import { Layout, PageHeader } from "../../common/components";
import { HomePage } from "./page";

const HomeHeader = (
  <PageHeader title="Dashboard" />
)

const Home = () => (
  <Layout PageHeader={HomeHeader}>
    <Switch>
      <Route exact path="/" component={HomePage} />
    </Switch>
  </Layout>
);

Home.propTypes = {
  match: PropTypes.shape({}),
};

Home.defaultProps = {
  match: {},
};

export default withRouter(Home);

import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";

const { Search } = Input;

class SearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state ={
            placeholder: "search",
            minText: 0,
            allowClear: false,
        }
      }

  handleOnChange = (val) => {
    const minTextLength = this.props.minText || this.state.minText;
    if (val.length > minTextLength) this.props.onSearch(val);
    else if (val === "") this.props.onSearch();
  };

  render() {
    return (
      <>
        <Search
          placeholder={this.props.placeholder || this.state.placeholder}
          onChange={(val) => this.handleOnChange(val.target.value)}
          allowClear={this.props.allowClear || this.state.allowClear}
          onSearch={this.props.onSearch}
          loading={this.props.loading}
        />
      </>
    );
  }
}

SearchBox.propTypes = {
  placeholder: PropTypes.string,
  minText: PropTypes.number,
  onSearch: PropTypes.func,
  loading: PropTypes.bool,
  allowClear: PropTypes.bool,
};

SearchBox.defaultProps = {
  placeholder: "",
  minText: 0,
  onSearch: () => {},
  loading: false,
  allowClear: false,
};

export default SearchBox;

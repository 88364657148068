import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col, Spin } from "antd";

import { IdleSettingsForm } from "../forms";
import actions from "../actions";

class IdleSettingsModal extends React.Component {
  CloseModal = () => {
    this.props.dispatch(actions.closeNominalModal());
    this.props.refreshGrid();
  };

  CreateIdleSettings = (data) => {
    this.props.dispatch(
      actions.createIdleSettings(data, this.CloseModal)
    );
  };

  UpdateIdleSettings = (data) => {
    const updateData = {
      Id: this.props.AlertSettings.NominalIdleSettings.Id,
      IdleTimeLimit: data.IdleTimeLimit,
      UpdateNominalAlertSettings: 
        {
          Id: this.props.AlertSettings.Id,
          IsNotifyEmail: data.CreateNominalAlertSettings.IsNotifyEmail,
          IsNotifyText: data.CreateNominalAlertSettings.IsNotifyText,
          IsNotifyTeamEmail: data.CreateNominalAlertSettings.IsNotifyTeamEmail,
          IsUrgent: data.CreateNominalAlertSettings.IsUrgent,
          IsAutomaticPushNotification: true,
          IsActive: true,
          NominalId: this.props.Nominal.Id,
        },
    };
    this.props.dispatch(
      actions.updateIdleSettings(updateData, this.CloseModal)
    );
  };

  render() {
    return (
      <>
        <Modal
          title="Idle Device Alert"
          visible={this.props.showIdleSettingsModal}
          footer={null}
          width={600}
          onCancel={this.CloseModal}
        >
          <div>
            <Row>
              <Col span={24}>
                <Spin spinning={this.props.loading}>
                  <IdleSettingsForm
                    refreshGrid={this.props.refreshGrid}
                    onCancel={this.CloseModal}
                    onSubmit={this.props.AlertSettings && this.props.AlertSettings.NominalIdleSettings ? this.UpdateIdleSettings : this.CreateIdleSettings}
                    Nominal={this.props.Nominal}
                    {...this.props.AlertSettings}
                  />
                </Spin>
              </Col>
            </Row>
          </div>
        </Modal>
      </>
    );
  }
}

IdleSettingsModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  showIdleSettingsModal: PropTypes.bool,
  loading: PropTypes.bool,
  Nominal: PropTypes.shape(),
  AlertSettings: PropTypes.shape(),
};

IdleSettingsModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  showIdleSettingsModal: true,
  loading: false,
  Nominal: {},
  AlertSettings: {},
};

function mapStateToProps(state) {
  const {
    loading,
    showIdleSettingsModal,
    Nominal,
    AlertSettings,
  } = state.Nominal;

  return { loading, showIdleSettingsModal, Nominal, AlertSettings };
}

const connectedIdleSettingsModal = connect(mapStateToProps)(
    IdleSettingsModal
);

export { connectedIdleSettingsModal as default };

import Geocode from "react-geocode";
import L from 'leaflet';
import "leaflet-control-geocoder/dist/Control.Geocoder.css";
import "leaflet-control-geocoder/dist/Control.Geocoder";
import apiRequest from "../common/utils/apiRequest";

function getFieldFromAddressComponents(component) {
    let field = component.long_name;
    if (field) field = field.toLowerCase();
    return field;
}

function getAddressFromPosition(lat, lng, callback) {
  const geocoder = L.Control.Geocoder.nominatim();
  const scale = 1; // TODO
  geocoder.reverse({lat, lng}, scale,
    result => {callback(result)});
    // Geocode.setApiKey(process.env.GOOGLE_GEOCODE_TOKEN);
    // Geocode.setLanguage("en");
    
   // return Geocode.fromLatLng(lat, lng);
}

function getPositionFromAddress(address) {
  Geocode.setApiKey(process.env.GOOGLE_GEOCODE_TOKEN);
  Geocode.setLanguage("en");
    
  return Geocode.fromAddress(address);
}

async function validateAddress(data) 
{
    let position = {
        lat: null,
        lng: null
    };

    let fullAddress = data.Address1;
    if (data.Address2) {
        fullAddress = `${fullAddress} ${data.Address2}`;
      }
      fullAddress = `${fullAddress}, ${data.City} ${data.PostalCode}`;
  
      Geocode.setApiKey(process.env.GOOGLE_GEOCODE_TOKEN);
      Geocode.setLanguage("en");
      
    await Geocode.fromAddress(fullAddress)
        .then(response => {
            if (response && response.results && response.results.length > 0) {
            const components = response.results[0].address_components;
            let streetNumber = "";
            let route = "";
            let city = "";
            let state = "";
            // let country = "";
            let postalCode = "";
        for (let i = 0; i < components.length; i += 1) {
                for (let j = 0; j < response.results[0].address_components[i].types.length; j += 1) {
                    switch (response.results[0].address_components[i].types[j]) {
                      case "street_number":
                        streetNumber = getFieldFromAddressComponents(response.results[0].address_components[i]);
                        break;
                      case "route":
                        route = getFieldFromAddressComponents(response.results[0].address_components[i]);
                        break;
                      case "locality":
                      case "postal_town":
                        city = getFieldFromAddressComponents(response.results[0].address_components[i]);
                        break;
                      case "administrative_area_level_1":
                        state = getFieldFromAddressComponents(response.results[0].address_components[i]);
                        break;
                    //   case "country":
                    //     country = response.results[0].address_components[i].long_name;
                    //     break;
                      case "postal_code":
                        postalCode = getFieldFromAddressComponents(response.results[0].address_components[i]);
                        postalCode = postalCode.replace(/\s/g, "");
                        break;
                      default:
                        break;
                    }
                }
            }
            if ((data.Address1.toLowerCase() === `${streetNumber} ${route}`) &&
            ((data.City.toLowerCase() === `${city}`) || (data.City.toLowerCase() === state)) && 
            (data.PostalCode.toLowerCase().replace(/\s/g, "") === `${postalCode}`)) {
                position = response.results[0].geometry.location;
            }
        }
        })
        // eslint-disable-next-line no-console
        .catch((error) => window.console && console.log(error));
    return position;
}

function getLocationAddress(data) {
  return apiRequest()
    .post(`/api/ConsumerLocation/LocationAddress`, data, { withCredentials: true, 'Access-Control-Allow-Origin': '*' })
    .then((response) => response)
    .catch((error) => Promise.reject(error));
}

export default { 
    validateAddress,
    getLocationAddress,
    getAddressFromPosition,
    getPositionFromAddress
}
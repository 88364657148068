import React from "react";
import PropTypes from "prop-types";
// import { connect } from "react-redux";
import { Formik } from "formik";
import * as Yup from "yup";

import { Row, Col, Form, Divider, Radio } from "antd";

import { CancelBtn, SaveBtn } from "../../../common/components/form";
import { DynamicSelect } from "../../../common/components/dynamic-select";
import { OrganisationService, UserService } from "../../../services";

class BulkTransferNominalForm extends React.Component {
  constructor(props) {
    super(props);
    this.formIK = React.createRef();
  }

  componentDidUpdate() {
    this.formIK.current.handleReset();
  }

  pivotData = (data) => ({
    ForceId: data.ForceId,
    AreaId: data.AreaId === 0 ? null : data.AreaId,
    TeamId: data.TeamId,
    NominalId: data.NominalId,
    OMId: data.OffenderManagerId,
  });

  render() {
    return (
      <Formik
        innerRef={this.formIK}
        enableReinitialize
        validateOnMount
        initialValues={{
          ForceId: null,
          AreaId: null,
          TeamId: null,
          NominalId: this.props.selectedNominals,
          OffenderManagerId: this.props.OffenderManagerId,         
        }}
        validationSchema={Yup.object().shape({
          ForceId: Yup.number().required("Force is required").typeError("Force is required"),
          AreaId: Yup.number(),
          TeamId: Yup.number().required("Team is required").typeError("Team is required"),
          NominalId:Yup.array().of(Yup.number()),
          OffenderManagerId: Yup.number().required("Offender Manager is required").typeError("Offender Manager is required"),
        })}
        onSubmit={(values) => this.props.onSubmit(this.pivotData(values))}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          setFieldValue,
        }) =>  {
          const onRadioChange = (e) => {
            setFieldValue('AreaId', 0);
            setFieldValue('HaveAreas', e.target.value);
          }
        return (
          <Form id="BulkNominalTransferForm" onSubmit={handleSubmit} onChange={handleChange}>
            <div>
              <Row>
                <Col span={24}>
                  <Row gutter={{ xs: 8, sm: 16, md: 10}}>
                    <Col xs={24} sm={24} md={24} className="gutter-row">
                      Assigning offender manager will override any current assignments on the selected Nominal(s).
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={24} sm={4} md={6}>
                      <span className="required-field">
                        Force:
                      </span>
                    </Col>
                    <Col xs={24} sm={20} md={16}>
                      <Form.Item
                        validateStatus={
                        errors &&
                        errors.ForceId &&
                        touched.ForceId 
                            ? "error"
                            : "success"
                        }
                        help={
                        errors &&
                        errors.ForceId &&
                        touched.ForceId 
                            ? errors.ForceId
                            : null
                        }
                      >
                        <DynamicSelect
                          hasNone={false}
                          className=""
                          field="ForcePicker"
                          getData={OrganisationService.getForceGridOdata}
                          displayFieldName="Name"
                          valueFieldName="Id"
                          value={values.ForceId}
                          onBlur={handleBlur}
                          onChange={(val) => {
                            setFieldValue("ForceId", val);
                            setFieldValue("AreaId", null);
                            setFieldValue("TeamId", null);
                            setFieldValue("OffenderManagerId", null);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <span>Will this nominal be associated with an Area?</span>
                    </Col>
                    <Col span={24}>
                      <Radio.Group onChange={onRadioChange} value={values.HaveAreas}>
                        {/* eslint-disable-next-line */}
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                      </Radio.Group>
                    </Col>
                  </Row>
                  {values.HaveAreas && (
                    <Row>
                      <Col xs={24} sm={4} md={6}>
                        <span>
                          Area:
                        </span>
                      </Col>
                      <Col xs={24} sm={20} md={16}>
                        <Form.Item
                          validateStatus={
                          errors &&
                          errors.AreaId &&
                          touched.AreaId
                              ? "error"
                              : "success"
                          }
                          help={
                          errors &&
                          errors.AreaId &&
                          touched.AreaId
                              ? errors.AreaId
                              : null
                          }
                        >
                          <DynamicSelect
                            hasNone
                            className=""
                            field="AreaPicker"
                            getData={OrganisationService.getAreaGridOdata}
                            filters={`$filter=ForceId eq ${values.ForceId}`}
                            displayFieldName="Name"
                            valueFieldName="Id"
                            value={values.AreaId}
                            onBlur={handleBlur}
                            onChange={(val) => {
                              setFieldValue("AreaId", val);
                              setFieldValue("TeamId", null);
                              setFieldValue("OffenderManagerId", null);
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  )}
                  <Row>
                    <Col xs={24} sm={4} md={6}>
                      <span className="required-field">
                        Team:
                      </span>
                    </Col>
                    <Col xs={24} sm={20} md={16}>
                      <Form.Item
                        validateStatus={
                        errors &&
                        errors.TeamId &&
                        touched.TeamId
                            ? "error"
                            : "success"
                        }
                        help={
                        errors &&
                        errors.ForceId &&
                        touched.TeamId
                            ? errors.TeamId
                            : null
                        }
                      >
                        <DynamicSelect
                          hasNone={false}
                          className=""
                          field="TeamPicker"
                          getData={OrganisationService.getTeamGridOdata}
                          filters={`$filter=ForceId eq ${
                            values.ForceId
                          } and AreaId eq ${
                            values.AreaId === 0 ? null : values.AreaId
                          }&`}
                          displayFieldName="Name"
                          valueFieldName="Id"
                          value={values.TeamId}
                          onBlur={handleBlur}
                          onChange={(val) => {
                            setFieldValue("TeamId", val);
                            setFieldValue("OffenderManagerId", null);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider plain />
                  <Row>
                    <Col sm={24} md={8}>
                      <span className="required-field">Offender Manager:</span>
                    </Col>
                    <Col sm={24} md={14}>
                      <Form.Item
                        validateStatus={
                          errors && errors.OffenderManagerId && touched.OffenderManagerId
                            ? "error"
                            : "success"
                        }
                        help={
                          errors && errors.OffenderManagerId && touched.OffenderManagerId
                            ? errors.OffenderManagerId
                            : null
                        }
                      >
                        <DynamicSelect
                          hasNone={false}
                          className=""
                          field="OMPicker"
                          getData={UserService.getOdataOMList}
                          filters={`$filter=TeamId ne null and ForceId eq ${
                            values.ForceId
                          } and AreaId eq ${
                            values.AreaId === 0 ? null : values.AreaId
                          } and TeamId eq ${values.TeamId}&`}
                          displayFieldName="Name"
                          valueFieldName="Id"
                          value={values.OffenderManagerId}
                          onBlur={handleBlur}
                          onChange={(val) => {
                            setFieldValue("OffenderManagerId", val);
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>                   
                  <Divider plain />
                  <Row gutter={{ xs: 8, sm: 16, md: 10 }} justify="end">
                    <Col xs={24} sm={8} md={6} className="gutter-row">
                      <CancelBtn onClick={this.props.onCancel} />
                    </Col>
                    <Col xs={24} sm={8} md={6} className="gutter-row">
                      <SaveBtn
                        onClick={handleSubmit}
                        loading={this.props.isLoading}
                        buttonText="Assign"
                        isDisabled={values.OffenderManagerId === null || values.NominalId.length === 0}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Form>
        )}}
      </Formik>
    );
  }
}

BulkTransferNominalForm.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  isLoading: PropTypes.bool,
  selectedNominals: PropTypes.arrayOf(PropTypes.number),
  OffenderManagerId: PropTypes.number,
};

BulkTransferNominalForm.defaultProps = {
  onCancel: () => {},
  onSubmit: () => {},
  isLoading: false,
  selectedNominals: [],
  OffenderManagerId: null,
};

export default BulkTransferNominalForm;
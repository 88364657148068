import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import { Modal, Row, Col } from "antd";

import { ForceForm } from "../forms";
import actions from "../actions";

class ForceEditModal extends React.Component {
  CloseForceModal = () => {
    this.props.dispatch(actions.closeForceModal());
    this.props.refreshGrid();
  }

  ForceUpdate = (data) => {
    this.props.dispatch(actions.updateForce(data, this.CloseForceModal));
  }
  
  render() {
    return (
      <Modal title="Edit Force" visible={this.props.show} onCancel={this.CloseForceModal} footer={null} width={500} destroyOnClose>
        <div>
          <Row>
            <Col span={24}>              
              <ForceForm onCancel={this.CloseForceModal} onSubmit={this.ForceUpdate} isLoading={this.props.loading} {...this.props.force} />
            </Col>
          </Row>
        </div>
      </Modal>
    );
  }
}

ForceEditModal.propTypes = {
  dispatch: PropTypes.func,
  refreshGrid: PropTypes.func,
  show: PropTypes.bool,
  loading: PropTypes.bool,
  force: PropTypes.shape({}),
};

ForceEditModal.defaultProps = {
  dispatch: () => {},
  refreshGrid: () => {},
  show: false,
  loading: false,
  force: {},
};

function mapStateToProps(state) {
  const { loading, force } = state.Organisation;
  const { show } = state.Organisation.ForceModal;

  return { show, loading, force };
}

const connectedForceEditModal = connect(mapStateToProps)(ForceEditModal);

export { connectedForceEditModal as default };
